export const checkInventory = function(items, callback){

  var itemsToCheck = {};
  if (Array.isArray(items)) {
    items.forEach((item) => {
      const { code, qty } = item;
      if (!code) {
        ['cap', 'hood', 'gown', 'gift'].forEach(type => {
          if (item[type]) {
            if(itemsToCheck.hasOwnProperty(item[type])){
              itemsToCheck[item[type]] += Number(qty);
            }
            else {
              itemsToCheck[item[type]] = Number(qty);
            }
          }
        })
      } else {
        itemsToCheck[code] = qty;
      }
    })
  } else {
    const { code, qty } = items;
    itemsToCheck[code] = qty;
  };

  fetch('/api/inv/checkOrder', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(itemsToCheck)})
    .then(response => response.json())
    .then(dbResponse => {
      const fails = [];
      if (dbResponse.length === 0) {
        // the case of no gift items
        fails.push({
          code : items.code,
          description: 'your selected item',
          maxQty : 0
        });
        return callback(fails);
      }

      dbResponse.forEach(function (item) {
        if (item.qty < itemsToCheck[item.code]) {
          fails.push({
            code: item.code,
            description: item.description,
            maxQty: item.qty
          });
        }
      });

      const checkedItemCodes = Object.keys(itemsToCheck);
      if (dbResponse.length < checkedItemCodes.length) {
        // some items do not exist in inventory
        if (checkedItemCodes.some(code => code.indexOf('FRA') !== -1)) {
          fails.push({
            code : checkedItemCodes[0],
            description : 'your selected frame',
            maxQty : 0
          });
          // Frames quantity inadequate
        } else {
          const missingItemCode = checkedItemCodes.filter(code => {
            const exists = dbResponse.find(found => {
              return found.code === code;
            })
            if (!exists) {
              return true;
            }
          })
          fails.push({
            code: missingItemCode[0],
            description: 'your selected item(s)',
            maxQty: 0
          });
        }

      }
      callback(fails);
    })
    .catch(error => console.log('error!', error))
};
