import React, { useState, useEffect, } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormLabel from '@material-ui/core/FormLabel';
import { Products } from '../../sources/products';

const useStyles = makeStyles(() => ({
  formControl: {
    margin: '12px 0',
    width: '100%',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
  }
}));

const Gown = ({ level, phdStyle, setSelectedProducts, selectedProducts }) => {
  const classes = useStyles();
  let location = useLocation();
  const bachelorGownCodes = ["GOW10001", "GOW10002", "GOW10003", "GOW10004", "GOW10005"];
  const economyBachelorGownCodes = ["GOW00001", "GOW00002", "GOW00003", "GOW00004", "GOW00005"];
  const [selections, setSelections] = useState(bachelorGownCodes);
  const [selectedValue, setSelectedValue] = useState(selectedProducts.gown);
  const degreeLevel = Products[location.pathname].level || level;
  const gownSubtype = Products[location.pathname]?.gownSubtype ?? null
  const initialValue = gownSubtype === 'economy'
    ? economyBachelorGownCodes[2]
    : bachelorGownCodes[2]

  useEffect(() => {
    if (degreeLevel === "Bachelor" && gownSubtype === 'economy') {
      setSelections(economyBachelorGownCodes)
    } else if(degreeLevel === "Bachelor" && !gownSubtype) {
      setSelections(bachelorGownCodes);
    }
    else if(degreeLevel === 'Master') {
      setSelections([
        'GOW00011',
        'GOW00012',
        'GOW00013',
        'GOW00014',
        'GOW00015'
      ]);
    }
    else if(degreeLevel === 'PhD') {
      switch(phdStyle){
        case 'cloth':
          setSelections([
            'GOW00021',
            'GOW00022',
            'GOW00023',
            'GOW00024',
            'GOW00025']);
        break;

        default:
          setSelections([
            'GOW00031',
            'GOW00032',
            'GOW00033',
            'GOW00034',
            'GOW00035'
          ]);
      }
    }

    if (!selectedValue) {
      setSelectedValue(initialValue);
    } else {
      setSelectedValue(selectedValue)
    }
  }, [location, level])

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setSelectedProducts({ ...selectedProducts, gown: event.target.value });
  }

  // const handleSelectType = (event) => {
  //   setSelectedGownType(event.target.value)
  //   if (event.target.value === 'premium') {
  //     setGown(`GOW1${selectedValue.slice(4)}`);
  //   }
  // }

  return (
    <div className="gown-size">
      <h3>Choose your Gown Size</h3>
      {/* <FormControl
        className={classes.formControl}
        component="fieldset"
      >
        <FormLabel component="legend">Gown Type Selection</FormLabel>
        <RadioGroup
          className={classes.radioGroup}
          name="gownType"
          value={selectedGownType}
          onChange={handleSelectType}
        >
          <FormControlLabel value="economy" control={<Radio />} label="Economy" />
          <FormControlLabel value="premium" control={<Radio />} label="Premium" />
        </RadioGroup>
        <FormHelperText>We offer the economy gown type which is less opaque with thinner fabric. Please consult your degree office before opting to select the economy option</FormHelperText>
        <FormLabel component="legend">Gown Size Selection</FormLabel>
      </FormControl> */}
      <FormControl className={classes.formControl} component="fieldset">
        <FormHelperText className={classes.fullWidth}>Please choose according to your height</FormHelperText>
        <Select
          labelId="gownSize"
          value={selectedValue}
          onChange={handleChange}
        >
          <MenuItem value={selections && selections[0]}>Extra Small (Less than 158cm)</MenuItem>
          <MenuItem value={selections && selections[1]}>Small (159 - 165cms)</MenuItem>
          <MenuItem value={selections && selections[2]}>Medium (166 - 172cms)</MenuItem>
          <MenuItem value={selections && selections[3]}>Large (173 - 179cms)</MenuItem>
          <MenuItem value={selections && selections[4]}>Extra Large (Greater than 180cms)</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default Gown;
