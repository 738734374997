import React, { Component } from 'react';

class Inventory extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const handleSubmit = (id) => {
      var productReq = new XMLHttpRequest();

      productReq.addEventListener('load', function(){
        var response = JSON.parse(this.response);
      });

      productReq.open("POST", "/api/adminapi/products/delete");
      productReq.setRequestHeader('Content-Type', 'application/json');
      productReq.send(JSON.stringify({id : id}));
    };

    let inventory = this.props.inventory.sort(function(a,b){

        return a.code.localeCompare(b.code);

    });

    const showTable = inventory.map((element) => (
      <tr key={element.id}>
        <td>{element.code}</td>
        <td>{element.description}</td>
        <td>{element.qty}</td>
        <td><button onClick={ () =>handleSubmit(element.id)}>X</button></td>
      </tr>
    ))

    return (
      <div className="inventory">
        <h1>Inventory</h1>
        <table>
          <thead>
            <tr>
              <th>Item Code</th>
              <th>Description</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {showTable}
          </tbody>
        </table>
      </div>
    );

  }

}

export default Inventory;