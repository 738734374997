const {
  BACHELOR_GOWN_CAP_HOOD,
  BACHELOR_GOWN_CAP,
  BACHELOR_GOWN_CAP_ECONOMY,
  BACHELOR_GOWN,
  BACHELOR_GOWN_ECONOMY,
  MASTER_GOWN_CAP_HOOD,
  MASTER_GOWN_CAP,
  MASTER_GOWN,
  HOOD,
  CAP,
} = require('../../constants')


export const Products = {
//Bachelor cap and gown set, no hood

"/academic-dress/bachelor-gown-set" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/bach-cap-gown-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165139/Regalia/bach-cap-gown-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/bach-cap-gown-back.jpg"],
  "metaTag" : "Buy your University bachelor style graduation gown and cap set from Gowning Street!",
  "altTags" : ["University Bachelor cap and gown set - Front view", "University Bachelor cap and gown set - Side view", "University Bachelor cap and gown set - Back view"],
  "name" : "Graduation Gown Set - Bachelor Style (Premium, B1)",
  "price" : BACHELOR_GOWN_CAP,
  "show" : ["gown", "cap"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : null,
  "productType" : "bachelor-gown-and-cap"
  },

  "/academic-dress/bachelor-gown-set-economy" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bachelor-cap-gown-set.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165140/Regalia/mast-cap-gown-side.jpg"],
    "metaTag" : "Buy your University bachelor style graduation gown and cap set from Gowning Street!",
    "altTags" : ["University Bachelor cap and gown set - Front view", "University Bachelor cap and gown set - Side view", "University Bachelor cap and gown set - Back view"],
    "name" : "Graduation Gown Set - Bachelor Style (Standard, B1)",
    "price" : BACHELOR_GOWN_CAP_ECONOMY,
    "show" : ["gown", "cap"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : null,
    "productType" : "bachelor-gown-and-cap",
    "gownSubtype" : "economy",
  },

//master cap and gown set, no hood

"/academic-dress/master-gown-set" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165141/Regalia/mast-cap-gown-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165140/Regalia/mast-cap-gown-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165141/Regalia/mast-cap-gown-back.jpg"],
  "metaTag" : "Buy your University master style graduation gown and cap set from Gowning Street!",
  "altTags" : ["University Master cap and gown set - Front view", "University Master cap and gown set - Side view", "University Master cap and gown set - Back view"],
  "name" : "Graduation Gown Set - Master Style",
  "price" : MASTER_GOWN_CAP,
  "show" : ["gown", "cap"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : null,
  "productType" : "master-gown-and-cap"
  },

  //Economy Bachelor gown only
  "/academic-dress/bachelor-gown-economy" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165130/Regalia/bachelor-gown.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165129/Regalia/master-gown.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007985/Aus-Uni-Photos/General/mast-gown-back.jpg"],
    "metaTag" : "Buy your University bachelor style graduation gown from Gowning Street!",
    "altTags" : ["University Bachelor gown - Front view", "University Bachelor gown set - Side view", "University Bachelor gown set - Back view"],
    "name" : "Graduation Gown - Bachelor Style (Standard, B1)",
    "price" : BACHELOR_GOWN_ECONOMY,
    "show" : ["gown"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Bachelor",
    "hood-code" : null,
    "productType" : "bachelor-gown",
    "gownSubtype" : "economy",
  },

  //Bachelor gown only
  "/academic-dress/bachelor-gown" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/bach-gown-back.jpg"],
    "metaTag" : "Buy your University bachelor style graduation gown from Gowning Street!",
    "altTags" : ["University Bachelor gown - Front view", "University Bachelor gown set - Side view", "University Bachelor gown set - Back view"],
    "name" : "Graduation Gown - Bachelor Style (Premium, B1)",
    "price" : BACHELOR_GOWN,
    "show" : ["gown"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Bachelor",
    "hood-code" : null,
    "productType" : "bachelor-gown"
  },

  //master gown only
  "/academic-dress/master-gown" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Regalia/mast-gown-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165140/Regalia/mast-cap-gown-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/mast-gown-back.jpg"],
    "metaTag" : "Buy your master style graduation gown from Gowning Street!",
    "altTags" : ["Master gown set - Front view", "Master gown set - Side view", "Master gown set - Back view"],
    "name" : "Graduation Gown - Master Style",
    "price" : MASTER_GOWN,
    "show" : ["gown"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Master",
    "hood-code" : null,
    "productType" : "master-gown"
  },

  //Cap only
  "/academic-dress/graduation-cap" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1557666353/Regalia/graduation-cap-1.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1557666354/Regalia/graduation-cap-2.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1557666353/Regalia/graduation-cap-3.jpg"],
    "metaTag" : "Buy your graduation cap from Gowning Street! Get any size of Graduation Cap at the very best prices. Browse graduation caps for school or university graduation.",
    "titleTag" : "Graduation Cap | Gowning Street Graduation Regalia in the UK",
    "altTags" : ["Graduation Cap - Front view", "Graduation Cap - Side view", "Graduation Cap - Back view"],
    "name" : "Graduation Cap",
    "price" : CAP,
    "show" : ["cap"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Bachelor",
    "hood-code" : null,
    "productType" : "bachelor-cap"
  },


  //Gifts
  //!! Remember to update cart-items.js if the below are ever updated
  "/academic-dress/graduation-teddy-bear" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165134/Regalia/graduation-teddy-bear-1.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165133/Regalia/graduation-teddy-bear-2.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165134/Regalia/graduation-teddy-bear-3.jpg"],
    "metaTag" : "Add a graduation teddy bear to your graduation gown set for the full, memorable experience, or buy one as a gift for a friend!",
    "titleTag" : "Graduation Teddy Bear | Gowning Street Graduation Gifts in the UK",
    "altTags" : ["Graduation Teddy Bear - Front view", "Graduation Teddy Bear - Side view", "Graduation Teddy Bear - Back view"],
    "name" : "Graduation Teddy Bear",
    "price" : 10,
    "show" : ["gift"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Bachelor",
    "hood-code" : "TED00001",
    "productType" : "gift",
    "giftDescription" : "The perfect gift to congratulate that special someone on their  graduation day. Soft, cuddly and smart, this teddy bear is guaranteed to make graduation day unforgettable!"
  },

  "/academic-dress/graduation-puppy-large" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165131/Regalia/graduation-puppy-smooth-1.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165132/Regalia/graduation-puppy-smooth-3.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165134/Regalia/graduation-puppy-smooth-2.jpg"],
    "metaTag" : "Add a graduation puppy to your graduation gown set for the full, memorable experience, or buy one as a gift for a friend!",
    "titleTag" : "Graduation Puppy - Large | Gowning Street Graduation Gifts in the UK",
    "altTags" : ["Graduation Puppy Large - Front view", "Graduation Puppy Large - Side view", "Graduation Puppy Large - Back view"],
    "name" : "Graduation Puppy - Large",
    "price" : 14,
    "show" : ["gift"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Bachelor",
    "hood-code" : "PUP00001",
    "productType" : "gift",
    "giftDescription" : "The perfect gift to congratulate that special someone on their  graduation day. Soft, cuddly and smart, this puppy is guaranteed to make graduation day unforgettable!"
  },

  "/academic-dress/graduation-puppy-small" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165132/Regalia/graduation-puppy-furry-1.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165135/Regalia/graduation-puppy-furry-2.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165135/Regalia/graduation-puppy-furry-3.jpg"],
    "metaTag" : "Add a graduation puppy to your graduation gown set for the full, memorable experience, or buy one as a gift for a friend!",
    "titleTag" : "Graduation Puppy | Gowning Street Graduation Gifts in the UK",
    "altTags" : ["Graduation Puppy Small - Front view", "Graduation Puppy Small - Side view", "Graduation Puppy Small - Back view"],
    "name" : "Graduation Puppy - Small",
    "price" : 10,
    "show" : ["gift"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Bachelor",
    "hood-code" : "PUP00002",
    "productType" : "gift",
    "giftDescription" : "The perfect gift to congratulate that special someone on their  graduation day. Soft, cuddly and smart, this puppy is guaranteed to make graduation day unforgettable!"
  },

  // Full University Set
  "/graduation-gown/leeds-beckett-university/bachelor-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1637449350/UK-Uni-Photos/Leeds%20Beckett/leedsb-bach-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637449350/UK-Uni-Photos/Leeds%20Beckett/leedsb-bach-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637449350/UK-Uni-Photos/Leeds%20Beckett/leedsb-bach-all-back.jpg"],
    "metaTag" : "Buy your Leeds Beckett University graduation gown set for all bachelor degrees with Gowning Street",
    "altTags" : ["Leeds Beckett University graduation gown set - All Bachelor Degrees - Front view", "Leeds Beckett University graduation gown set - All Bachelor Degrees - Side view", "Leeds Beckett University graduation gown set - All Bachelor Degrees - Back view"],
    "name" : "Leeds Beckett University Graduation Gown Set - All Bachelor Degrees",
    "price" : BACHELOR_GOWN_CAP_HOOD,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Leeds Beckett University Hood - All Bachelor Degrees",
    "hood-style" : "Blue and yellow hood",
    "colour" : "Blue and yellow",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOOD LEEDSB BACH",
    "productType" : "bachelor-full-set",
    "colors" : ["yellow", "blue"]
  },

  "/graduation-gown/bournemouth-university/bachelor-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1637449010/UK-Uni-Photos/Bournemouth/bmouth-bach-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637449010/UK-Uni-Photos/Bournemouth/bmouth-bach-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637449010/UK-Uni-Photos/Bournemouth/bmouth-bach-all-back.jpg"],
    "metaTag" : "Buy your Bournemouth University graduation gown set for all bachelor degrees with Gowning Street",
    "altTags" : ["Bournemouth University graduation gown set - All Bachelor Degrees - Front view", "Bournemouth University graduation gown set - All Bachelor Degrees - Side view", "Bournemouth University graduation gown set - All Bachelor Degrees - Back view"],
    "name" : "Bournemouth University Graduation Gown Set - All Bachelor Degrees",
    "price" : BACHELOR_GOWN_CAP_HOOD,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Bournemouth University Hood - All Bachelor Degrees",
    "hood-style" : "University Blue, part lined in University gold with top edge trimmed in white",
    "colour" : "Blue, gold, trimmed in white",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOOD BMOUTH BACH",
    "productType" : "bachelor-full-set",
    "colors" : ["yellow", "blue"]
  },

  "/graduation-gown/university-of-the-arts-london/bachelor-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1637448543/UK-Uni-Photos/UAL/ual-bach-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637448543/UK-Uni-Photos/UAL/ual-bach-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637448543/UK-Uni-Photos/UAL/ual-bach-all-back.jpg"],
    "metaTag" : "Buy your University of the Arts London (UAL) graduation gown set for all bachelor degrees with Gowning Street",
    "altTags" : ["University of the Arts London (UAL) graduation gown set - All Bachelor Degrees - Front view", "University of the Arts London (UAL) graduation gown set - All Bachelor Degrees - Side view", "University of the Arts London (UAL) graduation gown set - All Bachelor Degrees - Back view"],
    "name" : "University of the Arts London (UAL) Graduation Gown Set - All Bachelor Degrees",
    "price" : BACHELOR_GOWN_CAP_HOOD,
    "show" : ["gown", "cap", "hood"],
    "hood" : "UAL Hood - All Bachelor Degrees",
    "hood-style" : "Black hood, with magenta lining and purple trim",
    "colour" : "Magenta lining and purple trim",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOOD UAL BACH",
    "productType" : "bachelor-full-set",
    "colors" : ["#FF00FF","purple"]
  },

  "/graduation-gown/canterbury-christ-church-university/bachelor-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1637448755/UK-Uni-Photos/CCC%20Uni/ccc-bach-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637448755/UK-Uni-Photos/CCC%20Uni/ccc-bach-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637448755/UK-Uni-Photos/CCC%20Uni/ccc-bach-all-back.jpg"],
    "metaTag" : "Buy your Canterbury Christ Church University graduation gown set for all bachelor degrees with Gowning Street",
    "altTags" : ["Canterbury Christ Church University graduation gown set - All Bachelor Degrees - Front view", "Canterbury Christ Church University graduation gown set - All Bachelor Degrees - Side view", "Canterbury Christ Church University graduation gown set - All Bachelor Degrees - Back view"],
    "name" : "Canterbury Christ Church University Graduation Gown Set - All Bachelor Degrees",
    "price" : BACHELOR_GOWN_CAP_HOOD,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Canterbury Christ Church University Hood - All Bachelor Degrees",
    "hood-style" : "Cardinal red and purple hood",
    "colour" : "Cardinal red and purple",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOOD CCCU BACH",
    "productType" : "bachelor-full-set",
    "colors" : ["#c41e3a","#2E0854"]
  },

  "/graduation-gown/edinburgh-napier-university/bachelor-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1637449540/UK-Uni-Photos/Edinburgh%20Napier/enap-bach-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637449540/UK-Uni-Photos/Edinburgh%20Napier/enap-bach-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637449540/UK-Uni-Photos/Edinburgh%20Napier/enap-bach-all-back.jpg"],
    "metaTag" : "Buy your Edinburgh Napier University graduation gown set for all bachelor degrees with Gowning Street",
    "altTags" : ["Edinburgh Napier University graduation gown set - All Bachelor Degrees - Front view", "Edinburgh Napier University graduation gown set - All Bachelor Degrees - Side view", "Edinburgh Napier University graduation gown set - All Bachelor Degrees - Back view"],
    "name" : "Edinburgh Napier University Graduation Gown Set - All Bachelor Degrees",
    "price" : BACHELOR_GOWN_CAP_HOOD,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Edinburgh Napier University Hood - All Bachelor Degrees",
    "hood-style" : "Lined in red silk",
    "colour" : "Red",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOOD ENAP BACH",
    "productType" : "bachelor-full-set",
    "colors" : ["#c41e3a"]
  },

  "/graduation-gown/keele-university/bachelor-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1637449459/UK-Uni-Photos/Keele/keele-bach-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637449459/UK-Uni-Photos/Keele/keele-bach-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637449459/UK-Uni-Photos/Keele/keele-bach-all-back.jpg"],
    "metaTag" : "Buy your Keele University graduation gown set for all bachelor degrees with Gowning Street",
    "altTags" : ["Keele University graduation gown set - All Bachelor Degrees - Front view", "Keele University graduation gown set - All Bachelor Degrees - Side view", "Keele University graduation gown set - All Bachelor Degrees - Back view"],
    "name" : "Keele University Graduation Gown Set - All Bachelor Degrees",
    "price" : BACHELOR_GOWN_CAP_HOOD,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Keele University Hood - All Bachelor Degrees",
    "hood-style" : "Lined in yellow with a red trim",
    "colour" : "Yellow with red trim",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOOD KEELE BACH",
    "productType" : "bachelor-full-set",
    "colors" : ["yellow","red"]
  },

  "/graduation-gown/london-metropolitan-university/bachelor-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1637449233/UK-Uni-Photos/London%20Met/lmet-bach-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637449233/UK-Uni-Photos/London%20Met/lmet-bach-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637449233/UK-Uni-Photos/London%20Met/lmet-bach-all-back.jpg"],
    "metaTag" : "Buy your London Metropolitan University (London Met Uni) graduation gown set for all bachelor degrees with Gowning Street",
    "altTags" : ["London Metropolitan University graduation gown set - All Bachelor Degrees - Front view", "London Metropolitan University graduation gown set - All Bachelor Degrees - Side view", "London Metropolitan University graduation gown set - All Bachelor Degrees - Back view"],
    "name" : "London Metropolitan University Graduation Gown Set - All Bachelor Degrees",
    "price" : BACHELOR_GOWN_CAP_HOOD,
    "show" : ["gown", "cap", "hood"],
    "hood" : "London Metropolitan University Hood - All Bachelor Degrees",
    "hood-style" : "Violet and grey hood",
    "colour" : "Violet and grey",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOOD LMET BACH",
    "productType" : "bachelor-full-set",
    "colors" : ["#4B0082"," #A9A9A9"]
  },

  "/graduation-gown/teesside-university/bachelor-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1637449412/UK-Uni-Photos/Teesside/teesside-bach-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637449412/UK-Uni-Photos/Teesside/teesside-bach-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637449412/UK-Uni-Photos/Teesside/teesside-bach-all-back.jpg"],
    "metaTag" : "Buy your Teesside University graduation gown set for all bachelor degrees with Gowning Street",
    "altTags" : ["Teesside University graduation gown set - All Bachelor Degrees - Front view", "Teesside University graduation gown set - All Bachelor Degrees - Side view", "Teesside University graduation gown set - All Bachelor Degrees - Back view"],
    "name" : "Teesside University Graduation Gown Set - All Bachelor Degrees",
    "price" : BACHELOR_GOWN_CAP_HOOD,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Teesside University Hood - All Bachelor Degrees",
    "hood-style" : "Black hood, lined and bound in red, trimmed in grey",
    "colour" : "Red",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOOD TEESSIDE BACH",
    "productType" : "bachelor-full-set",
    "colors" : ["#B22222"," #DCDCDC"]
  },

  "/graduation-gown/university-of-bedfordshire/bachelor-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1637449102/UK-Uni-Photos/Bedfordshire/bedfordshire-bach-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637449102/UK-Uni-Photos/Bedfordshire/bedfordshire-bach-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637449103/UK-Uni-Photos/Bedfordshire/bedfordshire-bach-all-back.jpg"],
    "metaTag" : "Buy your University of Bedfordshire graduation gown set for all bachelor degrees with Gowning Street",
    "altTags" : ["University of Bedfordshire graduation gown set - All Bachelor Degrees - Front view", "University of Bedfordshire graduation gown set - All Bachelor Degrees - Side view", "University of Bedfordshire graduation gown set - All Bachelor Degrees - Back view"],
    "name" : "University of Bedfordshire Graduation Gown Set - All Bachelor Degrees",
    "price" : BACHELOR_GOWN_CAP_HOOD,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Bedfordshire Hood - All Bachelor Degrees",
    "hood-style" : "Royal Blue hood, lined in red and white",
    "colour" : "Red",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOOD BEDFD BACH",
    "productType" : "bachelor-full-set",
    "colors" : ["#DC143C","white"]
  },

  "/graduation-gown/derby-university/bachelor-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1637449055/UK-Uni-Photos/Derby/derby-bach-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637449055/UK-Uni-Photos/Derby/derby-bach-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637449055/UK-Uni-Photos/Derby/derby-bach-all-back.jpg"],
    "metaTag" : "Buy your University of Derby graduation gown set for all bachelor degrees with Gowning Street",
    "altTags" : ["University of Derby graduation gown set - All Bachelor Degrees - Front view", "University of Derby graduation gown set - All Bachelor Degrees - Side view", "University of Derby graduation gown set - All Bachelor Degrees - Back view"],
    "name" : "University of Derby Graduation Gown Set - All Bachelor Degrees",
    "price" : BACHELOR_GOWN_CAP_HOOD,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Derby Hood - All Bachelor Degrees",
    "hood-style" : "Black hood, lined with scarlet and light blue",
    "colour" : "Scarlet and light blue",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOOD DERBY BACH",
    "productType" : "bachelor-full-set",
    "colors" : ["#b31900","#87CEFA"]
  },

  "/graduation-gown/gloucestershire-university/bachelor-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1637448348/UK-Uni-Photos/Gloucester/glouc-bach-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637448348/UK-Uni-Photos/Gloucester/glouc-bach-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637448348/UK-Uni-Photos/Gloucester/glouc-bach-all-back.jpg"],
    "metaTag" : "Buy your University of Gloucestershire graduation gown set for all bachelor degrees with Gowning Street",
    "altTags" : ["University of Gloucestershire graduation gown set - All Bachelor Degrees - Front view", "University of Gloucestershire graduation gown set - All Bachelor Degrees - Side view", "University of Gloucestershire graduation gown set - All Bachelor Degrees - Back view"],
    "name" : "University of Gloucestershire Graduation Gown Set - All Bachelor Degrees",
    "price" : BACHELOR_GOWN_CAP_HOOD,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Gloucestershire Hood - All Bachelor Degrees",
    "hood-style" : "Blue hood, lined with red, with white border",
    "colour" : "Blue and red with white border",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOOD GLOUC BACH",
    "productType" : "bachelor-full-set",
    "colors" : ["#0000CD","#B22222"]
  },

  "/graduation-gown/lincoln-university/bachelor-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1637448902/UK-Uni-Photos/Lincoln/lincoln-bach-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637448902/UK-Uni-Photos/Lincoln/lincoln-bach-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637448903/UK-Uni-Photos/Lincoln/lincoln-bach-all-back.jpg"],
    "metaTag" : "Buy your University of Lincoln graduation gown set for all bachelor degrees with Gowning Street",
    "altTags" : ["University of Lincoln graduation gown set - All Bachelor Degrees - Front view", "University of Lincoln graduation gown set - All Bachelor Degrees - Side view", "University of Lincoln graduation gown set - All Bachelor Degrees - Back view"],
    "name" : "University of Lincoln Graduation Gown Set - All Bachelor Degrees",
    "price" : BACHELOR_GOWN_CAP_HOOD,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Lincoln Hood - All Bachelor Degrees",
    "hood-style" : "Black hood, lined with blue satin, with bright yellow ribbon trim",
    "colour" : "Blue and bright yellow",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOOD LINC BACH",
    "productType" : "bachelor-full-set",
    "colors" : ["blue", "#fff700"]
  },

  "/graduation-gown/university-of-salford/bachelor-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1637449169/UK-Uni-Photos/Salford/salford-bach-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637449169/UK-Uni-Photos/Salford/salford-bach-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637449169/UK-Uni-Photos/Salford/salford-bach-all-back.jpg"],
    "metaTag" : "Buy your University of Salford graduation gown set for all bachelor degrees with Gowning Street",
    "altTags" : ["University of Salford graduation gown set - All Bachelor Degrees - Front view", "University of Salford graduation gown set - All Bachelor Degrees - Side view", "University of Salford graduation gown set - All Bachelor Degrees - Back view"],
    "name" : "University of Salford Graduation Gown Set - All Bachelor Degrees",
    "price" : BACHELOR_GOWN_CAP_HOOD,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Salford Hood - All Bachelor Degrees",
    "hood-style" : "Blue hood, fully lined with gold satin, and edged with grey silk",
    "colour" : "Blue hood with gold and grey",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOOD SALF BACH",
    "productType" : "bachelor-full-set",
    "colors" : ["#FFD700","grey"]
  },

  "/graduation-gown/plymouth-marjon-university/bachelor-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1637448625/UK-Uni-Photos/Plymouth%20Marjon/pmouthmj-bach-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637448625/UK-Uni-Photos/Plymouth%20Marjon/pmouthmj-bach-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637448625/UK-Uni-Photos/Plymouth%20Marjon/pmouthmj-bach-all-back.jpg"],
    "metaTag" : "Buy your Plymouth Marjon University graduation gown set for all bachelor degrees with Gowning Street",
    "altTags" : ["Plymouth Marjon University graduation gown set - All Bachelor Degrees - Front view", "Plymouth Marjon University graduation gown set - All Bachelor Degrees - Side view", "Plymouth Marjon University graduation gown set - All Bachelor Degrees - Back view"],
    "name" : "Plymouth Marjon University Graduation Gown Set - All Bachelor Degrees",
    "price" : BACHELOR_GOWN_CAP_HOOD,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Plymouth Marjon University Hood - All Bachelor Degrees",
    "hood-style" : "Black hood, lined with maroon",
    "colour" : "Maroon",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOOD PMOUTH BACH",
    "productType" : "bachelor-full-set",
    "colors" : ["#800000"]
  },

  "/graduation-gown/west-london-university/bachelor-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1637448833/UK-Uni-Photos/West%20London/westlon-bach-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637448833/UK-Uni-Photos/West%20London/westlon-bach-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637448833/UK-Uni-Photos/West%20London/westlon-bach-all-back.jpg"],
    "metaTag" : "Buy your University of West London graduation gown set for all bachelor degrees with Gowning Street",
    "altTags" : ["University of West London graduation gown set - All Bachelor Degrees - Front view", "University of West London graduation gown set - All Bachelor Degrees - Side view", "University of West London graduation gown set - All Bachelor Degrees - Back view"],
    "name" : "University of West London Graduation Gown Set - All Bachelor Degrees",
    "price" : BACHELOR_GOWN_CAP_HOOD,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of West London Hood - All Bachelor Degrees",
    "hood-style" : "Blue hood, lined with grey",
    "colour" : "Blue and grey",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOOD WESTLON BACH",
    "productType" : "bachelor-full-set",
    "colors" : ["blue","grey"]
  },

  "/graduation-gown/westminster-university/bachelor-of-arts" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1637449296/UK-Uni-Photos/Westminster/westmin-bach-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637449296/UK-Uni-Photos/Westminster/westmin-bach-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637449296/UK-Uni-Photos/Westminster/westmin-bach-arts-back.jpg"],
    "metaTag" : "Buy your University of Westminster graduation gown set for Bachelor of Arts with Gowning Street",
    "altTags" : ["University of Westminster graduation gown set - Bachelor of Arts - Front view", "University of Westminster graduation gown set - Bachelor of Arts - Side view", "University of Westminster graduation gown set - Bachelor of Arts - Back view"],
    "name" : "University of Westminster Graduation Gown Set - Bachelor of Arts",
    "price" : BACHELOR_GOWN_CAP_HOOD,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Westminster Hood - Bachelor of Arts",
    "hood-style" : "Black hood, lined with claret",
    "colour" : "Claret",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOOD WESTMIN BARTS",
    "productType" : "bachelor-full-set",
    "colors" : ["#811331"]
  },

  "/graduation-gown/westminster-university/bachelor-of-science" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008626/Aus-Uni-Photos/UTAS/utas-mast-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008627/Aus-Uni-Photos/UTAS/utas-mast-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008625/Aus-Uni-Photos/UTAS/utas-mast-sci-back.jpg"],
    "metaTag" : "Buy your University of Westminster graduation gown set for Bachelor of Science with Gowning Street",
    "altTags" : ["University of Westminster graduation gown set - Bachelor of Science - Front view", "University of Westminster graduation gown set - Bachelor of Science - Side view", "University of Westminster graduation gown set - Bachelor of Science - Back view"],
    "name" : "University of Westminster Graduation Gown Set - Bachelor of Science",
    "price" : BACHELOR_GOWN_CAP_HOOD,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Westminster Hood - Bachelor of Science",
    "hood-style" : "Black hood, lined with silver-grey",
    "colour" : "Silver Grey",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOOD WESTMIN BSCI",
    "productType" : "bachelor-full-set",
    "colors" : ["white"]
  },

  "/graduation-gown/worcester-university/bachelor-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1637448493/UK-Uni-Photos/Worcester/worcester-bach-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637448493/UK-Uni-Photos/Worcester/worcester-bach-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637448493/UK-Uni-Photos/Worcester/worcester-bach-all-back.jpg"],
    "metaTag" : "Buy your University of Worcester graduation gown set for all bachelor degrees with Gowning Street",
    "altTags" : ["University of Worcester graduation gown set - All Bachelor Degrees - Front view", "University of Worcester graduation gown set - All Bachelor Degrees - Side view", "University of Worcester graduation gown set - All Bachelor Degrees - Back view"],
    "name" : "University of Worcester Graduation Gown Set - All Bachelor Degrees",
    "price" : BACHELOR_GOWN_CAP_HOOD,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Worcester Hood - All Bachelor Degrees",
    "hood-style" : "Black hood, lined with teal and silver",
    "colour" : "Teal",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOOD WORCESTER BACH",
    "productType" : "bachelor-full-set",
    "colors" : ["#008080","#D3D3D3"]
  },

  "/graduation-gown/lancaster-university/bachelor-of-arts" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1637448694/UK-Uni-Photos/Lancaster/lancester-bach-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637448694/UK-Uni-Photos/Lancaster/lancester-bach-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637448694/UK-Uni-Photos/Lancaster/lancester-bach-arts-back.jpg"],
    "metaTag" : "Buy your Lancaster University graduation gown set for your Bachelor of Arts degree with Gowning Street",
    "altTags" : ["Lancaster University graduation gown set - Bachelor of Arts - Front view", "Lancaster University graduation gown set - Bachelor of Arts - Side view", "Lancaster University graduation gown set - Bachelor of Arts - Back view"],
    "name" : "Lancaster University Graduation Gown Set - Bachelor of Arts",
    "price" : BACHELOR_GOWN_CAP_HOOD,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Lancaster University Hood - Bachelor of Arts",
    "hood-style" : "Black hood, lined with grey and red",
    "colour" : "Red",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOOD LANCASTER B ARTS",
    "productType" : "bachelor-full-set",
    "colors" : ["red"]
  },

  "/graduation-gown/lancaster-university/bachelor-of-science" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1637448955/UK-Uni-Photos/Lancaster/lancaster-bach-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637448955/UK-Uni-Photos/Lancaster/lancaster-bach-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1637448955/UK-Uni-Photos/Lancaster/lancaster-bach-sci-back.jpg"],
    "metaTag" : "Buy your Lancaster University graduation gown set for your Bachelor of Science degree with Gowning Street",
    "altTags" : ["Lancaster University graduation gown set - Bachelor of Science - Front view", "Lancaster University graduation gown set - Bachelor of Science - Side view", "Lancaster University graduation gown set - Bachelor of Science - Back view"],
    "name" : "Lancaster University Graduation Gown Set - Bachelor of Science",
    "price" : BACHELOR_GOWN_CAP_HOOD,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Lancaster University Hood - Bachelor of Science",
    "hood-style" : "Black hood, lined with grey, red and yellow",
    "colour" : "Red and yellow",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOOD LANCASTER B SCI",
    "productType" : "bachelor-full-set",
    "colors" : ["red","yellow"]
  },


  // Legal Wear
  "/legal-wear/solicitors-gown" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-front.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-side.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/bach-gown-back.jpg"],
    "metaTag" : "Our traditionally designed solicitor gowns are made from high quality polyester weave which are great for warmer climates, and feature a flap collar and stiffened yolk. Our Solicitor gowns are suitable for most commonwealth  jurisdictions. ",
    "titleTag" : "Solicitor's Gown for the UK and other Commonwealth Jurisdictions | Order online from £99",
    "altTags" : ["Solicitor's Gown - Front","Solicitor's Gown - Side","Solicitor's Gown - Back"],
    "name" : "Solicitor's Gown",
    "price" : 99,
    "show" : ["gift"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Bachelor",
    "hood-code" : "GOWL0001",
    "productType" : "gift",
    "giftDescription" : "Made from high quality polyester weave, featuring a flap collar and stiffened yolk. Our Solicitor gowns are suitable in the UK and for most commonwealth jurisdictions"
  },

  "/legal-wear/barristers-gown" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-front.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-side.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/bach-gown-back.jpg"],
    "metaTag" : "Our barrister gowns are handcrafted to perfection from premium quality polyester, great for warmer climates and feature a fully fluted back and stiffened yolk. Our barrister robes are suitable for use in commonwealth courtrooms around the world.",
    "titleTag" : "Barrister's Gown for the UK and other Commonwealth Jurisdictions | Order online from £99",
    "altTags" : ["Barrister's Gown - Front","Barrister's Gown - Side","Barrister's Gown - Back"],
    "name" : "Barrister's Gown",
    "price" : 99,
    "show" : ["gift"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Bachelor",
    "hood-code" : "GOWL0002",
    "productType" : "gift",
    "giftDescription" : "Handcrafted to perfection from premium quality polyester, great for warmer climates and feature a fully fluted back and stiffened yolk. Our barrister robes are suitable for use in the UK and other commonwealth courtrooms around the world."
  },

  "/legal-wear/barristers-wig" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Benchers_Wig.png","https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Benchers_Wig.png","https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Benchers_Wig.png"],
    "metaTag" : "Gowning Street Barrister's wigs hand crafted from 100% pure horsehair, designed for comfort",
    "titleTag" : "Barrister's wig for the UK and other Commonwealth Jurisdictions | Order online from £350",
    "altTags" : ["Barrister's Wig - Front","Barrister's Wig - Side","Barrister's Wig - Back"],
    "name" : "Barrister's Wig",
    "price" : 350,
    "show" : ["gift"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Bachelor",
    "hood-code" : "WIG00001",
    "productType" : "gift",
    "giftDescription" : "Barrister's wig hand crafted from 100% pure horsehair, finely stitched for comfort"
  },

  "/legal-wear/benchers-wig" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Benchers_Wig.png","https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Benchers_Wig.png","https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Benchers_Wig.png"],
    "metaTag" : "Our bencher’s wigs are handcrafted from 100% pure horsehair. Each strand is carefully woven to make a beautifully crafted wig providing you a comfortable fit and a made to last product.",
    "titleTag" : "Bencher's wig for the UK and other Commonwealth Jurisdictions | Order online from £899",
    "altTags" : ["Bencher's Wig - Front","Bencher's Wig - Side","Bencher's Wig - Back"],
    "name" : "Bencher's Wig",
    "price" : 899,
    "show" : ["gift"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Bachelor",
    "hood-code" : "WIG00002",
    "productType" : "gift",
    "giftDescription" : "Bencher's wig hand crafted from 100% pure horsehair, finely stitched for comfort"
  },

  "/legal-wear/judges-wig" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Barrister_Wig.png","https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Barrister_Wig.png","https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Barrister_Wig.png"],
    "metaTag" : "Our Judges wigs are beautifully handcrafted from 100% pure horsehair. Each strand is carefully woven to produce a comfortable long lasting product.",
    "titleTag" : "Judge's wig for the UK and other Commonwealth Jurisdictions | Order online from £1,600",
    "altTags" : ["Judge's Wig - Front","Judge's Wig - Side","Judge's Wig - Back"],
    "name" : "Judge's Wig",
    "price" : 1600,
    "show" : ["gift"],
    "hood" : null,
    "hood-style" : null,
    "colour" : null,
    "gownStyle" : "Cambridge/Oxford",
    "level" : "Bachelor",
    "hood-code" : "WIG00003",
    "productType" : "gift",
    "giftDescription" : "Judge's wig hand crafted from 100% pure horsehair, finely stitched for comfort"
  },

};