import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

const Terms = () => (
  <div className="terms-and-conditions">
    <Helmet
      title="Terms and Conditions on purchase of academic dress"
      meta={[
        {"name": "description", "content": "Gowning Street NZ Terms and Conditions on purchase of Academic Dress"},
      ]}
    />
    <div className="tc-content">
      <h1>Terms & Conditions</h1>
      <h2>Delivery Policy</h2>
        <>
          <h4>Order Confirmation:</h4>
          <p>After ordering online, you will receive an email confirmation containing your order details (if you have provided your email address). We will normally confirm receipt of your order within a few minutes of ordering. We will attempt to send your goods via APC  within 1-2 business days. If goods are unavailable we will notify you as soon as possible.</p>
        </>
        <>
          <h4>Shipping Estimates:</h4>
          <p>Gowning Street uses APC as its carrier. Shipping estimates can be found on our shipping estimates page, however it will ultimately be subject to APC. It is your responsibility to order your gown on time to factor in potential delays. Please also note that APC may experience delays in their service. This is out of our control and we therefore take no responsibility for late deliveries resulting from this.</p>
        </>
        <>
          <h4>Delay:</h4>
          <p>Once your order has been dispatched,any delays or loss of parcel must be raised with APC directly. We do not offer refunds for delays caused by APC.
</p>
        </>
      <h2>Refund, Returns & Exchange Policy</h2>
        <>
          <h4>Sale is Final:</h4>
          <p>All sales made by Gowning Street are final. As we are not a hire service, we are unable to offer any returns.</p>
        </>
        <>
          <h4>Usage:</h4>
          <p>The customer acknowledges that the products sold by Gowning Street are intended for graduations/photography/formal events and agrees that they will not use the product for a temporary period and subsequently seek to return it.</p>
        </>
        <>
          <h4>Change of Mind:</h4>
          <p>We understand that circumstances can change. However, Gowning Street does not accept return requests or provide refunds for changes of mind. By making a purchase, customers agree to this condition.</p>
        </>
        <>
          <h4>Defective Products:</h4>
          <p>If the product you receive is defective or incorrect due to our error, please notify us within 48 hours of receipt. The product must be returned in the same condition as received, unused, and in the original packaging. If the defect or error is confirmed by us, we will replace the item or, if a replacement is not available, offer a full refund, including shipping costs.</p>
        </>
        <>
          <h4>Non-returnable items:</h4>
          <p>For clarity, any item purchased from Gowning Street is non-returnable and non-refundable, except in the case of defective or incorrect items as detailed above.</p>
        </>
        <>
          <h4>Acknowledgment of Policy:</h4>
          <p>By placing an order, the customer acknowledges and agrees to be bound by this Return and Refund Policy. Gowning Street will not accept any return or refund requests that are not in line with this policy.</p>
        </>
        <p>Please contact our customer service team at support@gowningstreet.co.uk if you have any queries regarding this policy or any issues with your purchase.</p>
    </div>
  </div>
)

export default Terms;