import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { checkInventory } from './checkInventory';
import Loader from '../custom-items/loader';
import LockSvg from '../../assets/lock.svg'

const StripePayment = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const { customer, order, total, setError, error, history, } = props;
  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null)

  useEffect(() => {
    fetch("/api/charge/getPaymentClientSecret", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ customer, total })
    })
      .then(function (result) {
        return result.json();
      })
      .then(function (data) {
        setClientSecret(data.clientSecret);
      })
  }, []);

  const customerName = `${customer.firstname} ${customer.lastname}`;

  const payWithCard = async () => {
    if (!stripe || !elements) {
      setError({
        errorType: 'stripe',
        errorDetail: null
      });
      return;
    }
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: customerName,
        },
      },
      shipping: {
        name: customerName,
        address: {
          line1: customer.address_line1,
          line2: customer.address_line2,
          city: customer.city,
          postal_code: customer.postcode,
          country: 'gb'
        }
      },
      receipt_email: customer.email,
    });

    if (result.error) {
      setError({
        errorType: 'stripe',
        errorDetail: result.error.message
      });
      setLoading(false);
      return;
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        fetch("/api/charge/postPaymentSuccess", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ customer, order })
        })
          .then(function (result) {
            return result.json();
          })
          .then(function (data) {
            const orderNum = data.orderNum
            if (data.success) {
              setLoading(false);
              history.push("/thankyou?ordernum=" + orderNum);
            } else {
              setError({
                errorType: 'orderError',
                errorDetail: `Sorry! Something went wrong with your order, please send us an email with your name and order number - ${orderNum}`
              });
              setLoading(false);
            }
          })
      }
    }
  }

  const checkInv = () => {
    if (loading === false){
      setLoading(true);
    }
    checkInventory(order, function(fails){
      if(fails.length > 0){
        setError({ errorType: 'inventory', errorDetail: fails });
        setLoading(false);
      }
      else {
        payWithCard();
      }
    });
  }

  const handleSubmit = () => {
    checkInv()
  }

  const CARD_OPTIONS = {
    hidePostalCode: true,
    classes: {
      base: 'stripe-base-style',
      focus: 'stripe-focus-style',
    },
  };

  return(
    <div className="stripe-section">
      <section>
        <CardElement
          className="card"
          iconStyle="solid"
          hidePostalCode={true}
          options={CARD_OPTIONS}
        />
      </section>
      <section>
        <div className="stripe-icons">
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899593/Utility/Visa_Icon.png"/>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899593/Utility/Mastercard_Icon.png"/>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899593/Utility/AMEX_Icon.png"/>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1551600751/Utility/powered_by_stripe.svg"/>
        </div>
      </section>
      <section>
        {loading
          ? <Loader />
          : (
            <button
            className="pay-button"
            disabled={loading || error}
            onClick={handleSubmit}>
              <div className="button-content">
                <img src={LockSvg} />
                <span>{loading ? "Loading..." : "Pay with Stripe"}</span>
              </div>
          </button>
          )
        }
      </section>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    order: state.shoppingCart
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateState: (field, value) => {
      dispatch({
        type: 'UPDATE_CUSTOMER',
        field : field,
        value : value
      })
    }
  }
}


// TODO: Connec this again
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(StripePayment));



