import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const BackgroundImage = styled.div`
  background: linear-gradient( rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15) ), url(https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-jumping.jpg), no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: scroll;
  z-index: -1;
  width: 100%;

  display: flex;
  align-items: center;
`;

const SaveWithFriends = () => {
  return (
    <BackgroundImage className="home_imagebanner">
      <div className="imageoverlaytext">
        <h3>Buy with friends and save up to £10 per gown set</h3>
        <div className="buttons">
          <Link to="/save-with-friends"><button>Learn More</button></Link>
          <Link to="/academic-dress"><button>Shop Now</button></Link>
        </div>
      </div>
    </BackgroundImage>
  )

}

export default SaveWithFriends;




