import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const BackgroundImage = styled.div`
  background: linear-gradient( rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15) ), url(https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg), no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: scroll;
  z-index: -1;
  width: 100%;

  display: flex;
  align-items: center;
`;

const CallToAction = () => {
  return (
    <BackgroundImage className="home_imagebanner">
      <div className="imageoverlaytext">
        <h3>Our state of the art warehousing & logistics  team will ensure your order is dispatched and received on time!</h3>
        <div className="buttons">
          <Link to="/shipping"><button>Learn More</button></Link>
          <Link to="/academic-dress"><button>Shop Now</button></Link>
        </div>
      </div>
    </BackgroundImage>
  )

}

export default CallToAction;




