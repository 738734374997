import React, { Component } from 'react';

class BulkUpload extends Component {
  constructor(props){
    super(props);

    this.state = {
      csv: "",
      message : null
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({csv: event.target.value});
  }

  handleSubmit() {

    var JSONoutput = csvToJson(this.state.csv);

    function csvToJson(str) {
      var data = [], i = 0, k = 0, header = [];
      var csvLines = CSVToArray(str, "\t");

      for ( i = 0; i < csvLines.length; i++ ) {
        var line = csvLines[i];
        if ( i == 0 ) {
          header = csvLines[i];
        } else {
          var obj = {};
          for ( k = 0; k < header.length; k++ ) {
            if ( k < csvLines[i].length ) {
              obj[header[k]] = csvLines[i][k];
            }
          }
          data.push(obj);
        }
      }
      return data;
    }


    // this code was found here http://www.bennadel.com/blog/1504-Ask-Ben-Parsing-CSV-Strings-With-Javascript-Exec-Regular-Expression-Command.htm
    // This will parse a delimited string into an array of
    // arrays. The default delimiter is the comma, but this
    // can be overriden in the second argument.
    function CSVToArray( strData, strDelimiter ){
      // Check to see if the delimiter is defined. If not,
      // then default to comma.
      strDelimiter = (strDelimiter || ",");

      // Create a regular expression to parse the CSV values.
      var objPattern = new RegExp(
          (
           // Delimiters.
           "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

           // Quoted fields.
           "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

           // Standard fields.
           "([^\"\\" + strDelimiter + "\\r\\n]*))"
          ),
          "gi"
          );

      // Create an array to hold our data. Give the array
      // a default empty first row.
      var arrData = [[]];

      // Create an array to hold our individual pattern
      // matching groups.
      var arrMatches = null;


      // Keep looping over the regular expression matches
      // until we can no longer find a match.
      while (arrMatches = objPattern.exec( strData )){

        // Get the delimiter that was found.
        var strMatchedDelimiter = arrMatches[ 1 ];

        // Check to see if the given delimiter has a length
        // (is not the start of string) and if it matches
        // field delimiter. If id does not, then we know
        // that this delimiter is a row delimiter.
        if (
      strMatchedDelimiter.length &&
      (strMatchedDelimiter != strDelimiter)
           ){

          // Since we have reached a new row of data,
          // add an empty row to our data array.
          arrData.push( [] );

        }

        // Now that we have our delimiter out of the way,
        // let's check to see which kind of value we
        // captured (quoted or unquoted).
        if (arrMatches[ 2 ]){

          // We found a quoted value. When we capture
          // this value, unescape any double quotes.
          var strMatchedValue = arrMatches[ 2 ].replace(
        new RegExp( "\"\"", "g" ),
        "\""
        );

        } else {

          // We found a non-quoted value.
          var strMatchedValue = arrMatches[ 3 ];

        }

        // Now that we have our value string, let's add
        // it to the data array.
        arrData[ arrData.length - 1 ].push( strMatchedValue );
      }

      // Return the parsed data.
      return( arrData );
    }


    var that = this;

    var productReq = new XMLHttpRequest();
    productReq.addEventListener('load', function(){
      var response = JSON.parse(this.response);

      var successes = response.successArray.length;
      var fails = ""

      if (response.failArray.length > 0) {

        fails = "Unable to update: ";

        response.failArray.forEach(function(e){
          fails += e + "; "
        })

      }

      if(response.success === true){
        that.setState({
          message : successes + " products updated successfully. " + fails
        })

        that.props.reload();
      }
      else {
        that.setState({
          message : response.err
        })
      }

    })
    productReq.open("POST", "/api/adminapi/bulkupdate");
    productReq.setRequestHeader('Content-Type', 'application/json')
    productReq.send(JSON.stringify({
      bulkjson : JSONoutput
    }));

  }

  render() {
    return (
      <div className="edit-inventory">
        <h1>Bulk Upload</h1>
        <p>Upload CSV comma delimited file below</p>
        <p>...</p>
        <p>
          Click <a href="https://res.cloudinary.com/gowningstreet/raw/upload/v1593172937/Utility/BulkInvUpdate_Template.csv" target="_blank">here</a> for CSV template. Copy paste values into the text input below, with header, no blank lines.
        </p>
        <p>...</p>
        <textarea
          value={this.state.csv}
          onChange={this.handleChange}
        />
        <p>...</p>
        <button onClick={this.handleSubmit}>Submit</button>
        <p>{this.state.message}</p>
      </div>
    )
  }
};

export default BulkUpload;