'use strict';

import React from 'react';
import Helmet from 'react-helmet';

class NotFound extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="notfound">
        <Helmet
          title="Gowning Street - Page Not Found"
          meta={[
            {"name": "description", "content": "Gowning Street UK - page not found"},
          ]}
        />
        <h1>PAGE NOT FOUND</h1>
        <p>Unfortunately, the page you are trying to find is unavailable.</p>
        <p>Try looking for your product in our menu tab.</p>
        <p>If the problem persists, please email support@gowningstreet.co.uk</p>
      </div>
    )
  }
}

export default NotFound