import { css } from 'styled-components';

export const calculateEm = px => {
  const base = 16;
  return `${px / base}em`;
};


export const sizes = {
  desktop: 1025,
  mobile: 480,
  tablet: 1024,
};

export const mediaQueries = Object.keys(sizes).reduce((accumulator, label) => {
  const em = calculateEm(sizes[label]);
  // eslint-disable-next-line no-param-reassign
  accumulator[label] = (...args) => css`
    @media (max-width: ${em}) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});
