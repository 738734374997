import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

const BuyLegalwear = ({name}) => {

  return (
    <div className="shop-products">
      <Helmet
        title="Looking to Purchase A Graduation Gift? Shop at Gowning Street"
        meta={[
          {"name": "description", "content": "We have an Amazing range of Graduation Gifts at great prices! Fast Shipping UK-Wide."},
        ]}
      />
      <h1>Graduation Gifts Online | Fast Delivery UK Wide!</h1>
      <div className="subheading">
        <h2>Make your graduation memorable with a graduation teddy hat, teddy bear or certificate frame!</h2>
      </div>
      <div className="catalogue">
        <div className="img-container">
          <div className="img-box">
            <Link to="/academic-dress/graduation-puppy-small">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165132/Regalia/graduation-puppy-furry-1.jpg" alt="graduation puppy"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Puppy - Small</strong>
                  <p>£10</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/academic-dress/graduation-puppy-large">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165131/Regalia/graduation-puppy-smooth-1.jpg"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Puppy - Large</strong>
                  <p>£14</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/academic-dress/graduation-teddy-bear">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165134/Regalia/graduation-teddy-bear-1.jpg" alt="graduation bear"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Teddy Bear</strong>
                  <p>£10</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/academic-dress/graduation-cap">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1557666353/Regalia/graduation-cap-1.jpg" alt="graduation trencher"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Caps</strong>
                  <p>£28</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/certificate-frame">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1561283320/Frames/Waikato/Waikato_Modern_Maroon.jpg" alt="Degree certificate frame"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Certificate Frames</strong>
                  <p>£28</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
        </div>
        <div className="products-desc">
          <h2>We Have Lovely Graduation Gifts Available</h2>
          <p>Searching for a Graduation Gift? We have you covered,  graduation is such a special time and what better way to celebrate with your special person than giving them a lovely Graduation Gift. You will find we have an excellent range of products. Our team is constantly on the lookout for great grad gift ideas. Moreover, we deliver UK wide and in most cases this takes only 2-3 days to reach you.
          </p>
          <h2>Graduation Presents at Gowning Street</h2>
          <p>If you are wanting to purchase a graduation gift for a loved one. You must be wondering what the ideal grad present is?. Don't worry, we have some ideas for you! Over the many years of our experience , we have found which graduation presents are the most appreciated. Some of these items include our premium crafted pinewood certificate frames and our cuddly graduation teddy bears. We also keep a lookout for new and creative products created by local UK manufacturers.</p>
          <h2>Needing A Graduation Gift for Boys?</h2>
          <p>Graduation gifts for boys, no problem- we have lovely ties and cufflinks that would make a thoughtful graduation gift for him. No doubt, he will look sharp on his graduation day. We also have graduation certificate frames  to preserve his graduation testamur. Don't worry,if the gift is not to his liking, we have an easy and simple returns / exchanges policy, hence you can purchase with confidence at Gowning Street.</p>
          <h2>Wanting A Graduation Gift for Girls?</h2>
          <p>What makes the perfect graduation gift for girls that is the question? She has put in the hard work and this is her momentous occasion. We have some really cool graduation gift ideas for her. Our graduation teddy bears are very popular, they are small, cuddly, super cute and they are an excellent addition to graduation photographs. If you order before noon, we offer same day dispatch and we offer an easy exchange service.</p>
          <h2>We Have Fantastic Uni Graduation Gift Ideas</h2>
          <p>Graduation is such a fun time. It's a great time to celebrate with both friends and family. You might be wondering what makes a good uni graduation gift? Well we have some ideas at Gowning Street our sole focus is on making graduation amazing, with our many years of graduation experience,we have understood what makes the perfect uni graduation gift.  Some of our best sellers are our graduation certificate, graduation teddy bears and of course our adorable graduation puppies.</p>
          <h2>University Graduation Gift Needed?</h2>
          <p>Looking for a university graduation gift idea?  We have done all the hard work and we have sourced and manufactured some great university graduation gift options. Where possible, we also try and incorporate a classic British theme with our grad gifts. Whether you are purchasing for a friend, child or your partner,we have something for everyone. Our products are carefully selected to ensure they make an impact on this once in a lifetime occasion</p>
          <h2>A Graduation Gift for a Friend? What Is The Right Choice?</h2>
          <p>Getting a graduation gift for a friend, would be such a nice surprise for them. You may have been study buddies and this is a chance to celebrate all those eventful years. We have an excellent choice of graduation gift for friends. Rest assured our delivery is super quick and  if you order before 12 noon then we offer same day dispatch. We deliver UK wide and internationally. You will be impressed with our quality and attention to detail. Graduation is what we do and with our many years of experience, we know how to make this occasion a delight.</p>
          <h2>Graduation Gift for Daughter To Show Your Appreciation</h2>
          <p>If you are a parent and are looking for a graduation gift for your daughter, your in safe hands. At Gowning Street we have a knack for sourcing graduation gifts for her that she will love. We have something that suits everyone's taste. One of our most popular items is a classic handcrafted pinewood certificate frame. These frames are perfect to protect your daughters graduation testamur, our graduation frames are made perfectly to fit the universities graduation certificate. We also have plush graduation puppies, these graduation puppy soft toys are a lovely addition to your family graduation photographs.</p>
          <h2>Graduation Gift for Son; He’s Done You So Proud!</h2>
          <p>As a proud parent you must be wondering what would be the ideal graduation gift for your son. at Gowning Street, we have many types of graduation gifts for him. You may consider getting him a graduation tie to ensure he looks sharp on his special day. We also have some graduation cufflinks as well, given he would be embarking on his professional working career soon.both the ties and the cufflinks would be welcome additions to his wardrobe. At Gowning Street,our passion is graduation, so when you are looking for the ideal graduation gift for your son, you have come to the right place.</p>
        </div>
      </div>
    </div>
  )
};

export default BuyLegalwear;
