import React from 'react';
import { Route, Switch, useRouteMatch, Link } from "react-router-dom";
import Helmet from 'react-helmet';

import UniInfo from '../../sources/universities';
import UniDesc from '../../sources/uni-descriptions';

import Products from './product';

const University = () => {

  let { path, url } = useRouteMatch();

  var name = UniInfo[url].name;
  var degrees = UniInfo[url].degrees;

  var bachelors = [];
  var masters = [];
  var advanceddip = [];
  var PhD = [];
  var hasAD = false;
  var hasPhD = false;

  var regLink = "/regalia-regulations/" + UniInfo[url].abbreviation;

  degrees.forEach(function(element){
    if(element.level === "Bachelor"){
      bachelors.push(element);
    }
    else if (element.level === "Master") {
      masters.push(element);
    }
    else if (element.level === "Diploma"){
      hasAD = true;
      advanceddip.push(element);
    }
    else if (element.level === "PhD"){
      hasPhD = true;
      PhD.push(element);
    }
  });

  var populate = function(element,key){

    var color1 = {
      backgroundColor : "hsl(0,0%,25%)"
    };

    var color2 = {
      backgroundColor : "hsl(0,0%,25%)"
    };

    if(element.colors){
      color1.backgroundColor = element.colors[0];
      color2.backgroundColor = element.colors[1] || element.colors[0];
    }

    return (
      <Link key={key} to={element.path}>
        <div className="listitem">
          <div className="unicolor">
            <div className="color1" style={color1}></div>
            <div className="color2" style={color2}></div>
          </div>
          <p>{element.name}</p>
        </div>
      </Link>
    )
  }

  var Masters = masters.map(populate,"Masters");
  var Bachelors = bachelors.map(populate,"Bachelors");
  var Advanceddip = advanceddip.map(populate,"AdvancedDip");
  var PhDMenu = PhD.map(populate,"PhD");

  var AD = null;

  if (hasAD) {
    AD = (
      <div className="degreegroup">
        <h2>Certificate / Diploma</h2>
        <div className="degreeslist">
          {Advanceddip}
        </div>
      </div>
    )
  }

  var PhDMenuToggle = null;
  if (hasPhD) {
    PhDMenuToggle = (
      <div className="degreegroup">
        <h2>PhD</h2>
        <div className="degreeslist">
          {PhDMenu}
        </div>
      </div>
    )
  }


  var titleTag;
  var metaTag;

  if (UniInfo[url].titleTag) {
    titleTag = UniInfo[url].titleTag;
  }
  else {
    titleTag = "Buy Graduation Gowns | " + name;
  }

  if(UniInfo[url].metaTag) {
    metaTag = UniInfo[url].metaTag;
  }
  else {
    metaTag = "Just finished your course and need a graduation gown? Buy one at Gowning Street at a lower price than rental! | " + name;
  }

  //Graduation Dates Table
  var gradDates = null;

  if (UniInfo[url].graduations){
    gradDates = UniInfo[url].graduations.map(function(element, index){

      var subjects = element.Subjects.map(function(subject){
        return (
          <li key={subject} className="gsubjects">{subject}</li>
        )
      })
      return (
        <tr key={`${index} ${element.Subjects.length}`}>
          <td className="gdates">{element.path}</td>
          <td>
            <ul>{subjects}</ul>
          </td>
        </tr>
      )
    })
  }

  return (
    <div>
      <Switch>
        <Route exact path={url}>
          <div className="university">
            <Helmet
              title={titleTag}
              meta={[
                {"name": "description", "content": metaTag},
              ]}
            />
            <h1>{name}</h1>
            <img src={UniInfo[url].photo} alt={UniInfo[url].altTag} />
            <div className="bachmast">
              {AD}
              <div className="degreegroup">
                <h2>Bachelors</h2>
                <div className="degreeslist">
                  {Bachelors}
                </div>
              </div>
              <div className="degreegroup">
                <h2>Masters</h2>
                <div className="degreeslist">
                  {Masters}
                </div>
              </div>
              {PhDMenuToggle}
            </div>
            <UniDesc
              uniName={name}
            />

            {gradDates === null ? null :
              <div className="graduationdates">
                <h2>2019 Graduation Ceremony dates at {UniInfo[url].abbreviation}:</h2>
                <table>
                  <thead className="grad-thead">
                      <tr>
                      <th>Date</th>
                      <th>Faculty/Subject</th>
                    </tr>
                  </thead>
                  <tbody className="grad-tbody">
                    {gradDates}
                  </tbody>
                </table>
                <p>For the latest & most accurate dates, please refer to the <a href={UniInfo[url]["graduations-ref"]} target="_blank">University website</a></p>
              </div>
            }

            <div className="regulation-link">
              <h2>Want to find out more about the academic dress policy at {UniInfo[url].abbreviation}?</h2>
              <button><Link to={regLink}>{UniInfo[url].abbreviation} academic dress regulations</Link></button>
            </div>

          </div>
        </Route>
        <Route path={`${path}/:degree`}>
          <Products />
        </Route>
      </Switch>
    </div>

  )
}

export default University;