import React from 'react';
import { withFormsy } from 'formsy-react';

const CustomInput = (props) => {
  const changeValue = (event) => {
    props.handleChange(event);
  }
  const className = props.showRequired() ? 'required' : props.showError() ? 'error' : null;

  const errorMessage = props.getErrorMessage();

  return (
    <div className={className}>
      <input
        type="text"
        placeholder={props.placeholder}
        onChange={changeValue}
        value={props.value}
      />
      <span>{errorMessage}</span>
    </div>
  )

}


export default withFormsy(CustomInput);


