import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import axios from 'axios';
// import Loader from '../custom-items/loader';
// import { checkInventory } from './checkInventory';
import CapGown from '../../sources/gowncap';
import StripePayment from './stripepayment';
import ShippingCalc from './functions/shippingcalc';
import PriceCalc from './functions/pricecalc';

const Confirmation = (props) => {
  const {
    order,
    customer,
    customer: {
      firstname,
      lastname,
      email,
      address_line1,
      address_line2,
      city,
      postcode,
    },
    handleSubmit
  } = props;

  const shippingDetails = ShippingCalc(order, customer);
  const priceDetails = PriceCalc(order, shippingDetails);
  const total = priceDetails.total;
  const subtotal = priceDetails.subtotal;
  const shippingCost = shippingDetails.total;
  const discount = priceDetails.discount;
  const [error, setError] = useState(null);
  // const [loading, setLoading] = useState(false);


  // const executePaypalCheckout = () => {
  //   const token = Math.floor(Math.random() * 99999999999999999999);
  //   axios.post('/api/paypal', {
  //     total: total,
  //     order: order,
  //     customer: customer,
  //     token: token
  //   })
  //   .then(response => {
  //     console.log('checkout paypal response', response);
  //     if (response.data.success === true) {
  //       window.location = response.body
  //     } else {
  //       setConnectionError('Sorry, error connecting to Paypal. Please choose another payment option.');
  //       setLoading(false)
  //     }
  //   })
  //   .catch(error => {
  //     console.log('executePaypalCheckout error', error)
  //   });
  // }


  // const setPaymentErrors = (fails) => {
  //   setError(fails);
  //   setLoading(false);
  // }

  // const setPaymentErrors = (err) => {
  //   setConnectionError(err);
  //   setLoading(false);
  // }

  const goBack = () => {
    handleSubmit("customer");
  }

  const renderOrderDetails = order.map(function(item){
    return(
      <tr className="orderitem" key={item.id}>
        <td className="ordercontent">
          <p className="name">{item.name}</p>
          <div className="details">
          { CapGown[item.gown] && <p> - {CapGown[item.gown]}</p> }
          { CapGown[item.cap] &&  <p> - {CapGown[item.cap]}</p> }
          </div>
        </td>
        <td className="price">£{item.price}</td>
        <td className="qty">{item.qty}</td>
      </tr>
    )
  });

  const ErrorMessage = ({ error: { errorType, errorDetail } }) => {
    let message = <p>Something went wrong, please try again later.</p>
    if (errorType === 'stripe') {
      message = (
        <div>
          { errorDetail && <p>{errorDetail}</p> }
          <p>
            Please try another card or use an alternative payment method.
          </p>
        </div>
      )
    }
    if (errorType === 'inventory') {
      message = errorDetail.map((detail) => (
          <div key={detail.code}>
            <p>Sorry, we only have {detail.maxQty} of {detail.description}.</p>
            <p>Please choose a lower amount.</p>
            <p>Please click <Link to="/cart">here</Link> to edit cart</p>
          </div>
        )
      );
    }
    if (errorType === 'orderError' || errorType === 'paypal') {
    message = (<p>{errorDetail}</p>)
    }
    return message;
  };

  const OrderSummary = () => (
    <div className="confirmation-section">
      <div className="order-summary-block">
        <div className="confirmation-section-header">
          <h3>Order Summary</h3>
          <Link to="/cart">Edit Cart</Link>
        </div>
        <table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Price</th>
              <th>Qty</th>
            </tr>
          </thead>
          <tbody>
            {renderOrderDetails}
          </tbody>
        </table>
      </div>
      <div className="order-summary-block">
        <div className="price-display">
          <span>Delivery: </span>
          <span>£{shippingCost}</span>
        </div>
        <div className="price-display">
          <span>Subtotal: </span>
          <span>£{subtotal}</span>
        </div>
        {/* <p>Discount per Item: {discountFactor}</p>
        <p>Eligible Items: {eligibleItems}</p>
        <p>Total Discount: {discount}</p> */}
      </div>
      <div className="order-summary-block">
        <div className="price-display">
          <span>Order Total: </span>
          <span>£{total}</span>
        </div>
      </div>
    </div>
  );

  const DeliveryDetails = () => (
    <div className="confirmation-section">
      <div className="confirmation-section-header">
        <h3>Delivery Details</h3>
        <span className="link" onClick={goBack}>Edit Shipping Details</span>
      </div>
      <div className="shippingdetails">
      <div>
        <h5>Contact</h5>
        <p>{email}</p>
      </div>
      <div>
        <h5>Ship to</h5>
        <p>{firstname} {lastname}</p>
        <p>{address_line1}, {address_line2}, {postcode}</p>
        <p>{city}, United Kingdom</p>
      </div>
      <div>
        <h5>Shipping method</h5>
        <p>Shipping - Free</p>
      </div>
    </div>
    </div>
  );

  const PaymentDetails = () => (
    <div className="confirmation-section">
       <div className="section-container">
        <div className="confirmation-section-header">
          <h3>Payment methods</h3>
        </div>
      </div>
      <div className="payment-section">
        <div className="confirmation-section-header">
          <h5>Payment Card</h5>
        </div>
        <StripePayment
          total={total}
          customer={customer}
          setError={setError}
        />
        { error &&
          <div className="errors">
            <ErrorMessage error={error} />
          </div>
        }
        <p className="payment-footer">All transactions are secure and encrypted</p>
      </div>
    </div>
  )


  return (
    <div className="checkout-confirmation">
      <OrderSummary />
      <DeliveryDetails />
      <PaymentDetails />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    customer: state.customer,
    order: state.shoppingCart
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateState: (field, value) => {
      dispatch({
        type: 'UPDATE_CUSTOMER',
        field : field,
        value : value
      })
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Confirmation);




