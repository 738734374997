import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(() => ({
  formControl: {
    margin: '12px 0',
    minWidth: '120px',
  }
}));

const Cap = ({ level, hatType, setSelectedProducts, selectedProducts }) => {
  const classes = useStyles();
  const defaultHatCodes = ["HAT00001", "HAT00002", "HAT00003", "HAT00004"];
  const [ selections, setSelections ] = useState(defaultHatCodes);
  const [ selectedValue, setSelectedValue ] = useState(selectedProducts.cap);

  useEffect(() => {
    if(level === "PhD") {
      switch(hatType){
        case "bachelor":
          setSelections(["HAT00001", "HAT00002", "HAT00003", "HAT00004", "HAT00005"]);
          break;

        case "red":
          setSelections(["HAT00021", "HAT00022", "HAT00023", "HAT00024", "HAT00025"]);
          break;

        case "yellow":
          setSelections(["HAT00031", "HAT00032", "HAT00033", "HAT00034", "HAT00035"]);
          break;
      }
    }
    setSelectedValue(selections[2])
  }, [])

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setSelectedProducts({ ...selectedProducts, cap: event.target.value });
  }

  return (
    <div className="cap-size">
      <h3>Choose your Cap size</h3>
      <FormControl
        className={classes.formControl}
        component="fieldset"
      >
        <FormHelperText>
          Based on head circumference at the widest point. Click <a href="https://res.cloudinary.com/gowningstreet/image/upload/v1549425049/Utility/Gowning-Street-Printable-Measurement-Ruler.pdf" target="_blank">here</a> for a printable ruler
        </FormHelperText>
        <Select
          labelId="capSize"
          value={selectedValue}
          onChange={handleChange}
        >
          <MenuItem value={selections && selections[0]}>Small (Less than 52cms)</MenuItem>
          <MenuItem value={selections && selections[1]}>Medium (53 - 55cms)</MenuItem>
          <MenuItem value={selections && selections[2]}>Large (56 - 58cms)</MenuItem>
          <MenuItem value={selections && selections[3]}>Extra Large (More than 59cms)</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default Cap;
