import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { Products } from '../../sources/products';
import ShopItems from '../selections/shop-items';
import Description from '../selections/description';
import NotFound from '../404';

const Product = () => {
  const { pathname } = useLocation();
  const [image, setImage] = useState(null);
  const [mainAlt, setMainAlt] = useState(null);
  const [isProducts, setIsProducts] = useState(false);
  const productInfo = Products?.[pathname];

  const productPhotos = productInfo?.photos;
  const productAltTags = productInfo?.altTags;

  const isStandardGown = pathname.includes('economy');
  const isFullSet = productInfo?.productType === 'bachelor-full-set'

  useEffect(() => {
    const altTag = productAltTags?.[0] ?? "Graduation Gowns Display Image";
    setMainAlt(altTag)
    setImage(productPhotos?.[0])
  }, [pathname]);

  const setPrimary = (event) => {
    setMainAlt(event.target.alt);
    setImage(event.target.id);
  };

  const renderImage = productPhotos
    ? productPhotos.map(function (element, index) {
      if (!productAltTags) {
        return (
          <img
            src={element}
            alt="Graduation Gowns Display Image"
            className="image-mini"
            key={element}
            id={element}
            onClick={setPrimary}
          />
        )
      }
      else {
        return (
          <img
            src={element}
            alt={productAltTags[index]}
            className="image-mini"
            key={element}
            id={element}
            onClick={setPrimary}
          />
        )
      }
    })
    : [];

    const renderHeader = (header) => {
      if (header.indexOf('-') > 1) {
        const headerSections = header.split('-');
        return (
          <div>
            <h1 style={{
              marginBottom: '0',

            }}>{headerSections[0]}</h1>
            <hr style={{
              color: '#D3D3D3'
            }} />
            <h2 style={{
              padding: '5px 0',
              fontSize: '22px',
              marginBottom: '10px'
            }}>{headerSections[1]}</h2>
          </div>
        )
      }
      return header;
    }


  if (pathname.indexOf("products") !== -1) {
      setIsProducts(true);
  }

  let titleTag = productInfo?.titleTag
    ?? productInfo?.name + " | " + "University Graduation Gown Set";

    if (isProducts) {
      titleTag = "Gowning Street Graduation Regalia";
    }

  const metaTag = productInfo?.metaTag
    ?? "Gowning Street provides quality, hand crafted graduation gown sets for Australian University students";

  if (!productInfo) {
    return <NotFound />
  }
    return (
      <div className="product-set">
        <Helmet
          title={titleTag}
          meta={[
            { "name": "description", "content": metaTag },
          ]}
        />
        <div className="image-set">
          <div className="image-preview">
            {renderImage}
          </div>
          <img
            src={image}
            id="image-main"
            alt={mainAlt}
          />
        </div>
        <div style={{ textAlign: 'center', padding: '10px 0'}}>
          {renderHeader(productInfo?.name)}
        </div>
        {isFullSet &&
          <div
            className="economy-info">
            <p>
              Our full bachelor set comes with our premium 230gsm gown and includes a graduation hat and hood
            </p>
          </div>
        }
        { pathname.indexOf('gown-set') > -1 &&
          <div
            className="economy-info">
            <p>
              Our { isStandardGown ?'standard' : 'premium' } gown is made from { isStandardGown ?'160gsm' : '230gsm' } black fabric. Please note that the hood is not included in this set.
            </p>
          </div>
        }
        <h2 className="product-price">£{productInfo?.price}</h2>
        <ShopItems path={pathname} />
        <Description path={pathname} />
      </div>
    )
};

export default Product;