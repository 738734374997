import React, { Component } from 'react';

class Hood extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hood : props.hood
    };

  }

  render () {
    return (
      <div className="hood">
        <h3>Hood</h3>
        <p>{this.state.hood}</p>
      </div>
    )
  }

}


export default Hood;