import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

const Wholesale = () => (
  <div className="about">
    <div className="about-contents">
      <Helmet
        title="Wholesale Academic Gowns"
        meta={[
          {"name": "description", "content": "Gowning Street provides wholesale prices for bulk purchases of graduation gowns"},
        ]}
      />
      <h1>Wholesale Academic Gowns at Great Prices</h1>
      <p>Gowning Street is pleased to offer Wholesale prices for orders above 20 graduation gowns. If you are a school needing gowns for your teachers please enquire through our <Link to="/contact">contact us</Link> page</p>
      <h3>GRADUATION GOWNS FOR POLYTECHNICS AND SCHOOLS</h3>
      <p>If you are an academic institution and you require a solution for your graduation gowns, graduation hoods, or graduation caps, we have the expertise to help find the best solution for your needs. We are able to custom tailor any specific product requests you may have at affordable prices. Get in touch with someone from our friendly team and we can go through the many options available.</p>
    </div>
  </div>
)

export default Wholesale;