import React from 'react';
import { Products } from '../../sources/products';
import ShippingTable from '../info/shipping-calculator';

const Description = (props) => {
  const toggleState = (index, event) => {
    const description = document.getElementById(index);
    const button = document.getElementById(index * -1);

    if(description.style.display === "none"){
      description.style.display = "";
      button.src = "https://res.cloudinary.com/gowningstreet/image/upload/v1549165140/Utility/minus_box_grey_24x24.png";
    }
    else {
      description.style.display = "none";
      button.src = "https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Utility/add_box_grey_18x18.png";
    }
  };

  const path = props.path;
  const toShow = Products[path].show;
  const level = Products[path].level;
  const gownStyle = Products[path].gownStyle;
  const giftStyle = Products[path].giftDescription;

  var gown = null;
  var cap = null;
  var hood = null;
  var gift = null;

  if(toShow.indexOf("hood") !== -1) {
    var hoodDescription = Products[path]["hood-style"];
    hood = (
      <div>
        <p className="inline-title">Hood</p>
        <p>{hoodDescription}</p>
      </div>
    )
  }
  if(toShow.indexOf("gown") !== -1) {
    gown = (
      <div>
        <p className="inline-title">Gown</p>
        <p>Black, fully fluted B1 gown, with {level} length sleeves. </p>
        <p>Our premium gowns are made from 230gsm fabric. Our more affordable standard gowns are made from 160gsm fabric</p>
      </div>
    )
  }
  if(toShow.indexOf("cap") !== -1) {
    cap = (
      <div>
        <p className="inline-title">Cap</p>
        <p>Black cap, hard board, with soft cap and tassel</p>
      </div>
    )
  }
  if(toShow.indexOf("gift") !== -1) {
    gift = (
      <div>
        <p className="inline-title">Graduation Gift</p>
        <p>{giftStyle}</p>
      </div>
    )
  }

  const hidden = { display : "none" }

  return (
    <div className="product-description">
      <div className="description-head" onClick={toggleState.bind(this, -1)} >
        <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Utility/add_box_grey_18x18.png" id={1}/>
        <h4>Product Details</h4>
      </div>
      <div id={-1} style={hidden} className="inner-description">
        {gown}
        {cap}
        {hood}
        {gift}
      </div>
      <div className="description-head" onClick={toggleState.bind(this, -2)}>
        <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Utility/add_box_grey_18x18.png" id={2}/>
        <h4>Size Guide</h4>
      </div>
      <div id={-2} style={hidden} className="inner-description">
        <p className="inline-title">Graduation Gown</p>
        <p>Gown size is based on your height in centimetres</p>
        <p className="inline-title">Graduation Hat</p>
        <p>Hat size is based on your head circumference at the widest point, including hair. Pick the larger size if you are on the borderline.</p>
      </div>
      <div className="description-head" onClick={toggleState.bind(this, -3)} >
        <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Utility/add_box_grey_18x18.png" id={3}/>
        <h4>Shipping</h4>
      </div>
      <div id={-3} style={hidden} className="inner-description">
        <p className="inline-title">Dispatch</p>
        <p>Orders received before noon will generally be dispatched within the next 1-2 business days.</p>
        <p>Orders received after this time, or on weekends/public holidays will be dispatched on the next available business days.</p>
        <p className="inline-title">Shipping Time Estimate</p>
        <ShippingTable />
      </div>
    </div>
  )

}

export default Description;