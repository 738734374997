import React from 'react'
import styled from 'styled-components'
import { colors, sizes } from '../utils/theme'

export const Container = styled.div`
  color:${colors.primary};
  padding-left: 15px;
  padding-right: 15px;
`

const FlexRow = styled.div`
  display:flex;
  flex-flow: row wrap;
  margin: ${sizes.baseSizeLarge};
  align-items: flex-start;
`

export const Row = ({ className, children }) =>
  <FlexRow className={className}>{children}</FlexRow>

const FlexColumn = styled.div`
  flex:1 1 0px;
  align-content: flex-start;
`

export const Column = ({ className, children }) =>
  <FlexColumn className={className}>{children}</FlexColumn>

export const RightAlignedColumn = styled(FlexColumn)`
  text-align: right;
`

export const SpaceBetweenRow = styled.hr`
  margin: ${sizes.baseSizeLarge}
`

export const Outer = styled.div`
  margin: 15px 0 0 0;
`
export const AlignCenterWithPadding = styled.div`
  flex: 1 1 0px;
  text-align: center;
  padding: ${sizes.baseSizeSmall}
`