import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from '../../utils/mediaQueries';
import { Link } from 'react-router-dom';

const Background = styled.div`
  background: #F9F9F9;
  width: 100%;
`;

const Section = styled.div`
  display: block;
  margin: 0 auto;
  padding: 20px 40px;
  text-align: center;
  h2 {
    font-size: 36px;
    padding: 30px 0;
    line-height: 38px;
  }
  ${mediaQueries.mobile`
    max-width: 300px;

  `};
`

const Container = styled.div`
  max-width: 500px;
    margin: 0 auto;
  h3 {
    font-size: 24px;
    padding: 15px 0;
    line-height: 30px;
  }
  p {
    padding: 15px 0;
    line-height: 25px;
  }
`


const Details = () => {
  return (
    <Background>
      <Section className="section" style={{paddingTop: '30px'}}>
        <h2>WHY BUY YOUR UK GRADUATION GOWN FROM US?</h2>
        <Container>
          <h3>A company you can trust</h3>
          <p>Gowning Street is a brand that prides itself on it’s customer service, craftsmanship and trust.</p>
          <p>We are an organisation trusted by both students and educational colleges for manufacturing premium quality graduation regalia at affordable prices. Our values of excellence and impeccable stitching is extended through our range of handcrafted graduation trencher’s and graduation robes.</p>
        </Container>
        <Container>
          <h3>Our Craftmanship</h3>
          <p>
            The UK University academic hoods, caps and gowns are created in a manner conforming with strict University academic dress guidelines. You will find that our products provide a deluxe fit and comfortable feel, ensuring you are looking at your best for your convocation.
          </p>
        </Container>
        <Container>
          <h3>Your graduation convocation is a treasured moment</h3>
          <p>Completing your degree is an amazing achievement. Your graduation ceremony is a time of celebration and  reflection on what you have accomplished. As you begin the next chapter of life, it’s paramount to take a break and enjoy the moment!</p>
          <p>At Gowning Street we can assist with all of your academic dress needs; whether it’s an <Link to="/academic-dress/bachelor-gown">undergraduate gown</Link>, <Link to="/academic-dress/master-gown">post-graduate gown</Link> or even a <Link to="/certificate-frame">graduation certificate frame</Link>! We are there to make this special moment a day to cherish for the rest of your life.</p>
        </Container>
      </Section>
      <Section className="section">
        <h2>GRADUATION GOWN HIRE V PURCHASING YOUR REGALIA</h2>
        <Container>
          <p>You can choose between two options when it comes to organizing your graduation gown for your graduation ceremony, you can hire or purchase</p>
          <p>You may assume that renting is the more economical option, but you will be pleasantly surprised that at Gowning Street we are able to supply a beautifully-crafted academic gown for purchase at a better than hire (with much more ease).</p>
          <p>Our range covers most uni’s in UK including <Link to="/graduation-gown/university-college-london">UCL</Link>, <Link to="/graduation-gown/open-university">Open</Link>, <Link to="/graduation-gown/cardiff-university">Cardiff</Link> and many others. We also offer custom manufacturing.</p>
          <h2>THE PROS OF BUYING YOUR GRADUATION GOWN</h2>
          <p>It's a lifelong souvenir which represents an important time in your life. It’s something you can pass down to your loved ones.</p>
          <p>If you have peers who are doing the same degree you can let them borrow it</p>
          <p>By getting together with your classmates, you can take advantage of our students deals where you can save up to £10 per person on your graduation gown.</p>
          <p>People that hire a gown, have to worry about staining/damage fees and keeping their rented gown squeaky clean. You can relax in the knowledge that  you have made the smarter choice of purchasing your gown and can enjoy your day with no worries.</p>
          <p>Your peers will have to line up for their regalia on the day, often this can take hours!  We will post your gown straight to your home. You then have the option of taking your graduation photography at a time convenient to you.</p>
        </Container>
      </Section>
      <Section className="section">
        <h2>UK WIDE FAST DELIVERY</h2>
        <Container style={{paddingBottom: '30px'}}>
          <p>For same day dispatch of your regalia, order before 12pm. In most cases you should receive your gown in 5-7 business days.</p>
          <p>If you are seeking a highly professional organisation to buy your grad gown and cap from, you’ve come to the right place. You can count on us to quickly deliver a quality gown at an affordable price.</p>
          <p>If you require further information regarding our academic dress on offer, or you would like to speak to a staff member, please visit our <Link to="/contact">contact us</Link> page.</p>
          <p>For overseas customers, we offer international shipping around the world. If you live in Australia, please visit <a href="https://www.gowningstreet.com.au">www.gowningstreet.com.au</a>, or <a href="https://www.graduationgowns.co.nz">www.graduationgowns.co.nz</a> if you live in New Zealand</p>
        </Container>
      </Section>
    </Background>
  )

}

export default Details;




