import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

const About = ({ props }) => {
  return (
  <div className="about">
    <div className="about-contents">
      <Helmet
        title="About our Gowning Street"
        meta={[
          {"name": "description", "content": "Buy your Academic Gowns from Gowning Street. We have served thousands of happy customers in the UK, Australia and New Zealand. Fast Delivery. Easy Exchange."},
        ]}
      />
      <h1>About Us</h1>
      <p className="maindes">Gowning Street is premium seller of handcrafted graduation gowns. We pride ourselves on our fast service and customer satisfaction.</p>
      <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-leaving-ceremony.jpg" alt="University graduates leaving their ceremony"/>
      <h3>A Trusted Brand</h3>
      <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="University graduates"/>
      <p>Gowning Street has offices in the UK, Australia and New Zealand and has been trusted by institutions and students all over the world for our quality and attention to detail. We ensure all of our garments are manufactured in accordance with your institutions academic dress policies and we offer easy exchanges for sizing.
      </p>
      <h3>Rapid Delivery</h3>
      <p>We offer a same day dispatch for orders made before 12pm and we use the best logistical operators to ensure you receive your order as quickly as possible. Please see our delivery estimates <Link to="/shipping">here</Link></p>
      <h3>Price Beat Guarantee</h3>
      <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-celebrating-stairs.jpg" alt="University graduates on stairs"/>
      <p>We keep our prices as low as possible as we sell direct from manufacture- Essentially cutting out the middle men, meaning you get the best prices. You might be surprised to see that buying your graduation gown from us can equate to the same price as hiring</p>
      <h3>We Make Your Graduation Day More Pleasant</h3>
      <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-park-bench.jpg" alt="University graduate on park bench"/>
      <p>Having your graduation gown delivered to you before your ceremony allows you to skip the long queue on ceremony day and you also have the freedom to take your photographs at anytime as opposed to the ‘hiring time frames”. Moreover by purchasing your gown you can relax knowing that a spill or two of wine won’t incur any damage fees/miscellaneous fees by the hirer.</p>
      <h3>Memorabilia to Cherish Forever </h3>
      <p>The best part of buying your gown is that you get to keep it forever. You have put in so much work to get the cap and gown that it would be a shame that you only get to hire it for a few hours. You can also pass down your grad gown to friends/family or you may wish to keep it as memorabilia or use it for future alumni events.</p>
      <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165128/Placement%20Photos/graduate-on-uni-stairs.jpg" alt="University graduate wearing gown set sitting on University steps"/>
    </div>
  </div>
)}

export default About;