import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

const FAQ = () => (
  <div className="FAQ">
    <Helmet
      title="Gowning Street UK FAQ"
      meta={[
        {"name": "description", "content": "Gowning Street UK frequently asked questions"},
      ]}
    />
    <div className="FAQ-contents">
      <h1>Frequently Asked Questions</h1>
      <h3>Why are your gowns so affordable?</h3>
        <p>At Gowning Street, we run an extremely efficient operation, ensuring you get your regalia at the best price possible. How do we do this? We sell direct from manufacture, meaning we cut out the middlemen and sell directly to our customers. Given we operate internationally and have a high volume of orders, we are able to keep are prices low, providing all of our efficiencies to you the customer.</p>
      <h3>How long does postage and handling take?</h3>
        <p>If you order before 12pm, we can provide same day dispatch, for our estimated deliveries time please see here <Link to="/shipping">www.gowningstreet.co.uk/shipping</Link></p>
      <h3>Can I hire a gown?</h3>
        <p>Sorry we only offer a direct purchase option. If you are an institution and need a hire solution, get in touch with us and we may be able to provide a custom solution.</p>
      <h3>What is your refund and exchange policy?</h3>
        <p>Please check our refund and exchange policy here: www.gowningstreet.co.uk/terms</p>
      <h3>How can I pay?</h3>
        <p>We accept payments via Paypal or via Credit/Debit card through Stripe. Alternatively for bulk orders we are able to arrange payment via bank transfer. Sorry, we are unable at this stage to process phone credit card payments.</p>
      <h3>How am I sure your gowns and hoods will match my University?</h3>
        <p>Each product is meticulously handcrafted to your University academic dress policy. We take great care to ensure all of our products comply with your specific University academic dress policy.</p>
      <h3>How should I best look after my gown?</h3>
        <p>Once you receive your gown, we recommend that you hang it to allow any creases to fall out naturally. To remove any remaining creases, you can iron at low heat by putting a cloth on top of the gown before ironing over it. Similarly the trenchers should also be unpacked once received to remove any creases. If you are wanting to clean your gown, we recommend so by dry-cleaning only.</p>
      <h3>Do you have any specials?</h3>
        <p>Yes we do, please check our <Link to="/save-with-friends">students deal page</Link> for our current specials buy in bulk. For bulk orders of 25 or more gown sets, please <Link to="/contact">Contact Us</Link></p>
      <h3>What clothing is appropriate to wear with my gown?</h3>
        <p>We recommend formal wear and also advise you to check with your University if they have any specific dress requirements.</p>
    </div>
  </div>
)

export default FAQ;
