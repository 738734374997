import React, { Component } from 'react';
import Helmet from 'react-helmet';

class CheckOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ordernum : "",
      firstname : "",
      lastname : "",
      postcode : "",
      error : null,
      order : null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(field, event) {
    var nextState = {};
    nextState[field] = event.target.value;

    this.setState(nextState);
  }

  handleSubmit() {
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.addEventListener("load", function(){
      var response = JSON.parse(this.response);

      if(response.success === false){
        this.setState({
          error : response.err
        })
      }
      else {
        this.setState({
          order : response.data,
          error : null
        })
      }

    });
    xmlhttp.open("POST", "/checkorder");
    xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xmlhttp.send(JSON.stringify({
      ordernum : this.state.ordernum,
      firstname : this.state.firstname,
      lastname : this.state.lastname,
      postcode : this.state.postcode
    }));
  }

  render() {
    var order = null;
    var orderDetails = this.state.order;

    if(orderDetails !== null){
      var items = JSON.parse(orderDetails.items);

      items = items.map(function(element){
        return (
          <p key={element.id}>{element.qty} x {element.name}</p>
        )
      })

      order = (
        <div className="customerOrder">
          <div className="shippingdetails">
            <p>{orderDetails.first_name} {orderDetails.last_name}</p>
            <p>{orderDetails.address1}</p>
            <p>{orderDetails.address2}</p>
            <p>{orderDetails.postcode}</p>
            <p>{orderDetails.city}</p>
            <p>Shipping : {orderDetails.shipping}</p>
          </div>

          <div className="orderdetails">
            <p>Items:</p>
            {items}
          </div>
        </div>
      )
    }

    return (
      <div className="checkorder">
        <Helmet
          title="Check Your Order | Gowning Street"
          meta={[
            {"name": "description", "content": "Check your Gowning Street order details here."},
          ]}
        />
        <h1>Check Order</h1>
        <input
          type="text"
          placeholder="Order Number"
          value={this.state.ordernum}
          onChange={this.handleChange.bind(this, "ordernum")}
        />
        <input
          type="text"
          placeholder="First Name"
          value={this.state.firstname}
          onChange={this.handleChange.bind(this, "firstname")}
        />
        <input
          type="text"
          placeholder="Last Name"
          value={this.state.lastname}
          onChange={this.handleChange.bind(this, "lastname")}
        />
        <input
          type="text"
          placeholder="Postcode"
          value={this.state.postcode}
          onChange={this.handleChange.bind(this, "postcode")}
        />
        <button onClick={this.handleSubmit}>Search</button>
        <p className="error">{this.state.error}</p>
        <br/>
        {order}
      </div>
    );
  }
}

export default CheckOrder;
