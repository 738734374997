import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

const BuyLegalwear = ({name}) => {

  return (
    <div className="shop-products">
      <Helmet
        title="Looking for Quality Legal Wear? Shop at Gowning Street"
        meta={[
          {"name": "description", "content": "Buy Quality Legal Wear at affordable prices! and get fast shipping UK-wide. Our Legal Wear is hand crafted to perfection."},
        ]}
      />
      <h1>Buy Handcrafted Legal Wear in the UK</h1>
      <div className="subheading">
        <h2>Quality legal gowns and wigs at affordable prices</h2>
      </div>
      <div className="catalogue">
        <div className="img-container">
          <div className="img-box">
            <Link to="/legal-wear/solicitors-gown">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549186837/Regalia/master-gown-front-main.jpg" alt="Solicitor's Gown"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Solicitor's Gown</strong>
                  <p>£99</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/legal-wear/barristers-gown">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-side.jpg" alt="Barrister's Gown"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Barrister's Gown</strong>
                  <p>£99</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/legal-wear/barristers-wig">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Benchers_Wig.png" alt="Legal Wig"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Barrister's Wig</strong>
                  <p>£350</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/legal-wear/benchers-wig">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Benchers_Wig.png" alt="Bencher's Wig"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Bencher's Wig</strong>
                  <p>£899</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/legal-wear/judges-wig">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Barrister_Wig.png" alt="Judge's Wig"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Judge's Wig</strong>
                  <p>£1,600</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
        </div>
        <div className="products-desc">
          <h2>Looking for Quality Legal Wear?</h2>
          <p>Gowning Street is an experienced manufacturer of Legal Wear. We pride ourselves in the maintenance of standards of legal wear. Our company regularly seeks the advice of the legal fraternity to design and manufacture legal wear for institutions around the United Kingdom and the World.
          </p>
          <h2>Our Legal Regalia Options</h2>
          <p>Buying your legal regalia is simple with Gowning Street. Just make an order online and we will dispatch the same day if your order before midday. We have a flexible exchange and returns policy. If  the fitting is not quite right we can provide an exchange. You may wonder why our legal gowns are so affordably priced? As we are a Multinational Online Company and our specialisation is in the manufacturing of graduation gowns and legal wear- We have the scale and operational efficiencies to be able to provide you with an exceptional product at a great price.</p>
          <h2>We Provide Custom Manufactured Legal Wear</h2>
          <p>We are able to custom manufacture your legal wear to your needs as well. Just get in touch and we can give an overview of the many manufacturing options we have available.</p>
          <h2>Barrister's Wigs</h2>
          <p>Our barrister’s wig is hand crafted from 100% pure horsehair.</p>
          <h2>Solicitor's Gown</h2>
          <p>Our traditional designed solicitor gowns are made from high quality polyester weave which are great for warmer climates, and feature a flap collar and stiffened yolk. Our Solicitor gowns are suitable for most commonwealth jurisdictions.</p>
          <h2>Barrister's Gown</h2>
          <p>Our barrister gowns are handcrafted to perfection from premium quality polyester, great for warmer climates and feature a fully fluted back and stiffened yolk. Our barrister robes are suitable for use in commonwealth courtrooms around the world.</p>
          <h2>Bencher's Wigs</h2>
          <p>Our bencher’s wigs are handcrafted from 100% pure horsehair. Each strand is carefully woven to make a beautifully crafted wig providing you a comfortable fit and a made to last product.</p>
          <h2>Judge's Wigs</h2>
          <p>Our Judges wigs are beautifully handcrafted from 100% pure horsehair. Each strand is carefully woven to produce a comfortable long lasting product.</p>
        </div>
      </div>
    </div>
  )
};

export default BuyLegalwear;
