'use strict';

const shoppingCart = (
  state = [],
  action
) => {

  function getNextId() {
    if(state.length === 0) {
      return 0;
    }

    var highest = 0;
    state.forEach(function(element){
      if(element.id >= highest){
        highest = element.id + 1;
      }
    });

    return highest;
  }

  function checkDuplicate(gown, cap, hood, gift) {
    var id;

    state.forEach(function(element){
      if(
        element.gown === gown &&
        element.cap === cap &&
        element.hood === hood &&
        element.gift === gift
      ){
        id = element.id;
      }
    });

    return id;
  }

  switch(action.type) {
    case 'ADD_TO_CART':
      var exists = checkDuplicate(action.gown, action.cap, action.hood, action.gift);

      if(exists === undefined){
        return state.concat([{
          id: getNextId(),
          name: action.name,
          gown: action.gown,
          cap: action.cap,
          hood: action.hood,
          gift: action.gift,
          hoodStyle: action.hoodStyle,
          qty: action.qty,
          price: action.price,
          productType : action.productType,
          image : action.image
        }]);
      }
      else {
        return state.map(function(element){
          if(element.id === exists){
            return Object.assign(
              {},
              element,
              {qty: Number(element.qty) + Number(action.qty)}
            );
          }
          else {
            return element;
          }
        });
      }
      break;

    case 'UPDATE_QTY':
      return state.map(function(element){
        if(element.id === action.id){
          return Object.assign(
            {},
            element,
            {qty: action.qty}
          );
        }
        else {
          return element;
        }
      });

    case 'REMOVE_FROM_CART':
      var newState = [];

      state.forEach(function(element){
        if(Number(element.id) !== Number(action.id)){
          newState.push(element);
        }
      });

      return newState;

    case 'EMPTY_CART':
      return [];

    default:
      return state;
  }
};

export default shoppingCart;