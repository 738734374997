import React from 'react';

const frameSelection = props => {
  const handleChange = (event, type) => props.setSelection(type, event.target.value);
  const { title, options, type, chosen } = props

  return (
    <div className="frame-options-container">
      <p className="frame-option-title">{title}</p>
      {
        options.map(({barcode, label}) => (
          <div key={barcode} className="frame-option">
            <input
              id={barcode}
              type="radio"
              name={barcode}
              value={barcode}
              onChange={e => handleChange(e, type)}
              checked={chosen === barcode}
            />
            <label htmlFor={barcode}>{label}</label>
          </div>
        ))
      }
    </div>
  )
};

export default frameSelection;