import React from 'react';
import ShippingTable from './shipping-calculator';
import Helmet from 'react-helmet';

const ShippingPage = () => (
  <div className="shipping-page">
    <Helmet
      title="Gowning Street UK Shipping Estimates"
      meta={[
        {"name": "description", "content": "Gowning Street UK Shipping Estimates"},
      ]}
    />
    <h1>Shipping Estimates</h1>
    <ShippingTable />
  </div>
)


export default ShippingPage;