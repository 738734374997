import React, { Component } from 'react';

class Qty extends Component {
  constructor(props) {
    super(props);
    const qty = props.initialQty || 1;
    this.state = {
      selectedValue: qty
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate() {
    //Used for cart extras only. Allows add to cart
    if (this.props.extrasqty) {
      if (this.props.extrasqty !== this.state.selectedValue){
        this.setState({selectedValue: this.props.extrasqty})
      }
    }
  }

  handleChange(event) {
    this.setState({
      selectedValue: Number(event.target.value),
    });

    this.props.setQty(Number(event.target.value), this.props.cartId);
  }

  render() {
    var selections = [];
    var max = 30;

    for (var i = 1; i <= max; i++){
      selections.push(i);
    }

    selections = selections.map(function(element){
      return (
        <option value={element} key={element}>
          {element}
        </option>
      )
    })

    if(this.props.initialQty > max){
      selections = selections.concat(
        <option key={this.props.initialQty} value={this.props.initialQty}>{this.props.initialQty}</option>
      )
    }

    return (
      <div className="frame-qty">
        <select
          className="dropdown"
          value={this.state.selectedValue}
          onChange={this.handleChange}
        >
          {selections}
        </select>
      </div>
    );
  }
}

export default Qty;
