import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { Route, Switch, useRouteMatch, Link } from "react-router-dom";
import Universities from './shopping-routes/universities';

import UniObj from '../sources/universities'
import UniInfo from '../sources/universities';

// const UniRegions   = require('../sources/regions');

// All universities and regions on one page
const AllUnis = () => {

  let { path, url } = useRouteMatch('/graduation-gown');

  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const onChange = (event) => {
    setSearchValue(event.target.value.substr(0, 140));

    let results = [];

    for(var i in UniInfo) {
      const lowerCaseTerm = event.target.value.toLowerCase();
      if (UniInfo[i].searchName.toLowerCase().indexOf(lowerCaseTerm) !== -1) {
        results.push({
          name : UniInfo[i].searchName,
          path : i
        });
      }

      if(results.length === 4){
        break;
      }
    }

    if(results.length === 0) {
      results = [
      { name : 'Sorry, no results matched your search.',
        path :  null}
    ]

    }
    if(event.target.value.length < 2) {
      results = [];
    }
    setSearchResults(results)
  }

  const UniArray = [];

  for (var u in UniObj) {
    UniArray.push({
      name: UniObj[u].searchName,
      link: u
    });
  }

  var list = UniArray.sort(function(a,b){
    if (a.name < b.name) {return -1}
    return 0;
  }).map(function(uni){
    return (
      <div key={uni.name} className="unititle">
        <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Utility/uni_icon_grey_18x18.png" />
        <Link to={uni.link}>
          <p>{uni.name}</p>
        </Link>
      </div>
    )
  });


  //Rendering of list of Unis
  return (
    <div>
      <Helmet
          title="Academic Dress for UK Universities | Gowning Street"
          meta={[
            {"name": "description", "content": "Looking for Graduation Gown hire? Why hire when you can buy your complete graduation set for £45."},
          ]}
        >
      </Helmet>
      <Switch>
        <Route exact path={url}>
          <div className="shop-gowns">
            <h1>Academic Dress for UK Universities</h1>
            <div className="main-input">
              <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165120/Utility/search_grey_18x18.png"/>
              <input
                placeholder="Search by university name"
                type="text"
                value={searchValue}
                onChange={onChange}
              />
            </div>
            <div className="mainsearchresults">
              {searchResults.length > 0 && searchResults.map((element) => {
                  if(element.path !== null) {
                    return (
                      <Link to={element.path} key={element.path}>
                        <p key={element.name}>{element.name}</p>
                      </Link>
                      )
                  }
                  else {
                    return (
                      <p key={element.name}>{element.name}</p>
                    )
                  }
                })
              }
            </div>
            {list}
            <div className="toproducts">
              <p>Gowning Street manufactures Academic dress for <Link to="/graduation-gown/sheffield-hallam-university">SHU</Link>, <Link to="/graduation-gown/university-of-hull">Hull</Link> and <Link to="/graduation-gown/university-of-derby">Derby Uni</Link> to name a few. We offer fast dispatch, If you order before Midday, you will get same day dispatch and most items only take 3-4 days standard or 1-2 days express to reach. If you are after wholesale quotes, we have excellent deals available where we can provide custom solutions to your institution. If you are looking for quality Regalia at good value, then you have come to the right place! Gowning Street serves 1000’s of happy customers around the United Kingdom making your Graduation a special moment.</p>

              <h3>Graduation Gown Hire v Academic Dress Purchase</h3>
              <p>You might be surprised to find out that purchasing your academic dress from Gowning Street can be more affordable than hiring! We serve a large number of UK institutions and students year after year and as a consequence we are able to provide a sharper price point than most and with no compromise on quality. We serve Universities across all regions and counties, so whether it is a <Link to="/graduation-gown/cardiff-university">Cardiff Uni</Link> academic dress or <Link to="/graduation-gown/university-college-london">UCL</Link> academic dress we have it all here.</p>

              <h3>Looking for a Graduation Hat or Graduation Hood?</h3>
              <p>If you just want to purchase an individual <Link to="/academic-dress/graduation-cap">graduation cap</Link> or <Link to="/academic-dress/graduation-hoods">graduation hood</Link>, please go to our <Link to="/academic-dress">main products page</Link>. You can also purchase graduation gifts such as a <Link to="/academic-dress/graduation-teddy-bear">grad teddy bear</Link> or a certificate frame.
              </p>
            </div>
          </div>
        </Route>
        <Route path={`${path}/:uni`}>
          <Universities />
        </Route>
      </Switch>
    </div>
  )

}

export default AllUnis;