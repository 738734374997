
export const frameStyles = [
  {label: 'Modern Frame', barcode: 'FRA00001'},
  {label: 'Pine Wood Frame (+£2)', barcode: 'FRA00002'},
];
export const frameStylesOther = [
  {label: 'Modern Frame', barcode: 'FRA00011'},
  {label: 'Pine Wood Frame (+£2)', barcode: 'FRA00012'},
];
export const frameColours = [
  {label: 'Manor Black', barcode: 'MAT00001'},
  {label: 'Navy Blue', barcode: 'MAT00002'},
  {label: 'Burgundy', barcode: 'MAT00003'},
  {label: 'Forest Green', barcode: 'MAT00004'},
  {label: 'Light Cream', barcode: 'MAT00005'},
];
