import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';

import ShoppingCart from './cart-items';

const Cart = ({items}) => {
  return (
    <div>
      {items.length === 0 ? (
        <div className="cart">
          <h1>Shopping Cart</h1>
          <h2>Cart Empty - Please buy some items</h2>
        </div>
      ) : (
        <div className="cart">
          <Helmet
            title="Shopping Cart | Gowning Street"
            meta={[
              {"name": "description", "content": "Gowning Street Shopping Cart"},
            ]}
          />
          <h1>Shopping Cart</h1>
          <div className="cart-subtitle">
            <h2>My Items</h2>
            <hr></hr>
          </div>
          <ShoppingCart />
        </div>
      )}
    </div>
  )

}

const mapStateToProps = (state) => {
  return {
    items: state.shoppingCart
  }
};

export default connect(
  mapStateToProps
)(Cart);
