import React from 'react';
import { Link } from 'react-router-dom';

const UniDescriptions = ({uniName}) => {
  var description = null;

  switch(uniName){
    case "University of the Arts London Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>HERE FOR UNIVERSITY OF THE ARTS LONDON GRADUATION REGALIA?</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1637539162/UK%20Uni%20Campus%20Photos/ual-2.jpg" alt="University of Arts London main building"/>
          <p className="para">There is only one possible reason why you might be on this website: you made it! Years of sketching, planning and reconsidering multiple ideas you ever had, you made it to graduation, and you are just about to get the key to your dream job – whatever it might be! And you must be so excited to celebrate this very special occasion with those who are closest to you – your closest friends and family.  And you will want to do this in style, in a way that this special occasion deserves – and of course, because there is an official dress code. So, you will need an appropriate University of Arts London graduation gown! And you can get the best quality from Gowning Street.</p>
          <h3>WHY BUY UNIVERSITY OF THE ARTS LONDON ACADEMIC DRESS?</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1637539162/UK%20Uni%20Campus%20Photos/ual-1.jpg" alt="University of the Arts London building"/>
          <p className="para">I know what you are thinking now and while it might sound like an unusual idea to buy your University of the Arts London academic gown, there are actually more benefits to it than you would think! It is way easier and more convenient than hiring. Let us just list a couple to maybe help with the decision making:</p>
          <p className="para">Avoid the long pick up and drop off queues and save yourself time with our handy and reliable delivery service</p>
          <p className="para">Keep it as memorabilia and show it to your extended family and friends who couldn’t attend</p>
          <p className="para">If you team up with your uni friends, you can unlock discounts for all of you</p>
          <h3>HAVE MORE QUESTIONS? CONTACT US!</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-leaving-ceremony.jpg" alt="UAL graduates leaving their ceremony"/>
          <p className="para">If you have any urgent or pressing questions about ordering, our products or anything else, please don’t hesitate to <Link to="/contact">contact us</Link> and one of our wonderful colleagues will get back to you in no time! Or, if you need immediate answers, phone us</p>
          <p className="para">You may also take advantage of our great students discounts, where buying together allows you to save more, check out our <Link to="/save-with-friends">friends deals</Link> here. Our Academic gowns can be shipped NZ-wide for delivery estimates, please see <Link to="/shipping">here</Link> Our range doesn’t just stop at Academic dress, we also supply <Link to="/academic-dress">graduation teddy bears</Link> and graduation frames.
          </p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="UAL graduates holding their graduation hats"/>
          <h3>FURTHER INFORMATION NEEDED?</h3>
          <p className="para">If you have any questions regarding our UAL gowns range, please see our <Link to="/about">About page</Link>. You may also contact us via phone or email, please visit or <Link to="/contact">contact us</Link> page for further details</p>
        </div>
      )
    break;

    case "University of Salford Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>UNIVERSITY OF SALFORD GRADUATION GOWNS</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg"/>
          <p className="para">University of Salford is well-known for its education in a variety of subjects and is industry-leading in the creative industry, training the best professionals in the creative field and in management. And you made it to graduation and soon you are going to belong to the group of University of Salford graduates, ready to enter the job market. But before worrying about that, there is one more thing to think about: the graduation ceremony. The perfect occasion to celebrate this achievement with your closest friends, family and your peers. But since this is an official ceremony, it calls for an official University of Salford academic gown. And fortunately, here at Gowning Street you can find all the official regalia.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-jumping.jpg" alt="Photo of Salford graduates celebrating"/>
          <h3>UNIVERSITY OF SALFORD GRADUATION GOWN HIRE? WHY NOT BUY?</h3>
          <p className="para">Hiring University of Salford graduation gowns is the most common way. But we have a better offer: why not buy it? We know it sounds unusual, but we can list all the benefits of buying and by the end of this, you will wish you heard of this option sooner.</p>
          <p className="para">For example, by buying your academic dress from Gowning Street, you can skip those dreaded pick-up and drop off queues because we will ship your order straight to your door! And what about those friends and distant family members who couldn’t make it to your graduation? By being able to keep your University of Salford graduation gown, it will not only become valuable memorabilia, but you are going to be able to show it off to everyone. </p>
          <p className="para">And lastly: if you team up with your friends from university, you will all receive a discount on your orders.</p>
          <h3>CONTACT US</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Salford graduation hats"/>
          <p className="para">If you have any questions or a bit unsure about ordering, please do not hesitate to <Link to="/contact">contact us</Link> through our online form and one of our advisors will get back to you in no time!</p>
        </div>
      )
    break;

    case "University of London Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>LONDON UNI GRADUATION GOWNS</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg"/>
          <p className="para">You must be so excited! You are about to graduate from the University of London.</p>
          <p className="para">The University of London, which is the birthplace of long-distance learning and pioneer of making higher education available to marginalised groups. It was founded in 1836 and was an alternative to Oxford and Cambridge – Charles Dickens called it “The People’s University”. And now you can be proud of yourself: you are about to get your degree from this fantastic institution. Well done! But as it is an official ceremony, you will need one thing – the appropriate graduation attire before you start planning your celebration with family and closest friends. If you are looking for a University of London graduation gown, then you are at the best place. Gowning Street can help you with everything, and we can guarantee it is consistently high quality.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Photo of university graduate"/>
          <h3>WHY NOT BUY YOUR LONDON UNI ACADEMIC GOWN?</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of university graduates"/>
          <p className="para">Okay, it might sound a bit unconventional compared to the usual hire, but trust us: it works! There are many benefits to choosing to purchase your University of London academic dress instead of hiring one. If you are still unsure, let us list a couple very good reasons.</p>
          <p className="para">Forget the pick-up and drop off lines! We will ship your order straight to your door. Your time is valuable to us!</p>
          <p className="para">Keep the academic dress for later, as memorabilia and show it off to your extended family and friends.</p>
          <p className="para">Team up with your friends, order together and save some money on your order.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of university graduate with parents"/>
          <h3>HAVE MORE QUESTIONS? GET IN TOUCH!</h3>
          <p className="para">If you have any more questions about our products, ordering or anything else, don’t hesitate to <Link to="/contact">contact us</Link> through our website and one of our friendly colleagues will aim to get back to you within 24 hours.</p>
        </div>
      )
    break;

    case "University of Hull Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>LOOKING FOR A UNIVERSITY OF HULL GRADUATION GOWNS</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg"/>
          <p className="para">So you are here because you are about to celebrate one of the biggest achievements of your life: becoming a University of Hull graduate. This university is well-known for its academic excellence,  and are leading researchers in so many academic principles. It is a real honour to graduate from this fantastic university and you made it! Time to think about how you will celebrate this with your closest friends and your family. And of course, you will need one thing for that: an official University of Hull graduation gown. And you are at the best place for this, Gowning Street can sort you out with all the University of Hull regalia!</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Photo of university graduate"/>
          <h3>UNIVERSITY OF HULL ACADEMIC GOWNS</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of university graduates"/>
          <p className="para">But how about buying your University of Hull academic dress instead of hiring it? We know, it is a bit of an unconventional approach but if you keep reading, you will realise how much easier buying would make your life. For example, you could just skip the queues at pick up and drop off and have your order shipped to your door! Convenient, isn’t it? But we also recognise that not all your friends can make it to the ceremony and buying your University of Hull academic dress would mean that you can keep it as a memorabilia and show it off to friends and extended family.</p>
          <p className="para">And who doesn’t like a bargain? So if you team up with your uni friends, and receive a discount for all of you.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of university graduate with parents"/>
          <h3>ANY QUESTIONS? CONTACT US!</h3>
          <p className="para">If you have any questions about our products, ordering or anything else, don’t hesitate to <Link to="/contact">contact us</Link> through our online form and one of our advisors will be in touch in no time! Or if you have more pressing questions, call us and we will answer any questions you might have.</p>
        </div>
      )
    break;

    case "University of Hertfordshire Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>QUALITY UNIVERSITY OF HERTFORDSHIRE GRADUATION GOWNS</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg"/>
          <p className="para">The day finally arrived: once a hopeful fresher, now you are just inches away to becoming a University of Hertfordshire graduate. This university is well-known for its fantastic advances, innovations and excellent training in the aeronautical industry. The university is a true innovative force in improving and supporting this industry in Britain by training the best professionals in the field. And you are just about to join this group of people and make your family and friends proud.</p>
          <p className="para">But as this is an official ceremony, there is one thing to think about: your University of Hertfordshire graduation gown hire for the big occasion. Well, Gowning Street is right here for you, with the best solution. And what if we told you that there is a different way and you can just purchase it instead?</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Photo of university graduate"/>
          <h3>APPROVED UNIVERSITY OF HERTFORDSHIRE ACADEMIC GOWNS</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of university graduates"/>
          <p className="para">Yes, we know that purchasing your academic dress might sound like an unorthodox idea but there are numerous benefits to doing so, such as not having to wait in long pick up or drop off queues. Instead, we will just ship your order straight to your door!</p>
          <p className="para">You also get a discount when you team up with your friends and all place your orders together. And let’s not forget about the sentimental side: purchasing your very own University of Hertfordshire graduation gown means that you get to keep it as a memory and you can show it off to extended family and friends who couldn’t attend the ceremony.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of university graduate with parents"/>
          <h3>HAVE MORE QUESTIONS? CONTACT US!</h3>
          <p className="para">If you have any more questions about the ordering process or our products, do not hesitate to <Link to="/contact">contact us</Link> through our online form and one of our wonderful colleagues will get back to you in no time!</p>
        </div>
      )
    break;

    case "University of Derby Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>LOOKING FOR A UNIVERSITY OF DERBY GRADUATION REGALIA?</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1637539166/UK%20Uni%20Campus%20Photos/derby-2.jpg"/>
          <p className="para">You found this website which can only mean one thing: you made it! Years of hard work, all-night studying and effort finally paid off and soon you are going to get that piece of paper that is your golden ticket to the dream job. But before you start thinking about that, there is one more thing to celebrate your achievement in style and give it the attention it deserves! Yes, we are talking about the graduation ceremony. The occasion to celebrate with your closest friends and your family. But as it is an official ceremony, it requires appropriate wear. Here at Gowning Street you can find the University of Derby graduation gown you are looking for.</p>
          <h3>WHY BUY YOUR UNIVERSITY OF DERBY ACADEMIC GOWN?</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg" alt="Photo of Derby graduates celebrating"/>
          <p className="para">The standard route of acquiring a University of Derby academic dress is hiring one. But we have a better idea: why not buy it? We have a couple good reasons why it is way better than hiring:</p>
          <p className="para">Avoid the queues at pick up and drop off and have the academic gown shipped straight to your door!</p>
          <p className="para">Keep it as a memory to show it to extended family and friends who couldn’t attend your ceremony.</p>
          <p className="para">You don’t have to worry about any deposits or cleaning fees.</p>
          <p className="para">Team up with your uni friends and unlock a discount for everyone in the group!</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Photo of Derby graduates holding their graduation caps"/>
          <h3>HAVE MORE QUESTIONS?</h3>
          <p className="para">Do you have any pressing questions about us, our products or the ordering process? Then don’t hesitate to <Link to="/contact">contact us</Link> through our website. One of our friendly and helpful advisors will be in touch in no time. Or, alternatively, phone us if you have a very pressing question, we are happy to answer anything.</p>
        </div>
      )
    break;

    case "University of Bedfordshire Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>IN SEARCH OF UNIVERSITY OF BEDFORDSHIRE GRADUATION REGALIA?</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg"/>
          <p className="para">Well, look no longer! The fact that you are on this website can only mean one thing: you made it! After years of dedication, hard work and commitment, you are about to become a University of Bedfordshire graduate.</p>
          <p className="para">This university has an excellent reputation for its multicultural communities, overseas opportunities and its Research Institutes. Graduating from this university is a big achievement, so you should celebrate it in style, with your closest friends and family. But of course, as graduation is an official ceremony, you will need an official University of Bedfordshire academic gown. And this is where Gowning Street comes into picture: we sell official University of Bedfordshire regalia to get you ready for the big day.</p>
          <h3>BUY YOUR UNIVERSITY OF BEDFORDSHIRE ACADEMIC DRESS</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of Bedfordshire graduates"/>
          <p className="para">Buying academic gowns is becoming increasingly popular over hiring these days. There are many reasons for this, mainly it being a way more convenient and easier option.</p>
          <p className="para">Instead of standing in long pick-up and drop-off queues, save yourself some time and get your order shipped straight to your door.</p>
          <p className="para">By purchasing your own University of Bedfordshire graduation gown, you are not only going to be able to keep it as memento but show it off to family and friends who were not able to make it to the ceremony. They are proud of you after all!</p>
          <p className="para">And there is one more thing if you are not entirely convinced yet; if you team up with your university friends and place your order together, you will all receive a discount on your order! Doesn’t it sound great?</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of Bedfordshire graduate with parents"/>
          <h3>QUESTIONS? GET IN TOUCH</h3>
          <p className="para">If you have any more questions before placing your order, <Link to="/contact">contact us</Link> through our online form and one of the friendly advisors will be in touch with you within 24 hours.</p>
        </div>
      )
    break;

    case "Leeds Beckett University Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>LOOKING FOR UNIVERSITY OF BECKETT GRADUATION GOWN REGALIA?</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1637539171/UK%20Uni%20Campus%20Photos/leedsb-2.jpg"/>
          <p className="para">Finally you achieved your goal: you are graduating! After all the work, effort and sleepless nights you’ve put into your course, it has finally paid off. This is an exciting time, no doubt but now it is the time to think about the next thing: the graduation ceremony. The proper celebration and recognition of this achievement.</p>
          <p className="para">Because we are sure you will want to celebrate this in style and of course, there is the requirement for appropriate attire, we are here to provide a service that will make this day even more memorable, without any of the stress and worry.</p>
          <p className="para">But where to get a University of Beckett graduation gown? Here at Gowning Street we provide the service you are looking for – and even more.</p>
          <h3>WHY BUY YOUR UNIVERSITY OF BECKETT GRADUATION GOWN INSTEAD OF RENTING?</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of Leeds Beckett graduates"/>
          <p className="para">Firstly, this is the perfect memento of the big day and you can show it off to the extended family who might not have been able to attend your ceremony but are just as proud of you as you closest family members and friends. Even your kids will be able to see it!</p>
          <p className="para">No time wasted in lengthy pick up and drop off queues or worry over cleaning, deposits or damage</p>
          <p className="para">Teaming up with your uni friends really pays off, we offer discounts if you order in a group</p>
          <p className="para">And we send it straight to your door. Convenient, isn’t it?</p>
          <p className="para">The University of Beckett academic gowns provided by Gowning street are all exceptional quality and we are providing them to suit all degree requirements, so we able to help you, no matter what your degree is.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Photo of Leeds Beckett graduates holding their graduation hats"/>
          <h3>INTERESTED IN FINDING OUT MORE?</h3>
          <p className="para">If you are considering getting your University of Beckett academic dress from Gowning Street, <Link to="/contact">get in touch with us</Link>, one of our lovely team members will be happy to assist you with any queries you may have. If you prefer to talk on the phone, just phone us</p>
        </div>
      )
    break;

    case "University College London Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>UCL GRADUATION REGALIA</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg"/>
          <p className="para">Graduating from UCL is a long process and hard work that deserves the proper recognition. You were determined to get into university, complete the course and you are finally just inches away from the finish line after overcoming the last challenge. This is a really happy time for every graduating student, and of course, it deserves a fabulous ceremony to share this day with your peers, friends and your closest family.</p>
          <p className="para">This ceremony calls for a University College London academic gown as part of the official ceremony. And Gowning Street is just the right place to get one just in time, with no extra stress involved.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Photo of university graduate"/>
          <h3>BUY YOUR UCL ACADEMIC DRESS</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of university graduates"/>
          <p className="para">The usual route to acquiring a University College London graduation gown is hiring one. However, at Gowning Street, we encourage students to buy them, and it has many benefits:</p>
          <p className="para">Keep your academic gown as memory and be able to show it to your wider family who might not have been able to attend your ceremony.</p>
          <p className="para">Other friends or family members attending UCL will be able to use it later.</p>
          <p className="para">No time wasted in pick up or drop off lines, the graduation attire is shipped straight to your door.</p>
          <p className="para">If you get your friends involved and team up when ordering, you will be able to save money on your sets.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of university graduate with parents"/>
          <h3>UNIVERSITY COLLEGE LONDON IS NOT THE ONLY UNIVERSITY WE SERVE</h3>
          <p className="para">UCL is just one of the many universities we serve for academic gowns. They are all exceptional quality and we are constantly expanding our range to other universities.</p>
          <h3>INTERESTED IN LEARNING MORE?</h3>
          <p className="para">If you have any questions about how to order, who we are or anything else, don’t hesitate to phone us and one of our friendly advisors will answer any questions you may have. Alternatively, <Link to="/contact">contact us</Link> through our website and we will respond in no time!</p>
        </div>
      )
    break;

    case "Teesside University Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>TEESSIDE UNIVERSITY ACADEMIC GOWNS, TO YOUR DOOR!</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1637539162/UK%20Uni%20Campus%20Photos/teesside-1.jpg"/>
          <p className="para">Teesside University is knows for its innovative,  inspiring work and excellence. With an incredibly varied teaching profile and plenty of academic fields to choose from, it is a popular choice and the university and the Students Union has won numerous awards, which proves that it is a real pride to be the student of this fantastic academic institution. And if you are on this page, it can mean only one thing: you are about to graduate from this brilliant university. Your journey as a student is ending and you are finally about to see the result of your hard work and dedication.</p>
          <p className="para">This is a special day, celebrated with your peers, close friends and family and marks the day of becoming a Teesside University graduate and you are looking for a Teesside University graduation gown hire. You’ve done it!</p>
          <h3>BUY YOUR TEESSIDE UNIVERSITY GRADUATION GOWN FROM GOWNING STREET</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of Teesside uni graduates"/>
          <p className="para">A graduation gown is a memento, a memory that can remind you of your university years and show it later to your friends and family who couldn’t attend the graduation ceremony but they are just as proud of you as your closest loved ones. While the standard procedure is to rent your Teesside University academic dress, there are actually way better options out there now – for example, buying from Gowning Street.</p>
          <p className="para">And to top this off, it actually works out cheaper to buy your Teesside University academic dress, especially if you team up with your friends which can unlock discounts to everyone in your group and have it shipped straight to your door instead of spending precious time in pick-up and drop-off queues.</p>
          <p className="para">Our gowns are adhering to Teesside University standards and we offer all designs and colours for all faculties. You deserve the best and most perfect Teesside University graduation gown and we are delivering that to you – quite literally.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Photo of Teesside graduates holding their graduation hats"/>
          <h3>HAVE QUESTIONS?</h3>
          <p className="para">If you are considering us but have questions, please don’t hesitate to <Link to="/contact">contact us</Link> through our online form and one of our helpful advisors will get back to you in no time with all the answers.</p>
        </div>
      )
    break;

    case "Sheffield Hallam University Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>LOOKING FOR SHEFFIELD HALLAM UNIVERSITY GRADUATION REGALIA?</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg"/>
          <p className="para">Graduation is undoubtedly a very exciting time. After all the years of stress, hard work and staying up all night to study for an exam, then producing a dissertation, you finally reached your destination: you are about to graduate. But before starting to worry about entering the job market, there is one more thing to think about: the graduation ceremony. The graduation ceremony is the time to celebrate with your closest friends and your family. And a ceremony will require appropriate attire: a graduation gown. And Gowning Street is here to help you with that, we provide Sheffield Hallam University graduation gowns. And we are doing all that at affordable prices, so you don’t have to break the bank.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Photo of university graduate"/>
          <h3>WHY BUY YOUR SHU GRADUATION GOWN?</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of university graduates"/>
          <p className="para">The conventional route of acquiring a Sheffield Hallam University academic gown is hiring one. However, we have a better idea: why not buy it? It is going to be excellent memorabilia to show to extended family and friends who couldn’t attend your ceremony. And if there are friends or family attending the same university, it could come handy in the future. But there are other benefits to buying instead of renting as well:</p>
          <p className="para">You can avoid the queues at pick up and drop off, we will get the gown shipped straight to your door!</p>
          <p className="para">No worries about cleaning costs and lost deposits.</p>
          <p className="para">Team up with your university friends and secure a discount for all of you.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of university graduate with parents"/>
          <h3>ANY MORE QUESTIONS?</h3>
          <p className="para">If you have any more questions about us, buying process or our Sheffield Hallam University academic dresses, please do not hesitate to <Link to="/contact">contact us</Link> on our website and one of our lovely advisors will get back to you in 24 hours. We are looking forward to hearing from you!</p>
        </div>
      )
    break;

    case "Royal Holloway Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>A WIDE SELECTION OF ROYAL HOLLOWAY GRADUATION GOWNS</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg"/>
          <p className="para">The Royal Holloway is proud of its extensive history and achievements. As the name suggests, it enjoys the support of royal patrons and boasts of some notable achievements since it opened 150 years ago. From telling the story of women’s suffrage, to excelling at research, the list is almost endless.</p>
          <p className="para">That’s why becoming a student of The Royal Holloway is something you should be proud of! Now as you are reaching the end of your journey, all that hard work and dedication is finally starting to pay off. Soon you will be able to walk down the podium with your peers and celebrate this special day.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Photo of university graduate"/>
          <p className="para">But your graduation ceremony calls for an official and  approved Royal Holloway graduation gown. And here, at Gowning Street we are ready to provide it to you in a way that is just as innovative as your university is!</p>
          <h3>IT IS EASIER TO PURCHASE YOUR ROYAL HOLLOWAY ACADEMIC GOWN!</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of university graduates"/>
          <p className="para">Forget the renting procedure and purchase your Royal Holloway academic dress from us. On a special day like this, waiting in lengthy pick up and drop off queues and rushing to avoid late return fees or worrying about cleaning fees has no place!</p>
          <p className="para">This is why we offer delivery to your doorstop and you will have a valuable keepsake to cherish and remind you of this noteworthy achievement.</p>
          <p className="para">And because we know everyone loves a good deal, if you and your group places an order together, we will give you a discount, making your purchase cheaper than rental! The time of Royal Holloway graduation gown hires is over because we have a better way! </p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of university graduate with parents"/>
          <h3>WANT TO KNOW MORE?</h3>
          <p className="para">If you are pretty much convinced but have a couple more questions, just <Link to="/contact">contact us</Link> through our online form and someone will get in touch with you in no time. Or you can just call us if that is what you prefer!</p>
        </div>
      )
    break;

    case "Plymouth University Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>LOOKING FOR A PLYMOUTH UNIVERSITY GRADUATION GOWN?</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg"/>
          <p className="para">The most exciting time of your studies has arrived: you are about to harvest the fruit of your hard work, dedication and determination. After long years of studying, you are finally graduating and before entering the dreaded job market you are going to celebrate this special day with those who count the most: your closest friends and family who are all proud of your achievement. But there is one more thing to think about before the big celebration: your Plymouth University academic gown. Since this is an official ceremony, you will need the appropriate attire to attend. Granted, renting is the most common way to get your academic dress, but we have a better offer and a way better idea for you, so keep on reading!</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Photo of university graduate"/>
          <h3>BUY YOUR PLYMOUTH UNi ACADEMIC GOWN INSTEAD!</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of university graduates"/>
          <p className="para">We know, this might sound like an unconventional idea but keep reading as there are actually plenty of benefits to buying your own Plymouth University academic dress from Gowning Street. We provide the best quality products and there are many-many other advantages:</p>
          <p className="para">No more queues! We ship your package straight to your front door so you can avoid the pick-up and drop-off queues and save time.</p>
          <p className="para">Save it as memorabilia, show it off to your extended family and friends who couldn’t attend your ceremony and take some more pictures.</p>
          <p className="para">Alternatively, if you have a relative studying at the same university, it could come handy.</p>
          <p className="para">Team up with your friends and unlock discounts on group purchases.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of university graduate with parents"/>
          <h3>HAVE QUESTIONS? CONTACT US!</h3>
          <p className="para">If you have any pressing questions about our products, ordering or anything else, don’t hesitate to <Link to="/contact">contact us</Link> and one of our friendly and helpful advisors will get back to you in 24 hours.</p>
        </div>
      )
    break;

    case "Oxford Brookes University Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>LOOKING FOR OXFORD BROOKES UNIVERSITY GRADUATION REGALIA?</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg"/>
          <p className="para">Oxford Brookes University has an excellent reputation for a range of subjects and from arts to science everyone can find what they are looking for. This university also promotes social responsibility and sustainability which are core values in today’s world.  Attending and graduating this university will put you in a group of people that you can always be proud of. And now you are at the final steps: your hard work paid off and you are planning your graduation ceremony. But as this is a very special occasion, you will need an official Oxford Brookes University graduation gown. And luckily enough, here at Gowning Street we can help you with that.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Photo of university graduate"/>
          <h3>APPROVED OXFORD BROOKES UNIVERSITY ACADEMIC GOWNS</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of university graduates"/>
          <p className="para">But what if we told you, that instead of hiring, you should purchase your Oxford Brookes University academic gown? We know that it might sound like an unorthodox solution but it is getting more and more popular.</p>
          <p className="para">Firstly, it will save you the time of waiting in long pick up and drop off queues by shipping it straight to your door instead so you can spend that time on more important stuff. By purchasing it, you can save it as a memento for later and show it off to extended family and friends as well</p>
          <p className="para">And we have one more benefit for you: if you team up with your friends and order together, all of you will get a discount on your academic gowns. Doesn’t this sound great?</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of university graduate with parents"/>
          <h3>HAVE SOME QUESTIONS? CONTACT US!</h3>
          <p className="para">If you are intrigued but not quite sure yet and you have any questions about our products or anything else, don’t hesitate to <Link to="/contact">contact us</Link> through our website and one of our customer advisors will be in touch with you within one business day</p>
        </div>
      )
    break;

    case "Open University Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>LOOKING FOR AN OPEN UNIVERSITY GRADUATION GOWN?</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg"/>
          <p className="para">The day has finally come, and you are able to enjoy the result of your hard work and dedication: you are just about to graduate. But before starting to think about entering the workforce, there is one more thing: the graduation ceremony to get the recognition for your efforts. And yes, Open University has its graduation ceremonies too, so now you are on the hunt after an appropriate graduation gown. Because you will want to celebrate this big day in style, with your uni friends and your family. Well, you are in the right place, Gowning Street can get you sorted!</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Photo of university graduate"/>
          <h3>BUY YOUR OPEN UNIVERSITY ACADEMIC GOWN INSTEAD!</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of university graduates"/>
          <p className="para">I know you think it is bit of a strange concept: why would you buy your Open University academic dress instead of the usual renting process that is common practice? Well, we can list many different reasons – one of them would be being able to keep it as memorabilia and show it off later to extended family and other friends who weren’t able to attend your ceremony. But there are many other reasons:</p>
          <p className="para">Avoid queues at pick up and drop off and have your graduation gown shipped straight to your door!</p>
          <p className="para">Team up with your friends and unlock a discount for your group!</p>
          <p className="para">We don’t only serve OU, we offer academic gowns for many other universities across England and are constantly expanding our services, so your friends at other universities might want to hear about us too.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of university graduate with parents"/>
          <h3>HAVE MORE QUESTIONS?</h3>
          <p className="para">If you have any more questions about the process or us, just <Link to="/contact">contact us</Link> through our website and one of our friendly advisors will get back to you as soon as. Or, if you would like to get immediate answer, just phone us!</p>
        </div>
      )
    break;

    case "Cardiff University Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>CARDIFF UNIVERSITY GRADUATION GOWN HIRE? WHY NOT BUY?</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg"/>
          <p className="para">You are looking at this website and that can only mean one thing: you made it! After years of admirable effort, work – and maybe even dreaded group work – you have reached the finish line and you are just about to graduate. But before thinking about entering the job market and putting your shiny new degree to use, there is one more thing to think about: the proper recognition of your achievement. The graduation ceremony, where you can celebrate with your peers and your family.</p>
          <p className="para">Of course, this calls for a special attire and you will need a Cardiff University graduation gown. The most obvious way would be rental, but there are other ways around it. You don’t need the unnecessary hassle to celebrate in style!</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Photo of university graduate"/>
          <h3>REASONS TO BUY CARDIFF UNIVERSITY ACADEMIC GOWN</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of university graduates"/>
          <p className="para">Buying your academic dress instead of hiring might seem like a bit of an unconventional idea. But there are actually more benefits to it than you would think. It is also way more convenient than you would think. For example, you are going to be able to keep it as a memory of this important day or show it off to the entire family who couldn’t make it to the ceremony. But we have some more reasons if that is not enough!</p>
          <p className="para">No long queues at the pick-up and drop off for rental!</p>
          <p className="para">You get a group discount if you get your university friends involved and you order in a group.</p>
          <p className="para">It arrives straight to your door.</p>
          <p className="para">No worries about rental windows or late fees.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of university graduate with parents"/>
          <h3>HAVE MORE QUESTIONS? CONTACT US!</h3>
          <p className="para">We are happy to help with any enquiry or question you might have. You just have to pick up the phone and <Link to="/contact">contact us</Link> and our friendly and helpful colleagues will be in touch with you soon. Or if you need more information, find out more about us and our values.</p>
        </div>
      )
    break;

    case "Canterbury Christ Church University Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>CANTERBURY CHRIST CHURCH UNIVERSITY GRADUATION GOWNS, DELIVERED TO YOUR DOOR</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-jumping.jpg"/>
          <p className="para">Silver in the Teaching Excellence Framework, an award-winning creative arts facility, a very own Business Awards – these are just few of the things Canterbury Christ Church University (or CCCU for short) excels at. With a plethora of fantastic degree programmes, anyone would rightly be proud to become a graduate of this university.</p>
          <p className="para">You have made it to the end of your university journey and that can only mean one thing: you are looking for a Canterbury Christ Church University graduation gown hire. Because your graduation ceremony is calling for Canterbury Christ Church University official regalia to celebrate the big day. Fortunately, here at Gowning Street you can get it all in one place!</p>
          <h3>WHY NOT BUY YOUR CCCU GRADUATION GOWN?</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-leaving-ceremony.jpg" alt="Photo of CCC graduates"/>
          <p className="para">Your graduation gown is not only an outfit for the day, it’s a symbol of your hard work and efforts over the years. Therefore, we believe it should be treated as memorabilia and kept as a treasure. That is why we are advocating for purchasing your Canterbury Christ Church University academic dress over hiring. And there are a couple more advantages as well:</p>
          <p className="para">No queues at all! Our orders are shipped straight to your door, saving time you would spend in queues. And if that is not enough, you and your friends can save money if you team up and place your orders together!</p>
          <p className="para">All our gowns are meeting university specifications and standards, making sure that the colour, tailoring and the cut of your academic gown are fit for your perfect big moment on that stage.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-celebrating-stairs.jpg" alt="Photo of CCC graduates"/>
          <h3>GET IN TOUCH WITH US</h3>
          <p className="para">If you have any more questions or if you simply just want to talk to someone before ordering, <Link to="/contact">contact Gowning Street</Link> through our online form and someone will be back to you in absolutely no time! Or you can just simply give us a ring. We love hearing from graduates.</p>
        </div>
      )
    break;

    case "University of Gloucestershire Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>UNIVERSITY OF GLOUCESTERSHIRE GRADUATION GOWNS, DELIVERED TO YOUR DOOR</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg"/>
          <p className="para">University of Gloucestershire is a fantastic university to study teaching (as it originally started as a teacher training college) or just about anything due to their rapidly expanding course list.</p>
          <p className="para">After several years of being part of this fantastic university community, you are about to become a graduate of University of Gloucestershire. But before starting to think about getting a job, it is time to plan the appropriate celebration of your achievement: the graduation ceremony! And it calls for an official University of Gloucestershire graduation gown.</p>
          <p className="para">Well, you are at the best possible place for that. Gowning Street is here to help you with all your University of Gloucestershire regalia-related needs – just keep reading!</p>
          <h3>GET YOUR GLOS ACADEMIC GOWN FROM GOWNING STREET</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-jumping.jpg" alt="Photo of Gloucestershire graduates"/>
          <p className="para">However, instead of the usual renting process, why don’t you buy your academic gown instead? While it sounds unconventional a bit, it is a much easier way of acquiring your University of Gloucestershire academic dress. And here are the reasons why:</p>
          <p className="para">Forget the endless pick up and drop off lines and save time! We are just shipping your order straight to your door.</p>
          <p className="para">Keep your academic dress as memorabilia and show it off to your extended family and your friends who might not have been able to attend your ceremony.</p>
          <p className="para">And the best part: if you team up with your friends from university and place your order together, we will give a discount to all of you! Isn’t that amazing?</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Photo of Gloucestershire graduates with their graduation hats"/>
          <h3>QUESTIONS? CONTACT US!</h3>
          <p className="para">If you are almost ready to order, but not quite there yet and you have any pressing questions, <Link to="/contact">contact us</Link> through our online form and one of our fantastic advisors will be in touch with you in no time. Or alternatively, if you are more of a phone call person, just give us a ring.</p>
        </div>
      )
    break;

    case "University of West London Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>A VARIETY OF UWL GRADUATION GOWNS</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1637539162/UK%20Uni%20Campus%20Photos/westlon-1.jpg" alt="Photo of UWL"/>
          <p className="para">So the day has come: after being a University of West London student for years, you are ready to become a proud graduate. Thanks to its fantastically career oriented courses and teaching, you are more than ready to start your dream job. But before you are heading off sending job applications, it is time to think about one more thing: the graduation ceremony. This is the celebration of your achievement; therefore it calls for appropriate attire.</p>
          <p className="para">Here at Gowning Street, we take pride in supplying official and approved University of West London regalia. Not only that, but we make the process so much easier as well!</p>
          <h3>A BETTER WAY TO GET YOUR UWL ACADEMIC GOWN</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-jumping.jpg" alt="Photo of UWL graduates"/>
          <p className="para">University graduation gown hire programs are a thing of the past! Gowning Street is committed to creating an easier way to acquiring your University of West London academic dress. We want to say goodbye to long pick up and drop off queues, late return fees and cash-only payments!</p>
          <p className="para">We want to make this process hassle-free by shipping your order straight to your door, making the purchase just as easy as buying any other clothing item.</p>
          <p className="para">Purchasing your very own UWL academic gown also means that you walk away with valued memorabilia after your graduation ceremony.</p>
          <p className="para">And if that is not enough, you can also save money: we offer group discounts if you team up with your friends and place your order together.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Photo of UWL graduations holding their graduation hats"/>
          <h3>ANY PRESSING QUESTIONS?</h3>
          <p className="para">If you are pretty much ready to place your order, but still have some questions, our customer service team is happy to answer all of them. Just <Link to="/contact">contact us</Link> through our online form and one of our customer service assistants will get back to you in no time. Or, just give us a ring!</p>
        </div>
      )
    break;

    case "University of Westminster Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>HIGH QUALITY WESTMINSTER UNI GRADUATION GOWNS</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1637539164/UK%20Uni%20Campus%20Photos/westminster-1.jpg"/>
          <p className="para">With University of Westminster being such a renowned institution for research and having multiple campuses internationally, anyone would be proud of being a student here. The university is a proud holder of multiple top ranks in national and international lists and Westminster alumni achieved some impressive things, such as BAFTA awards, Oscars, Grammy awards and even a Nobel laureate in Medicine!</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Westminster uni graduates holding their graduations hats"/>
          <h3>THE BEST QUALITY WESTMINSTER UNI GRADUATION GOWN</h3>
          <p className="para">Your graduation day marks a very important chapter in your life. After years of hard work, dedication and all-nighters, you are finally about to see the result and you are ready to get your dream job. And as it is such an important day, it calls for an attire that suits it.</p>
          <p className="para">But we want to say goodbye to the experience of having to stand in long queues and having to rush to return the gowns to avoid hefty late return fees. Instead, you could purchase your University of Westminster academic dress and not only save yourself a whole lot of money and effort but have a valuable keepsake after your graduation ceremony to mark this chapter of your life.</p>
          <h3>KEEP YOUR WESTMINSTER UNI ACADEMIC GOWN</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-leaving-ceremony.jpg" alt="Photo of Westminster Uni graduates"/>
          <p className="para">After all, your university experience is a significant part of your life and Gowning Street only provides the best quality of officially approved academic gowns, shipped straight to your door to save time and effort. Because this day is all about a positive experience, not tight return windows!</p>
          <p className="para">And if that is not enough, if you buy a set of gowns for your friends as well, we will give you a discount, meaning that the purchase price will be lower than the rental fee.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of university graduate with parents"/>
          <p className="para">And if you are ready to order but have a few more questions about our products or the ordering process, just <Link to="/contact">contact us</Link> through our online form and we will get back to you in no time!</p>
        </div>
      )
    break;

    case "University of Worcester Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>A BETTER ALTERNATIVE TO WORCESTER UNI GRADUATION GOWN HIRE</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1637539164/UK%20Uni%20Campus%20Photos/worcester-2.jpg"/>
          <p className="para">Finally, after years of dedication, hard work and effort, you reached this glorious moment in your life: you are about to graduate from the fantastic University of Worcester! Getting into this university is an achievement on its own, and you should be even prouder of reaching the finish line.</p>
          <p className="para">But in the middle of finishing off assessments, studying for final exams and planning your dream career, the effort of hiring your University of Worcester graduation gown is just something you do not need. Forget the lengthy pick up and drop-off queues, and two-hour return windows!</p>
          <p className="para">Your graduation day is all about celebrating your achievement with your friends, family and course mates. That is why Gowning Street wants to offer a simpler, more convenient and cheaper alternative to renting. We believe that acquiring your University of Worcester academic dress should be just as easy as buying any other item!</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-leaving-ceremony.jpg" alt="Photo of Worcester graduates leaving ceremony"/>
          <h3>WORCESTER UNI ACADEMIC GOWNS THAT MAKE THE CUT</h3>
          <p className="para">Gowning Street supplies high-quality gowns that meet all University of Worcester criteria, making it entirely suitable to walk down that podium in it! And if that is not enough, we have a few more reasons why you should choose to purchase your very own University of Worcester academic dress:</p>
          <p className="para">We will ship your order straight to your door!</p>
          <p className="para">You get to skip the long queues at pick-up and drop-off.</p>
          <p className="para">Forget about late return and cleaning fees!</p>
          <p className="para">You get to keep it as a valuable keepsake to remind you of your achievement.</p>
          <p className="para">And if that is not enough, if you and your university friends team up and place your order together, we will offer a discount on every set you order! Who doesn’t love a good deal? </p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg" alt="Worcester uni graduates celebrating"/>
          <h3>GET IN TOUCH WITH US!</h3>
          <p className="para">You can order through our website, or if you would like to know more about us or our products, <Link to="/contact">contact us</Link> through our enquiry form or give us a ring. We can’t wait to hear from you!</p>

        </div>
      )
    break;

    case "Bournemouth University Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>FOR BOURNEMOUTH UNIVERSITY GRADUATION REGALIA, LOOK NO FURTHER!</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1637539165/UK%20Uni%20Campus%20Photos/bmouth-1.jpg"/>
          <p className="para">Bournemouth University has an excellent reputation for training globally employable graduates and excelling in research and having a bright vision for the university. And now you are going to be one of their graduates!</p>
          <p className="para">But before you jump into the job market with your fresh degree, you have to think about the graduation ceremony. This is the occasion to celebrate your big achievement appropriately, with your closest friends and family who are all very proud of you. But as this is an official ceremony, it calls for an official Bournemouth University graduation gown. Well, here at Gowning Street we can help you with all graduation-related needs!</p>
          <h3>BUY YOUR BOURNEMOUTH UNIVERSITY ACADEMIC DRESS</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1637539166/UK%20Uni%20Campus%20Photos/bmouth-2.jpg" alt="Bournemouth uni aerial view"/>
          <p className="para">More and more graduates are purchasing their graduation gowns instead of the traditional hiring. Mainly because hiring is a complicated and a time-consuming process. Here, at Gowning Street you can purchase your very own Bournemouth University academic gown. And if that is not enough, here is why you should do so:</p>
          <p className="para">Save time by getting your order shipped straight to your door instead of standing in long pick up or drop off lines!</p>
          <p className="para">Keep your academic dress as memento and show it off to wider family and friends who can’t attend your ceremony. Or, if you have a family member attending Bournemouth University, they are going to be able to use it as well.</p>
          <p className="para">Teaming up and ordering together with your university friends means all of you gets a discount! Isn’t that great?</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1637539167/UK%20Uni%20Campus%20Photos/bmouth-4.jpg" alt="Photo of Bournemouth uni campus"/>
          <h3>ANY QUESTIONS? CONTACT US!</h3>
          <p className="para">We know you might have some pressing questions about our products or on how to order. Simply just <Link to="/contact">contact us</Link> through our website and one of our advisors will get back to you soon.</p>
        </div>
      )
    break;

    case "London Metropolitan University Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>LONDON METROPOLITAN UNIVERSITY GRADUATION GOWNS</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1637539171/UK%20Uni%20Campus%20Photos/lmet-1.jpg"/>
          <p className="para">London Metropolitan University (or commonly known the London Met) is home to a wide range of degrees and other qualifications, located in a wonderful modern campus, close to the buzz of London. In this environment, studying gets a hundred times better, but you are nearing the end of your university journey and you are preparing for your graduation. After years of studying and hard work, you are about to see the results of it and have the golden ticket to your dream job. But there is one more thing.</p>
          <p className="para">The graduation ceremony is the right way to celebrate this with your friends, family and peers. But you need one important thing for it – a London Metropolitan University graduation gown! It is an official ceremony after all. While the traditional way of acquiring one is to hire it, here at Gowning Street we have a better suggestion.</p>
          <h3>BUY YOUR LONDON METROPOLITAN UNIVERSITY ACADEMIC GOWN</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1637539170/UK%20Uni%20Campus%20Photos/lmet-3.jpg" alt="LMET campus photo"/>
          <p className="para">Yes, we know this sounds weird. Everyone hires their academic gowns, why would you buy it? It is a less known and much easier way to get ready for your big event. And there are plenty of benefits to it.</p>
          <p className="para">For a starter, you just get to skip the long queues and waiting at pick-up and drop-off and let us ship your order straight to your door. You also get to keep your London Metropolitan University academic dress as memorabilia and show it to all your friends and family who could not attend your ceremony. </p>
          <p className="para">And we leave the best for the last: if you team up with your university friends and place your order together, you will all receive a discount!</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-leaving-ceremony.jpg" alt="Photo of LMET graduates"/>
          <h3>ANY QUESTIONS? WE ARE HERE!</h3>
          <p className="para">If you are ready to order but have a few more questions, do not hesitate to <Link to="/contact">contact us</Link> through our online form and one of our fantastic advisors will get back to you in no time.</p>
        </div>
      )
    break;

    case "Keele University Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>ARE YOU AFTER A KEELE UNIVERSITY GRADUATION GOWN HIRE?</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1637539169/UK%20Uni%20Campus%20Photos/keele-2.jpg"/>
          <p className="para">The fact that you are searching for Keele University graduation gown hire can only mean one thing: you are ready to see the result of hard work and dedication in the form of a degree. The fact that you are getting that sheet from the prestigious Keele University is just the icing on the cake, considering that the National Student Survey ranked it No.1 in research in 2018! No matter what your degree is in , this is an achievement that needs to be celebrated in style.</p>
          <p className="para">And because it is an official ceremony, it requires official Keele University academic gown. Luckily, here at Gowning Street you can find everything you need. But there is a twist to it!</p>
          <h3>BUY YOUR KEELE UNIVERSITY GRADUATION GOWN!</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg" alt="Photo of Keele graduates"/>
          <p className="para">Yes, you read that right. Everyone is hiring their academic gowns, but we have a better solution. While it might sound a bit unconventional, there are plenty of benefits to purchase over hire. Just to list a few:</p>
          <p className="para">You will not have to deal with the long queues at pick up and drop-off. Let us save you time by just shipping your order straight to your front door.</p>
          <p className="para">Purchasing your very own Keele University academic dress means that you will get to keep it after the ceremony and show it off to your extended family and all your friends and take plenty of photographs to celebrate this milestone in your life.</p>
          <p className="para">And finally: everyone loves a good bargain! That is why we will give a discount to you and your friends if you decide to team up and place your orders together. Doesn’t that sound amazing? </p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Photo of Keele graduates holding their graduation hats"/>
          <h3>CONTACT US!</h3>
          <p className="para">If you are not quite sure yet, or have any questions, just <Link to="/contact">contact us</Link> through our handy online form and one of our friendly advisors will respond to your query in absolutely no time.</p>
        </div>
      )
    break;

    case "Edinburgh Napier University Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>EDINBURGH NAPIER GRADUATION GOWN HIRE? NO PROBLEM!</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1637539167/UK%20Uni%20Campus%20Photos/enap-3.jpg"/>
          <p className="para">So, you are about to become a graduate of Edinburgh Napier University. This establishment, located in the gorgeous capital of Scotland is renowned for its excellent training in various scientific fields and is offering a wide range of other courses, training excellent professionals. Whatever your field might be, you can be proud of yourself that you reached this fantastic milestone. Now, the next thing to plan is your graduation ceremony to celebrate this fantastic achievement with your friends and family. Of course, this calls for an official Edinburgh Napier University graduation gown as appropriate attire. At Gowning Street, you can find official regalia and we even have a twist for you!</p>          
          <h3>WHY NOT BUY YOUR EDINBURGH NAPIER ACADEMIC GOWN?</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1637539167/UK%20Uni%20Campus%20Photos/enap-2.jpg" alt="Photo of Edinburgh Napier campus"/>
          <p className="para">At Gowning Street, we have a little different approach than our competitors and we think that buying your Edinburgh Napier academic dress is actually a much better solution than hiring it. There are many reasons for this, mainly the fact that you can just skip those dreaded pick-up and drop off queues and have your order shipped straight to your door instead.</p>
          <p className="para">Or, you can keep your academic gown as memento and show it off to or your friends and the distant aunts who just couldn’t make it to your graduation but are just as proud of you as mum.</p>
          <p className="para">And if that is not enough, who doesn’t like a deal? If you team up with your course mates, you will all receive a discount on your order, making this not only convenient but a much better offer than hiring.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg" alt="Photo of Edinburgh Napier graduates celebrating"/>
          <h3>QUESTIONS? CONTACT US!</h3>
          <p className="para">If you are almost convinced but still have some questions, <Link to="/contact">contact us</Link> through our online form with any questions you might have, and we will get back to you in no time!</p>
        </div>
      )
    break;

    case "Edge Hill University Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>EDGE HILL UNIVERSITY GRADUATION GOWN HIRE</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg"/>
          <p className="para">So, here you are. After many years of tireless study and effort, you are about to become a graduate of Edge Hill University. This university has a wide range of courses to choose from and no matter what you studied, it is going to be valued and acknowledged when you enter the dreaded job market. But, before we think about that, there is one more thing to consider. Yes, it is your graduation ceremony, the occasion to celebrate your fantastic achievement with fellow students, your friends and your family.</p>
          <p className="para">This is going to be an unforgettable occasion and it is calling for an appropriate attire, the official Edge Hill University graduation gown. And that is why you are here, looking for that. Well, let us guide you through this confusing process and show you a new way of dealing with this seemingly daunting task.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Photo of university graduate"/>
          <h3>WHY NOT BUY YOUR EDGE HILL UNIVERSITY ACADEMIC GOWN? </h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of university graduates"/>
          <p className="para">We know that this sounds very unconventional, but more and more students are shifting from renting their academic dress to purchasing it. This is mainly due to the high rental prices and the inconvenient renting process. That’s why Gowning Street pledged to make a difference and start selling official Edge Hill University regalia. And there are many benefits to purchasing your very own academic dress:</p>
          <p className="para">No more pickup and drop off queues! If you place an order with us, we will just ship your package straight to your door, saving you hours of your precious time.</p>
          <p className="para">Keep it as memorabilia, show it off to your friends, family, neighbours! Or if you know anyone studying at the same university, that’s them sorted!</p>
          <p className="para">Convince your friends to join in on your order and save some money for everyone with our group discount.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of university graduate with parents"/>
          <h3>GET IN TOUCH</h3>
          <p className="para">If after reading this, you are intrigued and you have any questions, <Link to="/contact">contact us</Link> through our website or simply give us a call. We are looking forward to hearing from you!</p>
        </div>
      )
    break;

    case "Plymouth Marjon University Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>THE BEST PLACE FOR A PLYMOUTH MARJON UNIVERSITY GRADUATION GOWN</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg"/>
          <p className="para">Plymouth Marjon University is famous for its world-class sports facilities and its modern, spacious and student-centered campus. If you are a graduate of this fantastic institute, you almost got the golden ticket to the next stage of your life.</p>
          <p className="para">But between writing your dissertation and hunting for your dream job, there is one more big milestone: your graduation ceremony, to celebrate this massive achievement with your closest friends and your family. And you most certainly came to the right place: Gowning Street is here to meet all your Marjon University regalia-related needs. We are not only proud of our quality products that meet all university specifications, but our service is one of its kind!</p>
          <h3>A NEW APPROACH TO MARJON UNIVERSITY GRADUATION GOWN HIRE</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of Plymouth Marjon graduates"/>
          <p className="para">Here, at Gowning Street we believe that there is a better way to obtain the attire for this special day than renting it. That is why you can purchase your Marjon academic dress from us. And this is not the only upside; we also offer a group discount if you team up with your university friends and place an order together. But wait, there is even more:</p>
          <p className="para">We ship your order to your door, meaning that you don’t have to worry about those long pick up and drop off queues. All you need to do is wait for that doorbell to ring.</p>
          <p className="para">You can also wave goodbye to late drop off and dry-cleaning fees.</p>
          <p className="para">And finally, you get to keep your graduation gown as a valuable keepsake to remind you of this day. Not only that, but you will be able to take pictures with your extended family and friends who might not have been able to make it to your ceremony.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-celebrating-stairs.jpg" alt="Photo of Plymouth Marjon graduates"/>
          <h3>QUESTIONS? CONTACT US!</h3>
          <p className="para">If you are almost ready to order but have a few more questions about the process, shipping or anything else, <Link to="/contact">contact us</Link> through our website and we will be back in touch with you in no time! We would be happy to hear from you!</p>
        </div>
      )
    break;

    case "University of Lincoln Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>ARE AFTER A UNIVERSITY OF LINCOLN GRADUATION GOWN HIRE?</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1637539169/UK%20Uni%20Campus%20Photos/lincoln-2.jpg"/>
          <p className="para">The fact that you are on this page can only mean one thing: you made it! After years of hard work and dedication, you are about to become a graduate of the University of Lincoln. This university has a reputation for its research work and conducting cutting-edge research in the medical field. No matter what your course was, this is an exciting time for you, your friends and your family.</p>
          <p className="para">And as this is an official ceremony it is calling for an appropriate attire: an official University of Lincoln graduation gown to walk down that stage. And here, at Gowning Street we can provide all the University of Lincoln regalia you will need – and we are committed to make everything absolutely painless and smooth in the process.</p>
          <h3>CA NEW WAY OF GETTING A UNIVERSITY OF LINCOLN ACADEMIC GOWN</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg" alt="Photo of Lincoln Uni graduates"/>
          <p className="para">While universities are still promoting graduation gown hire as a way of obtaining one, Gowning Street found a better way to get around this problem and it is to purchase one. And while it sounds like an odd decision, it is better than you would think!</p>
          <p className="para">For example, save time by ordering from us and skipping queues at pick up and drop off because we are shipping your order straight to your door! And by not having to return it, you are going to be able to show it off to all your friends and your extended family (who are probably also really proud of you for your achievement).</p>
          <p className="para">And if that isn’t enough, who doesn’t love a bargain? If you team up with your friends and place your orders together, you will receive a discount</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Photo of Lincoln uni graduates with their graduation hats"/>
          <h3>HOW TO CONTACT US</h3>
          <p className="para">If you are almost ready to order, but still have some pressing questions, <Link to="/contact">contact us</Link> through our online form and one of our helpful and friendly advisors will be in touch with you in no time! Or, alternatively, just give us a call</p>
        </div>
      )
    break;

    case "Lancaster University Graduation Gown Sets" :
      description = (
        <div className="su-description">
          <h2>A FANTASTIC SELECTION OF LANCASTER UNIVERSITY GRADUATION GOWNS</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1637539169/UK%20Uni%20Campus%20Photos/lancaster-2.jpg"/>
          <p className="para">The fact that you are Googling “Lancaster University graduation hire” and ended up on this site can only mean one thing: the years of hard work, all night studying and the enormous amount of effort all paid off and graduation is ahead of you. But before you move on to the next challenge - finding your dream job – there is one more thing to think of: your graduation ceremony.</p>
          <p className="para">As this is a very special event marking this big milestone, it calls for a special attire: a Lancaster University graduation gown. Here, at Gowning Street, we take pride in providing official Lancaster University regalia that meets all specifications.</p>
          <h3>CHOOSE GOWNING STREET TO BUY YOUR LANCASHIRE UNIVERSITY ACADEMIC GOWN</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg" alt="Photo of Lancaster Uni graduates"/>
          <p className="para">While renting is the traditional route to acquiring your desired academic dress, purchasing it is starting to become more and more popular among graduates. Why? The reasons are really simple: you are not only able to save money when you team up with your university friends and place an order together, but this way is much more convenient. Purchasing your very own Lancashire University also brings additional benefits:</p>
          <p className="para">We are shipping your order directly to you, meaning you can forget about lengthy pick up and drop off queues! You just have to wait for the delivery man to knock on your door.</p>
          <p className="para">Similarly, there are no late drop-off and dry-cleaning charges, giving you a complete peace of mind.</p>
          <p className="para">And the best thing: you get to keep your graduation gown as a valuable keepsake and be able to take photos with extended family and friends who might not have been able to make it to your ceremony.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Photo of Lancster uni graduates holding their graduation hats"/>
          <h3>ARE YOU READY TO ORDER? CONTACT US!</h3>
          <p className="para">If you feel that you are ready to order, but maybe have a few more questions, <Link to="/contact">contact us</Link> through our website, or simply lift up the phone and chat to one of our friendly and effective customer service advisors</p>
        </div>
      )
    break;

    default:
      description = (
        <div className="su-description">
          <h2>{uniName} For Sale</h2>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg"/>
          <p className="para">Gowning Street provides a wide range of <Link to="/academic-dress">graduation regalia</Link>, including <Link to="/academic-dress/bachelor-gown">bachelor gowns</Link>, <Link to="/academic-dress/master-gown">master gowns</Link> and PhD gowns. We also sell <Link to="/academic-dress/graduation-cap">caps</Link> and gifts such as graduation teddies and <Link to="/certificate-frame">certificate frames</Link></p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1638055193/Placement%20Photos/Graduation-hat-celebration.jpg" alt="Photo of university graduate"/>
          <h3>WHY RENT WHEN YOU CAN BUY FOR LESS?</h3>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Photo of university graduates"/>
          <p className="para">We know that this isn't what you are used to, but more and more students are shifting from renting their academic dress to purchasing it. They recognise that high rental prices and the inconvenience of renting process means that there is a better way. That’s why Gowning Street pledged to make a difference to start selling {uniName} that comply with the University regulations.</p>
          <p className="para">And there are many benefits to purchasing your very own academic dress, which can be kept as a lifelong memorabilia, or handed down to siblings.</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Photo of university graduate with parents"/>
          <h3>CONTACT US WITH ANY QUESTIONS</h3>
          <p className="para">Please visit our <Link to="/contact">contact us</Link> page if you have any remaining questions. We look forward to hearing from you!</p>
        </div>
      )

  }

  return (
    <div className="uni-description">
      {description}
    </div>
  )
}

export default UniDescriptions;
