import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Products } from '../sources/products';
import { HOOD } from '../../constants';
import Qty from './selections/qty';
import { checkInventory } from './selections/checkaddtoinv';
import CustomButton from './custom-items/button-loader';
import Popup from './selections/popup';
import ShippingTable from './info/shipping-calculator';

class HoodOnly extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue : '',
      searchResults : [],
      name : null,
      image : null,
      hood : null,
      qty : 1,
      hoodStyle : null,
      error : null,
      xhrLoaded : false,
      popup : false
    };

    this.onChange = this.onChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.setImage = this.setImage.bind(this);
    this.setQty = this.setQty.bind(this);
    this.toggleState = this.toggleState.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
  }

  onChange(event) {
    this.setState({searchValue: event.target.value.substr(0, 140)});
    var results = [];

    for(var i in Products) {
      var lowerCaseTerm = event.target.value.toLowerCase();

      if(Products[i].colour){
        if (Products[i].colour.toLowerCase().indexOf(lowerCaseTerm) !== -1 ||
            Products[i].name.toLowerCase().indexOf(lowerCaseTerm) !== -1 ||
            i.toLowerCase().indexOf(lowerCaseTerm) !== -1 || Products[i].hood.toLowerCase().indexOf(lowerCaseTerm) !== -1) {

          results.push({
            name : Products[i].colour,
            uni : Products[i].hood,
            hood : Products[i]["hood-code"],
            hoodStyle : Products[i]["hood-style"],
            image : Products[i].photos[2],
            colors : Products[i].colors
          });

        }

      }

    }

    if(results.length === 0) {
      results = [
        {
          name : 'Sorry, no results matched your search.'
        }
      ];
    }
    if(event.target.value.length < 2) {
      results = [];
    }
    this.setState({
      searchResults : results
    });

  }

  handleClick(){
    const context = this;
    const toCheck = {};

    toCheck.code = this.state.hood;
    toCheck.qty = 1

    checkInventory(toCheck, function(fails){
      if(fails.length === 0) {
        context.props.onClick(
          context.state.name,
          null,
          null,
          context.state.hood,
          context.state.hoodStyle,
          context.state.qty,
          HOOD,
          "hood-only",
          context.state.image
        );

        context.setState({
          popup : true,
          xhrLoaded : true
        });
      }
      else {
        context.setState({
          error : fails,
          xhrLoaded : true
        });
      }
    });
  }

  setImage(image, hood, hoodStyle, name, event){
    this.setState({
      image : image,
      hood : hood,
      hoodStyle : hoodStyle,
      name : name,
      searchResults : [],
      error: null,
    });
  }

  setQty(qty){
    this.setState({
      qty : qty
    });
  }

  hidePopup(){
    this.setState({
      popup : false
    });
  }

  toggleState(index, event){
    var description = document.getElementById(index);
    var button = document.getElementById("toggle-shipping");

    if(description.style.display === "none"){
      description.style.display = "";
      button.src = "https://res.cloudinary.com/gowningstreet/image/upload/v1549165140/Utility/minus_box_grey_24x24.png";
    }
    else {
      description.style.display = "none";
      button.src = "https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Utility/add_box_grey_18x18.png";
    }
  }

  render() {

    var context = this;

    var searchResults = this.state.searchResults.map(function(element, index, array){
      if(index < 50){
        if(!element.uni){
          return (
            <h2 key={element.name}>{element.name}</h2>
          )
        }

        var img_photo = element.image;

        return (
          <div
            onClick={() => context.setImage(element.image, element.hood, element.hoodStyle, element.uni)}
            className="resultunit"
            key={element.uni}
          >
            <div className="unicolor">
              <img className="samplePhoto" src={img_photo}></img>
            </div>
            <div className="resultdes">
              <p>
                <span className="bold">{element.name.indexOf("Stole") === -1 ? "Hood :" : "Stole :"} </span>
                {element.name}
              </p>
              <p className="matchesdes">
                <span className="bold">Matches: </span>
                {element.uni}
              </p>
            </div>
          </div>
        )
      }

    })

    var hoodPurchase = null;

    if(this.state.image){

      var errors = null;

      if(this.state.error){
        var errors = this.state.error.map(function(error){
          return (
            <p key={error.code}>
              Sorry, we only have {error.maxQty} of {error.description}. Please choose a lower amount.
            </p>
          )
        });
      }

      var hidden = {
        display : "none"
      }

      hoodPurchase = (
        <div className="hoodproduct">
          <img src={this.state.image} />
          <h2>{this.state.name}</h2>
          <h3>{this.state.hoodStyle}</h3>
          <h4>£{HOOD}</h4>
          <div className="quantity">
            <h3>Qty</h3>
            <Qty setQty={this.setQty}/>
          </div>
          <div className="errors">
            {errors}
          </div>
          <CustomButton
            handleSubmit={this.handleClick}
            content="Add to Cart"
            xhrLoaded={this.state.xhrLoaded}
          />
          <div className="shippingdes">
            <div className="shipping-header" onClick={this.toggleState.bind(this, "shippingtable")}>
              <img id="toggle-shipping" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Utility/add_box_grey_18x18.png" />
              <h4>Shipping Guide</h4>
            </div>
            <div id="shippingtable" style={hidden}>
              <ShippingTable />
            </div>
          </div>
        </div>
      )
    }


    return (
      <div className="searchHoods">
        <Helmet
          title="Graduation Hoods | University Academic Hoods - Gowning Street"
          meta={[
            {"name": "description", "content": "Buy Oxford and Cambridge Style academic and graduation hoods. Simply search for a colour, style, or a UK University or degree."},
          ]}
        />
        <h1>Buy Academic and Graduation Hoods</h1>
        <h3>Search a Colour, University or Degree</h3>
        <div className="hoodsearch">
          <input
            placeholder="E.g. Red, London or Arts"
            type="text"
            value={this.state.searchValue}
            onChange={this.onChange}
          />
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165120/Utility/search_grey_18x18.png"/>
        </div>
        <div className="searchresults">
          {searchResults}
        </div>
        {hoodPurchase}
        {this.state.popup === true ?
          <Popup
            price={HOOD}
            qty={this.state.qty}
            hidePopup={this.hidePopup}
            name={this.state.name}
          /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.shoppingCart
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClick: (name, gown, cap, hood, hoodStyle, qty, price, productType, image) => {
      dispatch({
        type: "ADD_TO_CART",
        name: name,
        gown: gown,
        cap: cap,
        hood: hood,
        hoodStyle: hoodStyle,
        qty: qty,
        price: price,
        productType : productType,
        image : image
      })
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HoodOnly);


