import React from 'react';
import Helmet from 'react-helmet';

const Friends = () => {
  var amounts = [2,3,4,5,6,7,8,9,10];

  var deals = amounts.map(function(element){
    return (
      <div className="deal" key={element}>
        <p className="deal-head">Buy {element}</p>
        <p className="deal-amount">GET £{element} OFF</p>
        <p className="deal-foot">each gown, no extra shipping cost</p>
      </div>
    )
  });

  return (
    <div className="friends">
      <Helmet
        title="Save with Friends"
        meta={[
          {"name": "description", "content": "Looking for a deal? Get together with your friends and save money on your graduation gown."},
        ]}
      />
      <h1>Deals</h1>
      <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg" alt="Graduates throwing their graduation caps" />
      <p className="maindes">If you are buying multiple sets of graduation attire then you can take advantage of our bulk order deals. You can save up to £10 off each gown set when buying in bulk and there is no additional shipping for each extra set added. The deals are automatically added once you check out</p>
      <div className="alldeals">
        {deals}
      </div>
    </div>
  )
}

export default Friends;
