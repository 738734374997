import React from 'react';
import { Link } from 'react-router-dom';

const DrawerContent = ({close}) => {
  const handleToggle = (target, event) => {
    var targetEl = document.getElementById(target);
    var toggleEl = document.getElementById(event.target.id);

    if(targetEl.style.display === "none"){
      targetEl.style.display = "";
      toggleEl.innerHTML = "-";
    }
    else {
      targetEl.style.display = "none";
      toggleEl.innerHTML = "+";
    }
  };
  const hidden = { display: "none" };

    return (
      <div className="menuitems">
        <h2>Menu</h2>
        <div className="menuitem"><Link onClick={close} to="/">Home</Link></div>
        <div className="menuitem" id="menu-shop">
          <Link onClick={close} to="/academic-dress"><p>Shop</p></Link>
          <p id="shop-toggle" className="toggle" onClick={handleToggle.bind(this, "menu-shop-items")}>+</p>
        </div>
        <div id="menu-shop-items" style={hidden}>
          <Link onClick={close} to="/graduation-gown">Full University Sets</Link>
          <Link onClick={close} to="/academic-dress">All Regalia</Link>
          <Link onClick={close} to="/certificate-frame">Certificate Frames</Link>
          <Link onClick={close} to="/academic-dress/bachelor-gown">Bachelor Style Gowns</Link>
          <Link onClick={close} to="/academic-dress/bachelor-gown-set">Bachelor Style Gown & Cap Sets</Link>
          <Link onClick={close} to="/academic-dress/master-gown">Master Style Gowns</Link>
          <Link onClick={close} to="/academic-dress/master-gown-set">Master Style Gown & Cap Sets</Link>
          <Link onClick={close} to="/academic-dress/graduation-cap">Graduation Caps</Link>
          <Link onClick={close} to="/graduation-gift">Graduation Gifts</Link>
          <Link onClick={close} to="/academic-dress/graduation-hoods">Academic Hoods</Link>
          <Link onClick={close} to="/legal-wear">Legal Wear</Link>
        </div>
        <div className="menuitem"><Link onClick={close} to="/pricebeat">Price Guarantee</Link></div>
        <div className="menuitem"><Link onClick={close} to="/save-with-friends">Save with Friends</Link></div>
        <div className="menuitem"><Link onClick={close} to="/wholesale">Wholesale</Link></div>
        <div className="menuitem"><Link onClick={close} to="/cart">View Cart</Link></div>
        <div className="menuitem"><Link onClick={close} to="/contact">Contact Us</Link></div>
        <div className="menuitem" id="menu-about">
          <p>Info</p>
          <p id="about-toggle" className="toggle" onClick={handleToggle.bind(this, "menu-about-items")}>+</p>
        </div>
        <div id="menu-about-items" style={hidden}>
          <Link onClick={close} to="/about">About Us</Link>
          <Link onClick={close} to="/faq">FAQ</Link>
          <Link onClick={close} to="/shipping">Shipping Calculator</Link>
          <Link onClick={close} to="/terms">Terms & Conditions</Link>
          <Link onClick={close} to="/privacy">Privacy Policy</Link>
{/*          <Link onClick={close} to="/checkorder">Check Order Status</Link>*/}
        </div>
      </div>
    )
}

export default DrawerContent;
