import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

import {
  BACHELOR_GOWN_CAP_HOOD,
  BACHELOR_GOWN_CAP,
  BACHELOR_GOWN_CAP_ECONOMY,
  BACHELOR_GOWN,
  BACHELOR_GOWN_ECONOMY,
  MASTER_GOWN_CAP_HOOD,
  MASTER_GOWN_CAP,
  MASTER_GOWN,
  HOOD,
  CAP,
} from '../../constants'


const Buy = ({name}) => {

  return (
    <div className="shop-products">
      <Helmet
        title="Buy Graduation Gown Sets UK | Graduation Gowns, Hoods, Caps, Gifts and Frames"
        meta={[
          {"name": "description", "content": "Buy premium graduation gown sets, including Bachelor and Master Style gowns, hoods and caps"},
        ]}
      />
      <h1>Buy Your Graduation Gown, Regalia, Gifts and Certificate Frames</h1>
      <div className="subheading">
        <h2>Hand Crafted Regalia for University, College and School Graduations</h2>
      </div>
      <div className="catalogue">
        <div className="img-container">
          <div className="img-box">
            <Link to="/graduation-gown/">
            <div className="img-unit">
              <div className="fixed-height-img">
                <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1637449102/UK-Uni-Photos/Bedfordshire/bedfordshire-bach-all-side.jpg" alt="Full university graduation gown set"/>
              </div>
              <div className="fixed-height-text">
                <strong className="item-name">Full University Graduation Gown Sets</strong>
                <p>£{BACHELOR_GOWN_CAP_HOOD}</p>
              </div>
              <button>Buy now</button>
            </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/academic-dress/bachelor-gown-set">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165139/Regalia/bach-cap-gown-side.jpg" alt="Bachelor style gown and trencher set"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Bachelor Gown & Cap Set</strong>
                  <p>£{BACHELOR_GOWN_CAP}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/academic-dress/bachelor-gown-set-economy">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bachelor-cap-gown-set.jpg" alt="Bachelor style gown and trencher set"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Bachelor Gown & Cap Set (Economy - 160gsm)</strong>
                  <p>£{BACHELOR_GOWN_CAP_ECONOMY}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/academic-dress/master-gown-set">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165127/Regalia/master-cap-gown-set.jpg" alt="Master style gown and trencher set"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Master Gown & Cap Set</strong>
                  <p>£{MASTER_GOWN_CAP}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/academic-dress/bachelor-gown">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-side.jpg" alt="Bachelor style gown"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Bachelor Graduation Gown</strong>
                  <p>£{BACHELOR_GOWN}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/academic-dress/bachelor-gown-economy">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165130/Regalia/bachelor-gown.jpg" alt="Bachelor style gown"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Bachelor Graduation Gown (Economy 160gsm)</strong>
                  <p>£{BACHELOR_GOWN_ECONOMY}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/academic-dress/master-gown">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549186837/Regalia/master-gown-front-main.jpg" alt="Master style gown"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Master Graduation Gown</strong>
                  <p>£{MASTER_GOWN}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/academic-dress/graduation-hoods">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Regalia/hood.jpg" alt="Academic hood"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Academic Hoods</strong>
                  <p>£{HOOD}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/academic-dress/graduation-puppy-small">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165132/Regalia/graduation-puppy-furry-1.jpg" alt="graduation puppy"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Puppy - Small</strong>
                  <p>£10</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/academic-dress/graduation-puppy-large">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165131/Regalia/graduation-puppy-smooth-1.jpg"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Puppy - Large</strong>
                  <p>£14</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/academic-dress/graduation-teddy-bear">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165134/Regalia/graduation-teddy-bear-1.jpg" alt="graduation bear"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Teddy Bear</strong>
                  <p>£10</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/academic-dress/graduation-cap">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1557666353/Regalia/graduation-cap-1.jpg" alt="graduation trencher"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Caps</strong>
                  <p>£{CAP}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/graduation-gown/university-of-the-arts-london">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1637448543/UK-Uni-Photos/UAL/ual-bach-all-side.jpg" alt="Full university graduation gown set"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">University of the Arts London Graduation Gowns</strong>
                  <p>£{BACHELOR_GOWN_CAP_HOOD}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/graduation-gown/keele-university">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1637449459/UK-Uni-Photos/Keele/keele-bach-all-side.jpg" alt="Full university graduation gown set"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Keele University Graduation Gowns</strong>
                  <p>£{BACHELOR_GOWN_CAP_HOOD}</p>
                </div>
                <button>Buy now</button>
              </div>
            </Link>
          </div>
        </div>
        <div className="products-desc">
          <h2>UK'S QUALITY GRADUATION GOWN</h2>
          <p>The time has begun for your graduation ceremony. All those years of hard work bring you to this moment. It’s an occasion  to enjoy and spend time with those special people who assisted you along the way!
          </p>
          <p>When you’re searching for an academic gown for your  graduation ceremony, you have the option of either renting your academic dress or purchasing. So which option do you choose?</p>
          <p>Interestingly, with Gowning Street, buying a cap and gown can actually work out more economic than organising a rental. You will have the added flexibility of arranging your graduation photography at any time (not the standard hiring window provided by your uni) and you can avoid the dreaded line of students gathering to pick up their rental gown on the day of graduation. Furthermore, given the special significance of your achievement, your academic dress is something you will want to keep onto</p>
          <h2>WE SUPPLY TO MANY DIFFERENT ACADEMIC ORGANISATION’S UK-WIDE</h2>
          <p>We can supply for a variety of occasions, whether it’s University Graduation or School Teacher’s who regalia for their end year functions. Our graduation gowns are all hand-crafted  to deluxe cut and superior finish and can be delivered UK-Wide.</p>
          <p>We provide wholesale academic gowns for  purchase, we can completely customise your graduation gowns to your institute – pick the material, design, and colours that embody your college and we can tailor an exclusive, premium-quality gown that represents the brand of your school admirably.</p>
          <p>We have extensive range of options, check our deals page, for <Link to="/wholesale">large volume order’s</Link> or if you are a graduating student, you can group your order with your class-mates to take advantage of our <Link to="save-with-friends">student deal</Link> where you can save up to £10 per gown</p>
          <h2>ENQUIRIES REGARDING OUR OTHER PRODUCTS</h2>
          <p>We have a variety of graduation accessories also available. If you are looking for a quality graduation certificate frame or cute graduation teddy bears, we offer quick courier and same day dispatch for orders received before 12pm. Please see our <Link to="/shipping">shipping page</Link></p>
          <p>If you have further enquiries  regarding our range of  graduation regalia that we have in stock, please visit our <Link to="/contact">contact us</Link> page</p>
        </div>
      </div>
    </div>
  )
};

export default Buy;
