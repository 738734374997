export default  {
  "/certificate-frame/university-of-the-arts-london" : {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566700508/Frames/ACU/ACU_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566700709/Frames/ACU/ACU_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566700558/Frames/ACU/ACU_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566700679/Frames/ACU/ACU_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566700609/Frames/ACU/ACU_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283962/Frames/ACU/ACU_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272212/Frames/ACU/ACU_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282880/Frames/ACU/ACU_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282388/Frames/ACU/ACU_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281305/Frames/ACU/ACU_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your University of the Arts London certificate frame for only £28 | Fast delivery, excellent quality, made to fit your University of the Arts London testamur perfectly",
    "altTags" : [
      "University of the Arts London certificate frame - Pine Wood Manor Black",
      "University of the Arts London certificate frame - Pine Wood Marine Blue",
      "University of the Arts London certificate frame - Pine Wood Wine",
      "University of the Arts London certificate frame - Pine Wood Eucalypt",
      "University of the Arts London certificate frame - Pine Wood Light Cream",
      "University of the Arts London certificate frame - Modern Black",
      "University of the Arts London certificate frame - Modern Marine Blue",
      "University of the Arts London certificate frame - Modern Wine",
      "University of the Arts London certificate frame - Modern Eucalypt",
      "University of the Arts London certificate frame - Modern Light Cream",
    ],
    "name" : "University of the Arts London Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "University of the Arts London Certificate Frame | From £28"
  },

  "/certificate-frame/university-of-salford": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566703144/Frames/CSU/CSU_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566703222/Frames/CSU/CSU_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566703206/Frames/CSU/CSU_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566703187/Frames/CSU/CSU_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566703166/Frames/CSU/CSU_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284069/Frames/CSU/CSU_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272340/Frames/CSU/CSU_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282966/Frames/CSU/CSU_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282441/Frames/CSU/CSU_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281370/Frames/CSU/CSU_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your University of Salford certificate frame for only £28 | Fast delivery, excellent quality, made to fit your University of Salford testamur perfectly",
    "altTags" : [
      "University of Salford certificate frame - Pine Wood Manor Black",
      "University of Salford certificate frame - Pine Wood Marine Blue",
      "University of Salford certificate frame - Pine Wood Wine",
      "University of Salford certificate frame - Pine Wood Eucalypt",
      "University of Salford certificate frame - Pine Wood Light Cream",
      "University of Salford certificate frame - Modern Black",
      "University of Salford certificate frame - Modern Marine Blue",
      "University of Salford certificate frame - Modern Wine",
      "University of Salford certificate frame - Modern Eucalypt",
      "University of Salford certificate frame - Modern Light Cream",
    ],
    "name" : "University of Salford Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "University of Salford Certificate Frame | From £28"
  },
  "/certificate-frame/university-of-london": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your University of London certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Waikato Uni testamur perfectly",
    "altTags" : [
      "University of London certificate frame - Pine Wood Manor Black",
      "University of London certificate frame - Pine Wood Marine Blue",
      "University of London certificate frame - Pine Wood Wine",
      "University of London certificate frame - Pine Wood Eucalypt",
      "University of London certificate frame - Pine Wood Light Cream",
      "University of London certificate frame - Modern Black",
      "University of London certificate frame - Modern Marine Blue",
      "University of London certificate frame - Modern Wine",
      "University of London certificate frame - Modern Eucalypt",
      "University of London certificate frame - Modern Light Cream",
    ],
    "name" : "University of London Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "University of London Certificate Frame | From £28"
  },
  "/certificate-frame/university-of-leeds-beckett-material": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your University of Leeds Beckett Material certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Lincoln Uni testamur perfectly",
    "altTags" : [
      "University of Leeds Beckett Material certificate frame - Pine Wood Manor Black",
      "University of Leeds Beckett Material certificate frame - Pine Wood Marine Blue",
      "University of Leeds Beckett Material certificate frame - Pine Wood Wine",
      "University of Leeds Beckett Material certificate frame - Pine Wood Eucalypt",
      "University of Leeds Beckett Material certificate frame - Pine Wood Light Cream",
      "University of Leeds Beckett Material certificate frame - Modern Black",
      "University of Leeds Beckett Material certificate frame - Modern Marine Blue",
      "University of Leeds Beckett Material certificate frame - Modern Wine",
      "University of Leeds Beckett Material certificate frame - Modern Eucalypt",
      "University of Leeds Beckett Material certificate frame - Modern Light Cream",
    ],
    "name" : "University of Leeds Beckett Material Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "University of Leeds Beckett Material Certificate Frame | From £28"
  },

  "/certificate-frame/university-of-hull": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your University of Hull certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Massey Uni testamur perfectly",
    "altTags" : [
      "University of Hull certificate frame - Pine Wood Manor Black",
      "University of Hull certificate frame - Pine Wood Marine Blue",
      "University of Hull certificate frame - Pine Wood Wine",
      "University of Hull certificate frame - Pine Wood Eucalypt",
      "University of Hull certificate frame - Pine Wood Light Cream",
      "University of Hull certificate frame - Modern Black",
      "University of Hull certificate frame - Modern Marine Blue",
      "University of Hull certificate frame - Modern Wine",
      "University of Hull certificate frame - Modern Eucalypt",
      "University of Hull certificate frame - Modern Light Cream",
    ],
    "name" : "University of Hull Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "University of Hull Certificate Frame | From £28"
  },
  "/certificate-frame/university-of-hertfordshire": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your University of Hertfordshire certificate frame for only £28 | Fast delivery, excellent quality, made to fit your University of Hertfordshire testamur perfectly",
    "altTags" : [
      "University of Hertfordshire certificate frame - Pine Wood Manor Black",
      "University of Hertfordshire certificate frame - Pine Wood Marine Blue",
      "University of Hertfordshire certificate frame - Pine Wood Wine",
      "University of Hertfordshire certificate frame - Pine Wood Eucalypt",
      "University of Hertfordshire certificate frame - Pine Wood Light Cream",
      "University of Hertfordshire certificate frame - Modern Black",
      "University of Hertfordshire certificate frame - Modern Marine Blue",
      "University of Hertfordshire certificate frame - Modern Wine",
      "University of Hertfordshire certificate frame - Modern Eucalypt",
      "University of Hertfordshire certificate frame - Modern Light Cream",
    ],
    "name" : "University of Hertfordshire Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "University of Hertfordshire Certificate Frame | From £28"
  },
  "/certificate-frame/university-of-derby": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your University of Derby certificate frame for only £28 | Fast delivery, excellent quality, made to fit your University of Derby testamur perfectly",
    "altTags" : [
      "University of Derby certificate frame - Pine Wood Manor Black",
      "University of Derby certificate frame - Pine Wood Marine Blue",
      "University of Derby certificate frame - Pine Wood Wine",
      "University of Derby certificate frame - Pine Wood Eucalypt",
      "University of Derby certificate frame - Pine Wood Light Cream",
      "University of Derby certificate frame - Modern Black",
      "University of Derby certificate frame - Modern Marine Blue",
      "University of Derby certificate frame - Modern Wine",
      "University of Derby certificate frame - Modern Eucalypt",
      "University of Derby certificate frame - Modern Light Cream",
    ],
    "name" : "University of Derby Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "University of Derby Certificate Frame | From £28"
  },

  "/certificate-frame/university-of-bedfordshire": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your University of Bedfordshire certificate frame for only £28 | Fast delivery, excellent quality, made to fit your University of Bedfordshire testamur perfectly",
    "altTags" : [
      "University of Bedfordshire certificate frame - Pine Wood Manor Black",
      "University of Bedfordshire certificate frame - Pine Wood Marine Blue",
      "University of Bedfordshire certificate frame - Pine Wood Wine",
      "University of Bedfordshire certificate frame - Pine Wood Eucalypt",
      "University of Bedfordshire certificate frame - Pine Wood Light Cream",
      "University of Bedfordshire certificate frame - Modern Black",
      "University of Bedfordshire certificate frame - Modern Marine Blue",
      "University of Bedfordshire certificate frame - Modern Wine",
      "University of Bedfordshire certificate frame - Modern Eucalypt",
      "University of Bedfordshire certificate frame - Modern Light Cream",
    ],
    "name" : "University of Bedfordshire Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "University of Bedfordshire Certificate Frame | From £28"
  },

  "/certificate-frame/university-college-london": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your University College London certificate frame for only £28 | Fast delivery, excellent quality, made to fit your University College London testamur perfectly",
    "altTags" : [
      "University College London certificate frame - Pine Wood Manor Black",
      "University College London certificate frame - Pine Wood Marine Blue",
      "University College London certificate frame - Pine Wood Wine",
      "University College London certificate frame - Pine Wood Eucalypt",
      "University College London certificate frame - Pine Wood Light Cream",
      "University College London certificate frame - Modern Black",
      "University College London certificate frame - Modern Marine Blue",
      "University College London certificate frame - Modern Wine",
      "University College London certificate frame - Modern Eucalypt",
      "University College London certificate frame - Modern Light Cream",
    ],
    "name" : "University College London Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "University College London Certificate Frame | From £28"
  },

  "/certificate-frame/teesside-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Teesside University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Teesside University testamur perfectly",
    "altTags" : [
      "Teesside University certificate frame - Pine Wood Manor Black",
      "Teesside University certificate frame - Pine Wood Marine Blue",
      "Teesside University certificate frame - Pine Wood Wine",
      "Teesside University certificate frame - Pine Wood Eucalypt",
      "Teesside University certificate frame - Pine Wood Light Cream",
      "Teesside University certificate frame - Modern Black",
      "Teesside University certificate frame - Modern Marine Blue",
      "Teesside University certificate frame - Modern Wine",
      "Teesside University certificate frame - Modern Eucalypt",
      "Teesside University certificate frame - Modern Light Cream",
    ],
    "name" : "Teesside University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Teesside University Certificate Frame | From £28"
  },

  "/certificate-frame/sheffield-hallam-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Sheffield Hallam University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Sheffield Hallam University testamur perfectly",
    "altTags" : [
      "Sheffield Hallam University certificate frame - Pine Wood Manor Black",
      "Sheffield Hallam University certificate frame - Pine Wood Marine Blue",
      "Sheffield Hallam University certificate frame - Pine Wood Wine",
      "Sheffield Hallam University certificate frame - Pine Wood Eucalypt",
      "Sheffield Hallam University certificate frame - Pine Wood Light Cream",
      "Sheffield Hallam University certificate frame - Modern Black",
      "Sheffield Hallam University certificate frame - Modern Marine Blue",
      "Sheffield Hallam University certificate frame - Modern Wine",
      "Sheffield Hallam University certificate frame - Modern Eucalypt",
      "Sheffield Hallam University certificate frame - Modern Light Cream",
    ],
    "name" : "Sheffield Hallam University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Sheffield Hallam University Certificate Frame | From £28"
  },

  "/certificate-frame/plymouth-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Plymouth University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Plymouth University testamur perfectly",
    "altTags" : [
      "Plymouth University certificate frame - Pine Wood Manor Black",
      "Plymouth University certificate frame - Pine Wood Marine Blue",
      "Plymouth University certificate frame - Pine Wood Wine",
      "Plymouth University certificate frame - Pine Wood Eucalypt",
      "Plymouth University certificate frame - Pine Wood Light Cream",
      "Plymouth University certificate frame - Modern Black",
      "Plymouth University certificate frame - Modern Marine Blue",
      "Plymouth University certificate frame - Modern Wine",
      "Plymouth University certificate frame - Modern Eucalypt",
      "Plymouth University certificate frame - Modern Light Cream",
    ],
    "name" : "Plymouth University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Plymouth University Certificate Frame | From £28"
  },

  "/certificate-frame/oxford-brookes-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Oxford Brookes University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Oxford Brookes University testamur perfectly",
    "altTags" : [
      "Oxford Brookes University certificate frame - Pine Wood Manor Black",
      "Oxford Brookes University certificate frame - Pine Wood Marine Blue",
      "Oxford Brookes University certificate frame - Pine Wood Wine",
      "Oxford Brookes University certificate frame - Pine Wood Eucalypt",
      "Oxford Brookes University certificate frame - Pine Wood Light Cream",
      "Oxford Brookes University certificate frame - Modern Black",
      "Oxford Brookes University certificate frame - Modern Marine Blue",
      "Oxford Brookes University certificate frame - Modern Wine",
      "Oxford Brookes University certificate frame - Modern Eucalypt",
      "Oxford Brookes University certificate frame - Modern Light Cream",
    ],
    "name" : "Oxford Brookes University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Oxford Brookes University Certificate Frame | From £28"
  },

  "/certificate-frame/open-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Open University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Open University testamur perfectly",
    "altTags" : [
      "Open University certificate frame - Pine Wood Manor Black",
      "Open University certificate frame - Pine Wood Marine Blue",
      "Open University certificate frame - Pine Wood Wine",
      "Open University certificate frame - Pine Wood Eucalypt",
      "Open University certificate frame - Pine Wood Light Cream",
      "Open University certificate frame - Modern Black",
      "Open University certificate frame - Modern Marine Blue",
      "Open University certificate frame - Modern Wine",
      "Open University certificate frame - Modern Eucalypt",
      "Open University certificate frame - Modern Light Cream",
    ],
    "name" : "Open University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Open University Certificate Frame | From £28"
  },

  "/certificate-frame/edinburgh-napier-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Edinburgh Napier University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Edinburgh Napier University testamur perfectly",
    "altTags" : [
      "Edinburgh Napier University certificate frame - Pine Wood Manor Black",
      "Edinburgh Napier University certificate frame - Pine Wood Marine Blue",
      "Edinburgh Napier University certificate frame - Pine Wood Wine",
      "Edinburgh Napier University certificate frame - Pine Wood Eucalypt",
      "Edinburgh Napier University certificate frame - Pine Wood Light Cream",
      "Edinburgh Napier University certificate frame - Modern Black",
      "Edinburgh Napier University certificate frame - Modern Marine Blue",
      "Edinburgh Napier University certificate frame - Modern Wine",
      "Edinburgh Napier University certificate frame - Modern Eucalypt",
      "Edinburgh Napier University certificate frame - Modern Light Cream",
    ],
    "name" : "Edinburgh Napier University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Edinburgh Napier University Certificate Frame | From £28"
  },

  "/certificate-frame/edge-hill-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Edge Hill University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Edge Hill University testamur perfectly",
    "altTags" : [
      "Edge Hill University certificate frame - Pine Wood Manor Black",
      "Edge Hill University certificate frame - Pine Wood Marine Blue",
      "Edge Hill University certificate frame - Pine Wood Wine",
      "Edge Hill University certificate frame - Pine Wood Eucalypt",
      "Edge Hill University certificate frame - Pine Wood Light Cream",
      "Edge Hill University certificate frame - Modern Black",
      "Edge Hill University certificate frame - Modern Marine Blue",
      "Edge Hill University certificate frame - Modern Wine",
      "Edge Hill University certificate frame - Modern Eucalypt",
      "Edge Hill University certificate frame - Modern Light Cream",
    ],
    "name" : "Edge Hill University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Edge Hill University Certificate Frame | From £28"
  },

  "/certificate-frame/cardiff-university-material": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Cardiff University Material certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Cardiff University Material testamur perfectly",
    "altTags" : [
      "Cardiff University Material certificate frame - Pine Wood Manor Black",
      "Cardiff University Material certificate frame - Pine Wood Marine Blue",
      "Cardiff University Material certificate frame - Pine Wood Wine",
      "Cardiff University Material certificate frame - Pine Wood Eucalypt",
      "Cardiff University Material certificate frame - Pine Wood Light Cream",
      "Cardiff University Material certificate frame - Modern Black",
      "Cardiff University Material certificate frame - Modern Marine Blue",
      "Cardiff University Material certificate frame - Modern Wine",
      "Cardiff University Material certificate frame - Modern Eucalypt",
      "Cardiff University Material certificate frame - Modern Light Cream",
    ],
    "name" : "Cardiff University Material Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Cardiff University Material Certificate Frame | From £28"
  },

  "/certificate-frame/bournemouth-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Bournemouth University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Bournemouth University testamur perfectly",
    "altTags" : [
      "Bournemouth University certificate frame - Pine Wood Manor Black",
      "Bournemouth University certificate frame - Pine Wood Marine Blue",
      "Bournemouth University certificate frame - Pine Wood Wine",
      "Bournemouth University certificate frame - Pine Wood Eucalypt",
      "Bournemouth University certificate frame - Pine Wood Light Cream",
      "Bournemouth University certificate frame - Modern Black",
      "Bournemouth University certificate frame - Modern Marine Blue",
      "Bournemouth University certificate frame - Modern Wine",
      "Bournemouth University certificate frame - Modern Eucalypt",
      "Bournemouth University certificate frame - Modern Light Cream",
    ],
    "name" : "Bournemouth University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Bournemouth University Certificate Frame | From £28"
  },

  "/certificate-frame/london-metropolitan-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your London Metropolitan University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your London Metropolitan University testamur perfectly",
    "altTags" : [
      "London Metropolitan University certificate frame - Pine Wood Manor Black",
      "London Metropolitan University certificate frame - Pine Wood Marine Blue",
      "London Metropolitan University certificate frame - Pine Wood Wine",
      "London Metropolitan University certificate frame - Pine Wood Eucalypt",
      "London Metropolitan University certificate frame - Pine Wood Light Cream",
      "London Metropolitan University certificate frame - Modern Black",
      "London Metropolitan University certificate frame - Modern Marine Blue",
      "London Metropolitan University certificate frame - Modern Wine",
      "London Metropolitan University certificate frame - Modern Eucalypt",
      "London Metropolitan University certificate frame - Modern Light Cream",
    ],
    "name" : "London Metropolitan University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "London Metropolitan University Certificate Frame | From £28"
  },

  "/certificate-frame/keele-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Keele University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Keele University testamur perfectly",
    "altTags" : [
      "Keele University certificate frame - Pine Wood Manor Black",
      "Keele University certificate frame - Pine Wood Marine Blue",
      "Keele University certificate frame - Pine Wood Wine",
      "Keele University certificate frame - Pine Wood Eucalypt",
      "Keele University certificate frame - Pine Wood Light Cream",
      "Keele University certificate frame - Modern Black",
      "Keele University certificate frame - Modern Marine Blue",
      "Keele University certificate frame - Modern Wine",
      "Keele University certificate frame - Modern Eucalypt",
      "Keele University certificate frame - Modern Light Cream",
    ],
    "name" : "Keele University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Keele University Certificate Frame | From £28"
  },

  "/certificate-frame/edinburgh-napier-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Edinburgh Napier University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Edinburgh Napier University testamur perfectly",
    "altTags" : [
      "Edinburgh Napier University certificate frame - Pine Wood Manor Black",
      "Edinburgh Napier University certificate frame - Pine Wood Marine Blue",
      "Edinburgh Napier University certificate frame - Pine Wood Wine",
      "Edinburgh Napier University certificate frame - Pine Wood Eucalypt",
      "Edinburgh Napier University certificate frame - Pine Wood Light Cream",
      "Edinburgh Napier University certificate frame - Modern Black",
      "Edinburgh Napier University certificate frame - Modern Marine Blue",
      "Edinburgh Napier University certificate frame - Modern Wine",
      "Edinburgh Napier University certificate frame - Modern Eucalypt",
      "Edinburgh Napier University certificate frame - Modern Light Cream",
    ],
    "name" : "Edinburgh Napier University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Edinburgh Napier University Certificate Frame | From £28"
  },

  "/certificate-frame/edge-hill-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Edge Hill University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Edge Hill University testamur perfectly",
    "altTags" : [
      "Edge Hill University certificate frame - Pine Wood Manor Black",
      "Edge Hill University certificate frame - Pine Wood Marine Blue",
      "Edge Hill University certificate frame - Pine Wood Wine",
      "Edge Hill University certificate frame - Pine Wood Eucalypt",
      "Edge Hill University certificate frame - Pine Wood Light Cream",
      "Edge Hill University certificate frame - Modern Black",
      "Edge Hill University certificate frame - Modern Marine Blue",
      "Edge Hill University certificate frame - Modern Wine",
      "Edge Hill University certificate frame - Modern Eucalypt",
      "Edge Hill University certificate frame - Modern Light Cream",
    ],
    "name" : "Edge Hill University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Edge Hill University Certificate Frame | From £28"
  },
  "/certificate-frame/aberdeen-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Aberdeen University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Aberdeen University testamur perfectly",
    "altTags" : [
      "Aberdeen University certificate frame - Pine Wood Manor Black",
      "Aberdeen University certificate frame - Pine Wood Marine Blue",
      "Aberdeen University certificate frame - Pine Wood Wine",
      "Aberdeen University certificate frame - Pine Wood Eucalypt",
      "Aberdeen University certificate frame - Pine Wood Light Cream",
      "Aberdeen University certificate frame - Modern Black",
      "Aberdeen University certificate frame - Modern Marine Blue",
      "Aberdeen University certificate frame - Modern Wine",
      "Aberdeen University certificate frame - Modern Eucalypt",
      "Aberdeen University certificate frame - Modern Light Cream",
    ],
    "name" : "Aberdeen University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Aberdeen University Certificate Frame | From £28"
  },
  "/certificate-frame/abertay-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Abertay University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Abertay University testamur perfectly",
    "altTags" : [
      "Abertay University certificate frame - Pine Wood Manor Black",
      "Abertay University certificate frame - Pine Wood Marine Blue",
      "Abertay University certificate frame - Pine Wood Wine",
      "Abertay University certificate frame - Pine Wood Eucalypt",
      "Abertay University certificate frame - Pine Wood Light Cream",
      "Abertay University certificate frame - Modern Black",
      "Abertay University certificate frame - Modern Marine Blue",
      "Abertay University certificate frame - Modern Wine",
      "Abertay University certificate frame - Modern Eucalypt",
      "Abertay University certificate frame - Modern Light Cream",
    ],
    "name" : "Abertay University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Abertay University Certificate Frame | From £28"
  },
  "/certificate-frame/aberystwyth-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Aberystwyth University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Aberystwyth University testamur perfectly",
    "altTags" : [
      "Aberystwyth University certificate frame - Pine Wood Manor Black",
      "Aberystwyth University certificate frame - Pine Wood Marine Blue",
      "Aberystwyth University certificate frame - Pine Wood Wine",
      "Aberystwyth University certificate frame - Pine Wood Eucalypt",
      "Aberystwyth University certificate frame - Pine Wood Light Cream",
      "Aberystwyth University certificate frame - Modern Black",
      "Aberystwyth University certificate frame - Modern Marine Blue",
      "Aberystwyth University certificate frame - Modern Wine",
      "Aberystwyth University certificate frame - Modern Eucalypt",
      "Aberystwyth University certificate frame - Modern Light Cream",
    ],
    "name" : "Aberystwyth University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Aberystwyth University Certificate Frame | From £28"
  },
  "/certificate-frame/anglia-ruskin-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Anglia Ruskin University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Anglia Ruskin University testamur perfectly",
    "altTags" : [
      "Anglia Ruskin University certificate frame - Pine Wood Manor Black",
      "Anglia Ruskin University certificate frame - Pine Wood Marine Blue",
      "Anglia Ruskin University certificate frame - Pine Wood Wine",
      "Anglia Ruskin University certificate frame - Pine Wood Eucalypt",
      "Anglia Ruskin University certificate frame - Pine Wood Light Cream",
      "Anglia Ruskin University certificate frame - Modern Black",
      "Anglia Ruskin University certificate frame - Modern Marine Blue",
      "Anglia Ruskin University certificate frame - Modern Wine",
      "Anglia Ruskin University certificate frame - Modern Eucalypt",
      "Anglia Ruskin University certificate frame - Modern Light Cream",
    ],
    "name" : "Anglia Ruskin University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Anglia Ruskin University Certificate Frame | From £28"
  },
  "/certificate-frame/arden-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Arden University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Arden University testamur perfectly",
    "altTags" : [
      "Arden University certificate frame - Pine Wood Manor Black",
      "Arden University certificate frame - Pine Wood Marine Blue",
      "Arden University certificate frame - Pine Wood Wine",
      "Arden University certificate frame - Pine Wood Eucalypt",
      "Arden University certificate frame - Pine Wood Light Cream",
      "Arden University certificate frame - Modern Black",
      "Arden University certificate frame - Modern Marine Blue",
      "Arden University certificate frame - Modern Wine",
      "Arden University certificate frame - Modern Eucalypt",
      "Arden University certificate frame - Modern Light Cream",
    ],
    "name" : "Arden University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Arden University Certificate Frame | From £28"
  },
  "/certificate-frame/arts-university-bournemouth": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Arts University Bournemouth certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Arts University Bournemouth testamur perfectly",
    "altTags" : [
      "Arts University Bournemouth certificate frame - Pine Wood Manor Black",
      "Arts University Bournemouth certificate frame - Pine Wood Marine Blue",
      "Arts University Bournemouth certificate frame - Pine Wood Wine",
      "Arts University Bournemouth certificate frame - Pine Wood Eucalypt",
      "Arts University Bournemouth certificate frame - Pine Wood Light Cream",
      "Arts University Bournemouth certificate frame - Modern Black",
      "Arts University Bournemouth certificate frame - Modern Marine Blue",
      "Arts University Bournemouth certificate frame - Modern Wine",
      "Arts University Bournemouth certificate frame - Modern Eucalypt",
      "Arts University Bournemouth certificate frame - Modern Light Cream",
    ],
    "name" : "Arts University Bournemouth Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Arts University Bournemouth Certificate Frame | From £28"
  },
  "/certificate-frame/aston-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Aston University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Aston University testamur perfectly",
    "altTags" : [
      "Aston University certificate frame - Pine Wood Manor Black",
      "Aston University certificate frame - Pine Wood Marine Blue",
      "Aston University certificate frame - Pine Wood Wine",
      "Aston University certificate frame - Pine Wood Eucalypt",
      "Aston University certificate frame - Pine Wood Light Cream",
      "Aston University certificate frame - Modern Black",
      "Aston University certificate frame - Modern Marine Blue",
      "Aston University certificate frame - Modern Wine",
      "Aston University certificate frame - Modern Eucalypt",
      "Aston University certificate frame - Modern Light Cream",
    ],
    "name" : "Aston University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Aston University Certificate Frame | From £28"
  },
  "/certificate-frame/bangor-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Bangor University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Bangor University testamur perfectly",
    "altTags" : [
      "Bangor University certificate frame - Pine Wood Manor Black",
      "Bangor University certificate frame - Pine Wood Marine Blue",
      "Bangor University certificate frame - Pine Wood Wine",
      "Bangor University certificate frame - Pine Wood Eucalypt",
      "Bangor University certificate frame - Pine Wood Light Cream",
      "Bangor University certificate frame - Modern Black",
      "Bangor University certificate frame - Modern Marine Blue",
      "Bangor University certificate frame - Modern Wine",
      "Bangor University certificate frame - Modern Eucalypt",
      "Bangor University certificate frame - Modern Light Cream",
    ],
    "name" : "Bangor University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Bangor University Certificate Frame | From £28"
  },
  "/certificate-frame/bath-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Bath University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Bath University testamur perfectly",
    "altTags" : [
      "Bath University certificate frame - Pine Wood Manor Black",
      "Bath University certificate frame - Pine Wood Marine Blue",
      "Bath University certificate frame - Pine Wood Wine",
      "Bath University certificate frame - Pine Wood Eucalypt",
      "Bath University certificate frame - Pine Wood Light Cream",
      "Bath University certificate frame - Modern Black",
      "Bath University certificate frame - Modern Marine Blue",
      "Bath University certificate frame - Modern Wine",
      "Bath University certificate frame - Modern Eucalypt",
      "Bath University certificate frame - Modern Light Cream",
    ],
    "name" : "Bath University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Bath University Certificate Frame | From £28"
  },
  "/certificate-frame/bath-spa-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Bath Spa University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Bath Spa University testamur perfectly",
    "altTags" : [
      "Bath Spa University certificate frame - Pine Wood Manor Black",
      "Bath Spa University certificate frame - Pine Wood Marine Blue",
      "Bath Spa University certificate frame - Pine Wood Wine",
      "Bath Spa University certificate frame - Pine Wood Eucalypt",
      "Bath Spa University certificate frame - Pine Wood Light Cream",
      "Bath Spa University certificate frame - Modern Black",
      "Bath Spa University certificate frame - Modern Marine Blue",
      "Bath Spa University certificate frame - Modern Wine",
      "Bath Spa University certificate frame - Modern Eucalypt",
      "Bath Spa University certificate frame - Modern Light Cream",
    ],
    "name" : "Bath Spa University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Bath Spa University Certificate Frame | From £28"
  },
  "/certificate-frame/bishop-grosseteste-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Bishop Grosseteste University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Bishop Grosseteste University testamur perfectly",
    "altTags" : [
      "Bishop Grosseteste University certificate frame - Pine Wood Manor Black",
      "Bishop Grosseteste University certificate frame - Pine Wood Marine Blue",
      "Bishop Grosseteste University certificate frame - Pine Wood Wine",
      "Bishop Grosseteste University certificate frame - Pine Wood Eucalypt",
      "Bishop Grosseteste University certificate frame - Pine Wood Light Cream",
      "Bishop Grosseteste University certificate frame - Modern Black",
      "Bishop Grosseteste University certificate frame - Modern Marine Blue",
      "Bishop Grosseteste University certificate frame - Modern Wine",
      "Bishop Grosseteste University certificate frame - Modern Eucalypt",
      "Bishop Grosseteste University certificate frame - Modern Light Cream",
    ],
    "name" : "Bishop Grosseteste University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Bishop Grosseteste University Certificate Frame | From £28"
  },
  "/certificate-frame/birkbeck-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Birkbeck University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Birkbeck University testamur perfectly",
    "altTags" : [
      "Birkbeck University certificate frame - Pine Wood Manor Black",
      "Birkbeck University certificate frame - Pine Wood Marine Blue",
      "Birkbeck University certificate frame - Pine Wood Wine",
      "Birkbeck University certificate frame - Pine Wood Eucalypt",
      "Birkbeck University certificate frame - Pine Wood Light Cream",
      "Birkbeck University certificate frame - Modern Black",
      "Birkbeck University certificate frame - Modern Marine Blue",
      "Birkbeck University certificate frame - Modern Wine",
      "Birkbeck University certificate frame - Modern Eucalypt",
      "Birkbeck University certificate frame - Modern Light Cream",
    ],
    "name" : "Birkbeck University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Birkbeck University Certificate Frame | From £28"
  },
  "/certificate-frame/birmingham-city-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Birmingham City University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Birmingham City University testamur perfectly",
    "altTags" : [
      "Birmingham City University certificate frame - Pine Wood Manor Black",
      "Birmingham City University certificate frame - Pine Wood Marine Blue",
      "Birmingham City University certificate frame - Pine Wood Wine",
      "Birmingham City University certificate frame - Pine Wood Eucalypt",
      "Birmingham City University certificate frame - Pine Wood Light Cream",
      "Birmingham City University certificate frame - Modern Black",
      "Birmingham City University certificate frame - Modern Marine Blue",
      "Birmingham City University certificate frame - Modern Wine",
      "Birmingham City University certificate frame - Modern Eucalypt",
      "Birmingham City University certificate frame - Modern Light Cream",
    ],
    "name" : "Birmingham City University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Birmingham City University Certificate Frame | From £28"
  },
  "/certificate-frame/birmingham-college": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Birmingham College certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Birmingham College testamur perfectly",
    "altTags" : [
      "Birmingham College certificate frame - Pine Wood Manor Black",
      "Birmingham College certificate frame - Pine Wood Marine Blue",
      "Birmingham College certificate frame - Pine Wood Wine",
      "Birmingham College certificate frame - Pine Wood Eucalypt",
      "Birmingham College certificate frame - Pine Wood Light Cream",
      "Birmingham College certificate frame - Modern Black",
      "Birmingham College certificate frame - Modern Marine Blue",
      "Birmingham College certificate frame - Modern Wine",
      "Birmingham College certificate frame - Modern Eucalypt",
      "Birmingham College certificate frame - Modern Light Cream",
    ],
    "name" : "Birmingham College Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Birmingham College Certificate Frame | From £28"
  },
  "/certificate-frame/birmingham-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Birmingham University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Birmingham University testamur perfectly",
    "altTags" : [
      "Birmingham University certificate frame - Pine Wood Manor Black",
      "Birmingham University certificate frame - Pine Wood Marine Blue",
      "Birmingham University certificate frame - Pine Wood Wine",
      "Birmingham University certificate frame - Pine Wood Eucalypt",
      "Birmingham University certificate frame - Pine Wood Light Cream",
      "Birmingham University certificate frame - Modern Black",
      "Birmingham University certificate frame - Modern Marine Blue",
      "Birmingham University certificate frame - Modern Wine",
      "Birmingham University certificate frame - Modern Eucalypt",
      "Birmingham University certificate frame - Modern Light Cream",
    ],
    "name" : "Birmingham University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Birmingham University Certificate Frame | From £28"
  },
  "/certificate-frame/blackburn-college": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Blackburn College certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Blackburn College testamur perfectly",
    "altTags" : [
      "Blackburn College certificate frame - Pine Wood Manor Black",
      "Blackburn College certificate frame - Pine Wood Marine Blue",
      "Blackburn College certificate frame - Pine Wood Wine",
      "Blackburn College certificate frame - Pine Wood Eucalypt",
      "Blackburn College certificate frame - Pine Wood Light Cream",
      "Blackburn College certificate frame - Modern Black",
      "Blackburn College certificate frame - Modern Marine Blue",
      "Blackburn College certificate frame - Modern Wine",
      "Blackburn College certificate frame - Modern Eucalypt",
      "Blackburn College certificate frame - Modern Light Cream",
    ],
    "name" : "Blackburn College Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Blackburn College Certificate Frame | From £28"
  },
  "/certificate-frame/bolton-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Bolton University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Bolton University testamur perfectly",
    "altTags" : [
      "Bolton University certificate frame - Pine Wood Manor Black",
      "Bolton University certificate frame - Pine Wood Marine Blue",
      "Bolton University certificate frame - Pine Wood Wine",
      "Bolton University certificate frame - Pine Wood Eucalypt",
      "Bolton University certificate frame - Pine Wood Light Cream",
      "Bolton University certificate frame - Modern Black",
      "Bolton University certificate frame - Modern Marine Blue",
      "Bolton University certificate frame - Modern Wine",
      "Bolton University certificate frame - Modern Eucalypt",
      "Bolton University certificate frame - Modern Light Cream",
    ],
    "name" : "Bolton University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Bolton University Certificate Frame | From £28"
  },
  "/certificate-frame/bpp-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your BPP University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your BPP University testamur perfectly",
    "altTags" : [
      "BPP University certificate frame - Pine Wood Manor Black",
      "BPP University certificate frame - Pine Wood Marine Blue",
      "BPP University certificate frame - Pine Wood Wine",
      "BPP University certificate frame - Pine Wood Eucalypt",
      "BPP University certificate frame - Pine Wood Light Cream",
      "BPP University certificate frame - Modern Black",
      "BPP University certificate frame - Modern Marine Blue",
      "BPP University certificate frame - Modern Wine",
      "BPP University certificate frame - Modern Eucalypt",
      "BPP University certificate frame - Modern Light Cream",
    ],
    "name" : "BPP University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "BPP University Certificate Frame | From £28"
  },
  "/certificate-frame/bradford-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Bradford University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Bradford University testamur perfectly",
    "altTags" : [
      "Bradford University certificate frame - Pine Wood Manor Black",
      "Bradford University certificate frame - Pine Wood Marine Blue",
      "Bradford University certificate frame - Pine Wood Wine",
      "Bradford University certificate frame - Pine Wood Eucalypt",
      "Bradford University certificate frame - Pine Wood Light Cream",
      "Bradford University certificate frame - Modern Black",
      "Bradford University certificate frame - Modern Marine Blue",
      "Bradford University certificate frame - Modern Wine",
      "Bradford University certificate frame - Modern Eucalypt",
      "Bradford University certificate frame - Modern Light Cream",
    ],
    "name" : "Bradford University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Bradford University Certificate Frame | From £28"
  },
  "/certificate-frame/brighton-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Brighton University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Brighton University testamur perfectly",
    "altTags" : [
      "Brighton University certificate frame - Pine Wood Manor Black",
      "Brighton University certificate frame - Pine Wood Marine Blue",
      "Brighton University certificate frame - Pine Wood Wine",
      "Brighton University certificate frame - Pine Wood Eucalypt",
      "Brighton University certificate frame - Pine Wood Light Cream",
      "Brighton University certificate frame - Modern Black",
      "Brighton University certificate frame - Modern Marine Blue",
      "Brighton University certificate frame - Modern Wine",
      "Brighton University certificate frame - Modern Eucalypt",
      "Brighton University certificate frame - Modern Light Cream",
    ],
    "name" : "Brighton University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Brighton University Certificate Frame | From £28"
  },
  "/certificate-frame/bristol-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Bristol University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Bristol University testamur perfectly",
    "altTags" : [
      "Bristol University certificate frame - Pine Wood Manor Black",
      "Bristol University certificate frame - Pine Wood Marine Blue",
      "Bristol University certificate frame - Pine Wood Wine",
      "Bristol University certificate frame - Pine Wood Eucalypt",
      "Bristol University certificate frame - Pine Wood Light Cream",
      "Bristol University certificate frame - Modern Black",
      "Bristol University certificate frame - Modern Marine Blue",
      "Bristol University certificate frame - Modern Wine",
      "Bristol University certificate frame - Modern Eucalypt",
      "Bristol University certificate frame - Modern Light Cream",
    ],
    "name" : "Bristol University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Bristol University Certificate Frame | From £28"
  },
  "/certificate-frame/brunel-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Brunel University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Brunel University testamur perfectly",
    "altTags" : [
      "Brunel University certificate frame - Pine Wood Manor Black",
      "Brunel University certificate frame - Pine Wood Marine Blue",
      "Brunel University certificate frame - Pine Wood Wine",
      "Brunel University certificate frame - Pine Wood Eucalypt",
      "Brunel University certificate frame - Pine Wood Light Cream",
      "Brunel University certificate frame - Modern Black",
      "Brunel University certificate frame - Modern Marine Blue",
      "Brunel University certificate frame - Modern Wine",
      "Brunel University certificate frame - Modern Eucalypt",
      "Brunel University certificate frame - Modern Light Cream",
    ],
    "name" : "Brunel University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Brunel University Certificate Frame | From £28"
  },
  "/certificate-frame/buckingham-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Buckingham University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Buckingham University testamur perfectly",
    "altTags" : [
      "Buckingham University certificate frame - Pine Wood Manor Black",
      "Buckingham University certificate frame - Pine Wood Marine Blue",
      "Buckingham University certificate frame - Pine Wood Wine",
      "Buckingham University certificate frame - Pine Wood Eucalypt",
      "Buckingham University certificate frame - Pine Wood Light Cream",
      "Buckingham University certificate frame - Modern Black",
      "Buckingham University certificate frame - Modern Marine Blue",
      "Buckingham University certificate frame - Modern Wine",
      "Buckingham University certificate frame - Modern Eucalypt",
      "Buckingham University certificate frame - Modern Light Cream",
    ],
    "name" : "Buckingham University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Buckingham University Certificate Frame | From £28"
  },
  "/certificate-frame/buckingham-new-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Buckingham New University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Buckingham New University testamur perfectly",
    "altTags" : [
      "Buckingham New University certificate frame - Pine Wood Manor Black",
      "Buckingham New University certificate frame - Pine Wood Marine Blue",
      "Buckingham New University certificate frame - Pine Wood Wine",
      "Buckingham New University certificate frame - Pine Wood Eucalypt",
      "Buckingham New University certificate frame - Pine Wood Light Cream",
      "Buckingham New University certificate frame - Modern Black",
      "Buckingham New University certificate frame - Modern Marine Blue",
      "Buckingham New University certificate frame - Modern Wine",
      "Buckingham New University certificate frame - Modern Eucalypt",
      "Buckingham New University certificate frame - Modern Light Cream",
    ],
    "name" : "Buckingham New University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Buckingham New University Certificate Frame | From £28"
  },
  "/certificate-frame/camberwell-college-of-arts": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Camberwell College of Arts certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Camberwell College of Arts testamur perfectly",
    "altTags" : [
      "Camberwell College of Arts certificate frame - Pine Wood Manor Black",
      "Camberwell College of Arts certificate frame - Pine Wood Marine Blue",
      "Camberwell College of Arts certificate frame - Pine Wood Wine",
      "Camberwell College of Arts certificate frame - Pine Wood Eucalypt",
      "Camberwell College of Arts certificate frame - Pine Wood Light Cream",
      "Camberwell College of Arts certificate frame - Modern Black",
      "Camberwell College of Arts certificate frame - Modern Marine Blue",
      "Camberwell College of Arts certificate frame - Modern Wine",
      "Camberwell College of Arts certificate frame - Modern Eucalypt",
      "Camberwell College of Arts certificate frame - Modern Light Cream",
    ],
    "name" : "Camberwell College of Arts Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Camberwell College of Arts Certificate Frame | From £28"
  },
  "/certificate-frame/cambridge-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Cambridge University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Cambridge University testamur perfectly",
    "altTags" : [
      "Cambridge University certificate frame - Pine Wood Manor Black",
      "Cambridge University certificate frame - Pine Wood Marine Blue",
      "Cambridge University certificate frame - Pine Wood Wine",
      "Cambridge University certificate frame - Pine Wood Eucalypt",
      "Cambridge University certificate frame - Pine Wood Light Cream",
      "Cambridge University certificate frame - Modern Black",
      "Cambridge University certificate frame - Modern Marine Blue",
      "Cambridge University certificate frame - Modern Wine",
      "Cambridge University certificate frame - Modern Eucalypt",
      "Cambridge University certificate frame - Modern Light Cream",
    ],
    "name" : "Cambridge University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Cambridge University Certificate Frame | From £28"
  },
  "/certificate-frame/canterbury-christ-church-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Canterbury Christ Church University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Canterbury Christ Church University testamur perfectly",
    "altTags" : [
      "Canterbury Christ Church University certificate frame - Pine Wood Manor Black",
      "Canterbury Christ Church University certificate frame - Pine Wood Marine Blue",
      "Canterbury Christ Church University certificate frame - Pine Wood Wine",
      "Canterbury Christ Church University certificate frame - Pine Wood Eucalypt",
      "Canterbury Christ Church University certificate frame - Pine Wood Light Cream",
      "Canterbury Christ Church University certificate frame - Modern Black",
      "Canterbury Christ Church University certificate frame - Modern Marine Blue",
      "Canterbury Christ Church University certificate frame - Modern Wine",
      "Canterbury Christ Church University certificate frame - Modern Eucalypt",
      "Canterbury Christ Church University certificate frame - Modern Light Cream",
    ],
    "name" : "Canterbury Christ Church University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Canterbury Christ Church University Certificate Frame | From £28"
  },
  "/certificate-frame/cardiff-metropolitan-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Cardiff Metropolitan University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Cardiff Metropolitan University testamur perfectly",
    "altTags" : [
      "Cardiff Metropolitan University certificate frame - Pine Wood Manor Black",
      "Cardiff Metropolitan University certificate frame - Pine Wood Marine Blue",
      "Cardiff Metropolitan University certificate frame - Pine Wood Wine",
      "Cardiff Metropolitan University certificate frame - Pine Wood Eucalypt",
      "Cardiff Metropolitan University certificate frame - Pine Wood Light Cream",
      "Cardiff Metropolitan University certificate frame - Modern Black",
      "Cardiff Metropolitan University certificate frame - Modern Marine Blue",
      "Cardiff Metropolitan University certificate frame - Modern Wine",
      "Cardiff Metropolitan University certificate frame - Modern Eucalypt",
      "Cardiff Metropolitan University certificate frame - Modern Light Cream",
    ],
    "name" : "Cardiff Metropolitan University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Cardiff Metropolitan University Certificate Frame | From £28"
  },
  "/certificate-frame/central-lancashire-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Central Lancashire University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Central Lancashire University testamur perfectly",
    "altTags" : [
      "Central Lancashire University certificate frame - Pine Wood Manor Black",
      "Central Lancashire University certificate frame - Pine Wood Marine Blue",
      "Central Lancashire University certificate frame - Pine Wood Wine",
      "Central Lancashire University certificate frame - Pine Wood Eucalypt",
      "Central Lancashire University certificate frame - Pine Wood Light Cream",
      "Central Lancashire University certificate frame - Modern Black",
      "Central Lancashire University certificate frame - Modern Marine Blue",
      "Central Lancashire University certificate frame - Modern Wine",
      "Central Lancashire University certificate frame - Modern Eucalypt",
      "Central Lancashire University certificate frame - Modern Light Cream",
    ],
    "name" : "Central Lancashire University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Central Lancashire University Certificate Frame | From £28"
  },
  "/certificate-frame/central-saint-martins": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Central Saint Martins certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Central Saint Martins testamur perfectly",
    "altTags" : [
      "Central Saint Martins certificate frame - Pine Wood Manor Black",
      "Central Saint Martins certificate frame - Pine Wood Marine Blue",
      "Central Saint Martins certificate frame - Pine Wood Wine",
      "Central Saint Martins certificate frame - Pine Wood Eucalypt",
      "Central Saint Martins certificate frame - Pine Wood Light Cream",
      "Central Saint Martins certificate frame - Modern Black",
      "Central Saint Martins certificate frame - Modern Marine Blue",
      "Central Saint Martins certificate frame - Modern Wine",
      "Central Saint Martins certificate frame - Modern Eucalypt",
      "Central Saint Martins certificate frame - Modern Light Cream",
    ],
    "name" : "Central Saint Martins Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Central Saint Martins Certificate Frame | From £28"
  },
  "/certificate-frame/chelsea-college-of-arts": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Chelsea College of Arts certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Chelsea College of Arts testamur perfectly",
    "altTags" : [
      "Chelsea College of Arts certificate frame - Pine Wood Manor Black",
      "Chelsea College of Arts certificate frame - Pine Wood Marine Blue",
      "Chelsea College of Arts certificate frame - Pine Wood Wine",
      "Chelsea College of Arts certificate frame - Pine Wood Eucalypt",
      "Chelsea College of Arts certificate frame - Pine Wood Light Cream",
      "Chelsea College of Arts certificate frame - Modern Black",
      "Chelsea College of Arts certificate frame - Modern Marine Blue",
      "Chelsea College of Arts certificate frame - Modern Wine",
      "Chelsea College of Arts certificate frame - Modern Eucalypt",
      "Chelsea College of Arts certificate frame - Modern Light Cream",
    ],
    "name" : "Chelsea College of Arts Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Chelsea College of Arts Certificate Frame | From £28"
  },
  "/certificate-frame/chester-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Chester University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Chester University testamur perfectly",
    "altTags" : [
      "Chester University certificate frame - Pine Wood Manor Black",
      "Chester University certificate frame - Pine Wood Marine Blue",
      "Chester University certificate frame - Pine Wood Wine",
      "Chester University certificate frame - Pine Wood Eucalypt",
      "Chester University certificate frame - Pine Wood Light Cream",
      "Chester University certificate frame - Modern Black",
      "Chester University certificate frame - Modern Marine Blue",
      "Chester University certificate frame - Modern Wine",
      "Chester University certificate frame - Modern Eucalypt",
      "Chester University certificate frame - Modern Light Cream",
    ],
    "name" : "Chester University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Chester University Certificate Frame | From £28"
  },
  "/certificate-frame/chichester-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Chichester University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Chichester University testamur perfectly",
    "altTags" : [
      "Chichester University certificate frame - Pine Wood Manor Black",
      "Chichester University certificate frame - Pine Wood Marine Blue",
      "Chichester University certificate frame - Pine Wood Wine",
      "Chichester University certificate frame - Pine Wood Eucalypt",
      "Chichester University certificate frame - Pine Wood Light Cream",
      "Chichester University certificate frame - Modern Black",
      "Chichester University certificate frame - Modern Marine Blue",
      "Chichester University certificate frame - Modern Wine",
      "Chichester University certificate frame - Modern Eucalypt",
      "Chichester University certificate frame - Modern Light Cream",
    ],
    "name" : "Chichester University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Chichester University Certificate Frame | From £28"
  },
  "/certificate-frame/city-university-london": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your City University London certificate frame for only £28 | Fast delivery, excellent quality, made to fit your City University London testamur perfectly",
    "altTags" : [
      "City University London certificate frame - Pine Wood Manor Black",
      "City University London certificate frame - Pine Wood Marine Blue",
      "City University London certificate frame - Pine Wood Wine",
      "City University London certificate frame - Pine Wood Eucalypt",
      "City University London certificate frame - Pine Wood Light Cream",
      "City University London certificate frame - Modern Black",
      "City University London certificate frame - Modern Marine Blue",
      "City University London certificate frame - Modern Wine",
      "City University London certificate frame - Modern Eucalypt",
      "City University London certificate frame - Modern Light Cream",
    ],
    "name" : "City University London Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "City University London Certificate Frame | From £28"
  },
  "/certificate-frame/courtauld-institute-of-art": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Courtauld Institute of Art certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Courtauld Institute of Art testamur perfectly",
    "altTags" : [
      "Courtauld Institute of Art certificate frame - Pine Wood Manor Black",
      "Courtauld Institute of Art certificate frame - Pine Wood Marine Blue",
      "Courtauld Institute of Art certificate frame - Pine Wood Wine",
      "Courtauld Institute of Art certificate frame - Pine Wood Eucalypt",
      "Courtauld Institute of Art certificate frame - Pine Wood Light Cream",
      "Courtauld Institute of Art certificate frame - Modern Black",
      "Courtauld Institute of Art certificate frame - Modern Marine Blue",
      "Courtauld Institute of Art certificate frame - Modern Wine",
      "Courtauld Institute of Art certificate frame - Modern Eucalypt",
      "Courtauld Institute of Art certificate frame - Modern Light Cream",
    ],
    "name" : "Courtauld Institute of Art Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Courtauld Institute of Art Certificate Frame | From £28"
  },
  "/certificate-frame/coventry-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Coventry University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Coventry University testamur perfectly",
    "altTags" : [
      "Coventry University certificate frame - Pine Wood Manor Black",
      "Coventry University certificate frame - Pine Wood Marine Blue",
      "Coventry University certificate frame - Pine Wood Wine",
      "Coventry University certificate frame - Pine Wood Eucalypt",
      "Coventry University certificate frame - Pine Wood Light Cream",
      "Coventry University certificate frame - Modern Black",
      "Coventry University certificate frame - Modern Marine Blue",
      "Coventry University certificate frame - Modern Wine",
      "Coventry University certificate frame - Modern Eucalypt",
      "Coventry University certificate frame - Modern Light Cream",
    ],
    "name" : "Coventry University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Coventry University Certificate Frame | From £28"
  },
  "/certificate-frame/cumbria-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Cumbria University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Cumbria University testamur perfectly",
    "altTags" : [
      "Cumbria University certificate frame - Pine Wood Manor Black",
      "Cumbria University certificate frame - Pine Wood Marine Blue",
      "Cumbria University certificate frame - Pine Wood Wine",
      "Cumbria University certificate frame - Pine Wood Eucalypt",
      "Cumbria University certificate frame - Pine Wood Light Cream",
      "Cumbria University certificate frame - Modern Black",
      "Cumbria University certificate frame - Modern Marine Blue",
      "Cumbria University certificate frame - Modern Wine",
      "Cumbria University certificate frame - Modern Eucalypt",
      "Cumbria University certificate frame - Modern Light Cream",
    ],
    "name" : "Cumbria University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Cumbria University Certificate Frame | From £28"
  },
  "/certificate-frame/cranfield-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Cranfield University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Cranfield University testamur perfectly",
    "altTags" : [
      "Cranfield University certificate frame - Pine Wood Manor Black",
      "Cranfield University certificate frame - Pine Wood Marine Blue",
      "Cranfield University certificate frame - Pine Wood Wine",
      "Cranfield University certificate frame - Pine Wood Eucalypt",
      "Cranfield University certificate frame - Pine Wood Light Cream",
      "Cranfield University certificate frame - Modern Black",
      "Cranfield University certificate frame - Modern Marine Blue",
      "Cranfield University certificate frame - Modern Wine",
      "Cranfield University certificate frame - Modern Eucalypt",
      "Cranfield University certificate frame - Modern Light Cream",
    ],
    "name" : "Cranfield University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Cranfield University Certificate Frame | From £28"
  },
  "/certificate-frame/derby-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Derby University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Derby University testamur perfectly",
    "altTags" : [
      "Derby University certificate frame - Pine Wood Manor Black",
      "Derby University certificate frame - Pine Wood Marine Blue",
      "Derby University certificate frame - Pine Wood Wine",
      "Derby University certificate frame - Pine Wood Eucalypt",
      "Derby University certificate frame - Pine Wood Light Cream",
      "Derby University certificate frame - Modern Black",
      "Derby University certificate frame - Modern Marine Blue",
      "Derby University certificate frame - Modern Wine",
      "Derby University certificate frame - Modern Eucalypt",
      "Derby University certificate frame - Modern Light Cream",
    ],
    "name" : "Derby University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Derby University Certificate Frame | From £28"
  },
  "/certificate-frame/demontfort-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Demontfort University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Demontfort University testamur perfectly",
    "altTags" : [
      "Demontfort University certificate frame - Pine Wood Manor Black",
      "Demontfort University certificate frame - Pine Wood Marine Blue",
      "Demontfort University certificate frame - Pine Wood Wine",
      "Demontfort University certificate frame - Pine Wood Eucalypt",
      "Demontfort University certificate frame - Pine Wood Light Cream",
      "Demontfort University certificate frame - Modern Black",
      "Demontfort University certificate frame - Modern Marine Blue",
      "Demontfort University certificate frame - Modern Wine",
      "Demontfort University certificate frame - Modern Eucalypt",
      "Demontfort University certificate frame - Modern Light Cream",
    ],
    "name" : "Demontfort University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Demontfort University Certificate Frame | From £28"
  },
  "/certificate-frame/dundee-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Dundee University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Dundee University testamur perfectly",
    "altTags" : [
      "Dundee University certificate frame - Pine Wood Manor Black",
      "Dundee University certificate frame - Pine Wood Marine Blue",
      "Dundee University certificate frame - Pine Wood Wine",
      "Dundee University certificate frame - Pine Wood Eucalypt",
      "Dundee University certificate frame - Pine Wood Light Cream",
      "Dundee University certificate frame - Modern Black",
      "Dundee University certificate frame - Modern Marine Blue",
      "Dundee University certificate frame - Modern Wine",
      "Dundee University certificate frame - Modern Eucalypt",
      "Dundee University certificate frame - Modern Light Cream",
    ],
    "name" : "Dundee University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Dundee University Certificate Frame | From £28"
  },
  "/certificate-frame/durham-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Durham University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Durham University testamur perfectly",
    "altTags" : [
      "Durham University certificate frame - Pine Wood Manor Black",
      "Durham University certificate frame - Pine Wood Marine Blue",
      "Durham University certificate frame - Pine Wood Wine",
      "Durham University certificate frame - Pine Wood Eucalypt",
      "Durham University certificate frame - Pine Wood Light Cream",
      "Durham University certificate frame - Modern Black",
      "Durham University certificate frame - Modern Marine Blue",
      "Durham University certificate frame - Modern Wine",
      "Durham University certificate frame - Modern Eucalypt",
      "Durham University certificate frame - Modern Light Cream",
    ],
    "name" : "Durham University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Durham University Certificate Frame | From £28"
  },
  "/certificate-frame/east-anglia-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your East Anglia University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your East Anglia University testamur perfectly",
    "altTags" : [
      "East Anglia University certificate frame - Pine Wood Manor Black",
      "East Anglia University certificate frame - Pine Wood Marine Blue",
      "East Anglia University certificate frame - Pine Wood Wine",
      "East Anglia University certificate frame - Pine Wood Eucalypt",
      "East Anglia University certificate frame - Pine Wood Light Cream",
      "East Anglia University certificate frame - Modern Black",
      "East Anglia University certificate frame - Modern Marine Blue",
      "East Anglia University certificate frame - Modern Wine",
      "East Anglia University certificate frame - Modern Eucalypt",
      "East Anglia University certificate frame - Modern Light Cream",
    ],
    "name" : "East Anglia University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "East Anglia University Certificate Frame | From £28"
  },
  "/certificate-frame/east-london-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your East London University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your East London University testamur perfectly",
    "altTags" : [
      "East London University certificate frame - Pine Wood Manor Black",
      "East London University certificate frame - Pine Wood Marine Blue",
      "East London University certificate frame - Pine Wood Wine",
      "East London University certificate frame - Pine Wood Eucalypt",
      "East London University certificate frame - Pine Wood Light Cream",
      "East London University certificate frame - Modern Black",
      "East London University certificate frame - Modern Marine Blue",
      "East London University certificate frame - Modern Wine",
      "East London University certificate frame - Modern Eucalypt",
      "East London University certificate frame - Modern Light Cream",
    ],
    "name" : "East London University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "East London University Certificate Frame | From £28"
  },
  "/certificate-frame/edinburgh-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Edinburgh University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Edinburgh University testamur perfectly",
    "altTags" : [
      "Edinburgh University certificate frame - Pine Wood Manor Black",
      "Edinburgh University certificate frame - Pine Wood Marine Blue",
      "Edinburgh University certificate frame - Pine Wood Wine",
      "Edinburgh University certificate frame - Pine Wood Eucalypt",
      "Edinburgh University certificate frame - Pine Wood Light Cream",
      "Edinburgh University certificate frame - Modern Black",
      "Edinburgh University certificate frame - Modern Marine Blue",
      "Edinburgh University certificate frame - Modern Wine",
      "Edinburgh University certificate frame - Modern Eucalypt",
      "Edinburgh University certificate frame - Modern Light Cream",
    ],
    "name" : "Edinburgh University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Edinburgh University Certificate Frame | From £28"
  },
  "/certificate-frame/essex-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Essex University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Essex University testamur perfectly",
    "altTags" : [
      "Essex University certificate frame - Pine Wood Manor Black",
      "Essex University certificate frame - Pine Wood Marine Blue",
      "Essex University certificate frame - Pine Wood Wine",
      "Essex University certificate frame - Pine Wood Eucalypt",
      "Essex University certificate frame - Pine Wood Light Cream",
      "Essex University certificate frame - Modern Black",
      "Essex University certificate frame - Modern Marine Blue",
      "Essex University certificate frame - Modern Wine",
      "Essex University certificate frame - Modern Eucalypt",
      "Essex University certificate frame - Modern Light Cream",
    ],
    "name" : "Essex University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Essex University Certificate Frame | From £28"
  },
  "/certificate-frame/exeter-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Exeter University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Exeter University testamur perfectly",
    "altTags" : [
      "Exeter University certificate frame - Pine Wood Manor Black",
      "Exeter University certificate frame - Pine Wood Marine Blue",
      "Exeter University certificate frame - Pine Wood Wine",
      "Exeter University certificate frame - Pine Wood Eucalypt",
      "Exeter University certificate frame - Pine Wood Light Cream",
      "Exeter University certificate frame - Modern Black",
      "Exeter University certificate frame - Modern Marine Blue",
      "Exeter University certificate frame - Modern Wine",
      "Exeter University certificate frame - Modern Eucalypt",
      "Exeter University certificate frame - Modern Light Cream",
    ],
    "name" : "Exeter University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Exeter University Certificate Frame | From £28"
  },
  "/certificate-frame/falmouth-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Falmouth University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Falmouth University testamur perfectly",
    "altTags" : [
      "Falmouth University certificate frame - Pine Wood Manor Black",
      "Falmouth University certificate frame - Pine Wood Marine Blue",
      "Falmouth University certificate frame - Pine Wood Wine",
      "Falmouth University certificate frame - Pine Wood Eucalypt",
      "Falmouth University certificate frame - Pine Wood Light Cream",
      "Falmouth University certificate frame - Modern Black",
      "Falmouth University certificate frame - Modern Marine Blue",
      "Falmouth University certificate frame - Modern Wine",
      "Falmouth University certificate frame - Modern Eucalypt",
      "Falmouth University certificate frame - Modern Light Cream",
    ],
    "name" : "Falmouth University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Falmouth University Certificate Frame | From £28"
  },
  "/certificate-frame/glasgow-caledonian-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Glasgow Caledonian University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Glasgow Caledonian University testamur perfectly",
    "altTags" : [
      "Glasgow Caledonian University certificate frame - Pine Wood Manor Black",
      "Glasgow Caledonian University certificate frame - Pine Wood Marine Blue",
      "Glasgow Caledonian University certificate frame - Pine Wood Wine",
      "Glasgow Caledonian University certificate frame - Pine Wood Eucalypt",
      "Glasgow Caledonian University certificate frame - Pine Wood Light Cream",
      "Glasgow Caledonian University certificate frame - Modern Black",
      "Glasgow Caledonian University certificate frame - Modern Marine Blue",
      "Glasgow Caledonian University certificate frame - Modern Wine",
      "Glasgow Caledonian University certificate frame - Modern Eucalypt",
      "Glasgow Caledonian University certificate frame - Modern Light Cream",
    ],
    "name" : "Glasgow Caledonian University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Glasgow Caledonian University Certificate Frame | From £28"
  },
  "/certificate-frame/glasgow-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Glasgow University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Glasgow University testamur perfectly",
    "altTags" : [
      "Glasgow University certificate frame - Pine Wood Manor Black",
      "Glasgow University certificate frame - Pine Wood Marine Blue",
      "Glasgow University certificate frame - Pine Wood Wine",
      "Glasgow University certificate frame - Pine Wood Eucalypt",
      "Glasgow University certificate frame - Pine Wood Light Cream",
      "Glasgow University certificate frame - Modern Black",
      "Glasgow University certificate frame - Modern Marine Blue",
      "Glasgow University certificate frame - Modern Wine",
      "Glasgow University certificate frame - Modern Eucalypt",
      "Glasgow University certificate frame - Modern Light Cream",
    ],
    "name" : "Glasgow University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Glasgow University Certificate Frame | From £28"
  },
  "/certificate-frame/gloucestershire-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Gloucestershire University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Gloucestershire University testamur perfectly",
    "altTags" : [
      "Gloucestershire University certificate frame - Pine Wood Manor Black",
      "Gloucestershire University certificate frame - Pine Wood Marine Blue",
      "Gloucestershire University certificate frame - Pine Wood Wine",
      "Gloucestershire University certificate frame - Pine Wood Eucalypt",
      "Gloucestershire University certificate frame - Pine Wood Light Cream",
      "Gloucestershire University certificate frame - Modern Black",
      "Gloucestershire University certificate frame - Modern Marine Blue",
      "Gloucestershire University certificate frame - Modern Wine",
      "Gloucestershire University certificate frame - Modern Eucalypt",
      "Gloucestershire University certificate frame - Modern Light Cream",
    ],
    "name" : "Gloucestershire University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Gloucestershire University Certificate Frame | From £28"
  },
  "/certificate-frame/glyndwr-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Glyndwr University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Glyndwr University testamur perfectly",
    "altTags" : [
      "Glyndwr University certificate frame - Pine Wood Manor Black",
      "Glyndwr University certificate frame - Pine Wood Marine Blue",
      "Glyndwr University certificate frame - Pine Wood Wine",
      "Glyndwr University certificate frame - Pine Wood Eucalypt",
      "Glyndwr University certificate frame - Pine Wood Light Cream",
      "Glyndwr University certificate frame - Modern Black",
      "Glyndwr University certificate frame - Modern Marine Blue",
      "Glyndwr University certificate frame - Modern Wine",
      "Glyndwr University certificate frame - Modern Eucalypt",
      "Glyndwr University certificate frame - Modern Light Cream",
    ],
    "name" : "Glyndwr University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Glyndwr University Certificate Frame | From £28"
  },
  "/certificate-frame/goldsmiths": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Goldsmiths, University of London certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Goldsmiths, University of London testamur perfectly",
    "altTags" : [
      "Goldsmiths, University of London certificate frame - Pine Wood Manor Black",
      "Goldsmiths, University of London certificate frame - Pine Wood Marine Blue",
      "Goldsmiths, University of London certificate frame - Pine Wood Wine",
      "Goldsmiths, University of London certificate frame - Pine Wood Eucalypt",
      "Goldsmiths, University of London certificate frame - Pine Wood Light Cream",
      "Goldsmiths, University of London certificate frame - Modern Black",
      "Goldsmiths, University of London certificate frame - Modern Marine Blue",
      "Goldsmiths, University of London certificate frame - Modern Wine",
      "Goldsmiths, University of London certificate frame - Modern Eucalypt",
      "Goldsmiths, University of London certificate frame - Modern Light Cream",
    ],
    "name" : "Goldsmiths, University of London Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Goldsmiths, University of London Certificate Frame | From £28"
  },
  "/certificate-frame/greenwich-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Greenwich University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Greenwich University testamur perfectly",
    "altTags" : [
      "Greenwich University certificate frame - Pine Wood Manor Black",
      "Greenwich University certificate frame - Pine Wood Marine Blue",
      "Greenwich University certificate frame - Pine Wood Wine",
      "Greenwich University certificate frame - Pine Wood Eucalypt",
      "Greenwich University certificate frame - Pine Wood Light Cream",
      "Greenwich University certificate frame - Modern Black",
      "Greenwich University certificate frame - Modern Marine Blue",
      "Greenwich University certificate frame - Modern Wine",
      "Greenwich University certificate frame - Modern Eucalypt",
      "Greenwich University certificate frame - Modern Light Cream",
    ],
    "name" : "Greenwich University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Greenwich University Certificate Frame | From £28"
  },
  "/certificate-frame/grimsby-institute-of-higher-education": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Grimsby Institute of Higher Education certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Grimsby Institute of Higher Education testamur perfectly",
    "altTags" : [
      "Grimsby Institute of Higher Education certificate frame - Pine Wood Manor Black",
      "Grimsby Institute of Higher Education certificate frame - Pine Wood Marine Blue",
      "Grimsby Institute of Higher Education certificate frame - Pine Wood Wine",
      "Grimsby Institute of Higher Education certificate frame - Pine Wood Eucalypt",
      "Grimsby Institute of Higher Education certificate frame - Pine Wood Light Cream",
      "Grimsby Institute of Higher Education certificate frame - Modern Black",
      "Grimsby Institute of Higher Education certificate frame - Modern Marine Blue",
      "Grimsby Institute of Higher Education certificate frame - Modern Wine",
      "Grimsby Institute of Higher Education certificate frame - Modern Eucalypt",
      "Grimsby Institute of Higher Education certificate frame - Modern Light Cream",
    ],
    "name" : "Grimsby Institute of Higher Education Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Grimsby Institute of Higher Education Certificate Frame | From £28"
  },
  "/certificate-frame/london-guildhall-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your London Guildhall University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your London Guildhall University testamur perfectly",
    "altTags" : [
      "London Guildhall University certificate frame - Pine Wood Manor Black",
      "London Guildhall University certificate frame - Pine Wood Marine Blue",
      "London Guildhall University certificate frame - Pine Wood Wine",
      "London Guildhall University certificate frame - Pine Wood Eucalypt",
      "London Guildhall University certificate frame - Pine Wood Light Cream",
      "London Guildhall University certificate frame - Modern Black",
      "London Guildhall University certificate frame - Modern Marine Blue",
      "London Guildhall University certificate frame - Modern Wine",
      "London Guildhall University certificate frame - Modern Eucalypt",
      "London Guildhall University certificate frame - Modern Light Cream",
    ],
    "name" : "London Guildhall University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "London Guildhall University Certificate Frame | From £28"
  },
  "/certificate-frame/harper-adams-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Harper Adams University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Harper Adams University testamur perfectly",
    "altTags" : [
      "Harper Adams University certificate frame - Pine Wood Manor Black",
      "Harper Adams University certificate frame - Pine Wood Marine Blue",
      "Harper Adams University certificate frame - Pine Wood Wine",
      "Harper Adams University certificate frame - Pine Wood Eucalypt",
      "Harper Adams University certificate frame - Pine Wood Light Cream",
      "Harper Adams University certificate frame - Modern Black",
      "Harper Adams University certificate frame - Modern Marine Blue",
      "Harper Adams University certificate frame - Modern Wine",
      "Harper Adams University certificate frame - Modern Eucalypt",
      "Harper Adams University certificate frame - Modern Light Cream",
    ],
    "name" : "Harper Adams University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Harper Adams University Certificate Frame | From £28"
  },
  "/certificate-frame/heriot-watt-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Heriot Watt University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Heriot Watt University testamur perfectly",
    "altTags" : [
      "Heriot Watt University certificate frame - Pine Wood Manor Black",
      "Heriot Watt University certificate frame - Pine Wood Marine Blue",
      "Heriot Watt University certificate frame - Pine Wood Wine",
      "Heriot Watt University certificate frame - Pine Wood Eucalypt",
      "Heriot Watt University certificate frame - Pine Wood Light Cream",
      "Heriot Watt University certificate frame - Modern Black",
      "Heriot Watt University certificate frame - Modern Marine Blue",
      "Heriot Watt University certificate frame - Modern Wine",
      "Heriot Watt University certificate frame - Modern Eucalypt",
      "Heriot Watt University certificate frame - Modern Light Cream",
    ],
    "name" : "Heriot Watt University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Heriot Watt University Certificate Frame | From £28"
  },
  "/certificate-frame/highlands-islands-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Highlands Islands University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Highlands Islands University testamur perfectly",
    "altTags" : [
      "Highlands Islands University certificate frame - Pine Wood Manor Black",
      "Highlands Islands University certificate frame - Pine Wood Marine Blue",
      "Highlands Islands University certificate frame - Pine Wood Wine",
      "Highlands Islands University certificate frame - Pine Wood Eucalypt",
      "Highlands Islands University certificate frame - Pine Wood Light Cream",
      "Highlands Islands University certificate frame - Modern Black",
      "Highlands Islands University certificate frame - Modern Marine Blue",
      "Highlands Islands University certificate frame - Modern Wine",
      "Highlands Islands University certificate frame - Modern Eucalypt",
      "Highlands Islands University certificate frame - Modern Light Cream",
    ],
    "name" : "Highlands Islands University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Highlands Islands University Certificate Frame | From £28"
  },
  "/certificate-frame/huddersfield-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Huddersfield University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Huddersfield University testamur perfectly",
    "altTags" : [
      "Huddersfield University certificate frame - Pine Wood Manor Black",
      "Huddersfield University certificate frame - Pine Wood Marine Blue",
      "Huddersfield University certificate frame - Pine Wood Wine",
      "Huddersfield University certificate frame - Pine Wood Eucalypt",
      "Huddersfield University certificate frame - Pine Wood Light Cream",
      "Huddersfield University certificate frame - Modern Black",
      "Huddersfield University certificate frame - Modern Marine Blue",
      "Huddersfield University certificate frame - Modern Wine",
      "Huddersfield University certificate frame - Modern Eucalypt",
      "Huddersfield University certificate frame - Modern Light Cream",
    ],
    "name" : "Huddersfield University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Huddersfield University Certificate Frame | From £28"
  },
  "/certificate-frame/imperial-college-london": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Imperial College London certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Imperial College London testamur perfectly",
    "altTags" : [
      "Imperial College London certificate frame - Pine Wood Manor Black",
      "Imperial College London certificate frame - Pine Wood Marine Blue",
      "Imperial College London certificate frame - Pine Wood Wine",
      "Imperial College London certificate frame - Pine Wood Eucalypt",
      "Imperial College London certificate frame - Pine Wood Light Cream",
      "Imperial College London certificate frame - Modern Black",
      "Imperial College London certificate frame - Modern Marine Blue",
      "Imperial College London certificate frame - Modern Wine",
      "Imperial College London certificate frame - Modern Eucalypt",
      "Imperial College London certificate frame - Modern Light Cream",
    ],
    "name" : "Imperial College London Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Imperial College London Certificate Frame | From £28"
  },
  "/certificate-frame/institute-of-cancer-research": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Institute of Cancer Research certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Institute of Cancer Research testamur perfectly",
    "altTags" : [
      "Institute of Cancer Research certificate frame - Pine Wood Manor Black",
      "Institute of Cancer Research certificate frame - Pine Wood Marine Blue",
      "Institute of Cancer Research certificate frame - Pine Wood Wine",
      "Institute of Cancer Research certificate frame - Pine Wood Eucalypt",
      "Institute of Cancer Research certificate frame - Pine Wood Light Cream",
      "Institute of Cancer Research certificate frame - Modern Black",
      "Institute of Cancer Research certificate frame - Modern Marine Blue",
      "Institute of Cancer Research certificate frame - Modern Wine",
      "Institute of Cancer Research certificate frame - Modern Eucalypt",
      "Institute of Cancer Research certificate frame - Modern Light Cream",
    ],
    "name" : "Institute of Cancer Research Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Institute of Cancer Research Certificate Frame | From £28"
  },
  "/certificate-frame/kent-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Kent University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Kent University testamur perfectly",
    "altTags" : [
      "Kent University certificate frame - Pine Wood Manor Black",
      "Kent University certificate frame - Pine Wood Marine Blue",
      "Kent University certificate frame - Pine Wood Wine",
      "Kent University certificate frame - Pine Wood Eucalypt",
      "Kent University certificate frame - Pine Wood Light Cream",
      "Kent University certificate frame - Modern Black",
      "Kent University certificate frame - Modern Marine Blue",
      "Kent University certificate frame - Modern Wine",
      "Kent University certificate frame - Modern Eucalypt",
      "Kent University certificate frame - Modern Light Cream",
    ],
    "name" : "Kent University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Kent University Certificate Frame | From £28"
  },
  "/certificate-frame/kingston-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Kingston University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Kingston University testamur perfectly",
    "altTags" : [
      "Kingston University certificate frame - Pine Wood Manor Black",
      "Kingston University certificate frame - Pine Wood Marine Blue",
      "Kingston University certificate frame - Pine Wood Wine",
      "Kingston University certificate frame - Pine Wood Eucalypt",
      "Kingston University certificate frame - Pine Wood Light Cream",
      "Kingston University certificate frame - Modern Black",
      "Kingston University certificate frame - Modern Marine Blue",
      "Kingston University certificate frame - Modern Wine",
      "Kingston University certificate frame - Modern Eucalypt",
      "Kingston University certificate frame - Modern Light Cream",
    ],
    "name" : "Kingston University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Kingston University Certificate Frame | From £28"
  },
  "/certificate-frame/kings-college-london": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Kings College London certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Kings College London testamur perfectly",
    "altTags" : [
      "Kings College London certificate frame - Pine Wood Manor Black",
      "Kings College London certificate frame - Pine Wood Marine Blue",
      "Kings College London certificate frame - Pine Wood Wine",
      "Kings College London certificate frame - Pine Wood Eucalypt",
      "Kings College London certificate frame - Pine Wood Light Cream",
      "Kings College London certificate frame - Modern Black",
      "Kings College London certificate frame - Modern Marine Blue",
      "Kings College London certificate frame - Modern Wine",
      "Kings College London certificate frame - Modern Eucalypt",
      "Kings College London certificate frame - Modern Light Cream",
    ],
    "name" : "Kings College London Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Kings College London Certificate Frame | From £28"
  },
  "/certificate-frame/lancaster-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Lancaster University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Lancaster University testamur perfectly",
    "altTags" : [
      "Lancaster University certificate frame - Pine Wood Manor Black",
      "Lancaster University certificate frame - Pine Wood Marine Blue",
      "Lancaster University certificate frame - Pine Wood Wine",
      "Lancaster University certificate frame - Pine Wood Eucalypt",
      "Lancaster University certificate frame - Pine Wood Light Cream",
      "Lancaster University certificate frame - Modern Black",
      "Lancaster University certificate frame - Modern Marine Blue",
      "Lancaster University certificate frame - Modern Wine",
      "Lancaster University certificate frame - Modern Eucalypt",
      "Lancaster University certificate frame - Modern Light Cream",
    ],
    "name" : "Lancaster University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Lancaster University Certificate Frame | From £28"
  },
  "/certificate-frame/leeds-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Leeds University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Leeds University testamur perfectly",
    "altTags" : [
      "Leeds University certificate frame - Pine Wood Manor Black",
      "Leeds University certificate frame - Pine Wood Marine Blue",
      "Leeds University certificate frame - Pine Wood Wine",
      "Leeds University certificate frame - Pine Wood Eucalypt",
      "Leeds University certificate frame - Pine Wood Light Cream",
      "Leeds University certificate frame - Modern Black",
      "Leeds University certificate frame - Modern Marine Blue",
      "Leeds University certificate frame - Modern Wine",
      "Leeds University certificate frame - Modern Eucalypt",
      "Leeds University certificate frame - Modern Light Cream",
    ],
    "name" : "Leeds University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Leeds University Certificate Frame | From £28"
  },
  "/certificate-frame/leicester-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Leicester University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Leicester University testamur perfectly",
    "altTags" : [
      "Leicester University certificate frame - Pine Wood Manor Black",
      "Leicester University certificate frame - Pine Wood Marine Blue",
      "Leicester University certificate frame - Pine Wood Wine",
      "Leicester University certificate frame - Pine Wood Eucalypt",
      "Leicester University certificate frame - Pine Wood Light Cream",
      "Leicester University certificate frame - Modern Black",
      "Leicester University certificate frame - Modern Marine Blue",
      "Leicester University certificate frame - Modern Wine",
      "Leicester University certificate frame - Modern Eucalypt",
      "Leicester University certificate frame - Modern Light Cream",
    ],
    "name" : "Leicester University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Leicester University Certificate Frame | From £28"
  },
  "/certificate-frame/lincoln-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Lincoln University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Lincoln University testamur perfectly",
    "altTags" : [
      "Lincoln University certificate frame - Pine Wood Manor Black",
      "Lincoln University certificate frame - Pine Wood Marine Blue",
      "Lincoln University certificate frame - Pine Wood Wine",
      "Lincoln University certificate frame - Pine Wood Eucalypt",
      "Lincoln University certificate frame - Pine Wood Light Cream",
      "Lincoln University certificate frame - Modern Black",
      "Lincoln University certificate frame - Modern Marine Blue",
      "Lincoln University certificate frame - Modern Wine",
      "Lincoln University certificate frame - Modern Eucalypt",
      "Lincoln University certificate frame - Modern Light Cream",
    ],
    "name" : "Lincoln University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Lincoln University Certificate Frame | From £28"
  },
  "/certificate-frame/liverpool-hope-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Liverpool Hope University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Liverpool Hope University testamur perfectly",
    "altTags" : [
      "Liverpool Hope University certificate frame - Pine Wood Manor Black",
      "Liverpool Hope University certificate frame - Pine Wood Marine Blue",
      "Liverpool Hope University certificate frame - Pine Wood Wine",
      "Liverpool Hope University certificate frame - Pine Wood Eucalypt",
      "Liverpool Hope University certificate frame - Pine Wood Light Cream",
      "Liverpool Hope University certificate frame - Modern Black",
      "Liverpool Hope University certificate frame - Modern Marine Blue",
      "Liverpool Hope University certificate frame - Modern Wine",
      "Liverpool Hope University certificate frame - Modern Eucalypt",
      "Liverpool Hope University certificate frame - Modern Light Cream",
    ],
    "name" : "Liverpool Hope University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Liverpool Hope University Certificate Frame | From £28"
  },
  "/certificate-frame/Liverpool-john-moores-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Liverpool John Moores University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Liverpool John Moores University testamur perfectly",
    "altTags" : [
      "Liverpool John Moores University certificate frame - Pine Wood Manor Black",
      "Liverpool John Moores University certificate frame - Pine Wood Marine Blue",
      "Liverpool John Moores University certificate frame - Pine Wood Wine",
      "Liverpool John Moores University certificate frame - Pine Wood Eucalypt",
      "Liverpool John Moores University certificate frame - Pine Wood Light Cream",
      "Liverpool John Moores University certificate frame - Modern Black",
      "Liverpool John Moores University certificate frame - Modern Marine Blue",
      "Liverpool John Moores University certificate frame - Modern Wine",
      "Liverpool John Moores University certificate frame - Modern Eucalypt",
      "Liverpool John Moores University certificate frame - Modern Light Cream",
    ],
    "name" : "Liverpool John Moores University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Liverpool John Moores University Certificate Frame | From £28"
  },
  "/certificate-frame/liverpool-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Liverpool University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Liverpool University testamur perfectly",
    "altTags" : [
      "Liverpool University certificate frame - Pine Wood Manor Black",
      "Liverpool University certificate frame - Pine Wood Marine Blue",
      "Liverpool University certificate frame - Pine Wood Wine",
      "Liverpool University certificate frame - Pine Wood Eucalypt",
      "Liverpool University certificate frame - Pine Wood Light Cream",
      "Liverpool University certificate frame - Modern Black",
      "Liverpool University certificate frame - Modern Marine Blue",
      "Liverpool University certificate frame - Modern Wine",
      "Liverpool University certificate frame - Modern Eucalypt",
      "Liverpool University certificate frame - Modern Light Cream",
    ],
    "name" : "Liverpool University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Liverpool University Certificate Frame | From £28"
  },
  "/certificate-frame/london-business-school": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your London Business School certificate frame for only £28 | Fast delivery, excellent quality, made to fit your London Business School testamur perfectly",
    "altTags" : [
      "London Business School certificate frame - Pine Wood Manor Black",
      "London Business School certificate frame - Pine Wood Marine Blue",
      "London Business School certificate frame - Pine Wood Wine",
      "London Business School certificate frame - Pine Wood Eucalypt",
      "London Business School certificate frame - Pine Wood Light Cream",
      "London Business School certificate frame - Modern Black",
      "London Business School certificate frame - Modern Marine Blue",
      "London Business School certificate frame - Modern Wine",
      "London Business School certificate frame - Modern Eucalypt",
      "London Business School certificate frame - Modern Light Cream",
    ],
    "name" : "London Business School Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "London Business School Certificate Frame | From £28"
  },
  "/certificate-frame/london-college-of-communication": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your London College of Communication certificate frame for only £28 | Fast delivery, excellent quality, made to fit your London College of Communication testamur perfectly",
    "altTags" : [
      "London College of Communication certificate frame - Pine Wood Manor Black",
      "London College of Communication certificate frame - Pine Wood Marine Blue",
      "London College of Communication certificate frame - Pine Wood Wine",
      "London College of Communication certificate frame - Pine Wood Eucalypt",
      "London College of Communication certificate frame - Pine Wood Light Cream",
      "London College of Communication certificate frame - Modern Black",
      "London College of Communication certificate frame - Modern Marine Blue",
      "London College of Communication certificate frame - Modern Wine",
      "London College of Communication certificate frame - Modern Eucalypt",
      "London College of Communication certificate frame - Modern Light Cream",
    ],
    "name" : "London College of Communication Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "London College of Communication Certificate Frame | From £28"
  },
  "/certificate-frame/london-college-of-fashion": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your London College of Fashion certificate frame for only £28 | Fast delivery, excellent quality, made to fit your London College of Fashion testamur perfectly",
    "altTags" : [
      "London College of Fashion certificate frame - Pine Wood Manor Black",
      "London College of Fashion certificate frame - Pine Wood Marine Blue",
      "London College of Fashion certificate frame - Pine Wood Wine",
      "London College of Fashion certificate frame - Pine Wood Eucalypt",
      "London College of Fashion certificate frame - Pine Wood Light Cream",
      "London College of Fashion certificate frame - Modern Black",
      "London College of Fashion certificate frame - Modern Marine Blue",
      "London College of Fashion certificate frame - Modern Wine",
      "London College of Fashion certificate frame - Modern Eucalypt",
      "London College of Fashion certificate frame - Modern Light Cream",
    ],
    "name" : "London College of Fashion Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "London College of Fashion Certificate Frame | From £28"
  },
  "/certificate-frame/london-institute-of-banking-finance": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your London Institute of Banking Finance certificate frame for only £28 | Fast delivery, excellent quality, made to fit your London Institute of Banking Finance testamur perfectly",
    "altTags" : [
      "London Institute of Banking Finance certificate frame - Pine Wood Manor Black",
      "London Institute of Banking Finance certificate frame - Pine Wood Marine Blue",
      "London Institute of Banking Finance certificate frame - Pine Wood Wine",
      "London Institute of Banking Finance certificate frame - Pine Wood Eucalypt",
      "London Institute of Banking Finance certificate frame - Pine Wood Light Cream",
      "London Institute of Banking Finance certificate frame - Modern Black",
      "London Institute of Banking Finance certificate frame - Modern Marine Blue",
      "London Institute of Banking Finance certificate frame - Modern Wine",
      "London Institute of Banking Finance certificate frame - Modern Eucalypt",
      "London Institute of Banking Finance certificate frame - Modern Light Cream",
    ],
    "name" : "London Institute of Banking Finance Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "London Institute of Banking Finance Certificate Frame | From £28"
  },
  "/certificate-frame/london-school-of-economics": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your London School of Economics certificate frame for only £28 | Fast delivery, excellent quality, made to fit your London School of Economics testamur perfectly",
    "altTags" : [
      "London School of Economics certificate frame - Pine Wood Manor Black",
      "London School of Economics certificate frame - Pine Wood Marine Blue",
      "London School of Economics certificate frame - Pine Wood Wine",
      "London School of Economics certificate frame - Pine Wood Eucalypt",
      "London School of Economics certificate frame - Pine Wood Light Cream",
      "London School of Economics certificate frame - Modern Black",
      "London School of Economics certificate frame - Modern Marine Blue",
      "London School of Economics certificate frame - Modern Wine",
      "London School of Economics certificate frame - Modern Eucalypt",
      "London School of Economics certificate frame - Modern Light Cream",
    ],
    "name" : "London School of Economics Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "London School of Economics Certificate Frame | From £28"
  },
  "/certificate-frame/london-school-of-hygiene-tropical-medicine": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your London School of Hygiene Tropical Medicine certificate frame for only £28 | Fast delivery, excellent quality, made to fit your London School of Hygiene Tropical Medicine testamur perfectly",
    "altTags" : [
      "London School of Hygiene Tropical Medicine certificate frame - Pine Wood Manor Black",
      "London School of Hygiene Tropical Medicine certificate frame - Pine Wood Marine Blue",
      "London School of Hygiene Tropical Medicine certificate frame - Pine Wood Wine",
      "London School of Hygiene Tropical Medicine certificate frame - Pine Wood Eucalypt",
      "London School of Hygiene Tropical Medicine certificate frame - Pine Wood Light Cream",
      "London School of Hygiene Tropical Medicine certificate frame - Modern Black",
      "London School of Hygiene Tropical Medicine certificate frame - Modern Marine Blue",
      "London School of Hygiene Tropical Medicine certificate frame - Modern Wine",
      "London School of Hygiene Tropical Medicine certificate frame - Modern Eucalypt",
      "London School of Hygiene Tropical Medicine certificate frame - Modern Light Cream",
    ],
    "name" : "London School of Hygiene Tropical Medicine Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "London School of Hygiene Tropical Medicine Certificate Frame | From £28"
  },
  "/certificate-frame/london-south-bank-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your London South Bank University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your London South Bank University testamur perfectly",
    "altTags" : [
      "London South Bank University certificate frame - Pine Wood Manor Black",
      "London South Bank University certificate frame - Pine Wood Marine Blue",
      "London South Bank University certificate frame - Pine Wood Wine",
      "London South Bank University certificate frame - Pine Wood Eucalypt",
      "London South Bank University certificate frame - Pine Wood Light Cream",
      "London South Bank University certificate frame - Modern Black",
      "London South Bank University certificate frame - Modern Marine Blue",
      "London South Bank University certificate frame - Modern Wine",
      "London South Bank University certificate frame - Modern Eucalypt",
      "London South Bank University certificate frame - Modern Light Cream",
    ],
    "name" : "London South Bank University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "London South Bank University Certificate Frame | From £28"
  },
  "/certificate-frame/loughborough-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Loughborough University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Loughborough University testamur perfectly",
    "altTags" : [
      "Loughborough University certificate frame - Pine Wood Manor Black",
      "Loughborough University certificate frame - Pine Wood Marine Blue",
      "Loughborough University certificate frame - Pine Wood Wine",
      "Loughborough University certificate frame - Pine Wood Eucalypt",
      "Loughborough University certificate frame - Pine Wood Light Cream",
      "Loughborough University certificate frame - Modern Black",
      "Loughborough University certificate frame - Modern Marine Blue",
      "Loughborough University certificate frame - Modern Wine",
      "Loughborough University certificate frame - Modern Eucalypt",
      "Loughborough University certificate frame - Modern Light Cream",
    ],
    "name" : "Loughborough University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Loughborough University Certificate Frame | From £28"
  },
  "/certificate-frame/manchester-metropolitan-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Manchester Metropolitan University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Manchester Metropolitan University testamur perfectly",
    "altTags" : [
      "Manchester Metropolitan University certificate frame - Pine Wood Manor Black",
      "Manchester Metropolitan University certificate frame - Pine Wood Marine Blue",
      "Manchester Metropolitan University certificate frame - Pine Wood Wine",
      "Manchester Metropolitan University certificate frame - Pine Wood Eucalypt",
      "Manchester Metropolitan University certificate frame - Pine Wood Light Cream",
      "Manchester Metropolitan University certificate frame - Modern Black",
      "Manchester Metropolitan University certificate frame - Modern Marine Blue",
      "Manchester Metropolitan University certificate frame - Modern Wine",
      "Manchester Metropolitan University certificate frame - Modern Eucalypt",
      "Manchester Metropolitan University certificate frame - Modern Light Cream",
    ],
    "name" : "Manchester Metropolitan University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Manchester Metropolitan University Certificate Frame | From £28"
  },
  "/certificate-frame/manchester-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Manchester University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Manchester University testamur perfectly",
    "altTags" : [
      "Manchester University certificate frame - Pine Wood Manor Black",
      "Manchester University certificate frame - Pine Wood Marine Blue",
      "Manchester University certificate frame - Pine Wood Wine",
      "Manchester University certificate frame - Pine Wood Eucalypt",
      "Manchester University certificate frame - Pine Wood Light Cream",
      "Manchester University certificate frame - Modern Black",
      "Manchester University certificate frame - Modern Marine Blue",
      "Manchester University certificate frame - Modern Wine",
      "Manchester University certificate frame - Modern Eucalypt",
      "Manchester University certificate frame - Modern Light Cream",
    ],
    "name" : "Manchester University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Manchester University Certificate Frame | From £28"
  },
  "/certificate-frame/marjon-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Marjon University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Marjon University testamur perfectly",
    "altTags" : [
      "Marjon University certificate frame - Pine Wood Manor Black",
      "Marjon University certificate frame - Pine Wood Marine Blue",
      "Marjon University certificate frame - Pine Wood Wine",
      "Marjon University certificate frame - Pine Wood Eucalypt",
      "Marjon University certificate frame - Pine Wood Light Cream",
      "Marjon University certificate frame - Modern Black",
      "Marjon University certificate frame - Modern Marine Blue",
      "Marjon University certificate frame - Modern Wine",
      "Marjon University certificate frame - Modern Eucalypt",
      "Marjon University certificate frame - Modern Light Cream",
    ],
    "name" : "Marjon University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Marjon University Certificate Frame | From £28"
  },
  "/certificate-frame/middlesex-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Middlesex University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Middlesex University testamur perfectly",
    "altTags" : [
      "Middlesex University certificate frame - Pine Wood Manor Black",
      "Middlesex University certificate frame - Pine Wood Marine Blue",
      "Middlesex University certificate frame - Pine Wood Wine",
      "Middlesex University certificate frame - Pine Wood Eucalypt",
      "Middlesex University certificate frame - Pine Wood Light Cream",
      "Middlesex University certificate frame - Modern Black",
      "Middlesex University certificate frame - Modern Marine Blue",
      "Middlesex University certificate frame - Modern Wine",
      "Middlesex University certificate frame - Modern Eucalypt",
      "Middlesex University certificate frame - Modern Light Cream",
    ],
    "name" : "Middlesex University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Middlesex University Certificate Frame | From £28"
  },
  "/certificate-frame/newcastle-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Newcastle University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Newcastle University testamur perfectly",
    "altTags" : [
      "Newcastle University certificate frame - Pine Wood Manor Black",
      "Newcastle University certificate frame - Pine Wood Marine Blue",
      "Newcastle University certificate frame - Pine Wood Wine",
      "Newcastle University certificate frame - Pine Wood Eucalypt",
      "Newcastle University certificate frame - Pine Wood Light Cream",
      "Newcastle University certificate frame - Modern Black",
      "Newcastle University certificate frame - Modern Marine Blue",
      "Newcastle University certificate frame - Modern Wine",
      "Newcastle University certificate frame - Modern Eucalypt",
      "Newcastle University certificate frame - Modern Light Cream",
    ],
    "name" : "Newcastle University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Newcastle University Certificate Frame | From £28"
  },
  "/certificate-frame/newcastle-college": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Newcastle College certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Newcastle College testamur perfectly",
    "altTags" : [
      "Newcastle College certificate frame - Pine Wood Manor Black",
      "Newcastle College certificate frame - Pine Wood Marine Blue",
      "Newcastle College certificate frame - Pine Wood Wine",
      "Newcastle College certificate frame - Pine Wood Eucalypt",
      "Newcastle College certificate frame - Pine Wood Light Cream",
      "Newcastle College certificate frame - Modern Black",
      "Newcastle College certificate frame - Modern Marine Blue",
      "Newcastle College certificate frame - Modern Wine",
      "Newcastle College certificate frame - Modern Eucalypt",
      "Newcastle College certificate frame - Modern Light Cream",
    ],
    "name" : "Newcastle College Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Newcastle College Certificate Frame | From £28"
  },
  "/certificate-frame/new-college-durham": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your New College Durham certificate frame for only £28 | Fast delivery, excellent quality, made to fit your New College Durham testamur perfectly",
    "altTags" : [
      "New College Durham certificate frame - Pine Wood Manor Black",
      "New College Durham certificate frame - Pine Wood Marine Blue",
      "New College Durham certificate frame - Pine Wood Wine",
      "New College Durham certificate frame - Pine Wood Eucalypt",
      "New College Durham certificate frame - Pine Wood Light Cream",
      "New College Durham certificate frame - Modern Black",
      "New College Durham certificate frame - Modern Marine Blue",
      "New College Durham certificate frame - Modern Wine",
      "New College Durham certificate frame - Modern Eucalypt",
      "New College Durham certificate frame - Modern Light Cream",
    ],
    "name" : "New College Durham Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "New College Durham Certificate Frame | From £28"
  },
  "/certificate-frame/newman-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Newman University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Newman University testamur perfectly",
    "altTags" : [
      "Newman University certificate frame - Pine Wood Manor Black",
      "Newman University certificate frame - Pine Wood Marine Blue",
      "Newman University certificate frame - Pine Wood Wine",
      "Newman University certificate frame - Pine Wood Eucalypt",
      "Newman University certificate frame - Pine Wood Light Cream",
      "Newman University certificate frame - Modern Black",
      "Newman University certificate frame - Modern Marine Blue",
      "Newman University certificate frame - Modern Wine",
      "Newman University certificate frame - Modern Eucalypt",
      "Newman University certificate frame - Modern Light Cream",
    ],
    "name" : "Newman University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Newman University Certificate Frame | From £28"
  },
  "/certificate-frame/northampton-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Northampton University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Northampton University testamur perfectly",
    "altTags" : [
      "Northampton University certificate frame - Pine Wood Manor Black",
      "Northampton University certificate frame - Pine Wood Marine Blue",
      "Northampton University certificate frame - Pine Wood Wine",
      "Northampton University certificate frame - Pine Wood Eucalypt",
      "Northampton University certificate frame - Pine Wood Light Cream",
      "Northampton University certificate frame - Modern Black",
      "Northampton University certificate frame - Modern Marine Blue",
      "Northampton University certificate frame - Modern Wine",
      "Northampton University certificate frame - Modern Eucalypt",
      "Northampton University certificate frame - Modern Light Cream",
    ],
    "name" : "Northampton University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Northampton University Certificate Frame | From £28"
  },
  "/certificate-frame/northumbria-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Northumbria University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Northumbria University testamur perfectly",
    "altTags" : [
      "Northumbria University certificate frame - Pine Wood Manor Black",
      "Northumbria University certificate frame - Pine Wood Marine Blue",
      "Northumbria University certificate frame - Pine Wood Wine",
      "Northumbria University certificate frame - Pine Wood Eucalypt",
      "Northumbria University certificate frame - Pine Wood Light Cream",
      "Northumbria University certificate frame - Modern Black",
      "Northumbria University certificate frame - Modern Marine Blue",
      "Northumbria University certificate frame - Modern Wine",
      "Northumbria University certificate frame - Modern Eucalypt",
      "Northumbria University certificate frame - Modern Light Cream",
    ],
    "name" : "Northumbria University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Northumbria University Certificate Frame | From £28"
  },
  "/certificate-frame/norwich-university-of-the-arts": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Norwich University of the Arts certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Norwich University of the Arts testamur perfectly",
    "altTags" : [
      "Norwich University of the Arts certificate frame - Pine Wood Manor Black",
      "Norwich University of the Arts certificate frame - Pine Wood Marine Blue",
      "Norwich University of the Arts certificate frame - Pine Wood Wine",
      "Norwich University of the Arts certificate frame - Pine Wood Eucalypt",
      "Norwich University of the Arts certificate frame - Pine Wood Light Cream",
      "Norwich University of the Arts certificate frame - Modern Black",
      "Norwich University of the Arts certificate frame - Modern Marine Blue",
      "Norwich University of the Arts certificate frame - Modern Wine",
      "Norwich University of the Arts certificate frame - Modern Eucalypt",
      "Norwich University of the Arts certificate frame - Modern Light Cream",
    ],
    "name" : "Norwich University of the Arts Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Norwich University of the Arts Certificate Frame | From £28"
  },
  "/certificate-frame/nottingham-trent-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Nottingham Trent University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Nottingham Trent University testamur perfectly",
    "altTags" : [
      "Nottingham Trent University certificate frame - Pine Wood Manor Black",
      "Nottingham Trent University certificate frame - Pine Wood Marine Blue",
      "Nottingham Trent University certificate frame - Pine Wood Wine",
      "Nottingham Trent University certificate frame - Pine Wood Eucalypt",
      "Nottingham Trent University certificate frame - Pine Wood Light Cream",
      "Nottingham Trent University certificate frame - Modern Black",
      "Nottingham Trent University certificate frame - Modern Marine Blue",
      "Nottingham Trent University certificate frame - Modern Wine",
      "Nottingham Trent University certificate frame - Modern Eucalypt",
      "Nottingham Trent University certificate frame - Modern Light Cream",
    ],
    "name" : "Nottingham Trent University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Nottingham Trent University Certificate Frame | From £28"
  },
  "/certificate-frame/nottingham-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Nottingham University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Nottingham University testamur perfectly",
    "altTags" : [
      "Nottingham University certificate frame - Pine Wood Manor Black",
      "Nottingham University certificate frame - Pine Wood Marine Blue",
      "Nottingham University certificate frame - Pine Wood Wine",
      "Nottingham University certificate frame - Pine Wood Eucalypt",
      "Nottingham University certificate frame - Pine Wood Light Cream",
      "Nottingham University certificate frame - Modern Black",
      "Nottingham University certificate frame - Modern Marine Blue",
      "Nottingham University certificate frame - Modern Wine",
      "Nottingham University certificate frame - Modern Eucalypt",
      "Nottingham University certificate frame - Modern Light Cream",
    ],
    "name" : "Nottingham University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Nottingham University Certificate Frame | From £28"
  },
  "/certificate-frame/oxford-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Oxford University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Oxford University testamur perfectly",
    "altTags" : [
      "Oxford University certificate frame - Pine Wood Manor Black",
      "Oxford University certificate frame - Pine Wood Marine Blue",
      "Oxford University certificate frame - Pine Wood Wine",
      "Oxford University certificate frame - Pine Wood Eucalypt",
      "Oxford University certificate frame - Pine Wood Light Cream",
      "Oxford University certificate frame - Modern Black",
      "Oxford University certificate frame - Modern Marine Blue",
      "Oxford University certificate frame - Modern Wine",
      "Oxford University certificate frame - Modern Eucalypt",
      "Oxford University certificate frame - Modern Light Cream",
    ],
    "name" : "Oxford University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Oxford University Certificate Frame | From £28"
  },
  "/certificate-frame/plymouth-marion-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Plymouth Marjon University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Plymouth Marjon University testamur perfectly",
    "altTags" : [
      "Plymouth Marjon University certificate frame - Pine Wood Manor Black",
      "Plymouth Marjon University certificate frame - Pine Wood Marine Blue",
      "Plymouth Marjon University certificate frame - Pine Wood Wine",
      "Plymouth Marjon University certificate frame - Pine Wood Eucalypt",
      "Plymouth Marjon University certificate frame - Pine Wood Light Cream",
      "Plymouth Marjon University certificate frame - Modern Black",
      "Plymouth Marjon University certificate frame - Modern Marine Blue",
      "Plymouth Marjon University certificate frame - Modern Wine",
      "Plymouth Marjon University certificate frame - Modern Eucalypt",
      "Plymouth Marjon University certificate frame - Modern Light Cream",
    ],
    "name" : "Plymouth Marjon University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Plymouth Marjon University Certificate Frame | From £28"
  },
  "/certificate-frame/portsmouth-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Portsmouth University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Portsmouth University testamur perfectly",
    "altTags" : [
      "Portsmouth University certificate frame - Pine Wood Manor Black",
      "Portsmouth University certificate frame - Pine Wood Marine Blue",
      "Portsmouth University certificate frame - Pine Wood Wine",
      "Portsmouth University certificate frame - Pine Wood Eucalypt",
      "Portsmouth University certificate frame - Pine Wood Light Cream",
      "Portsmouth University certificate frame - Modern Black",
      "Portsmouth University certificate frame - Modern Marine Blue",
      "Portsmouth University certificate frame - Modern Wine",
      "Portsmouth University certificate frame - Modern Eucalypt",
      "Portsmouth University certificate frame - Modern Light Cream",
    ],
    "name" : "Portsmouth University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Portsmouth University Certificate Frame | From £28"
  },
  "/certificate-frame/queen-margaret-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Queen Margaret University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Queen Margaret University testamur perfectly",
    "altTags" : [
      "Queen Margaret University certificate frame - Pine Wood Manor Black",
      "Queen Margaret University certificate frame - Pine Wood Marine Blue",
      "Queen Margaret University certificate frame - Pine Wood Wine",
      "Queen Margaret University certificate frame - Pine Wood Eucalypt",
      "Queen Margaret University certificate frame - Pine Wood Light Cream",
      "Queen Margaret University certificate frame - Modern Black",
      "Queen Margaret University certificate frame - Modern Marine Blue",
      "Queen Margaret University certificate frame - Modern Wine",
      "Queen Margaret University certificate frame - Modern Eucalypt",
      "Queen Margaret University certificate frame - Modern Light Cream",
    ],
    "name" : "Queen Margaret University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Queen Margaret University Certificate Frame | From £28"
  },
  "/certificate-frame/queen-mary": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Queen Mary, University of London certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Queen Mary, University of London testamur perfectly",
    "altTags" : [
      "Queen Mary, University of London certificate frame - Pine Wood Manor Black",
      "Queen Mary, University of London certificate frame - Pine Wood Marine Blue",
      "Queen Mary, University of London certificate frame - Pine Wood Wine",
      "Queen Mary, University of London certificate frame - Pine Wood Eucalypt",
      "Queen Mary, University of London certificate frame - Pine Wood Light Cream",
      "Queen Mary, University of London certificate frame - Modern Black",
      "Queen Mary, University of London certificate frame - Modern Marine Blue",
      "Queen Mary, University of London certificate frame - Modern Wine",
      "Queen Mary, University of London certificate frame - Modern Eucalypt",
      "Queen Mary, University of London certificate frame - Modern Light Cream",
    ],
    "name" : "Queen Mary, University of London Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Queen Mary, University of London Certificate Frame | From £28"
  },
  "/certificate-frame/queens-university-belfast": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Queen's University Belfast certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Queen's University Belfast testamur perfectly",
    "altTags" : [
      "Queen's University Belfast certificate frame - Pine Wood Manor Black",
      "Queen's University Belfast certificate frame - Pine Wood Marine Blue",
      "Queen's University Belfast certificate frame - Pine Wood Wine",
      "Queen's University Belfast certificate frame - Pine Wood Eucalypt",
      "Queen's University Belfast certificate frame - Pine Wood Light Cream",
      "Queen's University Belfast certificate frame - Modern Black",
      "Queen's University Belfast certificate frame - Modern Marine Blue",
      "Queen's University Belfast certificate frame - Modern Wine",
      "Queen's University Belfast certificate frame - Modern Eucalypt",
      "Queen's University Belfast certificate frame - Modern Light Cream",
    ],
    "name" : "Queen's University Belfast Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Queen's University Belfast Certificate Frame | From £28"
  },
  "/certificate-frame/ravensbourne-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Ravensbourne University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Ravensbourne University testamur perfectly",
    "altTags" : [
      "Ravensbourne University certificate frame - Pine Wood Manor Black",
      "Ravensbourne University certificate frame - Pine Wood Marine Blue",
      "Ravensbourne University certificate frame - Pine Wood Wine",
      "Ravensbourne University certificate frame - Pine Wood Eucalypt",
      "Ravensbourne University certificate frame - Pine Wood Light Cream",
      "Ravensbourne University certificate frame - Modern Black",
      "Ravensbourne University certificate frame - Modern Marine Blue",
      "Ravensbourne University certificate frame - Modern Wine",
      "Ravensbourne University certificate frame - Modern Eucalypt",
      "Ravensbourne University certificate frame - Modern Light Cream",
    ],
    "name" : "Ravensbourne University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Ravensbourne University Certificate Frame | From £28"
  },
  "/certificate-frame/reading-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Reading University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Reading University testamur perfectly",
    "altTags" : [
      "Reading University certificate frame - Pine Wood Manor Black",
      "Reading University certificate frame - Pine Wood Marine Blue",
      "Reading University certificate frame - Pine Wood Wine",
      "Reading University certificate frame - Pine Wood Eucalypt",
      "Reading University certificate frame - Pine Wood Light Cream",
      "Reading University certificate frame - Modern Black",
      "Reading University certificate frame - Modern Marine Blue",
      "Reading University certificate frame - Modern Wine",
      "Reading University certificate frame - Modern Eucalypt",
      "Reading University certificate frame - Modern Light Cream",
    ],
    "name" : "Reading University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Reading University Certificate Frame | From £28"
  },
  "/certificate-frame/regents-university-london": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Regents University London certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Regents University London testamur perfectly",
    "altTags" : [
      "Regents University London certificate frame - Pine Wood Manor Black",
      "Regents University London certificate frame - Pine Wood Marine Blue",
      "Regents University London certificate frame - Pine Wood Wine",
      "Regents University London certificate frame - Pine Wood Eucalypt",
      "Regents University London certificate frame - Pine Wood Light Cream",
      "Regents University London certificate frame - Modern Black",
      "Regents University London certificate frame - Modern Marine Blue",
      "Regents University London certificate frame - Modern Wine",
      "Regents University London certificate frame - Modern Eucalypt",
      "Regents University London certificate frame - Modern Light Cream",
    ],
    "name" : "Regents University London Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Regents University London Certificate Frame | From £28"
  },
  "/certificate-frame/robert-gordon-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Robert Gordon University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Robert Gordon University testamur perfectly",
    "altTags" : [
      "Robert Gordon University certificate frame - Pine Wood Manor Black",
      "Robert Gordon University certificate frame - Pine Wood Marine Blue",
      "Robert Gordon University certificate frame - Pine Wood Wine",
      "Robert Gordon University certificate frame - Pine Wood Eucalypt",
      "Robert Gordon University certificate frame - Pine Wood Light Cream",
      "Robert Gordon University certificate frame - Modern Black",
      "Robert Gordon University certificate frame - Modern Marine Blue",
      "Robert Gordon University certificate frame - Modern Wine",
      "Robert Gordon University certificate frame - Modern Eucalypt",
      "Robert Gordon University certificate frame - Modern Light Cream",
    ],
    "name" : "Robert Gordon University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Robert Gordon University Certificate Frame | From £28"
  },
  "/certificate-frame/roehampton-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Roehampton University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Roehampton University testamur perfectly",
    "altTags" : [
      "Roehampton University certificate frame - Pine Wood Manor Black",
      "Roehampton University certificate frame - Pine Wood Marine Blue",
      "Roehampton University certificate frame - Pine Wood Wine",
      "Roehampton University certificate frame - Pine Wood Eucalypt",
      "Roehampton University certificate frame - Pine Wood Light Cream",
      "Roehampton University certificate frame - Modern Black",
      "Roehampton University certificate frame - Modern Marine Blue",
      "Roehampton University certificate frame - Modern Wine",
      "Roehampton University certificate frame - Modern Eucalypt",
      "Roehampton University certificate frame - Modern Light Cream",
    ],
    "name" : "Roehampton University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Roehampton University Certificate Frame | From £28"
  },
  "/certificate-frame/royal-academy-of-music": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Royal Academy of Music certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Royal Academy of Music testamur perfectly",
    "altTags" : [
      "Royal Academy of Music certificate frame - Pine Wood Manor Black",
      "Royal Academy of Music certificate frame - Pine Wood Marine Blue",
      "Royal Academy of Music certificate frame - Pine Wood Wine",
      "Royal Academy of Music certificate frame - Pine Wood Eucalypt",
      "Royal Academy of Music certificate frame - Pine Wood Light Cream",
      "Royal Academy of Music certificate frame - Modern Black",
      "Royal Academy of Music certificate frame - Modern Marine Blue",
      "Royal Academy of Music certificate frame - Modern Wine",
      "Royal Academy of Music certificate frame - Modern Eucalypt",
      "Royal Academy of Music certificate frame - Modern Light Cream",
    ],
    "name" : "Royal Academy of Music Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Royal Academy of Music Certificate Frame | From £28"
  },
  "/certificate-frame/royal-agricultural-college": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Royal Agricultural College certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Royal Agricultural College testamur perfectly",
    "altTags" : [
      "Royal Agricultural College certificate frame - Pine Wood Manor Black",
      "Royal Agricultural College certificate frame - Pine Wood Marine Blue",
      "Royal Agricultural College certificate frame - Pine Wood Wine",
      "Royal Agricultural College certificate frame - Pine Wood Eucalypt",
      "Royal Agricultural College certificate frame - Pine Wood Light Cream",
      "Royal Agricultural College certificate frame - Modern Black",
      "Royal Agricultural College certificate frame - Modern Marine Blue",
      "Royal Agricultural College certificate frame - Modern Wine",
      "Royal Agricultural College certificate frame - Modern Eucalypt",
      "Royal Agricultural College certificate frame - Modern Light Cream",
    ],
    "name" : "Royal Agricultural College Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Royal Agricultural College Certificate Frame | From £28"
  },
  "/certificate-frame/royal-college-of-art": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Royal College of Art certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Royal College of Art testamur perfectly",
    "altTags" : [
      "Royal College of Art certificate frame - Pine Wood Manor Black",
      "Royal College of Art certificate frame - Pine Wood Marine Blue",
      "Royal College of Art certificate frame - Pine Wood Wine",
      "Royal College of Art certificate frame - Pine Wood Eucalypt",
      "Royal College of Art certificate frame - Pine Wood Light Cream",
      "Royal College of Art certificate frame - Modern Black",
      "Royal College of Art certificate frame - Modern Marine Blue",
      "Royal College of Art certificate frame - Modern Wine",
      "Royal College of Art certificate frame - Modern Eucalypt",
      "Royal College of Art certificate frame - Modern Light Cream",
    ],
    "name" : "Royal College of Art Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Royal College of Art Certificate Frame | From £28"
  },
  "/certificate-frame/royal-college-of-music": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Royal College of Music certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Royal College of Music testamur perfectly",
    "altTags" : [
      "Royal College of Music certificate frame - Pine Wood Manor Black",
      "Royal College of Music certificate frame - Pine Wood Marine Blue",
      "Royal College of Music certificate frame - Pine Wood Wine",
      "Royal College of Music certificate frame - Pine Wood Eucalypt",
      "Royal College of Music certificate frame - Pine Wood Light Cream",
      "Royal College of Music certificate frame - Modern Black",
      "Royal College of Music certificate frame - Modern Marine Blue",
      "Royal College of Music certificate frame - Modern Wine",
      "Royal College of Music certificate frame - Modern Eucalypt",
      "Royal College of Music certificate frame - Modern Light Cream",
    ],
    "name" : "Royal College of Music Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Royal College of Music Certificate Frame | From £28"
  },
  "/certificate-frame/royal-college-of-nursing": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Royal College of Nursing certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Royal College of Nursing testamur perfectly",
    "altTags" : [
      "Royal College of Nursing certificate frame - Pine Wood Manor Black",
      "Royal College of Nursing certificate frame - Pine Wood Marine Blue",
      "Royal College of Nursing certificate frame - Pine Wood Wine",
      "Royal College of Nursing certificate frame - Pine Wood Eucalypt",
      "Royal College of Nursing certificate frame - Pine Wood Light Cream",
      "Royal College of Nursing certificate frame - Modern Black",
      "Royal College of Nursing certificate frame - Modern Marine Blue",
      "Royal College of Nursing certificate frame - Modern Wine",
      "Royal College of Nursing certificate frame - Modern Eucalypt",
      "Royal College of Nursing certificate frame - Modern Light Cream",
    ],
    "name" : "Royal College of Nursing Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Royal College of Nursing Certificate Frame | From £28"
  },
  "/certificate-frame/royal-conservatoire-of-scotland": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Royal Conservatoire of Scotland certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Royal Conservatoire of Scotland testamur perfectly",
    "altTags" : [
      "Royal Conservatoire of Scotland certificate frame - Pine Wood Manor Black",
      "Royal Conservatoire of Scotland certificate frame - Pine Wood Marine Blue",
      "Royal Conservatoire of Scotland certificate frame - Pine Wood Wine",
      "Royal Conservatoire of Scotland certificate frame - Pine Wood Eucalypt",
      "Royal Conservatoire of Scotland certificate frame - Pine Wood Light Cream",
      "Royal Conservatoire of Scotland certificate frame - Modern Black",
      "Royal Conservatoire of Scotland certificate frame - Modern Marine Blue",
      "Royal Conservatoire of Scotland certificate frame - Modern Wine",
      "Royal Conservatoire of Scotland certificate frame - Modern Eucalypt",
      "Royal Conservatoire of Scotland certificate frame - Modern Light Cream",
    ],
    "name" : "Royal Conservatoire of Scotland Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Royal Conservatoire of Scotland Certificate Frame | From £28"
  },
  "/certificate-frame/royal-holloway": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Royal Holloway certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Royal Holloway testamur perfectly",
    "altTags" : [
      "Royal Holloway certificate frame - Pine Wood Manor Black",
      "Royal Holloway certificate frame - Pine Wood Marine Blue",
      "Royal Holloway certificate frame - Pine Wood Wine",
      "Royal Holloway certificate frame - Pine Wood Eucalypt",
      "Royal Holloway certificate frame - Pine Wood Light Cream",
      "Royal Holloway certificate frame - Modern Black",
      "Royal Holloway certificate frame - Modern Marine Blue",
      "Royal Holloway certificate frame - Modern Wine",
      "Royal Holloway certificate frame - Modern Eucalypt",
      "Royal Holloway certificate frame - Modern Light Cream",
    ],
    "name" : "Royal Holloway Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Royal Holloway Certificate Frame | From £28"
  },
  "/certificate-frame/royal-northern-college-of-music": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Royal Northern College of Music certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Royal Northern College of Music testamur perfectly",
    "altTags" : [
      "Royal Northern College of Music certificate frame - Pine Wood Manor Black",
      "Royal Northern College of Music certificate frame - Pine Wood Marine Blue",
      "Royal Northern College of Music certificate frame - Pine Wood Wine",
      "Royal Northern College of Music certificate frame - Pine Wood Eucalypt",
      "Royal Northern College of Music certificate frame - Pine Wood Light Cream",
      "Royal Northern College of Music certificate frame - Modern Black",
      "Royal Northern College of Music certificate frame - Modern Marine Blue",
      "Royal Northern College of Music certificate frame - Modern Wine",
      "Royal Northern College of Music certificate frame - Modern Eucalypt",
      "Royal Northern College of Music certificate frame - Modern Light Cream",
    ],
    "name" : "Royal Northern College of Music Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Royal Northern College of Music Certificate Frame | From £28"
  },
  "/certificate-frame/royal-veterinary-college": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Royal Veterinary College certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Royal Veterinary College testamur perfectly",
    "altTags" : [
      "Royal Veterinary College certificate frame - Pine Wood Manor Black",
      "Royal Veterinary College certificate frame - Pine Wood Marine Blue",
      "Royal Veterinary College certificate frame - Pine Wood Wine",
      "Royal Veterinary College certificate frame - Pine Wood Eucalypt",
      "Royal Veterinary College certificate frame - Pine Wood Light Cream",
      "Royal Veterinary College certificate frame - Modern Black",
      "Royal Veterinary College certificate frame - Modern Marine Blue",
      "Royal Veterinary College certificate frame - Modern Wine",
      "Royal Veterinary College certificate frame - Modern Eucalypt",
      "Royal Veterinary College certificate frame - Modern Light Cream",
    ],
    "name" : "Royal Veterinary College Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Royal Veterinary College Certificate Frame | From £28"
  },
  "/certificate-frame/sheffield-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Sheffield University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Sheffield University testamur perfectly",
    "altTags" : [
      "Sheffield University certificate frame - Pine Wood Manor Black",
      "Sheffield University certificate frame - Pine Wood Marine Blue",
      "Sheffield University certificate frame - Pine Wood Wine",
      "Sheffield University certificate frame - Pine Wood Eucalypt",
      "Sheffield University certificate frame - Pine Wood Light Cream",
      "Sheffield University certificate frame - Modern Black",
      "Sheffield University certificate frame - Modern Marine Blue",
      "Sheffield University certificate frame - Modern Wine",
      "Sheffield University certificate frame - Modern Eucalypt",
      "Sheffield University certificate frame - Modern Light Cream",
    ],
    "name" : "Sheffield University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Sheffield University Certificate Frame | From £28"
  },
  "/certificate-frame/southampton-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Southampton University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Southampton University testamur perfectly",
    "altTags" : [
      "Southampton University certificate frame - Pine Wood Manor Black",
      "Southampton University certificate frame - Pine Wood Marine Blue",
      "Southampton University certificate frame - Pine Wood Wine",
      "Southampton University certificate frame - Pine Wood Eucalypt",
      "Southampton University certificate frame - Pine Wood Light Cream",
      "Southampton University certificate frame - Modern Black",
      "Southampton University certificate frame - Modern Marine Blue",
      "Southampton University certificate frame - Modern Wine",
      "Southampton University certificate frame - Modern Eucalypt",
      "Southampton University certificate frame - Modern Light Cream",
    ],
    "name" : "Southampton University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Southampton University Certificate Frame | From £28"
  },
  "/certificate-frame/south-wales-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your South Wales University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your South Wales University testamur perfectly",
    "altTags" : [
      "South Wales University certificate frame - Pine Wood Manor Black",
      "South Wales University certificate frame - Pine Wood Marine Blue",
      "South Wales University certificate frame - Pine Wood Wine",
      "South Wales University certificate frame - Pine Wood Eucalypt",
      "South Wales University certificate frame - Pine Wood Light Cream",
      "South Wales University certificate frame - Modern Black",
      "South Wales University certificate frame - Modern Marine Blue",
      "South Wales University certificate frame - Modern Wine",
      "South Wales University certificate frame - Modern Eucalypt",
      "South Wales University certificate frame - Modern Light Cream",
    ],
    "name" : "South Wales University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "South Wales University Certificate Frame | From £28"
  },
  "/certificate-frame/staffordshire-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Staffordshire University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Staffordshire University testamur perfectly",
    "altTags" : [
      "Staffordshire University certificate frame - Pine Wood Manor Black",
      "Staffordshire University certificate frame - Pine Wood Marine Blue",
      "Staffordshire University certificate frame - Pine Wood Wine",
      "Staffordshire University certificate frame - Pine Wood Eucalypt",
      "Staffordshire University certificate frame - Pine Wood Light Cream",
      "Staffordshire University certificate frame - Modern Black",
      "Staffordshire University certificate frame - Modern Marine Blue",
      "Staffordshire University certificate frame - Modern Wine",
      "Staffordshire University certificate frame - Modern Eucalypt",
      "Staffordshire University certificate frame - Modern Light Cream",
    ],
    "name" : "Staffordshire University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Staffordshire University Certificate Frame | From £28"
  },
  "/certificate-frame/st-andrews-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your St Andrews University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your St Andrews University testamur perfectly",
    "altTags" : [
      "St Andrews University certificate frame - Pine Wood Manor Black",
      "St Andrews University certificate frame - Pine Wood Marine Blue",
      "St Andrews University certificate frame - Pine Wood Wine",
      "St Andrews University certificate frame - Pine Wood Eucalypt",
      "St Andrews University certificate frame - Pine Wood Light Cream",
      "St Andrews University certificate frame - Modern Black",
      "St Andrews University certificate frame - Modern Marine Blue",
      "St Andrews University certificate frame - Modern Wine",
      "St Andrews University certificate frame - Modern Eucalypt",
      "St Andrews University certificate frame - Modern Light Cream",
    ],
    "name" : "St Andrews University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "St Andrews University Certificate Frame | From £28"
  },
  "/certificate-frame/st-georges-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your St Georges University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your St Georges University testamur perfectly",
    "altTags" : [
      "St Georges University certificate frame - Pine Wood Manor Black",
      "St Georges University certificate frame - Pine Wood Marine Blue",
      "St Georges University certificate frame - Pine Wood Wine",
      "St Georges University certificate frame - Pine Wood Eucalypt",
      "St Georges University certificate frame - Pine Wood Light Cream",
      "St Georges University certificate frame - Modern Black",
      "St Georges University certificate frame - Modern Marine Blue",
      "St Georges University certificate frame - Modern Wine",
      "St Georges University certificate frame - Modern Eucalypt",
      "St Georges University certificate frame - Modern Light Cream",
    ],
    "name" : "St Georges University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "St Georges University Certificate Frame | From £28"
  },
  "/certificate-frame/st-marys-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your St Marys University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your St Marys University testamur perfectly",
    "altTags" : [
      "St Marys University certificate frame - Pine Wood Manor Black",
      "St Marys University certificate frame - Pine Wood Marine Blue",
      "St Marys University certificate frame - Pine Wood Wine",
      "St Marys University certificate frame - Pine Wood Eucalypt",
      "St Marys University certificate frame - Pine Wood Light Cream",
      "St Marys University certificate frame - Modern Black",
      "St Marys University certificate frame - Modern Marine Blue",
      "St Marys University certificate frame - Modern Wine",
      "St Marys University certificate frame - Modern Eucalypt",
      "St Marys University certificate frame - Modern Light Cream",
    ],
    "name" : "St Marys University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "St Marys University Certificate Frame | From £28"
  },
  "/certificate-frame/stirling-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Stirling University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Stirling University testamur perfectly",
    "altTags" : [
      "Stirling University certificate frame - Pine Wood Manor Black",
      "Stirling University certificate frame - Pine Wood Marine Blue",
      "Stirling University certificate frame - Pine Wood Wine",
      "Stirling University certificate frame - Pine Wood Eucalypt",
      "Stirling University certificate frame - Pine Wood Light Cream",
      "Stirling University certificate frame - Modern Black",
      "Stirling University certificate frame - Modern Marine Blue",
      "Stirling University certificate frame - Modern Wine",
      "Stirling University certificate frame - Modern Eucalypt",
      "Stirling University certificate frame - Modern Light Cream",
    ],
    "name" : "Stirling University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Stirling University Certificate Frame | From £28"
  },
  "/certificate-frame/strathclyde-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Strathclyde University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Strathclyde University testamur perfectly",
    "altTags" : [
      "Strathclyde University certificate frame - Pine Wood Manor Black",
      "Strathclyde University certificate frame - Pine Wood Marine Blue",
      "Strathclyde University certificate frame - Pine Wood Wine",
      "Strathclyde University certificate frame - Pine Wood Eucalypt",
      "Strathclyde University certificate frame - Pine Wood Light Cream",
      "Strathclyde University certificate frame - Modern Black",
      "Strathclyde University certificate frame - Modern Marine Blue",
      "Strathclyde University certificate frame - Modern Wine",
      "Strathclyde University certificate frame - Modern Eucalypt",
      "Strathclyde University certificate frame - Modern Light Cream",
    ],
    "name" : "Strathclyde University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Strathclyde University Certificate Frame | From £28"
  },
  "/certificate-frame/sunderland-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Sunderland University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Sunderland University testamur perfectly",
    "altTags" : [
      "Sunderland University certificate frame - Pine Wood Manor Black",
      "Sunderland University certificate frame - Pine Wood Marine Blue",
      "Sunderland University certificate frame - Pine Wood Wine",
      "Sunderland University certificate frame - Pine Wood Eucalypt",
      "Sunderland University certificate frame - Pine Wood Light Cream",
      "Sunderland University certificate frame - Modern Black",
      "Sunderland University certificate frame - Modern Marine Blue",
      "Sunderland University certificate frame - Modern Wine",
      "Sunderland University certificate frame - Modern Eucalypt",
      "Sunderland University certificate frame - Modern Light Cream",
    ],
    "name" : "Sunderland University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Sunderland University Certificate Frame | From £28"
  },
  "/certificate-frame/surrey-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Surrey University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Surrey University testamur perfectly",
    "altTags" : [
      "Surrey University certificate frame - Pine Wood Manor Black",
      "Surrey University certificate frame - Pine Wood Marine Blue",
      "Surrey University certificate frame - Pine Wood Wine",
      "Surrey University certificate frame - Pine Wood Eucalypt",
      "Surrey University certificate frame - Pine Wood Light Cream",
      "Surrey University certificate frame - Modern Black",
      "Surrey University certificate frame - Modern Marine Blue",
      "Surrey University certificate frame - Modern Wine",
      "Surrey University certificate frame - Modern Eucalypt",
      "Surrey University certificate frame - Modern Light Cream",
    ],
    "name" : "Surrey University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Surrey University Certificate Frame | From £28"
  },
  "/certificate-frame/sussex-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Sussex University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Sussex University testamur perfectly",
    "altTags" : [
      "Sussex University certificate frame - Pine Wood Manor Black",
      "Sussex University certificate frame - Pine Wood Marine Blue",
      "Sussex University certificate frame - Pine Wood Wine",
      "Sussex University certificate frame - Pine Wood Eucalypt",
      "Sussex University certificate frame - Pine Wood Light Cream",
      "Sussex University certificate frame - Modern Black",
      "Sussex University certificate frame - Modern Marine Blue",
      "Sussex University certificate frame - Modern Wine",
      "Sussex University certificate frame - Modern Eucalypt",
      "Sussex University certificate frame - Modern Light Cream",
    ],
    "name" : "Sussex University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Sussex University Certificate Frame | From £28"
  },
  "/certificate-frame/swansea-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Swansea University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Swansea University testamur perfectly",
    "altTags" : [
      "Swansea University certificate frame - Pine Wood Manor Black",
      "Swansea University certificate frame - Pine Wood Marine Blue",
      "Swansea University certificate frame - Pine Wood Wine",
      "Swansea University certificate frame - Pine Wood Eucalypt",
      "Swansea University certificate frame - Pine Wood Light Cream",
      "Swansea University certificate frame - Modern Black",
      "Swansea University certificate frame - Modern Marine Blue",
      "Swansea University certificate frame - Modern Wine",
      "Swansea University certificate frame - Modern Eucalypt",
      "Swansea University certificate frame - Modern Light Cream",
    ],
    "name" : "Swansea University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Swansea University Certificate Frame | From £28"
  },
  "/certificate-frame/trinity-laban-conservatoire": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Trinity Laban Conservatoire certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Trinity Laban Conservatoire testamur perfectly",
    "altTags" : [
      "Trinity Laban Conservatoire certificate frame - Pine Wood Manor Black",
      "Trinity Laban Conservatoire certificate frame - Pine Wood Marine Blue",
      "Trinity Laban Conservatoire certificate frame - Pine Wood Wine",
      "Trinity Laban Conservatoire certificate frame - Pine Wood Eucalypt",
      "Trinity Laban Conservatoire certificate frame - Pine Wood Light Cream",
      "Trinity Laban Conservatoire certificate frame - Modern Black",
      "Trinity Laban Conservatoire certificate frame - Modern Marine Blue",
      "Trinity Laban Conservatoire certificate frame - Modern Wine",
      "Trinity Laban Conservatoire certificate frame - Modern Eucalypt",
      "Trinity Laban Conservatoire certificate frame - Modern Light Cream",
    ],
    "name" : "Trinity Laban Conservatoire Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Trinity Laban Conservatoire Certificate Frame | From £28"
  },
  "/certificate-frame/ulster-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Ulster University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Ulster University testamur perfectly",
    "altTags" : [
      "Ulster University certificate frame - Pine Wood Manor Black",
      "Ulster University certificate frame - Pine Wood Marine Blue",
      "Ulster University certificate frame - Pine Wood Wine",
      "Ulster University certificate frame - Pine Wood Eucalypt",
      "Ulster University certificate frame - Pine Wood Light Cream",
      "Ulster University certificate frame - Modern Black",
      "Ulster University certificate frame - Modern Marine Blue",
      "Ulster University certificate frame - Modern Wine",
      "Ulster University certificate frame - Modern Eucalypt",
      "Ulster University certificate frame - Modern Light Cream",
    ],
    "name" : "Ulster University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Ulster University Certificate Frame | From £28"
  },
  "/certificate-frame/university-college-birmingham": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your University College Birmingham certificate frame for only £28 | Fast delivery, excellent quality, made to fit your University College Birmingham testamur perfectly",
    "altTags" : [
      "University College Birmingham certificate frame - Pine Wood Manor Black",
      "University College Birmingham certificate frame - Pine Wood Marine Blue",
      "University College Birmingham certificate frame - Pine Wood Wine",
      "University College Birmingham certificate frame - Pine Wood Eucalypt",
      "University College Birmingham certificate frame - Pine Wood Light Cream",
      "University College Birmingham certificate frame - Modern Black",
      "University College Birmingham certificate frame - Modern Marine Blue",
      "University College Birmingham certificate frame - Modern Wine",
      "University College Birmingham certificate frame - Modern Eucalypt",
      "University College Birmingham certificate frame - Modern Light Cream",
    ],
    "name" : "University College Birmingham Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "University College Birmingham Certificate Frame | From £28"
  },
  "/certificate-frame/university-for-the-creative-arts": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your University for the Creative Arts certificate frame for only £28 | Fast delivery, excellent quality, made to fit your University for the Creative Arts testamur perfectly",
    "altTags" : [
      "University for the Creative Arts certificate frame - Pine Wood Manor Black",
      "University for the Creative Arts certificate frame - Pine Wood Marine Blue",
      "University for the Creative Arts certificate frame - Pine Wood Wine",
      "University for the Creative Arts certificate frame - Pine Wood Eucalypt",
      "University for the Creative Arts certificate frame - Pine Wood Light Cream",
      "University for the Creative Arts certificate frame - Modern Black",
      "University for the Creative Arts certificate frame - Modern Marine Blue",
      "University for the Creative Arts certificate frame - Modern Wine",
      "University for the Creative Arts certificate frame - Modern Eucalypt",
      "University for the Creative Arts certificate frame - Modern Light Cream",
    ],
    "name" : "University for the Creative Arts Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "University for the Creative Arts Certificate Frame | From £28"
  },
  "/certificate-frame/university-of-central-lancashire": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your University of Central Lancashire certificate frame for only £28 | Fast delivery, excellent quality, made to fit your University of Central Lancashire testamur perfectly",
    "altTags" : [
      "University of Central Lancashire certificate frame - Pine Wood Manor Black",
      "University of Central Lancashire certificate frame - Pine Wood Marine Blue",
      "University of Central Lancashire certificate frame - Pine Wood Wine",
      "University of Central Lancashire certificate frame - Pine Wood Eucalypt",
      "University of Central Lancashire certificate frame - Pine Wood Light Cream",
      "University of Central Lancashire certificate frame - Modern Black",
      "University of Central Lancashire certificate frame - Modern Marine Blue",
      "University of Central Lancashire certificate frame - Modern Wine",
      "University of Central Lancashire certificate frame - Modern Eucalypt",
      "University of Central Lancashire certificate frame - Modern Light Cream",
    ],
    "name" : "University of Central Lancashire Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "University of Central Lancashire Certificate Frame | From £28"
  },
  "/certificate-frame/university-of-east-anglia": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your University of East Anglia certificate frame for only £28 | Fast delivery, excellent quality, made to fit your University of East Anglia testamur perfectly",
    "altTags" : [
      "University of East Anglia certificate frame - Pine Wood Manor Black",
      "University of East Anglia certificate frame - Pine Wood Marine Blue",
      "University of East Anglia certificate frame - Pine Wood Wine",
      "University of East Anglia certificate frame - Pine Wood Eucalypt",
      "University of East Anglia certificate frame - Pine Wood Light Cream",
      "University of East Anglia certificate frame - Modern Black",
      "University of East Anglia certificate frame - Modern Marine Blue",
      "University of East Anglia certificate frame - Modern Wine",
      "University of East Anglia certificate frame - Modern Eucalypt",
      "University of East Anglia certificate frame - Modern Light Cream",
    ],
    "name" : "University of East Anglia Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "University of East Anglia Certificate Frame | From £28"
  },
  "/certificate-frame/university-of-estate-management": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your University of Estate Management certificate frame for only £28 | Fast delivery, excellent quality, made to fit your University of Estate Management testamur perfectly",
    "altTags" : [
      "University of Estate Management certificate frame - Pine Wood Manor Black",
      "University of Estate Management certificate frame - Pine Wood Marine Blue",
      "University of Estate Management certificate frame - Pine Wood Wine",
      "University of Estate Management certificate frame - Pine Wood Eucalypt",
      "University of Estate Management certificate frame - Pine Wood Light Cream",
      "University of Estate Management certificate frame - Modern Black",
      "University of Estate Management certificate frame - Modern Marine Blue",
      "University of Estate Management certificate frame - Modern Wine",
      "University of Estate Management certificate frame - Modern Eucalypt",
      "University of Estate Management certificate frame - Modern Light Cream",
    ],
    "name" : "University of Estate Management Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "University of Estate Management Certificate Frame | From £28"
  },
  "/certificate-frame/university-of-law": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your University of Law certificate frame for only £28 | Fast delivery, excellent quality, made to fit your University of Law testamur perfectly",
    "altTags" : [
      "University of Law certificate frame - Pine Wood Manor Black",
      "University of Law certificate frame - Pine Wood Marine Blue",
      "University of Law certificate frame - Pine Wood Wine",
      "University of Law certificate frame - Pine Wood Eucalypt",
      "University of Law certificate frame - Pine Wood Light Cream",
      "University of Law certificate frame - Modern Black",
      "University of Law certificate frame - Modern Marine Blue",
      "University of Law certificate frame - Modern Wine",
      "University of Law certificate frame - Modern Eucalypt",
      "University of Law certificate frame - Modern Light Cream",
    ],
    "name" : "University of Law Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "University of Law Certificate Frame | From £28"
  },
  "/certificate-frame/university-of-the-west-of-scotland": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your University of the West of Scotland certificate frame for only £28 | Fast delivery, excellent quality, made to fit your University of the West of Scotland testamur perfectly",
    "altTags" : [
      "University of the West of Scotland certificate frame - Pine Wood Manor Black",
      "University of the West of Scotland certificate frame - Pine Wood Marine Blue",
      "University of the West of Scotland certificate frame - Pine Wood Wine",
      "University of the West of Scotland certificate frame - Pine Wood Eucalypt",
      "University of the West of Scotland certificate frame - Pine Wood Light Cream",
      "University of the West of Scotland certificate frame - Modern Black",
      "University of the West of Scotland certificate frame - Modern Marine Blue",
      "University of the West of Scotland certificate frame - Modern Wine",
      "University of the West of Scotland certificate frame - Modern Eucalypt",
      "University of the West of Scotland certificate frame - Modern Light Cream",
    ],
    "name" : "University of the West of Scotland Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "University of the West of Scotland Certificate Frame | From £28"
  },
  "/certificate-frame/university-of-wales": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your University of Wales certificate frame for only £28 | Fast delivery, excellent quality, made to fit your University of Wales testamur perfectly",
    "altTags" : [
      "University of Wales certificate frame - Pine Wood Manor Black",
      "University of Wales certificate frame - Pine Wood Marine Blue",
      "University of Wales certificate frame - Pine Wood Wine",
      "University of Wales certificate frame - Pine Wood Eucalypt",
      "University of Wales certificate frame - Pine Wood Light Cream",
      "University of Wales certificate frame - Modern Black",
      "University of Wales certificate frame - Modern Marine Blue",
      "University of Wales certificate frame - Modern Wine",
      "University of Wales certificate frame - Modern Eucalypt",
      "University of Wales certificate frame - Modern Light Cream",
    ],
    "name" : "University of Wales Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "University of Wales Certificate Frame | From £28"
  },
  "/certificate-frame/university-of-wales-trinity-st-david": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your University of Wales Trinity St David certificate frame for only £28 | Fast delivery, excellent quality, made to fit your University of Wales Trinity St David testamur perfectly",
    "altTags" : [
      "University of Wales Trinity St David certificate frame - Pine Wood Manor Black",
      "University of Wales Trinity St David certificate frame - Pine Wood Marine Blue",
      "University of Wales Trinity St David certificate frame - Pine Wood Wine",
      "University of Wales Trinity St David certificate frame - Pine Wood Eucalypt",
      "University of Wales Trinity St David certificate frame - Pine Wood Light Cream",
      "University of Wales Trinity St David certificate frame - Modern Black",
      "University of Wales Trinity St David certificate frame - Modern Marine Blue",
      "University of Wales Trinity St David certificate frame - Modern Wine",
      "University of Wales Trinity St David certificate frame - Modern Eucalypt",
      "University of Wales Trinity St David certificate frame - Modern Light Cream",
    ],
    "name" : "University of Wales Trinity St David Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "University of Wales Trinity St David Certificate Frame | From £28"
  },
  "/certificate-frame/warwick-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Warwick University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Warwick University testamur perfectly",
    "altTags" : [
      "Warwick University certificate frame - Pine Wood Manor Black",
      "Warwick University certificate frame - Pine Wood Marine Blue",
      "Warwick University certificate frame - Pine Wood Wine",
      "Warwick University certificate frame - Pine Wood Eucalypt",
      "Warwick University certificate frame - Pine Wood Light Cream",
      "Warwick University certificate frame - Modern Black",
      "Warwick University certificate frame - Modern Marine Blue",
      "Warwick University certificate frame - Modern Wine",
      "Warwick University certificate frame - Modern Eucalypt",
      "Warwick University certificate frame - Modern Light Cream",
    ],
    "name" : "Warwick University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Warwick University Certificate Frame | From £28"
  },
  "/certificate-frame/warwickshire-college": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Warwickshire College certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Warwickshire College testamur perfectly",
    "altTags" : [
      "Warwickshire College certificate frame - Pine Wood Manor Black",
      "Warwickshire College certificate frame - Pine Wood Marine Blue",
      "Warwickshire College certificate frame - Pine Wood Wine",
      "Warwickshire College certificate frame - Pine Wood Eucalypt",
      "Warwickshire College certificate frame - Pine Wood Light Cream",
      "Warwickshire College certificate frame - Modern Black",
      "Warwickshire College certificate frame - Modern Marine Blue",
      "Warwickshire College certificate frame - Modern Wine",
      "Warwickshire College certificate frame - Modern Eucalypt",
      "Warwickshire College certificate frame - Modern Light Cream",
    ],
    "name" : "Warwickshire College Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Warwickshire College Certificate Frame | From £28"
  },
  "/certificate-frame/westminster-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Westminster University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Westminster University testamur perfectly",
    "altTags" : [
      "Westminster University certificate frame - Pine Wood Manor Black",
      "Westminster University certificate frame - Pine Wood Marine Blue",
      "Westminster University certificate frame - Pine Wood Wine",
      "Westminster University certificate frame - Pine Wood Eucalypt",
      "Westminster University certificate frame - Pine Wood Light Cream",
      "Westminster University certificate frame - Modern Black",
      "Westminster University certificate frame - Modern Marine Blue",
      "Westminster University certificate frame - Modern Wine",
      "Westminster University certificate frame - Modern Eucalypt",
      "Westminster University certificate frame - Modern Light Cream",
    ],
    "name" : "Westminster University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Westminster University Certificate Frame | From £28"
  },
  "/certificate-frame/west-london-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your West London University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your West London University testamur perfectly",
    "altTags" : [
      "West London University certificate frame - Pine Wood Manor Black",
      "West London University certificate frame - Pine Wood Marine Blue",
      "West London University certificate frame - Pine Wood Wine",
      "West London University certificate frame - Pine Wood Eucalypt",
      "West London University certificate frame - Pine Wood Light Cream",
      "West London University certificate frame - Modern Black",
      "West London University certificate frame - Modern Marine Blue",
      "West London University certificate frame - Modern Wine",
      "West London University certificate frame - Modern Eucalypt",
      "West London University certificate frame - Modern Light Cream",
    ],
    "name" : "West London University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "West London University Certificate Frame | From £28"
  },
  "/certificate-frame/west-of-england-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your West of England University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your West of England University testamur perfectly",
    "altTags" : [
      "West of England University certificate frame - Pine Wood Manor Black",
      "West of England University certificate frame - Pine Wood Marine Blue",
      "West of England University certificate frame - Pine Wood Wine",
      "West of England University certificate frame - Pine Wood Eucalypt",
      "West of England University certificate frame - Pine Wood Light Cream",
      "West of England University certificate frame - Modern Black",
      "West of England University certificate frame - Modern Marine Blue",
      "West of England University certificate frame - Modern Wine",
      "West of England University certificate frame - Modern Eucalypt",
      "West of England University certificate frame - Modern Light Cream",
    ],
    "name" : "West of England University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "West of England University Certificate Frame | From £28"
  },
  "/certificate-frame/wimbeldon-college-of-arts": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Wimbeldon College of Arts certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Wimbeldon College of Arts testamur perfectly",
    "altTags" : [
      "Wimbeldon College of Arts certificate frame - Pine Wood Manor Black",
      "Wimbeldon College of Arts certificate frame - Pine Wood Marine Blue",
      "Wimbeldon College of Arts certificate frame - Pine Wood Wine",
      "Wimbeldon College of Arts certificate frame - Pine Wood Eucalypt",
      "Wimbeldon College of Arts certificate frame - Pine Wood Light Cream",
      "Wimbeldon College of Arts certificate frame - Modern Black",
      "Wimbeldon College of Arts certificate frame - Modern Marine Blue",
      "Wimbeldon College of Arts certificate frame - Modern Wine",
      "Wimbeldon College of Arts certificate frame - Modern Eucalypt",
      "Wimbeldon College of Arts certificate frame - Modern Light Cream",
    ],
    "name" : "Wimbeldon College of Arts Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Wimbeldon College of Arts Certificate Frame | From £28"
  },
  "/certificate-frame/winchester-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Winchester University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Winchester University testamur perfectly",
    "altTags" : [
      "Winchester University certificate frame - Pine Wood Manor Black",
      "Winchester University certificate frame - Pine Wood Marine Blue",
      "Winchester University certificate frame - Pine Wood Wine",
      "Winchester University certificate frame - Pine Wood Eucalypt",
      "Winchester University certificate frame - Pine Wood Light Cream",
      "Winchester University certificate frame - Modern Black",
      "Winchester University certificate frame - Modern Marine Blue",
      "Winchester University certificate frame - Modern Wine",
      "Winchester University certificate frame - Modern Eucalypt",
      "Winchester University certificate frame - Modern Light Cream",
    ],
    "name" : "Winchester University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Winchester University Certificate Frame | From £28"
  },
  "/certificate-frame/wolverhampton-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Wolverhampton University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Wolverhampton University testamur perfectly",
    "altTags" : [
      "Wolverhampton University certificate frame - Pine Wood Manor Black",
      "Wolverhampton University certificate frame - Pine Wood Marine Blue",
      "Wolverhampton University certificate frame - Pine Wood Wine",
      "Wolverhampton University certificate frame - Pine Wood Eucalypt",
      "Wolverhampton University certificate frame - Pine Wood Light Cream",
      "Wolverhampton University certificate frame - Modern Black",
      "Wolverhampton University certificate frame - Modern Marine Blue",
      "Wolverhampton University certificate frame - Modern Wine",
      "Wolverhampton University certificate frame - Modern Eucalypt",
      "Wolverhampton University certificate frame - Modern Light Cream",
    ],
    "name" : "Wolverhampton University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Wolverhampton University Certificate Frame | From £28"
  },
  "/certificate-frame/worcester-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Worcester University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Worcester University testamur perfectly",
    "altTags" : [
      "Worcester University certificate frame - Pine Wood Manor Black",
      "Worcester University certificate frame - Pine Wood Marine Blue",
      "Worcester University certificate frame - Pine Wood Wine",
      "Worcester University certificate frame - Pine Wood Eucalypt",
      "Worcester University certificate frame - Pine Wood Light Cream",
      "Worcester University certificate frame - Modern Black",
      "Worcester University certificate frame - Modern Marine Blue",
      "Worcester University certificate frame - Modern Wine",
      "Worcester University certificate frame - Modern Eucalypt",
      "Worcester University certificate frame - Modern Light Cream",
    ],
    "name" : "Worcester University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Worcester University Certificate Frame | From £28"
  },
  "/certificate-frame/writtle-college": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Writtle College certificate frame for only £28 | Fast delivery, excellent quality, made to fit your Writtle College testamur perfectly",
    "altTags" : [
      "Writtle College certificate frame - Pine Wood Manor Black",
      "Writtle College certificate frame - Pine Wood Marine Blue",
      "Writtle College certificate frame - Pine Wood Wine",
      "Writtle College certificate frame - Pine Wood Eucalypt",
      "Writtle College certificate frame - Pine Wood Light Cream",
      "Writtle College certificate frame - Modern Black",
      "Writtle College certificate frame - Modern Marine Blue",
      "Writtle College certificate frame - Modern Wine",
      "Writtle College certificate frame - Modern Eucalypt",
      "Writtle College certificate frame - Modern Light Cream",
    ],
    "name" : "Writtle College Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "Writtle College Certificate Frame | From £28"
  },
  "/certificate-frame/york-st-john-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your York St John University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your York St John University testamur perfectly",
    "altTags" : [
      "York St John University certificate frame - Pine Wood Manor Black",
      "York St John University certificate frame - Pine Wood Marine Blue",
      "York St John University certificate frame - Pine Wood Wine",
      "York St John University certificate frame - Pine Wood Eucalypt",
      "York St John University certificate frame - Pine Wood Light Cream",
      "York St John University certificate frame - Modern Black",
      "York St John University certificate frame - Modern Marine Blue",
      "York St John University certificate frame - Modern Wine",
      "York St John University certificate frame - Modern Eucalypt",
      "York St John University certificate frame - Modern Light Cream",
    ],
    "name" : "York St John University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "York St John University Certificate Frame | From £28"
  },
  "/certificate-frame/york-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your York University certificate frame for only £28 | Fast delivery, excellent quality, made to fit your York University testamur perfectly",
    "altTags" : [
      "York University certificate frame - Pine Wood Manor Black",
      "York University certificate frame - Pine Wood Marine Blue",
      "York University certificate frame - Pine Wood Wine",
      "York University certificate frame - Pine Wood Eucalypt",
      "York University certificate frame - Pine Wood Light Cream",
      "York University certificate frame - Modern Black",
      "York University certificate frame - Modern Marine Blue",
      "York University certificate frame - Modern Wine",
      "York University certificate frame - Modern Eucalypt",
      "York University certificate frame - Modern Light Cream",
    ],
    "name" : "York University Certificate Frame",
    "price" : 28,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "productType" : "bachelor-frame",
    "titleTag" : "York University Certificate Frame | From £28"
  }

}

// module.exports = Frames;