import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import HomeContent from './homecontent';
import UniInfo from '../../sources/universities';

const Home = () => {
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const onChange = (event) => {
    setSearchValue(event.target.value.substr(0, 140));

    let results = [];

    for(var i in UniInfo) {
      const lowerCaseTerm = event.target.value.toLowerCase();
      if (UniInfo[i].searchName.toLowerCase().indexOf(lowerCaseTerm) !== -1) {
        results.push({
          name : UniInfo[i].searchName,
          path : i
        });
      }

      if(results.length === 4){
        break;
      }
    }

    if(results.length === 0) {
      results = [
      { name : 'Sorry, no results matched your search.',
        path :  null}
    ]

    }
    if(event.target.value.length < 2) {
      results = [];
    }
    setSearchResults(results)
  }

  return (
    <div className="homepage">
      <Helmet
        title="Buy UK Graduation Gown Sets | Academic Gowns & Dress from Gowning Street"
        meta={[
          {"name": "description", "content": "Forget renting an academic gown or dress. At Gowning Street you can buy your graduation gowns and save money! Bachelor, master or doctoral gowns shipped to your door throughout the UK"},
        ]}
      />
      <div className="home">
        <h1 className="maintitle">Hand-crafted Graduation Regalia</h1>
        <h3 className="subtitle">Why hire when you can buy for less?</h3>
        <div className="buttons">
          <Link to="/about"><button>Learn More</button></Link>
          <Link to="/academic-dress"><button>Shop Now</button></Link>
        </div>
        <div className="main-input">
          <input
            placeholder="Search your Uni"
            type="text"
            value={searchValue}
            onChange={onChange}
          />
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165120/Utility/search_grey_18x18.png"/>
        </div>
        <div className="mainsearchresults">
          {searchResults.length > 0 && searchResults.map((element) => {
              if(element.path !== null) {
                return (
                  <Link to={element.path} key={element.path}>
                    <p key={element.name}>{element.name}</p>
                  </Link>
                  )
              }
              else {
                return (
                  <p key={element.name}>{element.name}</p>
                )
              }
            })
          }
        </div>
      </div>
      <HomeContent />
    </div>
  );
}

export default Home;