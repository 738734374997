import React, { Component } from 'react';
import Loader from './loader';

class customButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClicked : false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    if(this.state.isClicked === false || this.props.xhrLoaded === true){
      this.setState({
        isClicked : true
      });
      this.props.handleSubmit();
    }
  }

  render() {
    var loader = null;

    if(this.state.isClicked === true && this.props.xhrLoaded === false ){
      loader = (<Loader />);
    }

    return (
      <div className="custombutton">
        <button onClick={this.handleSubmit}>{this.props.content || "Submit"}</button>
        {loader}
      </div>
    );
  }
}

export default customButton;

