export const checkInventory = function(items, callback){

// console.log('checkInventory items', items)
  var itemsToCheck = {};

  if (Array.isArray(items)) {
    items.forEach(function (set) {
      var types = [
        'cap',
        'gown',
        'hood',
        'gift',
        'frameStyle',
        'frameColour'
      ];

      types.forEach(function (type) {
        if (set[type]) {
          if (itemsToCheck.hasOwnProperty(set[type])) {
            itemsToCheck[set[type]] += Number(set.qty);
          }
          else {
            itemsToCheck[set[type]] = Number(set.qty);
          }
        }
      });
    });
  } else {
    const { code, qty } = items;
    itemsToCheck[code] = qty;
  };
// console.log('itemsToCheck', itemsToCheck)

  fetch('/api/inv/checkOrder', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(itemsToCheck)})
    .then(response => response.json())
    .then(dbResponse => {
      const fails = [];
// console.log(' DB RESPONSE', dbResponse)
      if (dbResponse.length === 0) {
        // the case of no gift items
        fails.push({
          code : items.code,
          description: 'your selected item',
          maxQty : 0
        });
        return callback(fails);
      }

      dbResponse.forEach(function (item) {
        if (item.qty < itemsToCheck[item.code]) {
          fails.push({
            code: item.code,
            description: item.description,
            maxQty: item.qty
          });
        }
      });

      const checkedItemCodes = Object.keys(itemsToCheck);
      if (dbResponse.length < checkedItemCodes.length) {
        // some items do not exist in inventory
        if (checkedItemCodes.some(code => code.indexOf('FRA') !== -1)) {
          fails.push({
            code : checkedItemCodes[0],
            description : 'your selected frame',
            maxQty : 0
          });
        } else {
          fails.push({
            code : checkedItemCodes[2],
            description : 'your selected hood',
            maxQty : 0
          });
        }

      }
      callback(fails);
    })
    .catch(error => console.log('error!', error))
};
