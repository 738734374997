import React from 'react';
import { Link } from 'react-router-dom';
import HowItWorks from './HowItWorks';
import PopularProducts from './PopularProducts';
import OurQuality from './OurQuality';
import QualityGuarantee from './QualityGuarantee';
import SaveWithFriends from './SaveWithFriends';
// import Testimonials from './Testimonials';
import CallToAction from './CallToAction';
import Details from './Details';

const HomeContent = () => {
  return (
    <div className="homecontent" style={{ display: 'block'}}>
      <div style={{ padding: '1rem 0 0' }}>
        <HowItWorks />
        <PopularProducts />
        <OurQuality />
        <QualityGuarantee />
        <SaveWithFriends />
        <CallToAction />
        {/* <Testimonials /> */}
        <Details />
      </div>
    </div>
  )

}

export default HomeContent;
