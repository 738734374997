import React from 'react';
import styled from 'styled-components'
import { Column, Container } from '../../components/Layout';
import { AlphaHeader } from '../../components/Section';
import { mediaQueries } from '../../utils/mediaQueries';
import { Link } from 'react-router-dom';

import {
  BACHELOR_GOWN_CAP_HOOD,
  BACHELOR_GOWN_CAP,
  BACHELOR_GOWN_CAP_ECONOMY,
  BACHELOR_GOWN,
  BACHELOR_GOWN_ECONOMY,
  MASTER_GOWN_CAP_HOOD,
  MASTER_GOWN_CAP,
  MASTER_GOWN,
  HOOD,
  CAP,
} from '../../../constants';

const products = [
  {
    id: 100,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1637449010/UK-Uni-Photos/Bournemouth/bmouth-bach-all-side.jpg',
    header: 'University Gown Sets (A-Z)',
    description: 'Full University Graduation Gown Sets',
    link: '/graduation-gown',
    price: `£${BACHELOR_GOWN_CAP_HOOD}`,
  },
  {
    id: 99,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-side.jpg',
    header: 'Bachelor Style Gown (Premium)',
    description: 'Graduation Gown - Bachelor Style',
    link: '/academic-dress/bachelor-gown',
    price: `£${BACHELOR_GOWN}`,
  },
  {
    id: 99.1,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1549165130/Regalia/bachelor-gown.jpg',
    header: 'Bachelor Style Gown (Standard)',
    description: '160 gsm Bachelor Gown',
    link: '/academic-dress/bachelor-gown-economy',
    price: `£${BACHELOR_GOWN_ECONOMY}`,
  },
  {
    id: 99.2,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1549165139/Regalia/bach-cap-gown-side.jpg',
    header: 'Bachelor Gown Set (Premium)',
    description: 'Bachelor Gown and Cap',
    link: '/academic-dress/bachelor-gown-set',
    price: `£${BACHELOR_GOWN_CAP}`,
  },
  {
    id: 99.3,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bachelor-cap-gown-set.jpg',
    header: 'Bachelor Gown Set (Standard)',
    description: '160 gsm Bachelor Gown and Cap',
    link: '/academic-dress/bachelor-gown-set-economy',
    price: `£${BACHELOR_GOWN_CAP_ECONOMY}`,
  },
  {
    id: 98,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1549186837/Regalia/master-gown-front-main.jpg',
    header: 'Master Style Gown',
    description: 'Graduation Gown - Master Style',
    link: '/academic-dress/master-gown',
    price: `£${MASTER_GOWN}`,
  },
  {
    id: 97,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Regalia/hood.jpg',
    header: 'Graduation Hoods',
    description: 'Choose graduation hoods from a variety of colours',
    link: '/academic-dress/graduation-hoods',
    price: `£${HOOD}`,
  },
];

const SectionConainerWhite = styled.div`
  padding: 70px 15px 80px 15px;
  background-color: #FFFFFF;
  ${mediaQueries.tablet`
    padding: 20px;
  `};
`
const StyledProductRow = styled.div`
  display:flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 0 auto;
  padding: 15px 0;
  ${mediaQueries.tablet`
    div:nth-child(5) {
      display: none;
    }
    margin: 15px auto;
  `};
  ${mediaQueries.mobile`
    margin: 0 auto;
  `};
`

const Title = styled.div`
  display: inline-block;
  p {
    font-weight: 700;
  }
`

const StyledLink = styled(Link)`
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #041733;
`

const ProductContainer = styled.div`
  display: inline-block;
  margin: 0 auto;
  max-width: 13rem;
  min-height: 25rem;
  padding: 15px 5px;
  border-radius: 4px;
  &:hover {
    box-shadow: 2px 5px 10px #888888;
  }
  p {
    line-height: 20px;
    margin-top: 14px;
  }
  ${mediaQueries.tablet`
    padding: 20px 63px;
  `};
`

const ProductImg = styled.div`
  padding: 15px 20px 10px 20px;
  img {
    height: 250px;
    border-radius: 4px;
  }
`

const BtnContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0 5px 0;
  button {
    font-size: 16px;
    background-color: white;
    color: black;
    width: 160px;
    height: 50px;
    padding: 0px;
    border: none;
    border-radius: 3px;
    box-shadow: 1px 1px 1px 1px #888888;
    text-align: center;
    margin-top: 5px;
  }
   ${mediaQueries.tablet`
      margin: 0 0 30px 0;
      button {
        font-size: 15px;
      }
  `};
   ${mediaQueries.mobile`
      button {
        font-size: 15px;
      }
  `};
`

const Steps = ({ steps }) => (
  <StyledProductRow>
    {
      products.map((product) => (
        <Column key={product.id}>
          <StyledLink to={product.link}>
            <ProductContainer>
              <ProductImg><img src={product.icon} /></ProductImg>
              <Title>
                <p>{ product.header }</p>
              </Title>
              <p>{ product.description }</p>
              <p>{ product.price }</p>
            </ProductContainer>
          </StyledLink>
        </Column>
        )
      )
    }
  </StyledProductRow>
)

const PopularProdcts = () => {
  return (
    <SectionConainerWhite>
      <AlphaHeader>Featured Graduation Regalia Products</AlphaHeader>
      <Steps products={products} />
      <BtnContainer to="/academic-dress"><button>Shop Now</button></BtnContainer>
    </SectionConainerWhite>
  )
}

export default PopularProdcts;




