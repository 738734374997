import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { Products } from '../../sources/products';
import { checkInventory } from '../cart/checkInventory';
import Gown from './gown';
import Cap from './cap';
import Hood from './hood';
import Qty from './qty';
import Popup from './popup';
import CustomButton from '../custom-items/button-loader';


const Items = (props) => {
  const path = window.location.pathname;
  const product = Products[path]
  const name = product.name;
  const hoodStyle = product["hood-style"];
  const productType = product.productType;
  const gownSubtype = product.gownSubtype;
  const price = product.price;
  const image = product.photos[0];
  const toShow = Products[path].show;

  const hoodCode = toShow.indexOf("hood") === -1
    ? null
    : product["hood-code"];

  const setInitialState = () => {
    const state = {
      gown: null,
      cap: toShow.includes('cap')
      ? 'HAT00003'
      : null,
      hood: hoodCode,
      gift: null,
    }
    const level = product.level;
    const phdStyle = product.phdStyle;
    const hatType = product.hatType;

    // by level
    if (level === "Bachelor") {
      if (gownSubtype === 'economy') {
        state.gown = 'GOW00003'
      } else {
        state.gown = 'GOW10003';
      }
    } else if (level === 'Master'){
      state.gown = 'GOW00013';
    } else if (level === 'PhD'){

      switch(phdStyle){
        case 'cloth':
          state.gown = 'GOW00023';
        break;

        default:
          state.gown = 'GOW00033';
      }

      switch(hatType){
        case "red":
          state.cap = "HAT00023";
          break;

        case "yellow":
          state.cap = "HAT00033";
          break;

        default:
          state.cap = "HAT00003";
      }

    }

    return state;

  }

  const [selectedProducts, setSelectedProducts] = useState(setInitialState());
  const [isLoaded, setLoaded] = useState(null);
  const [qty, setQty] = useState(1);
  const [popup, setPopup] = useState(false);
  const [error, setError] = useState(false);

  const handleClick = () => {

    ReactGA.event('add_to_cart', {
      category: 'add_to_cart',
      action: 'item added to cart',
      label: 'item added to cart',
      items: toShow.map(itemName => ({
        item_id: selectedProducts[itemName],
        item_category: productType,
        quantity: qty,
        price,
      }))
    });

    const toCheck = toShow.map((itemName) => {
      return ({
        code: selectedProducts[itemName],
        qty,
      })
    })

    checkInventory(toCheck, function(fails){
      if(fails.length === 0){
        const addToCartObject = {
          name,
          hoodStyle,
          qty,
          price,
          productType,
          image,
        };
        toShow.map(itemName => {
          return addToCartObject[itemName] = selectedProducts[itemName]
        })

        props.addToCart(addToCartObject);
        setPopup(true);
        setLoaded(true);
      }
      else {
        setLoaded(true);
        setError(fails);
      }
    });

  }

  const renderSelections = (product) => {
    const { hood, hoodStyle, level, phdStyle, hatType } = product;
    let selections = product.show.map(function(element){
      switch(element){
        case "gown":
          return <Gown
            key={element}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            level={level}
            phdStyle={phdStyle}
          />
        case "hood":
          return <Hood key={element} hood={hood} hoodStyle={hoodStyle} />
        case "cap":
          return <Cap
            key={element}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            level={level}
            hatType={hatType}
          />
      }
    });
    return selections;
  }

  return (
    <div className="product-selections">
      {renderSelections(product) }
      <div className="quantity">
        <h3>Qty</h3>
        <Qty
          initialQty={1}
          setQty={setQty}
        />
      </div>
      <div className="errors">
        { error &&
          error.map(function(error){
            if (error.maxQty === 0) {
              return (
                <p key={error.code}>
                  Sorry, we are currently out of stock of {error.description}.
                </p>
              )
            }
            else {
              return (
                <p key={error.code}>
                  Sorry, we only have {error.maxQty} of {error.description}. Please choose a lower amount.
                </p>
              )
            }
          })
        }
      </div>
      <CustomButton
        handleSubmit={() => handleClick()}
        content="Add to Cart"
        xhrLoaded={isLoaded}
      />
      {popup ?
      <div>
        <Popup
          price={price}
          qty={qty}
          hidePopup={() => setPopup(false)}
          name={name}
        />
      </div>
      : null
      }
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    items: state.shoppingCart
  }
}

const mapDispatchToProps = (dispatch) => {

  return {
    addToCart: (addToCartObject) => {
      const addToCart = {
        type: "ADD_TO_CART",
        gown: null,
        cap: null,
        hood: null,
        gift: null,
        hoodStyle: null,
        productType: '',
        ...addToCartObject
      }
      dispatch(addToCart)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Items);
