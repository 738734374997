import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer">
      <div className="column">
        <p className="title">Regalia & Memorabilia</p>
        <Link to="/graduation-gown">Full University Sets</Link>
        <Link to="/academic-dress/graduation-hoods">Graduation Hoods</Link>
        <Link to="/academic-dress/bachelor-gown-set">Bachelor Gown Sets</Link>
        <Link to="/academic-dress/bachelor-gown">Bachelor Gowns</Link>
        <Link to="/academic-dress/master-gown-set">Master Gown Sets</Link>
        <Link to="/academic-dress/master-gown">Master Gowns</Link>
        <Link to="/academic-dress/graduation-cap">Graduation Caps</Link>
        <Link to="/academic-dress/graduation-puppy-large">Graduation Puppies</Link>
        <Link to="/academic-dress/graduation-teddy-bear">Graduation Teddies</Link>
        <Link to="/graduation-gift">Graduation Gifts</Link>
        <Link to="/certificate-frame">Certificate Frames</Link>
        <Link to="/legal-wear">Legal Wear</Link>
      </div>
      <div className="column">
        <p className="title">About</p>
        <Link to="/about">About Us</Link>
        <Link to="/wholesale">Wholesale</Link>
        <Link to="/save-with-friends">Save with Friends</Link>
        <Link to="/pricebeat">Price Beat Guarantee</Link>
        <p className="title">International</p>
        <a target="blank" href="https://www.gowningstreet.com.au">Australia Store</a>
{/*        <a target="blank" href="https://www.graduationgowns.co.nz">New Zealand Store</a>*/}
      </div>
      <div className="column">
        <p className="title">Help & Support</p>
        <Link to="/contact">Contact Us</Link>
        <Link to="/faq">FAQ</Link>
        <Link to="/shipping">Shipping Estimator</Link>
        <Link to="/terms">Terms & Conditions</Link>
        <Link to="/privacy">Privacy Policy</Link>
      </div>
      <div className="column">
        <p className="title">Social</p>
        <div className="imgbox">
          <a target="blank" href="https://www.facebook.com/gowningstreet/"><img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550903393/Utility/Facebook_icon.png"/></a>
          <a target="blank" href="https://www.instagram.com/gowningst/"><img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550903393/Utility/Instagram_icon.png"/></a>
        </div>
        <p className="title">Cart</p>
        <Link to="/cart">Shopping Cart</Link>
        <p className="title">Payment</p>
        <p className="text">Pay securely with</p>
        <div className="imgbox">
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899593/Utility/Visa_Icon.png"/>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899593/Utility/Mastercard_Icon.png"/>
        </div>
        <div className="imgbox">
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899593/Utility/AMEX_Icon.png"/>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899593/Utility/Paypal_Icon.png"/>
        </div>
      </div>
    </div>
  )

}

export default Footer;




