import React from 'react';
import { withRouter } from 'react-router-dom';

const Popup = (props) => {
  const viewCart = (event) => {
    props.history.push("/cart");
  };

  const goCheckout = (event) => {
    props.history.push("/checkout");
  };

  var overLay = {
    position       : 'fixed',
    top            : 0,
    left           : 0,
    right          : 0,
    bottom         : 0,
    backgroundColor: 'hsla(100, 0%, 24%, 0.7)'
  };

  var popShow = {
    border      : '1px solid #ccc',
    background  : '#fff',
    borderRadius: '4px',
    outline     : 'none',
    padding     : '20px'
  };

  return (
    <div className="Popup" style={overLay}>
      <div className="popup-display" style={popShow}>
        <div className="cross">
          <img
            src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Utility/cross_grey_18x18.png"
            onClick={props.hidePopup}
          />
        </div>
        <h1>Item Added</h1>
        <p className="popupUniName">{props.name}</p>
        <div className="priceqty">
          <p className="popupInfo">Qty: {props.qty}</p>
          <p className="popupInfo">Price: {props.price}</p>
        </div>
        <button onClick={viewCart}>Proceed to Cart</button>
        <a onClick={props.hidePopup}>Continue Shopping</a>
        <p>or</p>
        <a onClick={goCheckout}>Proceed to Checkout</a>
      </div>
    </div>
  )
}

export default withRouter(Popup);
