const universities = {
    '/graduation-gown/university-of-the-arts-london': {
        name: 'University of the Arts London Graduation Gown Sets',
        searchName: 'University of the Arts London | UAL',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1637539162/UK%20Uni%20Campus%20Photos/ual-3.jpg',
        altTag: 'University of the Arts London Photo',
        abbreviation: 'Uni of the Arts London',
        titleTag: 'University of the Arts London graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your University of the Arts London graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Bachelor Degrees',
                path: '/graduation-gown/university-of-the-arts-london/bachelor-degrees',
                colors: ['#FF00FF', '#32174d'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/university-of-salford': {
        name: 'University of Salford Graduation Gown Sets',
        searchName: 'University of Salford',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1637539171/UK%20Uni%20Campus%20Photos/salford-1.jpg',
        altTag: 'University of Salford Photo',
        abbreviation: 'Uni of Salford',
        titleTag: 'University of Salford graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your University of Salford graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Bachelor Degrees',
                path: '/graduation-gown/university-of-salford/bachelor-degrees',
                colors: ['#FFD700', 'grey'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/university-of-london': {
        name: 'University of London Graduation Gown Sets',
        searchName: 'University of London',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'University of London Photo',
        abbreviation: 'Uni of London',
        titleTag: 'University of London graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your University of London graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/university-of-hull': {
        name: 'University of Hull Graduation Gown Sets',
        searchName: 'University of Hull',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'University of Hull Photo',
        abbreviation: 'Uni of Hull',
        titleTag: 'University of Hull graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your University of Hull graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/university-of-hertfordshire': {
        name: 'University of Hertfordshire Graduation Gown Sets',
        searchName: 'University of Hertfordshire',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'University of Hertfordshire Photo',
        abbreviation: 'Uni of Hertfordshire',
        titleTag: 'University of Hertfordshire graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your University of the Hertfordshire graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/university-of-bedfordshire': {
        name: 'University of Bedfordshire Graduation Gown Sets',
        searchName: 'University of Bedfordshire',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1637539164/UK%20Uni%20Campus%20Photos/bedfordshire-1.jpg',
        altTag: 'University of Bedfordshire Photo',
        abbreviation: 'Uni of Bedfordshire',
        titleTag: 'University of Bedfordshire graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your University of the Bedfordshire graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Bachelor Degrees',
                path: '/graduation-gown/university-of-bedfordshire/bachelor-degrees',
                colors: ['#DC143C', 'white'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/leeds-beckett-university': {
        name: 'Leeds Beckett University Graduation Gown Sets',
        searchName: 'Leeds Beckett University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1637539168/UK%20Uni%20Campus%20Photos/leedsb-1.jpg',
        altTag: 'University of Leeds Beckett Photo',
        abbreviation: 'Uni of Leeds Beckett',
        titleTag: 'Leeds Beckett University graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your University of Leeds Beckett graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Bachelor Degrees',
                path: '/graduation-gown/leeds-beckett-university/bachelor-degrees',
                colors: ['#FF9505', 'blue'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': 'https://www.leedsbeckett.ac.uk/studenthub/graduation/',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Sciences', 'Business'],
            },
            {
                Date: 'TBD',
                Subjects: ['Arts'],
            },
        ],
    },

    '/graduation-gown/university-college-london': {
        name: 'University College London Graduation Gown Sets',
        searchName: 'University College London | UCL',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'University College London Photo',
        abbreviation: 'Uni College London | UCL',
        titleTag: 'University College London graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your University College London graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/teesside-university': {
        name: 'Teesside University Graduation Gown Sets',
        searchName: 'Teesside University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1637539162/UK%20Uni%20Campus%20Photos/teesside-2.jpg',
        altTag: 'Teesside University Photo',
        abbreviation: 'Teesside Uni',
        titleTag: 'Teesside University graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your Teesside University graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Bachelor Degrees',
                path: '/graduation-gown/teesside-university/bachelor-degrees',
                colors: ['#B22222', ' #DCDCDC'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/sheffield-hallam-university': {
        name: 'Sheffield Hallam University Graduation Gown Sets',
        searchName: 'Sheffield Hallam University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Sheffield Hallam University Photo',
        abbreviation: 'Sheffield Hallam Uni | SHU',
        titleTag: 'Sheffield Hallam University graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your Sheffield Hallam University graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/plymouth-university': {
        name: 'Plymouth University Graduation Gown Sets',
        searchName: 'Plymouth University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Plymouth University Photo',
        abbreviation: 'Plymouth Uni',
        titleTag: 'Plymouth University graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your Plymouth University graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/oxford-brookes-university': {
        name: 'Oxford Brookes University Graduation Gown Sets',
        searchName: 'Oxford Brookes University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Oxford Brookes University Photo',
        abbreviation: 'Oxford Brookes Uni',
        titleTag: 'Oxford Brookes University graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your Oxford Brookes University graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/open-university': {
        name: 'Open University Graduation Gown Sets',
        searchName: 'Open University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Open University Photo',
        abbreviation: 'Open Uni',
        titleTag: 'Open University graduation gown and academic dress | from £39',
        metaTag: 'Why Hire? When you can buy your Open University graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/cardiff-university': {
        name: 'Cardiff University Graduation Gown Sets',
        searchName: 'Cardiff University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Cardiff University Photo',
        abbreviation: 'Cardiff Uni',
        titleTag: 'Cardiff University graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your Cardiff University graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/bournemouth-university': {
        name: 'Bournemouth University Graduation Gown Sets',
        searchName: 'Bournemouth University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1637539167/UK%20Uni%20Campus%20Photos/bmouth-3.jpg',
        altTag: 'Bournemouth University Photo',
        abbreviation: 'Bournemouth Uni',
        titleTag: 'Bournemouth University graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your Bournemouth University graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Bachelor Degrees',
                path: '/graduation-gown/bournemouth-university/bachelor-degrees',
                colors: ['#D2B48C', '#00468b'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/london-metropolitan-university': {
        name: 'London Metropolitan University Graduation Gown Sets',
        searchName: 'London Metropolitan University | LMET',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1637539170/UK%20Uni%20Campus%20Photos/lmet-2.jpg',
        altTag: 'London Metropolitan University Photo',
        abbreviation: 'London Metropolitan University',
        titleTag: 'London Metropolitan University graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your London Metropolitan University graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Bachelor Degrees',
                path: '/graduation-gown/london-metropolitan-university/bachelor-degrees',
                colors: ['#4B0082', ' #A9A9A9'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/keele-university': {
        name: 'Keele University Graduation Gown Sets',
        searchName: 'Keele University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1637539168/UK%20Uni%20Campus%20Photos/keele-1.jpg',
        altTag: 'Keele University Photo',
        abbreviation: 'Keele University',
        titleTag: 'Keele University graduation gown and academic dress | from £39',
        metaTag: 'Why Hire? When you can buy your Keele University graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Bachelor Degrees',
                path: '/graduation-gown/keele-university/bachelor-degrees',
                colors: ['#FFC000'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/edinburgh-napier-university': {
        name: 'Edinburgh Napier University Graduation Gown Sets',
        searchName: 'Edinburgh Napier University | ENAP',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1637539166/UK%20Uni%20Campus%20Photos/enap-1.jpg',
        altTag: 'Edinburgh Napier University Photo',
        abbreviation: 'Edinburgh Napier University',
        titleTag: 'Edinburgh Napier University graduation gown and academic dress | from £39',
        metaTag:
            'Why hire when you can buy your Edinburgh Napier Graduation Gown from £39? Gowning Street stocks both Bachelor and Masters Gowns.',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Bachelor Degrees',
                path: '/graduation-gown/edinburgh-napier-university/bachelor-degrees',
                colors: ['#c41e3a'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/edge-hill-university': {
        name: 'Edge Hill University Graduation Gown Sets',
        searchName: 'Edge Hill University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Edge Hill University Photo',
        abbreviation: 'Edge Hill University',
        titleTag: 'Edge Hill University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Edge Hill Graduation Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/aberdeen-university': {
        name: 'Aberdeen University Graduation Gown Sets',
        searchName: 'Aberdeen University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Aberdeen University Photo',
        abbreviation: 'Aberdeen University',
        titleTag: 'Aberdeen University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Aberdeen Graduation Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/abertay-university': {
        name: 'Abertay University Graduation Gown Sets',
        searchName: 'Abertay University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Abertay University Photo',
        abbreviation: 'Abertay University',
        titleTag: 'Abertay University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Abertay Graduation Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/aberystwyth-university': {
        name: 'Aberystwyth University Graduation Gown Sets',
        searchName: 'Aberystwyth University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Aberystwyth University Photo',
        abbreviation: 'Aberystwyth University',
        titleTag: 'Aberystwyth University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Aberystwyth Graduation Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/anglia-ruskin-university': {
        name: 'Anglia Ruskin University Graduation Gown Sets',
        searchName: 'Anglia Ruskin University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Anglia Ruskin University Photo',
        abbreviation: 'Anglia Ruskin University',
        titleTag: 'Anglia Ruskin University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Anglia Ruskin Graduation Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/arden-university': {
        name: 'Arden University Graduation Gown Sets',
        searchName: 'Arden University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Arden University Photo',
        abbreviation: 'Arden University',
        titleTag: 'Arden University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Arden Graduation Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/arts-university-bournemouth': {
        name: 'Arts University Bournemouth Graduation Gown Sets',
        searchName: 'Arts University Bournemouth',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Arts University Bournemouth Photo',
        abbreviation: 'Arts University Bournemouth',
        titleTag: 'Arts University Bournemouth graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Arts Graduation Bournemouth Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/aston-university': {
        name: 'Aston University Graduation Gown Sets',
        searchName: 'Aston University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Aston University Photo',
        abbreviation: 'Aston University',
        titleTag: 'Aston University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Aston Graduation Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/bangor-university': {
        name: 'Bangor University Graduation Gown Sets',
        searchName: 'Bangor University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Bangor University Photo',
        abbreviation: 'Bangor University',
        titleTag: 'Bangor University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Bangor Graduation Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/bath-university': {
        name: 'Bath University Graduation Gown Sets',
        searchName: 'Bath University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Bath University Photo',
        abbreviation: 'Bath University',
        titleTag: 'Bath University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Bath Graduation Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/bath-spa-university': {
        name: 'Bath Spa University Graduation Gown Sets',
        searchName: 'Bath Spa University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Bath Spa University Photo',
        abbreviation: 'Bath Spa University',
        titleTag: 'Bath Spa University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Bath Spa Graduation Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/bishop-grosseteste-university': {
        name: 'Bishop Grosseteste University Graduation Gown Sets',
        searchName: 'Bishop Grosseteste University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Bishop Grosseteste University Photo',
        abbreviation: 'Bishop Grosseteste University',
        titleTag: 'Bishop Grosseteste University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Bishop Grosseteste Graduation Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/birkbeck-university': {
        name: 'Birkbeck University Graduation Gown Sets',
        searchName: 'Birkbeck University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Birkbeck University Photo',
        abbreviation: 'Birkbeck University',
        titleTag: 'Birkbeck University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Birkbeck Graduation Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/birmingham-city-university': {
        name: 'Birmingham City University Graduation Gown Sets',
        searchName: 'Birmingham City University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Birmingham City University Photo',
        abbreviation: 'Birmingham City University',
        titleTag: 'Birmingham City University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Birmingham City Graduation Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/birmingham-college': {
        name: 'Birmingham College University Graduation Gown Sets',
        searchName: 'Birmingham College University (UCB)',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Birmingham College University Photo',
        abbreviation: 'UCB',
        titleTag: 'Birmingham College University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Birmingham College Graduation Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/birmingham-university': {
        name: 'Birmingham University Graduation Gown Sets',
        searchName: 'Birmingham University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Birmingham University Photo',
        abbreviation: 'Birmingham University',
        titleTag: 'Birmingham University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Birmingham Graduation Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/blackburn-college': {
        name: 'Blackburn College Graduation Gown Sets',
        searchName: 'Blackburn College',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Blackburn College Photo',
        abbreviation: 'Blackburn College',
        titleTag: 'Blackburn College graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Blackburn College Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/bolton-university': {
        name: 'University of Bolton Graduation Gown Sets',
        searchName: 'University of Bolton',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'University of Bolton Photo',
        abbreviation: 'University of Bolton',
        titleTag: 'University of Bolton graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your University of Bolton Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/bpp-university': {
        name: 'BPP University Graduation Gown Sets',
        searchName: 'BPP University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'BPP University Photo',
        abbreviation: 'BPP University',
        titleTag: 'BPP University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your BPP University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/bradford-university': {
        name: 'Bradford University Graduation Gown Sets',
        searchName: 'Bradford University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Bradford University Photo',
        abbreviation: 'Bradford University',
        titleTag: 'Bradford University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Bradford University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/brighton-university': {
        name: 'University of Brighton Graduation Gown Sets',
        searchName: 'University of Brighton',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'University of Brighton Photo',
        abbreviation: 'University of Brighton',
        titleTag: 'University of Brighton graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your University of Brighton Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/bristol-university': {
        name: 'Bristol University Graduation Gown Sets',
        searchName: 'Bristol University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Bristol University Photo',
        abbreviation: 'Bristol University',
        titleTag: 'Bristol University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Bristol University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/brunel-university': {
        name: 'Brunel University Graduation Gown Sets',
        searchName: 'Brunel University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Brunel University Photo',
        abbreviation: 'Brunel University',
        titleTag: 'Brunel University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Brunel University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/buckingham-university': {
        name: 'Buckingham University Graduation Gown Sets',
        searchName: 'Buckingham University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Buckingham University Photo',
        abbreviation: 'Buckingham University',
        titleTag: 'Buckingham University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Buckingham University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/buckingham-new-university': {
        name: 'Buckingham New University Graduation Gown Sets',
        searchName: 'Buckingham New University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Buckingham New University Photo',
        abbreviation: 'Buckingham New University',
        titleTag: 'Buckingham New University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Buckingham New University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/camberwell-college-of-arts': {
        name: 'Camberwell College of Arts Graduation Gown Sets',
        searchName: 'Camberwell College of Arts',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Camberwell College of Arts Photo',
        abbreviation: 'Camberwell College of Arts',
        titleTag: 'Camberwell College of Arts graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Camberwell College of Arts Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/cambridge-university': {
        name: 'Cambridge University Graduation Gown Sets',
        searchName: 'Cambridge University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Cambridge University Photo',
        abbreviation: 'Cambridge University',
        titleTag: 'Cambridge University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Cambridge University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/canterbury-christ-church-university': {
        name: 'Canterbury Christ Church University Graduation Gown Sets',
        searchName: 'Canterbury Christ Church University | CCC Uni',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1637539164/UK%20Uni%20Campus%20Photos/ccc-1.jpg',
        altTag: 'Canterbury Christ Church University Photo',
        abbreviation: 'Canterbury Christ Church University',
        titleTag: 'CCCU Graduation Gown Hire? Buy instead of Hire and Save! | from £39',
        metaTag:
            'Buy Canterbury Christ Church Uni Gown Sets, for £45 and get UK-wide fast delivery. Our grad-gowns are hand crafted to perfection.',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Bachelor Degrees',
                path: '/graduation-gown/canterbury-christ-church-university/bachelor-degrees',
                colors: ['#c41e3a', '#3c1f41'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/cardiff-metropolitan-university': {
        name: 'Cardiff Metropolitan University Graduation Gown Sets',
        searchName: 'Cardiff Metropolitan University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Cardiff Metropolitan University Photo',
        abbreviation: 'Cardiff Metropolitan University',
        titleTag: 'Cardiff Metropolitan University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Cardiff Metropolitan University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/central-lancashire-university': {
        name: 'Central Lancashire University Graduation Gown Sets',
        searchName: 'Central Lancashire University (UCLAN)',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Central Lancashire University Photo',
        abbreviation: 'UCLAN',
        titleTag: 'Central Lancashire University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Central Lancashire University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/central-saint-martins': {
        name: "Central Saint Martin's Graduation Gown Sets",
        searchName: "Central Saint Martin's",
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: "Central Saint Martin's Photo",
        abbreviation: "Central Saint Martin's",
        titleTag: "Central Saint Martin's graduation gown and academic dress | from £39",
        metaTag:
            "Why rent when you can buy your Central Saint Martin's Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ",
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/chelsea-college-of-arts': {
        name: 'Chelsea College of Arts Graduation Gown Sets',
        searchName: 'Chelsea College of Arts',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Chelsea College of Arts Photo',
        abbreviation: 'Chelsea College of Arts',
        titleTag: 'Chelsea College of Arts graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Chelsea College of Arts Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/chester-university': {
        name: 'Chester University Graduation Gown Sets',
        searchName: 'Chester University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Chester University Photo',
        abbreviation: 'Chester University',
        titleTag: 'Chester University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Chester University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/chichester-university': {
        name: 'Chichester University Graduation Gown Sets',
        searchName: 'Chichester University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Chichester University Photo',
        abbreviation: 'Chichester University',
        titleTag: 'Chichester University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Chichester University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/city-university-london': {
        name: 'City University London Graduation Gown Sets',
        searchName: 'City University London',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'City University London Photo',
        abbreviation: 'City University London',
        titleTag: 'City University London graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your City University London Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/courtauld-institute-of-art': {
        name: 'Courtauld Institute of Art Graduation Gown Sets',
        searchName: 'Courtauld Institute of Art',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Courtauld Institute of Art Photo',
        abbreviation: 'Courtauld Institute of Art',
        titleTag: 'Courtauld Institute of Art graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Courtauld Institute of Art Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/coventry-university': {
        name: 'University of Coventry Graduation Gown Sets',
        searchName: 'University of Coventry',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'University of Coventry Photo',
        abbreviation: 'University of Coventry',
        titleTag: 'University of Coventry graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your University of Coventry Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/cumbria-university': {
        name: 'Cumbria University Graduation Gown Sets',
        searchName: 'Cumbria University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Cumbria University Photo',
        abbreviation: 'Cumbria University',
        titleTag: 'Cumbria University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Cumbria University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/cranfield-university': {
        name: 'Cranfield University Graduation Gown Sets',
        searchName: 'Cranfield University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Cranfield University Photo',
        abbreviation: 'Cranfield University',
        titleTag: 'Cranfield University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Cranfield University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/derby-university': {
        name: 'University of Derby Graduation Gown Sets',
        searchName: 'University of Derby',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1637539168/UK%20Uni%20Campus%20Photos/derby-1.jpg',
        altTag: 'University of Derby Photo',
        abbreviation: 'University of Derby',
        titleTag: 'University of Derby graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your University of Derby Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Bachelor Degrees',
                path: '/graduation-gown/derby-university/bachelor-degrees',
                colors: ['#b31900', '#cfebfd'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/demontfort-university': {
        name: 'Demontfort University Graduation Gown Sets',
        searchName: 'Demontfort University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Demontfort University Photo',
        abbreviation: 'Demontfort University',
        titleTag: 'Demontfort University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Demontfort University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/dundee-university': {
        name: 'Dundee University Graduation Gown Sets',
        searchName: 'Dundee University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Dundee University Photo',
        abbreviation: 'Dundee University',
        titleTag: 'Dundee University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Dundee University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/durham-university': {
        name: 'Durham University Graduation Gown Sets',
        searchName: 'Durham University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Durham University Photo',
        abbreviation: 'Durham University',
        titleTag: 'Durham University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Durham University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/east-anglia-university': {
        name: 'East Anglia University Graduation Gown Sets',
        searchName: 'East Anglia University (UEA)',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'East Anglia University Photo',
        abbreviation: 'UEA',
        titleTag: 'East Anglia University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your East Anglia University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/east-london-university': {
        name: 'East London University Graduation Gown Sets',
        searchName: 'East London University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'East London University Photo',
        abbreviation: 'East London University',
        titleTag: 'East London University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your East London University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/edinburgh-university': {
        name: 'Edinburgh University Graduation Gown Sets',
        searchName: 'Edinburgh University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Edinburgh University Photo',
        abbreviation: 'Edinburgh University',
        titleTag: 'Edinburgh University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Edinburgh University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/essex-university': {
        name: 'Essex University Graduation Gown Sets',
        searchName: 'Essex University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Essex University Photo',
        abbreviation: 'Essex University',
        titleTag: 'Essex University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Essex University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/exeter-university': {
        name: 'Exeter University Graduation Gown Sets',
        searchName: 'Exeter University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Exeter University Photo',
        abbreviation: 'Exeter University',
        titleTag: 'Exeter University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Exeter University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/falmouth-university': {
        name: 'Falmouth University Graduation Gown Sets',
        searchName: 'Falmouth University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Falmouth University Photo',
        abbreviation: 'Falmouth University',
        titleTag: 'Falmouth University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Falmouth University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/glasgow-caledonian-university': {
        name: 'Glasgow Caledonian University Graduation Gown Sets',
        searchName: 'Glasgow Caledonian University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Glasgow Caledonian University Photo',
        abbreviation: 'Glasgow Caledonian University',
        titleTag: 'Glasgow Caledonian University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Glasgow Caledonian University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/glasgow-university': {
        name: 'Glasgow University Graduation Gown Sets',
        searchName: 'Glasgow University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Glasgow University Photo',
        abbreviation: 'Glasgow University',
        titleTag: 'Glasgow University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Glasgow University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/gloucestershire-university': {
        name: 'University of Gloucestershire Graduation Gown Sets',
        searchName: 'University of Gloucestershire',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1637539166/UK%20Uni%20Campus%20Photos/gloucestershire-1.jpg',
        altTag: 'University of Gloucestershire Photo',
        abbreviation: 'University of Gloucestershire',
        titleTag: 'University of Gloucestershire graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your University of Gloucestershire Gown from £39? Gowning Street crafts premium quality graduation gowns for purchase',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Bachelor Degrees',
                path: '/graduation-gown/gloucestershire-university/bachelor-degrees',
                colors: ['#000080', '#B22222'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/glyndwr-university': {
        name: 'Glyndwr University Graduation Gown Sets',
        searchName: 'Glyndwr University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Glyndwr University Photo',
        abbreviation: 'Glyndwr University',
        titleTag: 'Glyndwr University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Glyndwr University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/goldsmiths': {
        name: 'Goldsmiths, University of London Graduation Gown Sets',
        searchName: 'Goldsmiths University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Goldsmiths University Photo',
        abbreviation: 'Goldsmiths University',
        titleTag: 'Goldsmiths, University of London graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Goldsmiths University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/greenwich-university': {
        name: 'Greenwich University Graduation Gown Sets',
        searchName: 'Greenwich University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Greenwich University Photo',
        abbreviation: 'Greenwich University',
        titleTag: 'Greenwich University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Greenwich University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/grimsby-institute-of-higher-education': {
        name: 'Grimsby Institute of Higher Education Graduation Gown Sets',
        searchName: 'Grimsby Institute of Higher Education',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Grimsby Institute of Higher Education Photo',
        abbreviation: 'Grimsby Institute of Higher Education',
        titleTag: 'Grimsby Institute of Higher Education graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Grimsby Institute of Higher Education Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/london-guildhall-university': {
        name: 'London Guildhall University Graduation Gown Sets',
        searchName: 'London Guildhall University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'London Guildhall University Photo',
        abbreviation: 'Guildhall',
        titleTag: 'London Guildhall University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your London Guildhall University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/harper-adams-university': {
        name: 'Harper Adams University Graduation Gown Sets',
        searchName: 'Harper Adams University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Harper Adams University Photo',
        abbreviation: 'Harper Adams University',
        titleTag: 'Harper Adams University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Harper Adams University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/heriot-watt-university': {
        name: 'Heriot Watt University Graduation Gown Sets',
        searchName: 'Heriot Watt University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Heriot Watt University Photo',
        abbreviation: 'Heriot Watt University',
        titleTag: 'Heriot Watt University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Heriot Watt University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/highlands-islands-university': {
        name: 'Highlands & Islands University Graduation Gown Sets',
        searchName: 'Highlands & Islands University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Highlands & Islands University Photo',
        abbreviation: 'Highlands & Islands University',
        titleTag: 'Highlands & Islands University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Highlands & Islands University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/huddersfield-university': {
        name: 'Huddersfield University Graduation Gown Sets',
        searchName: 'Huddersfield University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Huddersfield University Photo',
        abbreviation: 'Huddersfield University',
        titleTag: 'Huddersfield University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Huddersfield University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/imperial-college-london': {
        name: 'Imperial College London Graduation Gown Sets',
        searchName: 'Imperial College London',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Imperial College London Photo',
        abbreviation: 'Imperial College London',
        titleTag: 'Imperial College London graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Imperial College London Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/institute-of-cancer-research': {
        name: 'Institute of Cancer Research Graduation Gown Sets',
        searchName: 'Institute of Cancer Research',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Institute of Cancer Research Photo',
        abbreviation: 'Institute of Cancer Research',
        titleTag: 'Institute of Cancer Research graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Institute of Cancer Research Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/kent-university': {
        name: 'Kent University Graduation Gown Sets',
        searchName: 'Kent University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Kent University Photo',
        abbreviation: 'Kent University',
        titleTag: 'Kent University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Kent University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/kingston-university': {
        name: 'Kingston University Graduation Gown Sets',
        searchName: 'Kingston University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Kingston University Photo',
        abbreviation: 'Kingston University',
        titleTag: 'Kingston University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Kingston University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/kings-college-london': {
        name: "King's College London Graduation Gown Sets",
        searchName: "King's College London",
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: "King's College London Photo",
        abbreviation: "King's College London",
        titleTag: "King's College London graduation gown and academic dress | from £39",
        metaTag:
            "Why rent when you can buy your King's College London Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ",
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/lancaster-university': {
        name: 'Lancaster University Graduation Gown Sets',
        searchName: 'Lancaster University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1637539168/UK%20Uni%20Campus%20Photos/lancaster-1.jpg',
        altTag: 'Lancaster University Photo',
        abbreviation: 'Lancaster University',
        titleTag: 'Lancaster University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Lancaster University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Bachelor of Arts (BA)',
                path: '/graduation-gown/lancaster-university/bachelor-of-arts',
                colors: ['red'],
            },
            {
                level: 'Bachelor',
                name: 'Bachelor of Science (BSc)',
                path: '/graduation-gown/lancaster-university/bachelor-of-science',
                colors: ['red', 'yellow'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/leeds-university': {
        name: 'Leeds University Graduation Gown Sets',
        searchName: 'Leeds University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Leeds University Photo',
        abbreviation: 'Leeds University',
        titleTag: 'Leeds University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Leeds University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/leicester-university': {
        name: 'University of Leicester Graduation Gown Sets',
        searchName: 'University of Leicester',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'University of Leicester Photo',
        abbreviation: 'University of Leicester',
        titleTag: 'University of Leicester graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your University of Leicester Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/lincoln-university': {
        name: 'University of Lincoln Graduation Gown Sets',
        searchName: 'University of Lincoln',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1637539168/UK%20Uni%20Campus%20Photos/lincoln-1.jpg',
        altTag: 'University of Lincoln Photo',
        abbreviation: 'University of Lincoln',
        titleTag: 'University of Lincoln graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your University of Lincoln Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Bachelor Degrees',
                path: '/graduation-gown/lincoln-university/bachelor-degrees',
                colors: ['blue', '#fff700'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/liverpool-hope-university': {
        name: 'Liverpool Hope University Graduation Gown Sets',
        searchName: 'Liverpool Hope University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Liverpool Hope University Photo',
        abbreviation: 'Liverpool Hope University',
        titleTag: 'Liverpool Hope University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Liverpool Hope University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/liverpool-john-moores-university': {
        name: 'Liverpool John Moores University Graduation Gown Sets',
        searchName: 'Liverpool John Moores University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Liverpool John Moores University Photo',
        abbreviation: 'Liverpool John Moores University',
        titleTag: 'Liverpool John Moores University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Liverpool John Moores University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/liverpool-university': {
        name: 'Liverpool University Graduation Gown Sets',
        searchName: 'Liverpool University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Liverpool University Photo',
        abbreviation: 'Liverpool University',
        titleTag: 'Liverpool University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Liverpool University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/london-business-school': {
        name: 'London Business School (LBS) Graduation Gown Sets',
        searchName: 'London Business School (LBS) (LBS)',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'London Business School (LBS) Photo',
        abbreviation: 'London Business School (LBS)',
        titleTag: 'London Business School (LBS) graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your London Business School (LBS) Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/london-college-of-communication': {
        name: 'London College of Communication Graduation Gown Sets',
        searchName: 'London College of Communication',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'London College of Communication Photo',
        abbreviation: 'London College of Communication',
        titleTag: 'London College of Communication graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your London College of Communication Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/london-college-of-fashion': {
        name: 'London College of Fashion Graduation Gown Sets',
        searchName: 'London College of Fashion',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'London College of Fashion Photo',
        abbreviation: 'London College of Fashion',
        titleTag: 'London College of Fashion graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your London College of Fashion Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/london-institute-of-banking-finance': {
        name: 'London Institute of Banking & Finance Graduation Gown Sets',
        searchName: 'London Institute of Banking & Finance',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'London Institute of Banking & Finance Photo',
        abbreviation: 'London Institute of Banking & Finance',
        titleTag: 'London Institute of Banking & Finance graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your London Institute of Banking & Finance Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/london-school-of-economics': {
        name: 'London School of Economics (LSE) Graduation Gown Sets',
        searchName: 'London School of Economics (LSE)',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'London School of Economics (LSE) Photo',
        abbreviation: 'LSE',
        titleTag: 'London School of Economics (LSE) graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your London School of Economics (LSE) Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/london-school-of-hygiene-tropical-medicine': {
        name: 'London School of Hygiene & Tropical Medicine Graduation Gown Sets',
        searchName: 'London School of Hygiene & Tropical Medicine',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'London School of Hygiene & Tropical Medicine Photo',
        abbreviation: 'London School of Hygiene & Tropical Medicine',
        titleTag: 'London School of Hygiene & Tropical Medicine graduation gown & academic dress | from £39',
        metaTag:
            'Why rent when you can buy your London School of Hygiene & Tropical Medicine Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/london-south-bank-university': {
        name: 'London South Bank University Graduation Gown Sets',
        searchName: 'London South Bank University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'London South Bank University Photo',
        abbreviation: 'London South Bank University',
        titleTag: 'London South Bank University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your London South Bank University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/loughborough-university': {
        name: 'Loughborough University Graduation Gown Sets',
        searchName: 'Loughborough University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Loughborough University Photo',
        abbreviation: 'Loughborough University',
        titleTag: 'Loughborough University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Loughborough University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/manchester-metropolitan-university': {
        name: 'Manchester Metropolitan University Graduation Gown Sets',
        searchName: 'Manchester Metropolitan University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Manchester Metropolitan University Photo',
        abbreviation: 'Manchester Metropolitan University',
        titleTag: 'Manchester Metropolitan University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Manchester Metropolitan University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/manchester-university': {
        name: 'Manchester University Graduation Gown Sets',
        searchName: 'Manchester University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Manchester University Photo',
        abbreviation: 'Manchester University',
        titleTag: 'Manchester University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Manchester University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/marjon-university': {
        name: 'Marjon University Graduation Gown Sets',
        searchName: 'Marjon University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Marjon University Photo',
        abbreviation: 'Marjon University',
        titleTag: 'Marjon University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Marjon University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/middlesex-university': {
        name: 'Middlesex University Graduation Gown Sets',
        searchName: 'Middlesex University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Middlesex University Photo',
        abbreviation: 'Middlesex University',
        titleTag: 'Middlesex University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Middlesex University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/newcastle-university': {
        name: 'Newcastle University Graduation Gown Sets',
        searchName: 'Newcastle University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Newcastle University Photo',
        abbreviation: 'Newcastle University',
        titleTag: 'Newcastle University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Newcastle University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/newcastle-college': {
        name: 'Newcastle College Graduation Gown Sets',
        searchName: 'Newcastle College',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Newcastle College Photo',
        abbreviation: 'Newcastle College',
        titleTag: 'Newcastle College graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Newcastle College Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/new-college-durham': {
        name: 'New College Durham Graduation Gown Sets',
        searchName: 'New College Durham',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'New College Durham Photo',
        abbreviation: 'New College Durham',
        titleTag: 'New College Durham graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your New College Durham Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/newman-university': {
        name: 'Newman University Graduation Gown Sets',
        searchName: 'Newman University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Newman University Photo',
        abbreviation: 'Newman University',
        titleTag: 'Newman University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Newman University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/northampton-university': {
        name: 'Northampton University Graduation Gown Sets',
        searchName: 'Northampton University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Northampton University Photo',
        abbreviation: 'Northampton University',
        titleTag: 'Northampton University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Northampton University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/northumbria-university': {
        name: 'Northumbria University Graduation Gown Sets',
        searchName: 'Northumbria University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Northumbria University Photo',
        abbreviation: 'Northumbria University',
        titleTag: 'Northumbria University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Northumbria University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/norwich-university-of-the-arts': {
        name: 'Norwich University of the Arts Graduation Gown Sets',
        searchName: 'Norwich University of the Arts',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Norwich University of the Arts Photo',
        abbreviation: 'Norwich University of the Arts',
        titleTag: 'Norwich University of the Arts graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Norwich University of the Arts Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/nottingham-trent-university': {
        name: 'Nottingham Trent University Graduation Gown Sets',
        searchName: 'Nottingham Trent University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Nottingham Trent University Photo',
        abbreviation: 'Nottingham Trent University',
        titleTag: 'Nottingham Trent University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Nottingham Trent University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/nottingham-university': {
        name: 'Nottingham University Graduation Gown Sets',
        searchName: 'Nottingham University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Nottingham University Photo',
        abbreviation: 'Nottingham University',
        titleTag: 'Nottingham University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Nottingham University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/oxford-university': {
        name: 'Oxford University Graduation Gown Sets',
        searchName: 'Oxford University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Oxford University Photo',
        abbreviation: 'Oxford University',
        titleTag: 'Oxford University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Oxford University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/plymouth-marjon-university': {
        name: 'Plymouth Marjon University Graduation Gown Sets',
        searchName: 'Plymouth Marjon University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1637539171/UK%20Uni%20Campus%20Photos/pmouth-1.jpg',
        altTag: 'Plymouth Marjon University Photo',
        abbreviation: 'Plymouth Marjon University',
        titleTag: 'Plymouth Marjon University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Plymouth Marjon University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Bachelor Degrees',
                path: '/graduation-gown/plymouth-marjon-university/bachelor-degrees',
                colors: ['#2F0909'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/portsmouth-university': {
        name: 'Portsmouth University Graduation Gown Sets',
        searchName: 'Portsmouth University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Portsmouth University Photo',
        abbreviation: 'Portsmouth University',
        titleTag: 'Portsmouth University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Portsmouth University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/queen-margaret-university': {
        name: 'Queen Margaret University Graduation Gown Sets',
        searchName: 'Queen Margaret University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Queen Margaret University Photo',
        abbreviation: 'Queen Margaret University',
        titleTag: 'Queen Margaret University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Queen Margaret University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/queen-mary': {
        name: 'Queen Mary University of London Graduation Gown Sets',
        searchName: 'Queen Mary University of London',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Queen Mary University of London Photo',
        abbreviation: 'Queen Mary University of London',
        titleTag: 'Queen Mary University of London graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Queen Mary University of London Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/queens-university-belfast': {
        name: "Queen's University Belfast Graduation Gown Sets",
        searchName: "Queen's University Belfast",
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: "Queen's University Belfast Photo",
        abbreviation: "Queen's University Belfast",
        titleTag: "Queen's University Belfast graduation gown and academic dress | from £39",
        metaTag:
            "Why rent when you can buy your Queen's University Belfast Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ",
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/ravensbourne-university': {
        name: 'Ravensbourne University Graduation Gown Sets',
        searchName: 'Ravensbourne University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Ravensbourne University Photo',
        abbreviation: 'Ravensbourne University',
        titleTag: 'Ravensbourne University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Ravensbourne University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/reading-university': {
        name: 'Reading University Graduation Gown Sets',
        searchName: 'Reading University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Reading University Photo',
        abbreviation: 'Reading University',
        titleTag: 'Reading University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Reading University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/regents-university-london': {
        name: "Regent's University London Graduation Gown Sets",
        searchName: "Regent's University London",
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: "Regent's University London Photo",
        abbreviation: "Regent's University London",
        titleTag: "Regent's University London graduation gown and academic dress | from £39",
        metaTag:
            "Why rent when you can buy your Regent's University London Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ",
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/robert-gordon-university': {
        name: 'Robert Gordon University Graduation Gown Sets',
        searchName: 'Robert Gordon University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Robert Gordon University Photo',
        abbreviation: 'Robert Gordon University',
        titleTag: 'Robert Gordon University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Robert Gordon University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/roehampton-university': {
        name: 'Roehampton University Graduation Gown Sets',
        searchName: 'Roehampton University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Roehampton University Photo',
        abbreviation: 'Roehampton University',
        titleTag: 'Roehampton University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Roehampton University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/royal-academy-of-music': {
        name: 'Royal Academy of Music Graduation Gown Sets',
        searchName: 'Royal Academy of Music',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Royal Academy of Music Photo',
        abbreviation: 'Royal Academy of Music',
        titleTag: 'Royal Academy of Music graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Royal Academy of Music Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/royal-agricultural-college': {
        name: 'Royal Agricultural College Graduation Gown Sets',
        searchName: 'Royal Agricultural College',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Royal Agricultural College Photo',
        abbreviation: 'Royal Agricultural College',
        titleTag: 'Royal Agricultural College graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Royal Agricultural College Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/royal-college-of-art': {
        name: 'Royal College of Art Graduation Gown Sets',
        searchName: 'Royal College of Art',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Royal College of Art Photo',
        abbreviation: 'Royal College of Art',
        titleTag: 'Royal College of Art graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Royal College of Art Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/royal-college-of-music': {
        name: 'Royal College of Music Graduation Gown Sets',
        searchName: 'Royal College of Music',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Royal College of Music Photo',
        abbreviation: 'Royal College of Music',
        titleTag: 'Royal College of Music graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Royal College of Music Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/royal-college-of-nursing': {
        name: 'Royal College of Nursing Graduation Gown Sets',
        searchName: 'Royal College of Nursing',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Royal College of Nursing Photo',
        abbreviation: 'Royal College of Nursing',
        titleTag: 'Royal College of Nursing graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Royal College of Nursing Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/royal-conservatoire-of-scotland': {
        name: 'Royal Conservatoire of Scotland Graduation Gown Sets',
        searchName: 'Royal Conservatoire of Scotland',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Royal Conservatoire of Scotland Photo',
        abbreviation: 'Royal Conservatoire of Scotland',
        titleTag: 'Royal Conservatoire of Scotland graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Royal Conservatoire of Scotland Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/royal-holloway': {
        name: 'Royal Holloway Graduation Gown Sets',
        searchName: 'Royal Holloway',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Royal Holloway Photo',
        abbreviation: 'Royal Holloway',
        titleTag: 'Royal Holloway graduation gown and academic dress | from £39',
        metaTag: 'Why rent when you can buy your Royal Holloway Gown from £39? Gowning Street crafts premium quality graduation gowns',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/royal-northern-college-of-music': {
        name: 'Royal Northern College of Music Graduation Gown Sets',
        searchName: 'Royal Northern College of Music',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Royal Northern College of Music Photo',
        abbreviation: 'Royal Northern College of Music',
        titleTag: 'Royal Northern College of Music graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Royal Northern College of Music Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/royal-veterinary-college': {
        name: 'Royal Veterinary College Graduation Gown Sets',
        searchName: 'Royal Veterinary College',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Royal Veterinary College Photo',
        abbreviation: 'Royal Veterinary College',
        titleTag: 'Royal Veterinary College graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Royal Veterinary College Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/sheffield-university': {
        name: 'Sheffield University Graduation Gown Sets',
        searchName: 'Sheffield University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Sheffield University Photo',
        abbreviation: 'Sheffield University',
        titleTag: 'Sheffield University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Sheffield University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/southampton-university': {
        name: 'Southampton University Graduation Gown Sets',
        searchName: 'Southampton University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Southampton University Photo',
        abbreviation: 'Southampton University',
        titleTag: 'Southampton University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Southampton University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/south-wales-university': {
        name: 'South Wales University Graduation Gown Sets',
        searchName: 'South Wales University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'South Wales University Photo',
        abbreviation: 'South Wales University',
        titleTag: 'South Wales University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your South Wales University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/staffordshire-university': {
        name: 'Staffordshire University Graduation Gown Sets',
        searchName: 'Staffordshire University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Staffordshire University Photo',
        abbreviation: 'Staffordshire University',
        titleTag: 'Staffordshire University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Staffordshire University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/st-andrews-university': {
        name: 'St Andrews University Graduation Gown Sets',
        searchName: 'St Andrews University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'St Andrews University Photo',
        abbreviation: 'St Andrews University',
        titleTag: 'St Andrews University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your St Andrews University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/st-georges-university': {
        name: "St George's University Graduation Gown Sets",
        searchName: "St George's University",
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: "St George's University Photo",
        abbreviation: "St George's University",
        titleTag: "St George's University graduation gown and academic dress | from £39",
        metaTag:
            "Why rent when you can buy your St George's University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ",
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/st-marys-university': {
        name: "St Mary's University Graduation Gown Sets",
        searchName: "St Mary's University",
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: "St Mary's University Photo",
        abbreviation: "St Mary's University",
        titleTag: "St Mary's University graduation gown and academic dress | from £39",
        metaTag:
            "Why rent when you can buy your St Mary's University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ",
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/stirling-university': {
        name: 'Stirling University Graduation Gown Sets',
        searchName: 'Stirling University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Stirling University Photo',
        abbreviation: 'Stirling University',
        titleTag: 'Stirling University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Stirling University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/strathclyde-university': {
        name: 'Strathclyde University Graduation Gown Sets',
        searchName: 'Strathclyde University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Strathclyde University Photo',
        abbreviation: 'Strathclyde University',
        titleTag: 'Strathclyde University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Strathclyde University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/sunderland-university': {
        name: 'Sunderland University Graduation Gown Sets',
        searchName: 'Sunderland University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Sunderland University Photo',
        abbreviation: 'Sunderland University',
        titleTag: 'Sunderland University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Sunderland University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/surrey-university': {
        name: 'Surrey University Graduation Gown Sets',
        searchName: 'Surrey University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Surrey University Photo',
        abbreviation: 'Surrey University',
        titleTag: 'Surrey University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Surrey University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/sussex-university': {
        name: 'Sussex University Graduation Gown Sets',
        searchName: 'Sussex University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Sussex University Photo',
        abbreviation: 'Sussex University',
        titleTag: 'Sussex University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Sussex University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/swansea-university': {
        name: 'Swansea University Graduation Gown Sets',
        searchName: 'Swansea University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Swansea University Photo',
        abbreviation: 'Swansea University',
        titleTag: 'Swansea University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Swansea University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/trinity-laban-conservatoire': {
        name: 'Trinity Laban Conservatoire Graduation Gown Sets',
        searchName: 'Trinity Laban Conservatoire',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Trinity Laban Conservatoire Photo',
        abbreviation: 'Trinity Laban Conservatoire',
        titleTag: 'Trinity Laban Conservatoire graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Trinity Laban Conservatoire Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/ulster-university': {
        name: 'Ulster University Graduation Gown Sets',
        searchName: 'Ulster University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Ulster University Photo',
        abbreviation: 'Ulster University',
        titleTag: 'Ulster University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Ulster University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/university-college-birmingham': {
        name: 'University College Birmingham Graduation Gown Sets',
        searchName: 'University College Birmingham',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'University College Birmingham Photo',
        abbreviation: 'University College Birmingham',
        titleTag: 'University College Birmingham graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your University College Birmingham Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/university-for-the-creative-arts': {
        name: 'University for the Creative Arts Graduation Gown Sets',
        searchName: 'University for the Creative Arts',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'University for the Creative Arts Photo',
        abbreviation: 'University for the Creative Arts',
        titleTag: 'University for the Creative Arts graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your University for the Creative Arts Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/university-of-east-anglia': {
        name: 'University of East Anglia Graduation Gown Sets',
        searchName: 'University of East Anglia',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'University of East Anglia Photo',
        abbreviation: 'University of East Anglia',
        titleTag: 'University of East Anglia graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your University of East Anglia Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/university-of-estate-management': {
        name: 'University of Estate Management Graduation Gown Sets',
        searchName: 'University of Estate Management',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'University of Estate Management Photo',
        abbreviation: 'University of Estate Management',
        titleTag: 'University of Estate Management graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your University of Estate Management Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/university-of-law': {
        name: 'University of Law Graduation Gown Sets',
        searchName: 'University of Law',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'University of Law Photo',
        abbreviation: 'University of Law',
        titleTag: 'University of Law graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your University of Law Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/university-of-the-west-of-scotland': {
        name: 'University of the West of Scotland Graduation Gown Sets',
        searchName: 'University of the West of Scotland',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'University of the West of Scotland Photo',
        abbreviation: 'University of the West of Scotland',
        titleTag: 'University of the West of Scotland graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your University of the West of Scotland Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/university-of-wales': {
        name: 'University of Wales Graduation Gown Sets',
        searchName: 'University of Wales',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'University of Wales Photo',
        abbreviation: 'University of Wales',
        titleTag: 'University of Wales graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your University of Wales Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/university-of-wales-trinity-st-david': {
        name: 'University of Wales Trinity St David (UWTSD) Graduation Gown Sets',
        searchName: 'University of Wales Trinity St David (UWTSD)',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'University of Wales Trinity St David (UWTSD) Photo',
        abbreviation: 'University of Wales Trinity St David (UWTSD)',
        titleTag: 'University of Wales Trinity St David (UWTSD) graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your University of Wales Trinity St David (UWTSD) Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/warwick-university': {
        name: 'Warwick University Graduation Gown Sets',
        searchName: 'Warwick University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Warwick University Photo',
        abbreviation: 'Warwick University',
        titleTag: 'Warwick University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Warwick University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/warwickshire-college': {
        name: 'Warwickshire College Graduation Gown Sets',
        searchName: 'Warwickshire College',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Warwickshire College Photo',
        abbreviation: 'Warwickshire College',
        titleTag: 'Warwickshire College graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Warwickshire College Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/westminster-university': {
        name: 'University of Westminster Graduation Gown Sets',
        searchName: 'University of Westminster',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1637539164/UK%20Uni%20Campus%20Photos/westminster-2.jpg',
        altTag: 'University of Westminster Photo',
        abbreviation: 'University of Westminster',
        titleTag: 'University of Westminster graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your University of Westminster Gown from £39? Gowning Street excels at crafting premium quality graduation gowns for purchase',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Bachelor of Arts',
                path: '/graduation-gown/westminster-university/bachelor-of-arts',
                colors: ['#811331'],
            },
            {
                level: 'Bachelor',
                name: 'Bachelor of Science',
                path: '/graduation-gown/westminster-university/bachelor-of-science',
                colors: ['white'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/west-london-university': {
        name: 'University of West London Graduation Gown Sets',
        searchName: 'University of West London',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1637539163/UK%20Uni%20Campus%20Photos/westlon-2.jpg',
        altTag: 'University of West London Photo',
        abbreviation: 'University of West London',
        titleTag: 'University of West London graduation gown and academic dress | from £39',
        metaTag:
            'Why hire when you can purchase your University of West London Graduation Gown from £39? Impeccable stitching, top quality and fast delivery.',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '/graduation-gown/west-london-university/bachelor-degrees',
                colors: ['#004080', 'grey'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/west-of-england-university': {
        name: 'West of England University Graduation Gown Sets',
        searchName: 'West of England University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'West of England University Photo',
        abbreviation: 'West of England University',
        titleTag: 'West of England University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your West of England University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/wimbeldon-college-of-arts': {
        name: 'Wimbeldon College of Arts Graduation Gown Sets',
        searchName: 'Wimbeldon College of Arts',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Wimbeldon College of Arts Photo',
        abbreviation: 'Wimbeldon College of Arts',
        titleTag: 'Wimbeldon College of Arts graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Wimbeldon College of Arts Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/winchester-university': {
        name: 'Winchester University Graduation Gown Sets',
        searchName: 'Winchester University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Winchester University Photo',
        abbreviation: 'Winchester University',
        titleTag: 'Winchester University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Winchester University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/wolverhampton-university': {
        name: 'Wolverhampton University Graduation Gown Sets',
        searchName: 'Wolverhampton University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Wolverhampton University Photo',
        abbreviation: 'Wolverhampton University',
        titleTag: 'Wolverhampton University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Wolverhampton University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/worcester-university': {
        name: 'University of Worcester Graduation Gown Sets',
        searchName: 'University of Worcester',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1637539162/UK%20Uni%20Campus%20Photos/worcester-1.jpg',
        altTag: 'University of Worcester Photo',
        abbreviation: 'University of Worcester',
        titleTag: 'University of Worcester graduation gown and academic dress | from £39',
        metaTag:
            'Why not buy your Worcester Uni Gown for £45 instead of Renting? Gowning Street specialises in crafting premium quality graduation gowns for purchase',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Bachelor Degrees',
                path: '/graduation-gown/worcester-university/bachelor-degrees',
                colors: ['#00BFFF', '#D3D3D3'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/writtle-college': {
        name: 'Writtle College Graduation Gown Sets',
        searchName: 'Writtle College',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Writtle College Photo',
        abbreviation: 'Writtle College',
        titleTag: 'Writtle College graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your Writtle College Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Bachelor',
                name: 'Gown & Cap Set (Coming Soon)',
                path: '#',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/york-st-john-university': {
        name: 'York St John University Graduation Gown Sets',
        searchName: 'York St John University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'York St John University Photo',
        abbreviation: 'York St John University',
        titleTag: 'York St John University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your York St John University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/york-university': {
        name: 'York University Graduation Gown Sets',
        searchName: 'York University',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'York University Photo',
        abbreviation: 'York University',
        titleTag: 'York University graduation gown and academic dress | from £39',
        metaTag:
            'Why rent when you can buy your York University Gown from £39? Gowning Street has several sets available for both Bachelor and Masters degrees. ',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/architectural-association-school-of-architecture': {
        name: 'Architectural Association School of Architecture Graduation Gown Sets',
        searchName: 'Architectural Association School of Architecture',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Architectural Association School of Architecture Photo',
        abbreviation: 'AA School',
        titleTag: 'Architectural Association School of Architecture graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your Architectural Association School of Architecture graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/brighton-sussex-medical-school': {
        name: 'Brighton & Sussex Medical School Graduation Gown Sets',
        searchName: 'Brighton & Sussex Medical School | BSMS',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Brighton & Sussex Medical School Photo',
        abbreviation: 'BSMS',
        titleTag: 'Brighton & Sussex Medical School graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your Brighton & Sussex Medical School graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/buckinghamshire-new-university': {
        name: 'Buckinghamshire New University Graduation Gown Sets',
        searchName: 'Buckinghamshire New University | Bucks',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Buckinghamshire New University Photo',
        abbreviation: 'Bucks',
        titleTag: 'Buckinghamshire New University graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your Buckinghamshire New University graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/council-for-national-academic-awards': {
        name: 'Council for National Academic Awards Graduation Gown Sets',
        searchName: 'Council for National Academic Awards | CNAA',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Council for National Academic Awards Photo',
        abbreviation: 'CNAA',
        titleTag: 'Council for National Academic Awards graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your Council for National Academic Awards graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/de-montfort-university': {
        name: 'De Montfort University Graduation Gown Sets',
        searchName: 'De Montfort University | DMU',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'De Montfort University Photo',
        abbreviation: 'DMU',
        titleTag: 'De Montfort University graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your De Montfort University graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/dublin-city-university': {
        name: 'Dublin City University Graduation Gown Sets',
        searchName: 'Dublin City University | DCU',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Dublin City University Photo',
        abbreviation: 'DCU',
        titleTag: 'Dublin City University graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your Dublin City University graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/dublin-institute-of-technology': {
        name: 'Dublin Institute of Technology Graduation Gown Sets',
        searchName: 'Dublin Institute of Technology',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Dublin Institute of Technology Photo',
        abbreviation: 'TU Dublin',
        titleTag: 'Dublin Institute of Technology graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your Dublin Institute of Technology graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/university-of-glamorgan': {
        name: 'University of Glamorgan Graduation Gown Sets',
        searchName: 'University of Glamorgan',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'University of Glamorgan Photo',
        abbreviation: 'University of Glamorgan',
        titleTag: 'University of Glamorgan graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your University of Glamorgan graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/hull-york-medical-school': {
        name: 'Hull-York Medical School Graduation Gown Sets',
        searchName: 'Hull-York Medical School | HYMS',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Hull-York Medical School Photo',
        abbreviation: 'HYMS',
        titleTag: 'Hull-York Medical School graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your Hull-York Medical School graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/university-of-limerick': {
        name: 'University of Limerick Graduation Gown Sets',
        searchName: 'University of Limerick',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'University of Limerick Photo',
        abbreviation: 'UL',
        titleTag: 'University of Limerick graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your University of Limerick graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/university-of-humberside': {
        name: 'University of Humberside Graduation Gown Sets',
        searchName: 'University of Humberside',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'University of Humberside Photo',
        abbreviation: 'Humberside',
        titleTag: 'University of Humberside graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your University of Humberside graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/st-patricks-college-maynooth': {
        name: "St Patrick's College, Maynooth Graduation Gown Sets",
        searchName: "St Patrick's College, Maynooth",
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: "St Patrick's College, Maynooth Photo",
        abbreviation: "St Patrick's College",
        titleTag: "St Patrick's College, Maynooth graduation gown and academic dress | from £39",
        metaTag:
            'Why Hire? When you can buy your St Patrick\'s College, Maynooth graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/medway-school-of-pharmacy': {
        name: 'Medway School of Pharmacy Graduation Gown Sets',
        searchName: 'Medway School of Pharmacy',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Medway School of Pharmacy Photo',
        abbreviation: 'Medway',
        titleTag: 'Medway School of Pharmacy graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your Medway School of Pharmacy graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/university-college-plymouth': {
        name: 'University College, Plymouth Graduation Gown Sets',
        searchName: 'University College, Plymouth',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'University College, Plymouth Photo',
        abbreviation: 'University College, Plymouth',
        titleTag: 'University College, Plymouth graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your University College, Plymouth graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/royal-society-of-chemistry': {
        name: 'Royal Society of Chemistry Graduation Gown Sets',
        searchName: 'Royal Society of Chemistry',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Royal Society of Chemistry Photo',
        abbreviation: 'Royal Society of Chemistry',
        titleTag: 'Royal Society of Chemistry graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your Royal Society of Chemistry graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/royal-university-of-ireland': {
        name: 'Royal University of Ireland Graduation Gown Sets',
        searchName: 'Royal University of Ireland',
        photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
        altTag: 'Royal University of Ireland Photo',
        abbreviation: 'Royal University of Ireland',
        titleTag: 'Royal University of Ireland graduation gown and academic dress | from £39',
        metaTag:
            'Why Hire? When you can buy your Royal University of Ireland graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
        degrees: [
            {
                level: 'Diploma',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Diploma',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Premium Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set',
                colors: ['black'],
            },
            {
                level: 'Bachelor',
                name: 'Standard Gown & Cap Set',
                path: '/academic-dress/bachelor-gown-set-economy',
                colors: ['black'],
            },
            {
                level: 'Master',
                name: 'Gown & Cap Set',
                path: '/academic-dress/master-gown-set',
                colors: ['black'],
            },
        ],
        'graduations-ref': '',
        graduations: [
            {
                Date: 'TBD',
                Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
            },
            {
                Date: 'TBD',
                Subjects: ['Medicine', 'Medical Sciences'],
            },
        ],
    },

    '/graduation-gown/national-university-of-ireland': {
      name: 'National University of Ireland Graduation Gown Sets',
      searchName: 'National University of Ireland',
      photo: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg',
      altTag: 'National University of Ireland Photo',
      abbreviation: 'National University of Ireland',
      titleTag: 'National University of Ireland graduation gown and academic dress | from £39',
      metaTag:
          'Why Hire? When you can buy your National University of Ireland graduation gown for £45. Fast Delivery. Easy Exchange. Premium Quality',
      degrees: [
          {
              level: 'Bachelor',
              name: 'Premium Gown & Cap Set',
              path: '/academic-dress/bachelor-gown-set',
              colors: ['black'],
          },
          {
              level: 'Bachelor',
              name: 'Standard Gown & Cap Set',
              path: '/academic-dress/bachelor-gown-set-economy',
              colors: ['black'],
          },
          {
              level: 'Master',
              name: 'Gown & Cap Set',
              path: '/academic-dress/master-gown-set',
              colors: ['black'],
          },
      ],
      'graduations-ref': '',
      graduations: [
          {
              Date: 'TBD',
              Subjects: ['Science', 'Business & Economics', 'Arts', 'Engineering', 'Law'],
          },
          {
              Date: 'TBD',
              Subjects: ['Medicine', 'Medical Sciences'],
          },
      ],
  },

};

module.exports = universities;
