import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import { connect } from 'react-redux';
import { checkInventory } from './checkInventory';

import Qty from '../selections/qty';
import CapGown from '../../sources/gowncap';
import CustomButton from '../custom-items/button-loader';


const Totals = ({ items, deleteItem, setQty, addToCart }) => {
  let history = useHistory();

  const [error, setError] = useState(null)
  const [xhrLoaded, setXhrLoaded] = useState(false)
  const [ dataFetched, setDataFetched ] = useState(false);

  const deleteCartItem = (e) => {
    deleteItem(e.target.id);
  }

  const checkOut = (history) => {
    checkInventory(items, function (fails) {
      if (fails.length > 0) {
        setDataFetched(true)
        setError(fails)
      }
      else {
        history.push('/checkout');
      }
    })
  }

  const setCartQty = (value, id) => {
    setQty(Number(value), id);
  }

  const addItemsToCart = (name, gown, cap, hood, gift, hoodStyle, qty, price, productType, image) => {
    addToCart(name, gown, cap, hood, gift, hoodStyle, qty, price, productType, image);
  }

  const renderCartItems = () => {
    var allItems = items.map(function(element){
      var hoodOrStole = null;

      if(element.hoodStyle){
        if(element.hoodStyle.indexOf("Stole") === -1){
          hoodOrStole = "Hood: " + element.hoodStyle;
        }
        else {
          hoodOrStole = "Stole: " + element.hoodStyle;
        }
      }

      return(
        <div key={element.id} className="itemunit">
          <div className="cartimage">
            <img src={element.image} />
          </div>
          <div className="itemdetails">
            <div className="itemheader">
              <h3>{element.name}</h3>
              <div className="deleteitem">
                <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165141/Utility/delete_grey_18x18.png" id={element.id} onClick={deleteCartItem} />
              </div>
            </div>
            <div className="priceqty">
              <p className="price">£{element.price}</p>
              <Qty
                setQty={setCartQty}
                initialQty={element.qty}
                cartId={element.id}
                extrasqty={element.qty}
              />
            </div>
            <div className="subitems">
              <p>{CapGown[element.gown]}</p>
              <p>{CapGown[element.cap]}</p>
              <p>{hoodOrStole}</p>
            </div>
          </div>
        </div>
      )
    });

    return allItems;
  }

    let subTotal = 0;
    let discountFactor = 0;
    let eligibleItems = 0;

    items.forEach(function(element){
      subTotal += Number(element.qty) * Number(element.price);

      if(element.price >= 69){
        discountFactor += Number(element.qty);
        eligibleItems += Number(element.qty);
      }

    })

    if(discountFactor > 10){
      discountFactor = 10;
    }

    if(discountFactor === 1){
        discountFactor = 0;
    }

    var discount = discountFactor * eligibleItems;

    var total = subTotal - discount;

    var errors = null;

    if(error){
      var errors = error.map(function(error){
        return (
          <p key={error.code}>
            Sorry, we only have {error.maxQty} of {error.description}. Please choose a lower amount.
          </p>
        )
      });
    }



    return (
      <div className="cartitems">
        {renderCartItems(items, deleteCartItem, setCartQty)}
{/*        <div className="extras">
          <h2>Add extras for a complete graduation package!</h2>
          <hr></hr>
          <div className="extras-items">
            <div className="extras-img">
              <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165132/Regalia/graduation-puppy-furry-1.jpg" />
            </div>
            <div className="extras-details">
              <h3>Graduation Puppy - Small</h3>
              <p>£10</p>
              <button onClick={() => this.addItemsToCart("Graduation Puppy - Small", null, null, null, "PUP00002", null, 1, 19, "gift", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165132/Regalia/graduation-puppy-furry-1.jpg")}>Add to Cart</button>
            </div>
          </div>
          <div className="extras-items">
            <div className="extras-img">
              <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165131/Regalia/graduation-puppy-smooth-1.jpg" />
            </div>
            <div className="extras-details">
              <h3>Graduation Puppy - Large</h3>
              <p>£14</p>
              <button onClick={() => this.addItemsToCart("Graduation Puppy - Large", null, null, null, "PUP00001", null, 1, 25, "gift", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165131/Regalia/graduation-puppy-smooth-1.jpg")}>Add to Cart</button>
            </div>
          </div>
          <div className="extras-items">
            <div className="extras-img">
              <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165134/Regalia/graduation-teddy-bear-1.jpg" />
            </div>
            <div className="extras-details">
              <h3>Graduation Teddy Bear</h3>
              <p>£10</p>
              <button onClick={() => this.addItemsToCart("Graduation Teddy Bear", null, null, null, "TED00001", null, 1, 19, "gift", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165134/Regalia/graduation-teddy-bear-1.jpg")}>Add to Cart</button>
            </div>
          </div>
        </div>*/}
        <div className="totals">
          {/* <p>SubTotal : {subTotal}</p> */}
          {/* <p>Discount per Item : {discountFactor}</p>
          <p>Eligible Items : {eligibleItems}</p>
          <p>Total Discount : {discount}</p> */}
          <p style={{ fontSize: '18px', padding: '8px 0', borderTop: '0.5px solid grey'}}>
            Total : £{total}
          </p>
        </div>
        <CustomButton
          handleSubmit={() => checkOut(history)}
          content="Continue"
          xhrLoaded={xhrLoaded}
          dataFetched={dataFetched}
        />
        <div className="errors">
          {errors}
        </div>
      </div>
    )

}


const mapStateToProps = (state) => {
  return {
    items: state.shoppingCart
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setQty: (value, id) => {
      dispatch({
        type: 'UPDATE_QTY',
        qty: value,
        id: id
      })
    },
    deleteItem: (id) => {
      dispatch({
        type: 'REMOVE_FROM_CART',
        id: id
      })
    },
    addToCart:(name, gown, cap, hood, gift, hoodStyle, qty, price, productType, image) => {

      dispatch({
        type: "ADD_TO_CART",
        name: name,
        gown: gown,
        cap: cap,
        hood: hood,
        gift: gift,
        hoodStyle: hoodStyle,
        qty: qty,
        price: price,
        productType : productType,
        image : image
      })
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Totals);
