import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import Frames from '../sources/frames';

const BuyFrames = ({name}) => {

  let UniArray = [];

  for (var u in Frames) {
    UniArray.push({
      name: Frames[u].name.substring(0,Frames[u].name.indexOf("Certificate")-1),
      link: u
    });
  }

  var list = UniArray.sort(function(a,b){
    if (a.name < b.name) {return -1}
    return 0;
  }).map(function(uni){
    return (
      <div key={uni.name} className="unititle">
        <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Utility/uni_icon_grey_18x18.png" />
        <Link to={uni.link}>
          <p>{uni.name}</p>
        </Link>
      </div>
    )
  });


  return (
    <div className="shop-products">
      <Helmet
        title="Buy UK Certificate Frames Online and Save | From £28"
        meta={[
          {"name": "description", "content": "Certificate Frames made for UK University Testamurs. Frame your University Graduation Certificate"},
        ]}
      />
        <h1>Certificate Frames for UK Universities</h1>
        <div className="subheading">
          <h2>Hand-crafted premium quality certificate frames made to fit your testamur perfectly from £28</h2>
        </div>
        <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1561279573/Frames/2_Frames_Image.png" alt="University degree certificate frames" className="topimage"/>
        {list}
        <div className="products-desc">
          <h2>WHY PURCHASE YOUR GRADUATION CERTIFICATE FRAME FROM GOWNING STREET?</h2>
          <p>Our Design: Our graduation certificate frames are manufactured to fit your University testamur perfectly</p>
          <p>Premium Quality Craftsmanship: Our traditional certificate frames are beautifully handcrafted using pine wood</p>
          <p>Great Value: We design and manufacture our own certificate frames and sell directly to you. We do not use 3rd party resellers.
          </p>
          <h2>Why buy a degree frame in the first place?</h2>
          <p>Protect and preserve: Unfortunately, your University testamur can lose shape/colour over time The best way to preserve it is by placing it inside a degree certificate frame which will ensure it keeps its shape and colour over time</p>
          <p>Showcase your achievement: Your certificate frame is an ideal place for your home, office or bedroom and is an excellent reminder of your hardwork and success!</p>
          <p>A degree frame is the perfect gift: Have friends or family graduating soon? Look no further for the perfect gift!  Purchasing a certificate frame from Gowning Street is easy- All of our frames are designed precisely to fit each specific size of the University’s graduation certificate</p>
        </div>
      </div>
  )
};

export default BuyFrames;
