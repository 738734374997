import styled from 'styled-components';
import { typography, colors, sizes } from '../utils/theme'
import { mediaQueries } from '../utils/mediaQueries';

export const Compartment =  styled.div`
  margin: 0 auto;
  padding: 15px;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  ${props => ({ ...props.theme })};
`;

export const SectionContainer = styled.div`
  padding-top: ${sizes.sectionPaddingSize};
  text-align: center;
  ${mediaQueries.tablet`
    h1 {
      font-size: ${typography.alpha};
    }
  `};
`;

export const AlphaHeader = styled.h2`
  color:${colors.primary};
  font-size: ${typography.alpha};
  font-weight: 600;
  padding: ${typography.alpha};
  text-align: center;
`;

export const BetaHeader = styled.h3`
  color:${colors.primary};
  font-size: ${typography.beta};
  font-weight: 400;
  padding: ${typography.micro};
`;