const ShippingCalc = function(order,customer){

  const choiceFactor = {
    "standard" : {
      price: 0,
    },
    "express" : {
      price: 12
    }
  };

  let qty = 0;
  order.forEach(function(item){
    qty += item.qty;
  });

  let shippingoption = choiceFactor[customer.shipping];

  return {
    price : shippingoption.price,
    qtyFactor : qty,
    total : shippingoption.price,
  };

};

module.exports = ShippingCalc;