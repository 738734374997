'use strict';

import { combineReducers } from 'redux';
import shoppingCart from './shopping-cart';
import customer from './customer';

const Reducers = combineReducers({
  shoppingCart,
  customer
});

export default Reducers;