import React from 'react';
import styled from 'styled-components'
import { Column, Container } from '../../components/Layout';
import { AlphaHeader, BetaHeader } from '../../components/Section';
import { mediaQueries } from '../../utils/mediaQueries';

const detailsLeft = [
  {
    id: 1,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1553343173/Home%20Page/1-200px.png',
    header: 'Hoods',
    description: 'Meticulously selected to ensure hood colours match University specifications, looking rich and vibrant'
  },
  {
    id: 2,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1553343172/Home%20Page/2-200px.png',
    header: 'Gowns',
    description: 'Our material has been carefully selected to not only look great, but also ensure comfort throughout your graduation day'
  }
];

const detailsRight = [
   {
    id: 3,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1553343172/Home%20Page/3-200px.png',
    header: 'Caps',
    description: 'Covered in high quality polyester to a firm shape and comfort. Comes in a range of sizes to ensure optimum fit'
  },
  {
    id: 4,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1553343172/Home%20Page/4-200px.png',
    header: 'Craftsmanship',
    description: 'All of our regalia is crafted to perfection. Each piece of regalia is hand-stitched to ensure the finest quality'
  },
]

const TextBox = styled.div`
  padding: 0 40px;
  margin: 0 auto;
  max-width: 300px;
   ${mediaQueries.tablet`
    padding: 0px;
  `};
   ${mediaQueries.mobile`
    padding: 0;
  `};
`

const DetailsContainer = styled.div`
  display: flex;
  width: 80%;
  margin: 0 auto;
  ${mediaQueries.tablet`
    width: 90%;
  `};
  ${mediaQueries.mobile`
    display: block;
    width: 100%;
  `};

`

const SectionConainerWhite = styled.div`
  padding: 60px 15px;
  background-color: #FFFFFF;
  margin: 0 auto;
  ${mediaQueries.tablet`
    padding: 30px 15px 50px 15px;
  `};
`
const StyledStepRow = styled.div`
  display:flex;
  flex-flow: column wrap;
  width: 90%;
  margin: 0 auto;
  padding-left: 22px;
  padding-right: 22px;
   ${mediaQueries.tablet`
    padding: 0;
  `};
`

const Title = styled.div`
  min-height: 2rem;
  padding: 2px;
  display: inline-block;
  vertical-align: middle;
  hr {
    background-color: #DC572B;
    height: 2px;
    border: 0;
    display: block;
  }
`

const StepsContainer = styled.div`
  border-radius: .125rem;
  outline: 0;
  margin: 0 auto;
  text-align: center;
  display: flex;
  alignContent: center;
  alignItems: center;
  flex-direction: column;
  height: 230px;
  padding: 10px;
  ${mediaQueries.mobile`
    height: 220px;
  `};
`

const SetHeightParagraph = styled.div`

  p {
    line-height: 20px;
    margin-top: 5px;
    padding: 0 5px;
  }
`

const StepIcon = styled.div`
  img {
    height: 105px;
     ${mediaQueries.tablet`
    height: 80px;
    `};
  }
`

const HeroImage = styled.div`
   ${mediaQueries.mobile`
    display: none;
  `};
`

const Details = ({ detailsLeft, detailsRight }) => (
  <DetailsContainer>
  <StyledStepRow>
    {
      detailsLeft.map((el) => (
        <Column key={el.id}>
          <StepsContainer>
            <SetHeightParagraph>
              <StepIcon><img src={el.icon} /></StepIcon>
              <TextBox>
                <Title>
                  <BetaHeader>{ el.header }</BetaHeader>
                </Title>
                <p>{ el.description }</p>
              </TextBox>

            </SetHeightParagraph>
          </StepsContainer>
        </Column>
        )
      )
    }
  </StyledStepRow>
  <HeroImage>
    <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1553343172/Home%20Page/Jo-200px.png" />
  </HeroImage>
  <StyledStepRow>
    {
      detailsRight.map((el) => (
        <Column key={el.id}>
          <StepsContainer>
            <SetHeightParagraph>
              <StepIcon><img src={el.icon} /></StepIcon>
              <TextBox>
                <Title>
                  <BetaHeader>{ el.header }</BetaHeader>
                </Title>
                <p>{ el.description }</p>
              </TextBox>
            </SetHeightParagraph>
          </StepsContainer>
        </Column>
        )
      )
    }
  </StyledStepRow>
  </DetailsContainer>
)



const QualityGuarantee = () => {
  return (
    <SectionConainerWhite>
      <AlphaHeader style={{ marginBottom: '20px'}}>Quality Guarantee</AlphaHeader>
      <Details detailsLeft={detailsLeft} detailsRight={detailsRight} />
    </SectionConainerWhite>
  )

}

export default QualityGuarantee;




