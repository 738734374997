const prices = {
  BACHELOR_GOWN_CAP_HOOD: 79,
  BACHELOR_GOWN_CAP: 59,
  BACHELOR_GOWN_CAP_ECONOMY: 45,
  BACHELOR_GOWN: 49,
  BACHELOR_GOWN_ECONOMY: 39,

  MASTER_GOWN_CAP_HOOD: 79,
  MASTER_GOWN_CAP: 59,
  MASTER_GOWN: 49,

  HOOD: 29,

  CAP: 24,

  // Gifts
  TEDDY_SMALL: 19,
  PUPPY_SMALL: 19,
  PUPPY_LARGE: 25,

  // Frames
  MODERN_FRAME: 49,
  TRADITIONAL_FRAME: 54,

  //Shipping
  EXPRESS_SHIPPING: 7,


  // Legalwear
  BARRISTER_GOWN: 199,
  ADMISSIONS_GOWN: 199,
  BARRISTER_WIG: 699,
  BENCHER_WIG: 799,
  // Shipping

  // Misc.
  GARMENT_BAG: 14
}

module.exports = prices;