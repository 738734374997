import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

const menuLinks = {
  shop: [
    {link: '/academic-dress', name: 'All Regalia'},
    {link: '/graduation-gown', name: 'University Sets'},
    {link: '/academic-dress/bachelor-gown-set', name: 'Bachelor Gown Sets'},
    // {link: '/academic-dress/bachelor-gown-set-economy', name: 'Standard Bachelor Gown Sets'},
    {link: '/academic-dress/bachelor-gown', name: 'Bachelor Gown'},
    // {link: '/academic-dress/bachelor-gown-economy', name: 'Standard Bachelor Gown'},
    {link: '/academic-dress/master-gown-set', name: 'Master Gown Sets'},
    {link: '/academic-dress/master-gown', name: 'Master Gown'},
    {link: '/academic-dress/graduation-cap', name: 'Graduation Caps'},
    {link: '/academic-dress/graduation-hoods', name: 'Graduation Hoods'},
    {link: '/certificate-frame', name: 'Certificate Frames'},
    {link: '/graduation-gift', name: 'Graduation Gifts'},
    {link: '/legal-wear', name: 'Legal Wear'},
    {link: '/wholesale', name: 'Wholesale'},
  ],
  uniSets: [
    {link: '/graduation-gown', name: 'All Universities (A-Z)'},
    {link: '/wholesale', name: 'Wholesale / Bulk'},
    {link: '/graduation-gown/bournemouth-university', name: 'Bournemouth'},
    {link: '/graduation-gown/canterbury-christ-church-university', name: 'CCC'},
    {link: '/graduation-gown/derby-university', name: 'Derby'},
    {link: '/graduation-gown/keele-university', name: 'Keele'},
    {link: '/graduation-gown/leeds-beckett-university', name: 'Leeds Beckett'},
    {link: '/graduation-gown/lincoln-university', name: 'Lincoln'},
    {link: '/graduation-gown/london-metropolitan-university', name: 'LMET'},
    {link: '/graduation-gown/teesside-university', name: 'Teesside'},
    {link: '/graduation-gown/university-of-the-arts-london', name: 'UAL'},
    {link: '/graduation-gown/worcester-university', name: 'Worcester'}
  ],
  frames: [
    {link: '/certificate-frame', name: 'All Universities (A-Z)'},
    {link: '/certificate-frame/bournemouth-university', name: 'Bournemouth Uni'},
    {link: '/certificate-frame/cardiff-university-material', name: 'Cardiff Uni Material'},
    {link: '/certificate-frame/edge-hill-university', name: 'Edge Hill Uni'},
    {link: '/certificate-frame/edinburgh-napier-university', name: 'Edinburgh Napier Uni'},
    {link: '/certificate-frame/open-university', name: 'Open Uni'},
    {link: '/certificate-frame/oxford-brookes-university', name: 'Oxford Brookes Uni'},
    {link: '/certificate-frame/plymouth-university', name: 'Plymouth Uni'},
  ],
  about: [
    {link: '/about', name: 'About Us'},
    {link: '/save-with-friends', name: 'Save With Friends'},
    {link: '/shipping', name: 'Shipping Estimates'},
    {link: '/pricebeat', name: 'Price Beat'},
    {link: '/faq', name: 'FAQ'},
    {link: '/contact', name: 'Contact Us'},
  ]
}

const Submenu = ({ menuTitle, handleLeave }) => {
  return (
    <div className="nav__submenu" key={menuTitle} onClick={handleLeave}>
      {
        menuLinks[menuTitle].map(({ link, name }) => (
          <Link to={link} className="nav__submenu-item" key={link}>
            { name }
          </Link>
        ))
      }
    </div>
  )
}

export default Submenu;
