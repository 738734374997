import React from 'react';

const ShippingTable = () => (
  <div className="shipping-table">
    <p className="label">Delivery time (business days):</p>
    <table>
      <thead>
        <tr>
          <th>England Metro</th>
          <th>England Rural</th>
          <th>Other UK</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>2-3 days</td>
          <td>4-5 days</td>
          <td>4-5 days</td>
        </tr>
      </tbody>
    </table>
    <div className="description">
      <p className="label">Dispatch time:</p>
      <p>Please also allow up to <strong>3</strong> business days for dispatch from our warehouse. Times shown above are in addition to dispatch times.</p>
      <p>Our deliveries are provided through APC. Please note that these are the delivery times as suggested by APC, and may vary during peak periods. We strongly suggest leaving a buffer prior to your graduation. Gowning Street cannot guarantee the above times and will not be held responsible for delays.</p>
      <p>
        For more information on post delivery estimates please visit
        <a href="https://apc-overnight.com/" target="_blank"> APC's website</a>
      </p>
    </div>
  </div>
)

export default ShippingTable;