'use strict'

require('../scss/styles.scss');

import React from 'react';
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch, } from "react-router-dom";
import { compose, createStore } from 'redux';
import { Provider } from 'react-redux';
import persistState from 'redux-localstorage';
import {
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga4';

import Header from './components/header';
import Footer from './components/footer';
import ScrollToTop from './components/ScrollToTop';
import About from './components/about';
import Buy from './components/buy';
import BuyFrames from './components/buy-frames';
import BuyGifts from './components/buy-gifts';
import BuyLegalwear from './components/buy-legalwear';
import AllUnis from './components/buy-uni';
import Home from './components/homepage/home';
import Cart from './components/cart/cart';
import Checkout from './components/cart/checkout';
import PayPal from './components/paypalConfirmation';
import HoodOnly from './components/hoodonly';
import Thankyou from './components/thankyou';
import Friendsdeal from './components/friendsdeal';
import Contact from './components/info/contact';
import Regulations from './components/info/regulations';
import FAQ from './components/info/faq';
import Pricebeat from './components/info/pricebeat';
import Privacy from './components/info/privacy';
import Terms from './components/info/terms';
import ShippingInfo from './components/info/shipping';
import CheckOrder from './components/checkOrder';
import Admin from './components/admin/admin';
import Wholesale from './components/info/wholesale';
import Product from './components/shopping-routes/product';
import Frame from './components/shopping-routes/frame';
import NotFound from './components/404';
import Reducers from './reducers/index';

//Google Analytics
ReactGA.initialize('G-0MFKEDGWSF');

const createPersistentStore = compose(
  persistState()
)(createStore);

const store = createPersistentStore(Reducers);

function logPageView() {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "pageview" });
  window.scrollTo(0, 0);
  return null;
}

const Stripe_Pk = loadStripe(process.env.STRIPE_PK);

export const App = () => (
    <div>
      <Header />
        <Route path="/" component={logPageView} />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/academic-dress" component={Buy} />
        <Route exact path="/graduation-gift" component={BuyGifts} />
        <Route exact path="/legal-wear" component={BuyLegalwear} />
        <Route path="/legal-wear/:uniset" component={Product} />
        <Route exact path="/certificate-frame" component={BuyFrames} />
        <Route exact path="/academic-dress/bachelor-gown-economy" component={Product} />
        <Route exact path="/academic-dress/graduation-hoods" component={HoodOnly} />
        <Route path="/academic-dress/:uniset" component={Product} />
        <Route path="/certificate-frame/:uni" component={Frame} />
        <Route path="/about" component={About} />
        <Route exact path="/cart">
          <Cart />
        </Route>
        <Route exact path="/checkout" component={Checkout} />
        <Route path="/paypalconf*" component={PayPal} />
        <Route path="/admin" component={Admin} />
        <Route path="/thankyou" component={Thankyou} />
        <Route path="/checkorder" component={CheckOrder} />
        <Route path="/save-with-friends" component={Friendsdeal} />
        <Route path="/wholesale" component={Wholesale}></Route>
        <Route path="/faq" component={FAQ} />
        <Route path="/contact" component={Contact} />
        <Route path="/regalia-regulations/:uni" component={Regulations} />
        <Route path="/pricebeat" component={Pricebeat} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/terms" component={Terms} />
        <Route path="/shipping" component={ShippingInfo} />

        <Route path="/graduation-gown">
          <AllUnis />
        </Route>

        <Route component={NotFound} />
      </Switch>
      <CookieConsent
        buttonText="I agree"
      >
        By continuing to browse this site, you are agreeing to the use of cookies, whose purpose is to provide web analytics of visitor traffic and essential functionality.
      </CookieConsent>
      <Footer />
    </div>
)

ReactDOM.hydrate(
  <Provider store={store}>
      <Elements stripe={Stripe_Pk}>
        <BrowserRouter>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </BrowserRouter>
      </Elements>
  </Provider>,
  document.getElementById('content')
);
