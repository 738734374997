import React from 'react';
import Submenu from './Submenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showShop: false,
      showUniSets: false,
      showFrames: false,
      showAbout: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick({ toShow }) {
    this.setState({ [toShow]: !this.state[toShow]})
  };


  handleLeave({ toShow }) {
     this.setState({ [toShow]: !this.state[toShow]})
  }

  render() {
    return (
      <nav className="navbar">
        <div className="nav__menu-item" onClick={() => this.handleClick({ toShow: 'showShop' })}>
          <span>
            Shop
            { this.state.showShop
              ? <FontAwesomeIcon icon={faChevronDown} className="chevron" />
              : <FontAwesomeIcon icon={faChevronUp} className="chevron"/>
            }
          </span>
          <div className="submenu-container">
            { this.state.showShop && <Submenu menuTitle="shop" handleLeave={() => this.handleLeave({ toShow: 'showShop' })}/> }
          </div>
        </div>
        <div className="nav__menu-item" onClick={() => this.handleClick({ toShow: 'showUniSets' })}>
          <span>
            Uni Sets
            { this.state.showUniSets
              ? <FontAwesomeIcon icon={faChevronDown} className="chevron" />
              : <FontAwesomeIcon icon={faChevronUp} className="chevron"/>
            }
          </span>
          <div className="submenu-container">
            { this.state.showUniSets && <Submenu menuTitle="uniSets" handleLeave={() => this.handleLeave({ toShow: 'showUniSets' })}/>}
          </div>
        </div>
        <div className="nav__menu-item" onClick={() => this.handleClick({ toShow: 'showFrames' })}>
          <span>
            Frames
            { this.state.showFrames
              ? <FontAwesomeIcon icon={faChevronDown} className="chevron" />
              : <FontAwesomeIcon icon={faChevronUp} className="chevron"/>
            }
          </span>
          <div className="submenu-container">
            { this.state.showFrames && <Submenu menuTitle="frames" handleLeave={() => this.handleLeave({ toShow: 'showFrames' })}/>}
          </div>
        </div>
        <div className="nav__menu-item" onClick={() => this.handleClick({ toShow: 'showAbout' })}>
          <span>
            About
            { this.state.showAbout
              ? <FontAwesomeIcon icon={faChevronDown} className="chevron" />
              : <FontAwesomeIcon icon={faChevronUp} className="chevron"/>
            }
          </span>
          <div className="submenu-container">
            { this.state.showAbout && <Submenu menuTitle="about" handleLeave={() => this.handleLeave({ toShow: 'showAbout' })} />}
          </div>
        </div>

      </nav>
    )
  }
}

export default Menu;