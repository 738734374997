import React from 'react';
import Helmet from 'react-helmet';

const PriceBeat = () => (
  <div className="pricebeat">
    <Helmet
      title="Price Beat Guarantee | Gowning Street"
      meta={[
        {"name": "description", "content": "Found a comparable product at a lower price? Gowning Street will beat it by 10%!"},
      ]}
    />
    <div className="pricebeat-contents">
      <h1>Price Beat Guarantee</h1>
      <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165137/Placement%20Photos/graduate-wearing-gown-on-park-bench.png" alt="Graduate sitting on park bench wearing graduation gown"/>
      <p>Gowning street is dedicated to providing the highest quality academic regalia at the best prices</p>
      <p>If you find a comparable product at a lower price, we will beat it by 10%</p>
      <h2>What we can price beat</h2>
      <p>We can price beat graduation regalia that is of equal quality, currently in stock and listed either online or at a retail store</p>
      <p>We cannot beat prices that are at liquidation, special sale, wholesale or bulk rates</p>
      <p>Contact us at</p>
      <p className="email">support@gowningstreet.co.uk</p>
    </div>
  </div>
)

export default PriceBeat;