import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import DrawerContent from './homepage/drawercontent';

import Menu from './Menu';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open : false
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.toCart = this.toCart.bind(this);
  }

  componentDidMount() {
    var items =  this.props.order;
    var customer = this.props.customer;

    var context = this;

    if(items.length > 0){
      var xhttp = new XMLHttpRequest();

      xhttp.addEventListener('load', function(){
        if (JSON.parse(this.response).success === false){

          context.props.dropCart();
          context.props.dropCustomer();

          window.location = '/';
        }
      });
      xhttp.open("POST", "/api/checkPrice");
      xhttp.setRequestHeader("Content-Type", "application/json");
      xhttp.send(JSON.stringify({
        items : items,
        customer : customer
      }));
    }
  }

  handleToggle() {
    this.setState({open: !this.state.open});
  }

  handleClose() {
    this.setState({open: false});
  }

  toCart() {
    this.props.history.push("/cart");
  }

  render() {
    let itemsInCart = 0;

    this.props.order.forEach(function(element){
      itemsInCart += element.qty;
    });

    return (
      <div className="header">

        <div className="banner">
          <div id="flags">
            <a><img src="https://res.cloudinary.com/gowningstreet/image/upload/v1588678348/Utility/United_Kingdom_Flag.png"/></a>
            <a target="blank" href="https://www.gowningstreet.com.au"><img src="https://res.cloudinary.com/gowningstreet/image/upload/v1588678348/Utility/Australia_Flag.png"/></a>
            <a target="blank" href="https://www.graduationgowns.co.nz/"><img src="https://res.cloudinary.com/gowningstreet/image/upload/v1588678348/Utility/New_Zealand_Flag.png"/></a>
          </div>
          <p>We are Open! Free UK shipping - Limited Time Only</p>
        </div>

        <div className="headertop">
          <img
            onClick={this.handleToggle}
            src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165140/Utility/menu_grey_24x24.png"
            className="nav-icon"
          />
          <Link to="/">
            <img
              src="https://res.cloudinary.com/gowningstreet/image/upload/v1550922414/Utility/Gowningstreet_logo.jpg"
              className="logo"
            />
          </Link>
          <div className="cart-and-items" onClick={this.toCart}>
            <img
              src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165120/Utility/shopping_cart_grey_24x24.png"
              className="cart-icon"
            />
            <p className="cart-items">
             {itemsInCart}
            </p>
          </div>
        </div>
        <Menu />
        <Drawer
          anchor="left"
          open={this.state.open}
          onClose={(open) => this.setState({ open: !open })}
          className="drawer"
          width={280}
        >
          <DrawerContent
            close={this.handleClose}
          />
        </Drawer>
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customer: state.customer,
    order: state.shoppingCart
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateState: (field, value) => {
      dispatch({
        type: 'UPDATE_CUSTOMER',
        field : field,
        value : value
      })
    },
    dropCart: () => {
      dispatch({
        type: 'EMPTY_CART'
      })
    },
    dropCustomer: () => {
      dispatch({
        type: 'DROP_CUSTOMER'
      })
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Header));
