import React from 'react';
import { withFormsy } from 'formsy-react';

const CustomDropdown = (props) => {
  const changeValue = (event) => {
    props.handleChange(event);
  }
  const className = props.required ? 'required' : props.validationError ? 'error' : null;
  const errorMessage = props.validationError;

  return (
    <div className={className}>
      <div className="block-form">
        <p>How did you hear about us?</p>
        <select
          value={props.value}
          onChange={changeValue}
        >
          <option value=""></option>
          <option value="Google Search">Google Search</option>
          <option value="Google Shopping">Google Shopping</option>
          <option value="Word of Mouth">Word of Mouth</option>
          <option value="Facebook">Facebook</option>
        </select>
      </div>
      <span>{errorMessage}</span>
    </div>
  )

}


export default withFormsy(CustomDropdown);


