import React, { Component } from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';

import CustomInput from './customInput';
import CustomDropdown from './customDropdown';

class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    const { customer } = props;
    this.state = {
      firstname : customer.firstname,
      lastname : customer.lastname,
      phone : customer.phone,
      email : customer.email,
      address_line1 : customer.address_line1,
      address_line2 : customer.address_line2,
      city : customer.city,
      // state : customer.state,
      postcode : customer.postcode,
      referral : customer.referral,
      comments : "",
      canSubmit : false
    };
    this.disableButton = this.disableButton.bind(this);
    this.enableButton = this.enableButton.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  enableButton()  {
    this.setState({
      canSubmit: true
    });
  }
  disableButton() {
    this.setState({
      canSubmit: false
    });
  }
  onSubmit(event) {
    this.props.handleSubmit("confirmation");
  }
  handleChange(field, event) {
    var nextState = {};
    nextState[field] = String(event.target.value);

    this.setState(nextState);

    this.props.updateState(field, String(event.target.value));
  }

  render() {
    return (
      <div className="form-page">
        <Formsy
          onValidSubmit={this.onSubmit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
          className="customerform"
        >
          <h2>Your Details</h2>
          <CustomInput
            name="firstname"
            placeholder="First name*"
            handleChange={this.handleChange.bind(this, 'firstname')}
            value={this.state.firstname}
            validations="maxLength:30"
            validationError="Max 30 Characters"
            required
          />
          <CustomInput
            name="lastname"
            placeholder="Last name*"
            value={this.state.lastname}
            validations="maxLength:30"
            validationError="Max 30 Characters"
            handleChange={this.handleChange.bind(this, 'lastname')}
            required
          />
          <CustomInput
            name="phone"
            value={this.state.phone}
            placeholder="Phone number*"
            validations="maxLength:20"
            validationError="Max 20 Characters"
            handleChange={this.handleChange.bind(this, 'phone')}
            required
          />
          <CustomInput
            name="email"
            validations="isEmail"
            placeholder="Email*"
            validationError="This is not a valid email"
            handleChange={this.handleChange.bind(this, 'email')}
            value={this.state.email}
            required
          />
          <CustomDropdown
            name="referral"
            placeholder="How did you hear about us?*"
            value={this.state.referral}
            handleChange={this.handleChange.bind(this, 'referral')}
            className="last"
          />
          <h2 className="break">Shipping Details</h2>
          <CustomInput
            name="address_line1"
            placeholder="Address Line 1*"
            value={this.state.address_line1}
            validations="maxLength:30"
            validationError="Max 30 Characters"
            handleChange={this.handleChange.bind(this, 'address_line1')}
            required
          />
          <CustomInput
            name="address_line2"
            placeholder="Address Line 2 (Optional)"
            value={this.state.address_line2}
            validations="maxLength:30"
            validationError="Max 30 Characters"
            handleChange={this.handleChange.bind(this, 'address_line2')}
          />
          <CustomInput
            name="city"
            placeholder="City/Town*"
            value={this.state.city}
            validations="maxLength:30"
            validationError="Max 30 Characters"
            handleChange={this.handleChange.bind(this, 'city')}
            required
          />
          <CustomInput
            name="postcode"
            placeholder="Postcode*"
            value={this.state.postcode}
            validations="maxLength:10"
            validationError="Please enter a valid postcode"
            handleChange={this.handleChange.bind(this, 'postcode')}
            required
          />
          {/* <CustomInput
            name="comments"
            placeholder="Comments (Optional)"
            validations="maxLength:500"
            validationError="Max 500 Characters"
            value={this.state.comments}
            handleChange={this.handleChange.bind(this, 'comments')}
          /> */}
          {!this.state.canSubmit ? (
            <div className="errors">
              <p className="error">Please complete all required fields above</p>
              <p className="error">*Denotes required field</p>
            </div>
            )
           : null}
          <button type="submit" disabled={!this.state.canSubmit}>Continue</button>
        </Formsy>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customer: state.customer
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateState: (field, value) => {
      dispatch({
        type: 'UPDATE_CUSTOMER',
        field : field,
        value : value
      })
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerDetails);
