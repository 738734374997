import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Route, Switch, useHistory, Link } from "react-router-dom";
import { connect } from 'react-redux';

import Frames from '../../sources/frames';
import { frameStyles, frameStylesOther, frameColours } from'../../sources/frameOptions';
import Qty from '../selections/qty';

import FrameSelection from '../selections/frameSelection';
import FrameDescription from '../selections/frameDescription';

import { checkInventory } from '../cart/checkInventory'
import Popup from '../selections/popup';

class FrameParent extends Component {

  constructor(props) {
    super(props);
    const path = `/certificate-frame/${props.match.params.uni}`
    let altTag = "Graduation Certificate Frame Display Image";
    let image = 'https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg'

    let frameStyle = 'FRA00001'
    let frameColour = 'MAT00001'

    if (Frames[path].frameSize !== "A4") {
      frameStyle = 'FRA00011';
    }

    if(Frames[path].photos){
      altTag = Frames[path].altTags[5];
      const photo = Frames[path].photos[5]
      image = photo.url
      frameStyle = photo.style
      frameColour = photo.colour
    }


    this.state = {
      image,
      path,
      frameStyle,
      frameColour,
      frameSize: Frames[path].frameSize,
      qty: 1,
      price: 28,
      popup : false,
      name: Frames[path].name,
      popupname: Frames[path].name,
      error: false,
      mainAlt : altTag,
    };

    this.setPrimary = this.setPrimary.bind(this);
    this.setQty = this.setQty.bind(this);
    this.setSelection = this.setSelection.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
  const { location: { pathname } } = this.props;
  if (prevState.path !== pathname){
      this.setState({
        path: pathname,
        name:  Frames[pathname].name,
        frameSize: Frames[pathname].frameSize,
        frameStyle: Frames[pathname].photos[5].style,
        price: 28,
        error: false
      })
    }
  }

  setPrimary(event) {
    this.setState({
      image : event.target.id,
      mainAlt : event.target.alt
    });
  }

  setQty(qty) {
    this.setState({
      qty : qty
    })
  }

  setSelection(type, value) {
    this.setState({ [type]: value })
    if (type === 'frameStyle') {
      if (value === 'FRA00002' || value === 'FRA00012') {
        this.setState({ price: 30 })
      } else {
        this.setState({ price: 28 })
      }
    }
  }

  handleClick() {

    const frameNames = {
      "FRA00001" : " - Modern",
      "FRA00002" : " - Pine Wood",
      "FRA00011" : " - Modern",
      "FRA00012" : " - Pine Wood",
    }

    const { frameStyle, frameColour, qty, price, name } = this.state;
    let toCheck = [];
    toCheck.push({ code: frameStyle, qty });
    toCheck.push({ code: frameColour, qty });

    const that = this;
    checkInventory(toCheck, function(fails){

      if(fails.length === 0){

        var name = that.state.name + frameNames[that.state.frameStyle]

        const imagePath = that.frameUrl ? that.frameUrl.getAttribute('src') : null

        that.props.addToCart(name, null, null, frameStyle, frameColour, null, qty, price, "frame", imagePath)

        that.setState({ popup: true, popupname: name, error: false })
      }
      else {
        that.setState({
          error : fails,
        })
      }
    })


  }

  hidePopup() {
    this.setState({
      error: false,
      popup : false
    })
  }

  renderImage(frameObj) {
    let foundIndex = 0;

      const photo = frameObj.photos.find((photo, index) => {
        if (photo.style === this.state.frameStyle && photo.colour === this.state.frameColour) {
          foundIndex = index
          return true
        }
      })
      const alt = frameObj.altTags[foundIndex]

      return (
        <img
          src={photo.url}
          alt={alt}
          id="frame-main"
          ref={(img) => {this.frameUrl = img} }
        />
      )
  }

  toCart() {
    let history = useHistory();
    history.push("/cart");
  }

  render() {
    const { path } = this.state;

    var that = this;

    var images = Frames[path].photos;
    var altTags = Frames[path].altTags;
    const uniShortName = Frames[path].name.split('Certificate')[0]

    var framesizing = frameStyles;

    if (this.state.frameSize !== "A4") {
      framesizing = frameStylesOther;
    }

    var display = images.map(function(element, index){

      if(!altTags){
        return (
          <img
            key={index}
            src={element}
            alt="Graduation Certificate Frames Display Image"
            className="image-mini"
            key={element}
            id={element}
            onClick={that.setPrimary}
          />
        )
      }
      else {
        return (
          <img
            key={index}
            src={element}
            alt={altTags[index]}
            className="image-mini"
            key={element}
            id={element}
            onClick={that.setPrimary}
          />
        )
      }
    })

    let errors = ''
    if(this.state.error){
      errors = this.state.error.map(function(error){
        if (error.maxQty === 0) {
          return (
            <p key={error.description}>
              Sorry, we are currently out of stock of {error.description}.
            </p>
          )
        }
        else {
          return (
            <p key={error.description}>
              Sorry, we only have {error.maxQty} of {error.description}. Please choose a lower amount.
            </p>
          )
        }
      });
    }

    var frameObj = Frames[path]

    var isFrames = false;

    if (window.location.pathname.indexOf("frames") !== -1){
      isFrames = true;
    }

    var titleTag = Frames[path].name + " | " + "University Certificate Frames";

    if (isFrames){
      titleTag = "Gowning Street Certificate Frames";
    }

    if(Frames[path].titleTag){
      titleTag = Frames[path].titleTag;
    }

    var metaTag = "Gowning Street provides quality, hand crafted graduation gown sets for UK University students";

    if(Frames[path].metaTag){
      metaTag = Frames[path].metaTag;
    }

    return (
       <div className="product-set">
        <Helmet
          title={titleTag}
          meta={[
            {"name": "description", "content": metaTag},
          ]}
        />
        <div className="frame-selection-container">
          <div className="image-set-noflex">
            <h1>{Frames[path].name}</h1>
            <h2 className="price">£{this.state.price} - <Link to="/contact">Contact Us</Link> to Purchase</h2> 
            { this.renderImage(frameObj) }
          </div>
          <div>
            <FrameSelection
              title="Frame style:"
              options={framesizing}
              type="frameStyle"
              setSelection={this.setSelection}
              chosen={this.state.frameStyle}
            />
            <FrameSelection
              title="Matte color:"
              options={frameColours}
              type="frameColour"
              setSelection={this.setSelection}
              chosen={this.state.frameColour}
            />
            <div className="qty-display">
              <p>Qty: </p>
              <Qty setQty={this.setQty} />
            </div>
            <div className="btn-group">
              <button onClick={this.handleClick} type="button" >Add to Cart</button>
            </div>
            <div className="errors">
              {errors}
            </div>
            {this.state.popup === true ?
             <Popup
               price={this.state.price}
               qty={this.state.qty}
               hidePopup={this.hidePopup}
               name={this.state.popupname}
             /> : null}
          </div>
        </div>

        <div className="description-container">
          <FrameDescription path={path} uniShortName={uniShortName}/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.shoppingCart
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteItem: (id) => {
      dispatch({
        type: 'REMOVE_FROM_CART',
        id: id
      })
    },
    addToCart:(name, gown, cap, hood, gift, hoodStyle, qty, price, productType, image) => {
      dispatch({
        type: "ADD_TO_CART",
        name: name,
        gown: gown,
        cap: cap,
        hood: hood,
        gift: gift,
        hoodStyle: hoodStyle,
        qty: qty,
        price: price,
        productType : productType,
        image : image,
      })
    }
  }
}

const UniFrames = connect(
  mapStateToProps,
  mapDispatchToProps
)(FrameParent);

export default UniFrames;
