module.exports = {
  "GOW00001" : "Economy Bachelor Style Gown - XS",
  "GOW00002" : "Economy Bachelor Style Gown - S",
  "GOW00003" : "Economy Bachelor Style Gown - M",
  "GOW00004" : "Economy Bachelor Style Gown - L",
  "GOW00005" : "Economy Bachelor Style Gown - XL",

  "GOW10001" : "Bachelor Style Gown - XS",
  "GOW10002" : "Bachelor Style Gown - S",
  "GOW10003" : "Bachelor Style Gown - M",
  "GOW10004" : "Bachelor Style Gown - L",
  "GOW10005" : "Bachelor Style Gown - XL",

  "GOW00011" : "Masters Style Gown - XS",
  "GOW00012" : "Masters Style Gown - S",
  "GOW00013" : "Masters Style Gown - M",
  "GOW00014" : "Masters Style Gown - L",
  "GOW00015" : "Masters Style Gown - XL",

  "HAT00001" : "Cap - S",
  "HAT00002" : "Cap - M",
  "HAT00003" : "Cap - L",
  "HAT00004" : "Cap - XL",
};